import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get loggedin admin profile info
export async function getAdminProfileInfo(){
    return await AxiosClone.get(`${api_url}/admin/auth/profile`);
}

//edit loggedin admin profile info
export async function editAdminProfileInfo(data){
    return await AxiosClone.post(`${api_url}/admin/auth/profile` , data);
}

//edit loggedin admin profile info
export async function editAdminSecurityInfo(data){
    return await AxiosClone.post(`${api_url}/admin/auth/profile/change_password` , data);
}