import React, { useContext, useEffect, useRef, useState } from 'react';
import TitleContext from '../../../../TitleContext';
import "../../../News/News.scss";
import uploadImage from "../../../../assets/icons/uploadimage.svg";
/* import deleteIcon from "../../../../assets/icons/deleteIcon.svg"; */
import { useLocation, useNavigate, useParams } from 'react-router';
import { getSlider } from '../../../../Apis/homeApi';
import { api_basename, api_host, api_url } from '../../../../Configuration/Config';
import formModel from './formModel';
import { setSliderData } from './formHandler';
import Tooltip from '@material-ui/core/Tooltip';
import { filterLanguage } from '../../../../utilities/filterLang';
import removeIcon from "../../../../assets/icons/removeIcon.svg";
import axios from 'axios';

export default function Form(props) {
    const { pathname } = useLocation();
    let {changeTitle} = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const {id} = useParams();
    let sliderImageRef = useRef();
    let [sliderImage , setSliderImage] = useState('');
    let navigate = useNavigate();
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(()=>{
        changeTitle(`${id ? `edit` : `add`} slider`);
        // eslint-disable-next-line
    } , []);

    useEffect(() => {
        if (id) {
            getSlider(id).then((res) => {
                let x = res.data.data;
                setSliderImage(`${api_host}/${x.image?.image}`);
                let slide = {...x}
                slide.image = x?.image?.id
                setModel(slide);
            })
        }else{
            setModel({
                locales: [],
                image: ""
            })
        }
    }, [id]);
    let [progress, setProgress] = useState(0)
    /* upload images */
    const token = `Bearer ` + localStorage.getItem('rh_Admin_Token');
    function addImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }

        axios.post(`${api_url}/admin/upload`, formData, config).then((res) => {
            let result = res.data.data;
            setSliderImage(`${api_host}/${result.image}`)
            setProgress(0);
            let obj = { ...model };
            obj.image = result.id;
            setModel(obj)
            // image validation
            let classes = Array.from(sliderImageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                sliderImageRef.current.nextSibling.classList.add("d-none");
            }
        }).catch((err) => {
            console.log(err.response)
            setProgress(0);
        })
    }
    // onchange
    const setFormData = (e, property , locale=false , language=null) => {
        setSliderData(model, setModel, e, property , locale , language);
    }

    // remove image
    function removeImgHandler(){
        setSliderImage('');
        let obj = { ...model };
        obj.image = '';
        setModel(obj)
    }

    // onsubmit
    const confirmSlider = async (e , model)=>{
        e.preventDefault();
        let formData = new FormData();

        if (sliderImage === '') {
            sliderImageRef.current.nextSibling.classList.remove("d-none");
        }

        for(const key in model){
            if (key === 'locales') {
                for (let i = 0; i < model.locales.length; i++) {
                    for (const key in model.locales[i]) {
                        if(model.locales[i][key] !== 'null' && model.locales[i][key] !== '' && model.locales[i][key] !== null){
                            formData.append(`locales[${i}][${key}]`, model.locales[i][key]);
                        }else{
                            formData.append(`locales[${i}][${key}]`, '');
                        }
                    }
                }
            }else if(key === 'status'){
                if(model.status === true || model.status === 1){
                    formData.append("status", 1);
                }else{
                    formData.append("status", 0)
                }
            }
            else {
                formData.append(key, model[key]);
            }
        }

        if (sliderImage !== ''){
            setLoading(true);
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if(submitProps === "done"){
                setLoading(false);
                navigate(`${api_basename}/dashboard/appearance/pages/home`);
                
            }else{
                setLoading(false);
                setErrors(submitProps);
            }
        }
    }


    return (
        <>
        <section className='componentGlobalWrapper addBlog'>
            <form className='formWidth' onSubmit={(e) => confirmSlider(e, model)}>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='title_en' className='formLabel'>title (english)</label>
                    <input name="name" id='title_en' type='text' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"en").length) ? filterLanguage(model.locales ,"en")[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , 'en')}/>
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='title_ar' className='formLabel'>title (arabic)</label>
                    <input name="name" id='title_ar' type='text' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"ar").length) ? filterLanguage(model.locales ,"ar")[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , 'ar')}/>
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='description_en' className='formLabel'>description (english)</label>
                    <textarea name="description" id='description_en' rows={6} value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"en").length) ? filterLanguage(model.locales ,"en")[0].description : ""} onChange={(e) => setFormData(e, 'description' , true , 'en')}/>
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='description_ar' className='formLabel'>description (arabic)</label>
                    <textarea name="description" id='description_ar' rows={6} value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"ar").length) ? filterLanguage(model.locales ,"ar")[0].description : ""} onChange={(e) => setFormData(e, 'description' , true , 'ar')}/>
                </div>

                <div className='d-flex flex-column mb-5'>
                    <label htmlFor='favicon' className='formLabel'>image</label>
                        { !sliderImage ?
                            <div>
                                <label ref={sliderImageRef} htmlFor="uploadImage" className='position-relative'>
                                    <div className='globalImageWrapper'>
                                        <input onChange={(e) => addImage(e)}  type='file' id='uploadImage' name='image' className="d-none"/>
                                        <img className='cloudIcon' src={uploadImage} alt="upload"/>
                                        <span>Upload Image</span>
                                    </div>
                                </label>
                                {progress !== 0 && (
                                <div className="progress mt-2 p-0" style={{ width:'11.25rem' }}>
                                    <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                        aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                        {progress}%
                                    </div>
                                </div>)}
                                <div className='validateToDelete text-danger d-none'>This field is required!</div>
                            </div>
                            :
                            <div className='globalImageWrapper position-relative'>
                                <Tooltip title='Remove Image'>
                                    <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                        <img src={removeIcon} alt="delete" onClick={removeImgHandler}/>
                                    </label>
                                </Tooltip>
                                <div className='globalPreviewedImage' style={{backgroundImage: `url(${sliderImage})`}}></div>
                            </div>
                        }
                        <div className='validateToDelete text-danger'> 
                            {errors?.image ? errors.image.map((error, index) => <p key={index}>{error}</p>) : ''}
                        </div>

                </div>

                {!loading ? 
                    <button className='confirmBtn' type='submit'>{id ? `save changes` : `add slider`}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }
            </form>
        </section>
        </>
    )
}
