import React, { useContext, useEffect } from 'react';
import TitleContext from '../../TitleContext';
import "./News.scss";
import search from "../../assets/icons/search.svg";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import undelete from "../../assets/icons/undelete.svg";
import { deleteBlog, getBlogs, undeleteBlog, updateBlog } from '../../Apis/news';
import { useState, useRef } from 'react';
import { filterLanguage } from './../../utilities/filterLang';
import moment from 'moment';
import DeleteModal from './../../utilities/deleteModal';
import { Link, useSearchParams } from 'react-router-dom';
import { api_basename, api_host } from './../../Configuration/Config';
import Pagination from './../../Components/Pagination/Pagination';
import Tooltip from '@material-ui/core/Tooltip';
import { getStatistics } from '../../Apis/overviewApi';
import permissionsContext from '../../permissionsContext';
import { useLocation } from 'react-router';

export default function NewsList() {
    let {Permissions} = useContext(permissionsContext);
    let {changeTitle} = useContext(TitleContext);
    const [blogs ,setBlogs] = useState([]);
    const [blogId ,setBlogId] = useState('');
    const [filterObj ,setfilterObj] = useState({search:'',per_page:'10',page:'1',trashed:'',status:''});
    const [pageCount,setPagecount]=useState(0);
    const [totalNews ,setTotalNews] = useState(0);
    const [totalPublish ,setTotalPublish] = useState(0);
    const [totalTrashed ,setTotalTrashed] = useState(0);
    const [searchParams] = useSearchParams();
    const [params] = useState(searchParams.get("status"));
    const activeBlog = useRef();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(()=>{
        changeTitle(`news list` , Permissions && Permissions.blog && Permissions.blog.create && {url: 'news/add-new-post', text: 'Add New post'});
        getAllBlogs();
        getStatistics().then((res)=>{
            setTotalPublish(res.data.data.overview.active_blogs);
            setTotalTrashed(res.data.data.overview.trashed_blogs);
        })
        if(params && Permissions?.blog?.view){
            activeBlog.current.click();
        }
        // eslint-disable-next-line
    } , [Permissions , params]);

    function getAllBlogs(){
        let filter = {...filterObj};
        filter.status = '';
        filter.trashed = '';
        setfilterObj(filter);
        let obj=`search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}&page=1&trashed=&status=`;
        getBlogs(obj).then(res => {
            let thisBlogs = [...blogs];
            thisBlogs = res.data.data;
            let total = res.data.pagination.total;
            setTotalNews(total)
            setPagecount(Math.ceil(total/filterObj.per_page)); 
            setBlogs(thisBlogs);
            thisBlogs.map((blog) => {
                if(blog.status){
                    document.getElementById(blog.id).checked = true;
                }else{
                    document.getElementById(blog.id).checked = false;
                }
                return true
            });
        })
    }
    
    
    /* useEffect(() => {
        if(blogs && blogs.length){blogs.map((blog) => {
            if(blog.status){
                document.getElementById(blog.id).checked = true;
            }else{
                document.getElementById(blog.id).checked = false;
            }
            
        });}
    },[blogs]) */

    function toggleStatus(id,status){
        let obj 
        let b = [...blogs]; 
        if(status){
            obj = { status: 0 };
            updateBlog(id,{...obj , "_method" : "patch"}).then((res)=>{
                b.map((blog)=>{
                    if(blog.id === id){
                        blog.status = false
                    }
                    return true
                }) 
            })
        }else{
            obj = { status: 1 };
            updateBlog(id,{...obj , "_method" : "patch"}).then((res)=>{
                b.map((blog)=>{
                    if(blog.id === id){
                        blog.status = true;
                    }
                    return true
                })
            })
        } 
        getStatistics().then((res)=>{
            setTotalPublish(res.data.data.overview.active_blogs);
            setTotalTrashed(res.data.data.overview.trashed_blogs);
        })
    }

    function getDeletedId(id){
        setBlogId(id)
    }

    const onSubmitDelete = async ()=>{
        return await deleteBlog(blogId).then(res =>{
            /* getAllBlogs(); */
            let filter = {...filterObj};
            let obj=`search=${filter && filter.search}&per_page=${filter && filter.per_page}&page=${filterObj?.page}
            &trashed=${filterObj && filterObj.trashed}&status=${filterObj && filterObj.status}`;
            getBlogs(obj).then(
                res=>{
                    setBlogs(res.data.data);
                    res.data.data.map((blog) => {
                        if(blog.status){
                            document.getElementById(blog.id).checked = true;
                        }else{
                            document.getElementById(blog.id).checked = false;
                        }
                        return true
                    });
                }
            )

            getStatistics().then((res)=>{
                setTotalPublish(res.data.data.overview.active_blogs);
                setTotalTrashed(res.data.data.overview.trashed_blogs);
            });
            return "done";
        }).catch(err => {
            console.log(err.response);
            return err.response.data.errors;
        })
    }

    function changeFilterObj(e){
        let thisObj = {...filterObj}
        thisObj[e.target.name]= e.target.value.trim();
        thisObj.page = 1
        setfilterObj(thisObj);
    }

    function getFilteredNews(e){
        getAllBlogs()
    }

    const handlepageChange= async data =>{
        let filter = {...filterObj};
        filter.page = data.selected+1;
        setfilterObj(filter);
        let obj=`search=${filter && filter.search}&per_page=${filter && filter.per_page}&page=${data.selected+1}
        &trashed=${filterObj && filterObj.trashed}&status=${filterObj && filterObj.status}`;
        getBlogs(obj).then(
            res=>{
                setBlogs(res.data.data);
                res.data.data.map((blog) => {
                    if(blog.status){
                        document.getElementById(blog.id).checked = true;
                    }else{
                        document.getElementById(blog.id).checked = false;
                    }
                    return true
                });
            }
        )
    }

    function getPublish(){
        let filter = {...filterObj};
        filter.status = 1;
        filter.trashed = '';
        setfilterObj(filter);
        let obj=`search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}
        &page=1&trashed=&status=1`;
        getBlogs(obj).then(res => {
            let thisBlogs = [...blogs];
            thisBlogs = res.data.data;
            let total = res.data.pagination.total;
            setPagecount(Math.ceil(total/filterObj.per_page)); 
            setBlogs(thisBlogs);
            thisBlogs.map((blog) => {
                if(blog.status){
                    document.getElementById(blog.id).checked = true;
                }
                return true
            });
        })
    }

    function getTrashed(){
        let filter = {...filterObj};
        filter.trashed = 1;
        setfilterObj(filter);
        let obj=`search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}
        &page=1&trashed=1&status=`;
        getBlogs(obj).then(res => {
            let thisBlogs = [...blogs];
            thisBlogs = res.data.data;
            let total = res.data.pagination.total;
            setPagecount(Math.ceil(total/filterObj.per_page)); 
            setBlogs(thisBlogs);
        });
    }

    function restore(id){
        undeleteBlog(id).then((res)=>console.log(res.data.data));
        getTrashed();
        getStatistics().then((res)=>{
            setTotalPublish(res.data.data.overview.active_blogs);
            setTotalTrashed(res.data.data.overview.trashed_blogs);
        });
    }
    
    return (
        <>
        {Permissions && Permissions.blog && Permissions.blog.view && <section className='componentGlobalWrapper'>
            <div>
                <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                    <div onClick={getAllBlogs} className='tablinks active' id="Gallery-tab" data-bs-toggle="tab" data-bs-target="#allnews">all news <span className='tabcounter'>({totalNews})</span></div>
                    <span className='separator'></span>
                    <div ref={activeBlog} onClick={getPublish} className='tablinks' id="published-tab" data-bs-toggle="tab" data-bs-target="#published-Img" >published <span className='tabcounter'>({totalPublish})</span></div>
                    <span className='separator'></span>
                    <div onClick={getTrashed} className='tablinks' id="trashed-tab" data-bs-toggle="tab" data-bs-target="#trashed-Img">trashed <span className='tabcounter'>({totalTrashed})</span></div>
                </div>

                <div className='searchActionsBar w-100'>
                    <div className='searchActionsBar mt-0 mb-0'>
                        {/*** search input ***/}
                        <div className='searchInputWrapper'>
                            <div>
                                <input type='text' className='barSearchInput' name='search' onChange={changeFilterObj}/>
                                {/*  <span className="inputPlaceholder">search</span> */}
                            </div>
                            <img src={search} alt='search-icon' className='barSearchIcon'/>
                        </div>
                    </div>
                    {/* show select */}
                    <div className='perPageContainer'>
                        <label htmlFor='selectShow' className='perPageLabel'>
                            show
                        </label>
                        <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' defaultValue='10'>
                            <option value='5'>5</option>
                            <option value='10'>10</option>
                            <option value='15'>15</option>
                            <option value='20'>20</option>
                        </select>
                    </div>
                    {/*** apply search btn  ***/}
                    <button className='applySearchBtn' onClick={getFilteredNews}>apply</button>
                </div>
                <div className='tableWrapper'>
                    <table className="w-100 table table-striped">
                        <thead>
                            <tr>
                                <th>image</th>
                                <th>title</th>
                                <th>description</th>
                                <th>created at</th>
                                <th>actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { blogs && blogs.map((blog,index)=>{
                                let en = filterLanguage(blog.locales ,'en');
                                let ar = filterLanguage(blog.locales,'ar');
                                return  <tr key={index}>
                                            <td className='sliderImg' style={{"width" : "23%"}}>
                                                <img src={`${api_host}/${blog?.image?.image}`} alt="slider"/>
                                            </td>
                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    {en && en.length ? en[0].name : ar && ar.length ? ar[0].name : ''}
                                                </div>
                                            </td>
                                            <td className='desc'>
                                                <div className='descriptionTd'>
                                                    {en && en.length ? en[0].short_description : ar && ar.length ? ar[0].short_description : ''}
                                                </div>
                                            </td>
                                            <td> {moment(blog.created_at).format('DD-MM-YY h:mm A')}</td>
                                            <td>
                                                {filterObj && filterObj.trashed !== 1?
                                                    <div>
                                                        {Permissions && Permissions.blog && Permissions.blog.edit && <label className="switch mb-0">
                                                            <input type="checkbox"  id={blog.id}  onChange={()=> toggleStatus(blog.id ,blog.status)}  />
                                                            <span className="slider round" />
                                                        </label>}
                                                        {/* <img src={eye_icon} alt="view"/> */}
                                                        {Permissions && Permissions.blog && Permissions.blog.edit && <Link to={`${api_basename}/dashboard/news/update-post/${blog.id}`}>
                                                            <img src={edit_icon} alt="edit"/>
                                                        </Link>}
                                                        { Permissions && Permissions.blog && Permissions.blog.delete && <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={()=>getDeletedId(blog.id)}/>}
                                                    </div>
                                                    :
                                                    <div>
                                                        {Permissions && Permissions.blog && Permissions.blog.restore && <Tooltip title='restore'>
                                                            <img width='18px' height='20px' src={undelete} onClick={()=>restore(blog.id)} alt='undelete'/>
                                                        </Tooltip>}
                                                        {Permissions && Permissions.blog && Permissions.blog.delete && <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={()=>getDeletedId(blog.id)}/>}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <DeleteModal onSubmitDelete={onSubmitDelete} name='post'></DeleteModal>
            </div>
            {<div className='d-flex justify-content-end mt-auto'>
                <div className='mt-auto'>
                    <Pagination  pagesNumber={pageCount} pageChangeHandler={handlepageChange} />
                </div>
            </div>}
        </section>}
        </>
    )
}
