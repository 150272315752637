import { createContext } from "react";
import { useState } from "react";

let PermissionsContext = createContext('');
export function PermissionsContextProvider(props){ 
    let[Permissions, setPermissions] = useState({});
    function changePermissions(permissions){
        setPermissions(permissions);
        //console.log(permissions);
    }
    return (
        <PermissionsContext.Provider value={{Permissions , changePermissions }}>
            {props.children}
        </PermissionsContext.Provider>
    )
}
export default PermissionsContext;