import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get general settings
export async function getGeneralSettings(){
    return await AxiosClone.get(`${api_url}/admin/settings/general-settings`)
}

//edit general settings
export async function updateGeneralSettings(data){
    /* for (var pair of data.entries()){
        console.log(pair[0]+ ', ' + pair[1]); 
    } */
    return await AxiosClone.post(`${api_url}/admin/settings`, data)
}