import React from 'react';
import { editAdminSecurityInfo } from '../../../Apis/profileApi';
import Form from './Form'

export default function EditSecuritySettings() {

    // onsubmit
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await editAdminSecurityInfo({...data , "_method": "patch"}).then(res => {
            return 'done';          
        }).catch(err => {
            console.log(err.response.data.errors);
            return err.response.data.errors;
        })
    };

    return (
        <Form onSubmit={onSubmit} ></Form>
    )
}
