import React, { useContext, useEffect } from 'react'
import TitleContext from '../../../TitleContext';
import Form from './Form'
import { useParams } from 'react-router';
import { updateProperty } from '../../../Apis/propertiesApi';

export default function Edit(){
    let {changeTitle} = useContext(TitleContext);
    let {id} = useParams();
    useEffect(()=>{
        changeTitle(`Update property`);
        // eslint-disable-next-line
    } , []);

    const onSubmit = async (e, data) => {
        e.preventDefault();
        data.append("_method", "patch");
        //to send request
        return await updateProperty(id ,data).then(res => {
            return 'done';          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };
    return (
        <>
            <Form onSubmit={onSubmit}></Form>
        </>
    )
}
