import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import TitleContext from '../../../TitleContext';
import uploadImage from "../../../assets/icons/uploadimage.svg";
import removeIcon from "../../../assets/icons/removeIcon.svg";
import "../../News/News.scss";
import formModel from './formModel';
import { api_basename, api_host, api_url } from '../../../Configuration/Config';
import { setAdminData } from './formHandler';
import { getAdmin } from '../../../Apis/adminApis';
import Tooltip from '@material-ui/core/Tooltip';
import { getRoles } from '../../../Apis/rolesApi';
import axios from 'axios';

export default function Form(props) {
    const { pathname } = useLocation();
    let { changeTitle } = useContext(TitleContext);
    const [allRoles, setAllRoles] = useState([]);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    let adminImageRef = useRef();
    let [adminImage, setAdminImage] = useState('');
    let navigate = useNavigate();
    let [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get all roles to fill selectbox options
    function getAllRoles() {
        let myallRoles = [...allRoles];
        getRoles().then(res => {
            myallRoles = res.data.data;
            setAllRoles(myallRoles);
            console.log(res.data.data);
        }).catch(err =>
            console.log(err))
    }

    useEffect(() => {
        changeTitle(`${id ? `edit` : `add`} user`);
        getAllRoles();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (id) {
            getAdmin(id).then((res) => {
                let x = res.data.data;
                if(x?.image){
                    setAdminImage(`${api_host}/${x?.image?.image}`);
                }
                let data={...x}
                data.image=x?.image?.id
                setModel(data);
                // handle role key when role = null
                if (x.role === null) {
                    setModel({ ...data, "role": '' });
                }
            })
        } else {
            setModel({})
        }
    }, [id]);
    let [progress, setProgress] = useState(0)
    /* upload images */
    const token = `Bearer ` + localStorage.getItem('rh_Admin_Token');
    function addImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }

        axios.post(`${api_url}/admin/upload`, formData, config).then((res) => {
            let result = res.data.data;
            setAdminImage(`${api_host}/${result.image}`)
            setProgress(0);
            let obj = { ...model };
            obj.image = result.id;
            setModel(obj)
            // image validation
            let classes = Array.from(adminImageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                adminImageRef.current.nextSibling.classList.add("d-none");
            }
        }).catch((err) => {
            console.log(err.response)
            setProgress(0);
        })
    }
    // onchange
    const setFormData = (e, property) => {
        setAdminData(model, setModel, e, property);
    }

    // remove image
    function removeImgHandler() {
        setAdminImage('');
        let obj = { ...model };
        obj.image = '';
        setModel(obj)
    }

    // onsubmit
    const confirmAdmin = async (e, model) => {
        e.preventDefault();
        let formData = new FormData();

        if (adminImage === '') {
            adminImageRef.current.nextSibling.classList.remove("d-none");
        }

        for (const key in model) {
                formData.append(key, model[key]);
        }

        if (adminImage !== '') {
            setLoading(true);
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if (submitProps === "done") {
                setLoading(false);
                navigate(`${api_basename}/dashboard/users/all-admins`);
                if (!id) {
                    setModel({});
                    setAdminImage("");
                }
            } else {
                setLoading(false);
                setErrors(submitProps);
            }
        }
    }

    return (
        <>
            <section className='componentGlobalWrapper addBlog'>
                <div className='mb-3'>
                    <h5 className='pageSubtitle'>user details</h5>
                </div>

                <form id='adminForm' onSubmit={(e) => confirmAdmin(e, model)}>
                    <div className='d-flex justify-content-start'>
                        <div className="me-4 pagesForm">
                            <div className='d-flex flex-column mb-4'>
                                <label htmlFor='firstname' className='formLabel'>first name</label>
                                <input name="firstname" id='firstname' type='text' value={(model && model.first_name) ? model.first_name : ""} onChange={(e) => setFormData(e, 'first_name')} required pattern="\S(.*\S)?" title="This field is required" minLength='3' maxLength='20' />
                            </div>
                            <div className='d-flex flex-column mb-4'>
                                <label htmlFor='lastname' className='formLabel'>last name</label>
                                <input name="lastname" id='lastname' type='text' value={(model && model.last_name) ? model.last_name : ""} onChange={(e) => setFormData(e, 'last_name')} required pattern="\S(.*\S)?" title="This field is required" minLength='3' maxLength='20' />
                            </div>
                            <div className='d-flex flex-column mb-4'>
                                <label htmlFor='username' className='formLabel'>username</label>
                                <input name="username" id='username' type='text' value={(model && model.username) ? model.username : ""} onChange={(e) => setFormData(e, 'username')} required pattern="\S(.*\S)?" title="This field is required" />
                                {errors?.username && <div className='text-danger validateToDelete'>
                                    {errors?.username[0]}
                                </div>}
                            </div>
                            <div className='d-flex flex-column mb-4'>
                                <label htmlFor='email' className='formLabel'>email</label>
                                <input name="email" id='email' type='email' value={(model && model.email) ? model.email : ""} onChange={(e) => setFormData(e, 'email')} required pattern="\S(.*\S)?" title="This field is required" />
                                {errors?.email && <div className='text-danger validateToDelete'>
                                    {errors?.email[0]}
                                </div>}
                            </div>
                            <div className='d-flex flex-column mb-4'>
                                <label htmlFor='password' className='formLabel'>password</label>
                                <input name="password" minLength={8} maxLength='30' id='password' type='text' onChange={(e) => setFormData(e, 'password')} required={props.requireds} pattern="\S(.*\S)?" title="This field is required" />
                            </div>
                        </div>

                        <div className="ms-2 ms-lg-5">
                            {/** admin image **/}
                            <div className='d-flex flex-column mb-4 w-100'>
                                {!adminImage ?
                                    <div>
                                        <label ref={adminImageRef} htmlFor="uploadImage" style={{ "width": "100%", "position": "relative" }}>
                                            <div className='individualImageWrapper'>
                                                <input type='file' id='uploadImage' name='image' className="d-none" onChange={(e) => addImage(e)} />
                                                <img src={uploadImage} alt="upload" />
                                                <span>Upload Image</span>
                                            </div>
                                        </label>
                                        {progress !== 0 && (
                                            <div className="progress mt-2 p-0" >
                                                <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                                    aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                                    {progress}%
                                                </div>
                                            </div>)}

                                        <div className='validateToDelete text-danger d-none'>this field is required!</div>
                                    </div>
                                    :
                                    <div>
                                        <div className="individualImageWrapper position-relative ">
                                            <Tooltip title='remove image'>
                                                <label htmlFor='viewImage' className='reloadAnotherIcon' onClick={removeImgHandler}>
                                                    <img src={removeIcon} width='23px' height='23px' alt="delete" />
                                                    <input type='file' id='viewImage' name='image' className="d-none" />
                                                </label>
                                            </Tooltip>
                                            <div className='previewedImg' style={{ backgroundImage: `url(${adminImage})` }}>
                                            </div>
                                        </div>
                                        <div className='validateToDelete text-danger d-none'>this field is required!</div>
                                        {errors?.image && <div className='text-danger validateToDelete'>
                                            {errors?.image[0]}
                                        </div>}
                                    </div>
                                }
                            </div>
                            {/*** role selectbox ***/}
                            <div className='d-flex flex-column w-100'>
                                <label htmlFor='role' className='formLabel'>role</label>
                                <div className='selectParent'>
                                    <select name='role' value={(model && model.role) ? model.role : ""} onChange={(e) => setFormData(e, 'role')} required>
                                        <option value='' disabled>select role</option>
                                        {allRoles && allRoles.map((role, index) => (
                                            <option value={role.id} key={index}>{role.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!loading ?
                        <button className='confirmBtn' type='submit'>{id ? `save changes` : `add admin`}</button>
                        :
                        <button className='confirmBtn' disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            saving...
                        </button>
                    }
                </form>
            </section>
        </>
    )
}
