import React, { useEffect, useRef, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { supportedLanguages } from './../../utilities/SupportedLanguages';
import formModel from './formModel';
import { getPageHeaderAndContent } from '../../Apis/pageHeadersApi';
import { filterLanguage } from '../../utilities/filterLang';
import { setWhatWeProvideSectionData } from './formHandler';
import uploadImage from "../../assets/icons/uploadimage.svg";
import removeIcon from "../../assets/icons/removeIcon.svg";
import { api_host } from './../../Configuration/Config';
import Tooltip from '@material-ui/core/Tooltip';

export default function SectionForm(props) {
    const [loading , setLoading] = useState(false);
    const [model, setModel] = useState(formModel);
    let [editors , setEditors ]= useState({});
    const pageImageRef = useRef();
    const [pageImage ,setPageImage] = useState('');
    const pageName = props.name;
    let [nestedObjKey ]= useState(`${props.nestedObjKey ? props.nestedObjKey : ''}`);

    useEffect(() => {
        getPageHeaderAndContent(pageName).then((res) => {
            let mypageContent;
            let thisEditors = {...editors};
            
            mypageContent = res.data.data.settings[`${nestedObjKey}`];
            delete mypageContent.value;
            setModel({...mypageContent});
            if(mypageContent.image){
                setPageImage(`${api_host}/${mypageContent.image}`);
            }
            // to preview data in text editors
            supportedLanguages.map((language)=>{
                let lang = filterLanguage(mypageContent.locales, language.slug);
                if(lang.length){
                    //set values at editor state
                    const contentDataStateAr = ContentState.createFromBlockArray(convertFromHTML(`${lang[0].description}`));
                    const editorDataState = EditorState.createWithContent(contentDataStateAr);
                    thisEditors[language.slug] = editorDataState;
                }
                setEditors(thisEditors);
                return true
            })
        })
        // eslint-disable-next-line
    }, []);

    // convert body to html
    const convertContentToHTML = (state) => {
        return convertToHTML(state.getCurrentContent());
    }

    // onchange editor handler
    const handleEditorChange = (state,lang) => {
        let thisEditors = {...editors}
        if(!thisEditors[lang]){ 
            thisEditors[lang]=EditorState.createEmpty()
        } 
        thisEditors[lang]=state;
        setEditors(thisEditors);
        
        let m ={...model}
        let existsLocales = m.locales.filter((i) => {
            return i.locale === lang;
        })
        if(existsLocales.length){
            existsLocales[0]['description'] = convertContentToHTML(state);
        }else{
            m.locales.push({'locale': lang});
            m.locales.map((i) => {
                if(i.locale === lang){
                    i['description'] = convertContentToHTML(state);
                }
                return true;
            });
        }
        setModel(m)
    }

    // onchange for inputs fields
    const setFormData = (e, property , locale=false , language=null) => {
        setWhatWeProvideSectionData(model, setModel, e, property , locale , language);

        if(e.target.name === 'image'){
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setPageImage(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
            // image validation
            let classes = Array.from(pageImageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                pageImageRef.current.nextSibling.classList.add("d-none");
            }
        }
        // eslint-disable-next-line
    }

    // remove image
    function removeImgHandler(){
        setPageImage('');
        let obj = { ...model };
        obj.image = '';
        setModel(obj)
    }

    // onsubmit what we provide section data
    const confirmWhatWeProvide = async (e , model)=>{
        e.preventDefault();
        let formData = new FormData();

        if (pageImage === '') {
            pageImageRef.current.nextSibling.classList.remove("d-none");
        }

        for(const key in model){
            if (key === 'locales') {
                for (let i = 0; i < model.locales.length; i++) {
                    for (const key in model.locales[i]) {
                        if(model.locales[i][key]!== null){
                            formData.append(`locales[${i}][${key}]`, model.locales[i][key]);
                        }else{
                            formData.append(`locales[${i}][${key}]`, '');
                        }
                    }
                }
            }else if(key === 'image'){
                if(pageImage !== `${api_host}/${model.image}`){
                    formData.append("image", model.image);
                }else{
                    delete model.image;
                }                
            }else {
                formData.append(key, model[key]);
            }
        }
        
        // for (var pair of formData.entries()){
        //     console.log(pair[0]+ ', ' + pair[1]); 
        // }

        if (pageImage !== ''){
            setLoading(true);
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if(submitProps === "done"){
                setLoading(false);
            }else{
                setLoading(false);
            }
        }
    }

    return (
        <>
        <form onSubmit={(e) => confirmWhatWeProvide(e, model)}>
            {supportedLanguages.map((language , index)=>(
                <div key={index} className='d-flex flex-column mb-4'>
                    <label htmlFor='name_ar' className='formLabel'>{`title in ${language.name}`}</label>
                    <input name="name" id='name_ar' type='text' value={(model && model.locales && model.locales.length && filterLanguage(model.locales , language.slug).length) ? filterLanguage(model.locales , language.slug)[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , language.slug)}/>
                </div>
            ))}

            {supportedLanguages.map((language , index)=>(
                <div key={index} className='d-flex flex-column mb-4'>
                    <label htmlFor='content' className='formLabel'>{`content in ${language.name}`}</label>
                    <div {...(language.slug === 'ar' ? {'dir':'rtl'}:{})}>
                        <Editor
                            editorState={model && model.locales && model.locales.length && editors[language.slug]}
                            onEditorStateChange={(state)=>handleEditorChange(state,language.slug)}
                        />
                    </div>
                </div>
            ))}

            <div className='d-flex flex-column mb-4'>
                    <label htmlFor='favicon' className='formLabel'>image</label>
                    { !pageImage ?
                        <div>
                            <label ref={pageImageRef} htmlFor="uploadImage" className='position-relative'>
                                <div className='globalImageWrapper'>
                                    <input onChange={(e) => setFormData(e, 'image')} type='file' id='uploadImage' name='image' className="d-none"/>
                                    <img className='cloudIcon' src={uploadImage} alt="upload"/>
                                    <span>Upload Image</span>
                                </div>
                            </label>
                            <div className='validateToDelete text-danger d-none'>This field is required!</div>
                        </div>
                        :
                        <div className='globalImageWrapper position-relative'>
                            <Tooltip title='Remove Image'>
                                <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                    <img src={removeIcon} alt="delete" onClick={removeImgHandler}/>
                                </label>
                            </Tooltip>
                            <div className='globalPreviewedImage' style={{backgroundImage: `url(${pageImage})`}}></div>
                        </div>
                    }
            </div>
            <div>
                { !loading ?
                    <button className='confirmBtn' type='submit'>update</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }
            </div>
        </form>
        </>
    )
}
