import React, { useContext, useEffect, useState } from 'react';
import TitleContext from '../../../../TitleContext';
import formModel from './formModel';
import "../../Aboutus/Aboutus.scss";
import { supportedLanguages } from '../../../../utilities/SupportedLanguages';
import { filterLanguage } from '../../../../utilities/filterLang';
import { setFAQData } from './formHandler';
import { useNavigate, useParams } from 'react-router';
import { getFaq } from '../../../../Apis/FAQsApi';
import { api_basename } from '../../../../Configuration/Config';
import { useLocation } from 'react-router';

export default function Form(props) {
    let {changeTitle} = useContext(TitleContext);
    const { pathname } = useLocation();
    const [loading , setLoading] = useState(false);
    const [model, setModel] = useState(formModel);
    const {id} = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(()=>{
        changeTitle(`${id ? `edit` : `add new`} faq`);
        // eslint-disable-next-line
    } , []);

    useEffect(() => {
        if (id) {
            getFaq(id).then((res) => {
                let x = res.data.data;
                setModel({...x});
            })
        }else{
            setModel({
                locales: []
            })
        }
    }, [id]);

    // onchange for inputs fields
    const setFormData = (e, property , locale=false , language=null) => {
        setFAQData(model, setModel, e, property , locale , language);
        // eslint-disable-next-line
    }

    // onsubmit what we provide section data
    const confirmFaq = async (e , model)=>{
        e.preventDefault();
        setLoading(true);
        console.log(model)
        // send request
        let submitProps = await props.onSubmit(e, model);
        if(submitProps === "done"){
            setLoading(false);
            navigate(`${api_basename}/dashboard/appearance/pages/faq`);
            if(!id){
                setModel({});
            }
        }else{
            setLoading(false);
        }
    }

    return (
        <>
        <section className='componentGlobalWrapper aboutus'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>{id ? `edit faq` : `add new faq`}</h5>
            </div>

            <form onSubmit={(e) => confirmFaq(e, model)}>
                {supportedLanguages.map((language , index)=>(
                    <div key={index} className='d-flex flex-column mb-4'>
                        <label htmlFor={`name${language.name}`} className='formLabel'>{`question in ${language.name}`}</label>
                        <input name="name" id={`name${language.name}`} type='text' value={(model && model.locales && model.locales.length && filterLanguage(model.locales , language.slug).length) ? filterLanguage(model.locales , language.slug)[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , language.slug)} required pattern="\S(.*\S)?" title="This field is required"/>
                    </div>
                ))}
                {supportedLanguages.map((language , index)=>(
                    <div key={index} className='d-flex flex-column mb-4'>
                        <label htmlFor={`description${language.name}`} className='formLabel'>{`answer in ${language.name}`}</label>
                        <textarea name="description" id={`description${language.name}`} type='text' value={(model && model.locales && model.locales.length && filterLanguage(model.locales , language.slug).length) ? filterLanguage(model.locales , language.slug)[0].description : ""} onChange={(e) => setFormData(e, 'description' , true , language.slug)} required pattern="\S(.*\S)?" title="This field is required"/>
                    </div>
                ))}
                <div>
                { !loading ?
                    <button className='confirmBtn' type='submit'>save changes</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }
            </div>
            </form>
        </section>
        </>
    )
}
