import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Subside from '../../Components/SubSide/Subside';
import { Route, Routes } from "react-router-dom";
import TitleContext from '../../TitleContext';
import EditCategory from './EditCategory';
import { getCategories } from '../../Apis/CategoriesApi';
import { useNavigate } from 'react-router';
import { api_basename } from './../../Configuration/Config';
import permissionsContext from '../../permissionsContext';

export default function Categories() {
    let {changeTitle} = useContext(TitleContext);
    let {Permissions} = useContext(permissionsContext);
    let navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    let [allCategories , setAllCategories] = useState([]);

    // get all categories
    function getAllCategories(){
        let myallCategories = [...allCategories];
        getCategories().then(res => {
            myallCategories = res.data.data;
            setAllCategories(myallCategories);
            if(myallCategories && myallCategories.length){
                let x = myallCategories[0].id;
                navigate(`${api_basename}/dashboard/property-settings/categories/edit/${x}`)
            }
        }).catch(err => 
            console.log(err))
    }

    useEffect(() => {
        changeTitle(`Categories`);
        getAllCategories();
        // eslint-disable-next-line
    }, [])

    // note
    function getCategoriesAfterChange(){
        let myallCategories = [...allCategories];
        getCategories().then(res => {
            myallCategories = res.data.data;
            setAllCategories(myallCategories);
        }).catch(err => 
            console.log(err.response))
    }
    

    return (
        <>
        {Permissions && Permissions.category && <section className='subside_action_wrapper'>
            {Permissions.category.view && <Subside items={allCategories} btnText="" btnUrl="property-settings/categories"/>}
            <Routes>
                {Permissions.category.edit && <Route path='edit/:id' element={<EditCategory getAll={getCategoriesAfterChange}/>}/>}
            </Routes>
        </section>}
        </>
    )
}
