import React, { useContext } from 'react';
import './overview.scss';
import { useState, useEffect } from 'react';
import {useLocation} from 'react-router';
import { Link } from 'react-router-dom';
import { getStatistics } from '../../Apis/overviewApi';
import {getBlogs} from '../../Apis/news'
import {getProperties} from '../../Apis/propertiesApi'
import { filterLanguage } from '../../utilities/filterLang';
import TitleContext from '../../TitleContext';
import { api_basename } from '../../Configuration/Config';

export default function Overview() {
    const { pathname } = useLocation();
    let {changeTitle} = useContext(TitleContext);
    const [overview,Setoverview]=useState({});
    const [latestProperties ,setLatestProperties] = useState([]);
    const [latestBlogs ,setLatestBlogs] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get all statistics
    useEffect(() => {
        changeTitle(`overview`);
        getStatistics().then((res) => {
        let over = { ...overview }
        over = res.data.data.overview;
        Setoverview(over)
        //console.log(over);
        });
        // get latest properties
        getProperties('per_page=7').then(res => {
            let mylatestProperties = [...latestProperties]
            mylatestProperties = res.data.data
            setLatestProperties(mylatestProperties)
        });
        // get latest blogs
        getBlogs('per_page=7').then(res => {
            let mylatestBlogs = [...latestBlogs]
            mylatestBlogs = res.data.data
            setLatestBlogs(mylatestBlogs)
        })
        // eslint-disable-next-line
    }, [])

    return (
        <div className='componentGlobalWrapper'>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-5 mb-5">
                <div className='col'>
                    <Link to={`${api_basename}/dashboard/leads/contact-requests?status=unread`} className='overViewCard h-100'>
                        <span className='details'>today contacts</span>
                        <span className='number'>{overview.contacts_today}</span>
                    </Link>
                </div>
                <div className='col'>
                    <Link to={`${api_basename}/dashboard/leads/leads-requests?status=unread`} className='overViewCard h-100'>
                        <span className='details'>today requests </span>
                        <span className='number'>{overview.enquiries_today}</span>
                    </Link>
                </div>
                <div className='col'>
                    <Link to={`${api_basename}/dashboard/properties/properties-list?status=active`} className='overViewCard h-100'>
                        <span className='details'>active properties </span>
                        <span className='number'>{overview.active_properties}</span>
                    </Link>
                </div>
                <div className='col'>
                    <Link to={`${api_basename}/dashboard/news/news-list?status=active`} className='overViewCard h-100'>
                        <span className='details'>Active Blogs</span>
                        <span className='number'>{overview.active_blogs}</span>
                    </Link>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-2 g-4">
                <div className='col'>
                    <div className='latestData pb-0 h-100'>
                        <div className="latestHeader">
                            <p>latest properties</p>
                            <Link to={`${api_basename}/dashboard/properties/properties-list`}>view properties</Link>
                        </div>
                        <div className="allTitlesOfLatest">
                            {latestProperties.map((property)=> {
                                let en = filterLanguage(property.locales,'en');
                                let ar = filterLanguage(property.locales,'ar');
                                return(
                                    <div key={property.slug} className="oneOfLatest" dir={en.length ? 'ltr' : 'rtl'}>
                                        <div className="titleOfLatest">
                                            <p>{ en.length ? en[0].name : ar.length ? ar[0].name:''}</p>
                                        </div>
                                        <hr/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className='latestData pb-0 h-100'>
                        <div className="latestHeader">
                            <p>latest blogs</p>
                            <Link to={`${api_basename}/dashboard/news/news-list`}>view blogs</Link>
                        </div>
                        <div className="allTitlesOfLatest">
                            {latestBlogs.map((blog)=> {
                                let en = filterLanguage(blog.locales,'en');
                                let ar = filterLanguage(blog.locales,'ar');
                                return(
                                    <div key={blog.slug} className="oneOfLatest" dir={en.length ? 'ltr' : 'rtl'}>
                                        <div className="titleOfLatest">
                                            <p>{ en.length ? en[0].name : ar.length ? ar[0].name:''}</p>
                                        </div>
                                        <hr/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
