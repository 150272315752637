import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get blogs 
export async function getBlogs(obj){
    return await AxiosClone.get(`${api_url}/admin/blogs?${obj}`);
}

//get blog
export async function getBlog(id){
    return await AxiosClone.get(`${api_url}/admin/blogs/${id}`);
}

//undelete blog
export async function undeleteBlog(id){
    return await AxiosClone.patch(`${api_url}/admin/blogs/${id}/restore`);
}

//edit blogs
export async function updateBlog(id ,data){
    return await AxiosClone.post(`${api_url}/admin/blogs/${id}`, data);
}

//delete blogs
export async function deleteBlog(id){
    return await AxiosClone.delete(`${api_url}/admin/blogs/${id}`);
}

//create blog
export async function createNewBlog(data){
    return await AxiosClone.post(`${api_url}/admin/blogs`, data);
}