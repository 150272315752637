import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import TitleContext from '../../TitleContext';
import "../News/News.scss";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import { deleteAdmin, getAdmins } from '../../Apis/adminApis';
import { api_basename, api_host } from './../../Configuration/Config';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import DeleteModal from '../../utilities/deleteModal';
import permissionsContext from '../../permissionsContext';

export default function UsersList() {
    let { Permissions } = useContext(permissionsContext);
    const { pathname } = useLocation();
    let { changeTitle } = useContext(TitleContext);
    let [allAdmins, setAllAdmins] = useState([]);
    const [adminId, setAdminId] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get admin id
    function getId(id) {
        setAdminId(id)
    }

    // get all admins
    function getAllAdmins() {
        let myallAdmins = [...allAdmins];
        getAdmins().then(res => {
            myallAdmins = res.data.data;
            console.log(myallAdmins)
            setAllAdmins(myallAdmins);
        }).catch(err =>
            console.log(err))
    }

    useEffect(() => {
        changeTitle(`users`);
        getAllAdmins();
        // eslint-disable-next-line
    }, []);

    // delete admin
    const onSubmitDelete = async () => {
        return await deleteAdmin(adminId).then(res => {
            getAllAdmins();
            return "done";
        }).catch(err => {
            return err.response.data.errors;
        })
    }

    return (
        <>
            {Permissions && Permissions.admin && 
            <section>
                <div style={{ "borderRadius": "8px", "border": "1px solid #DFE0EB" }}>
                    <div className='tableWrapper'>
                        <table className="w-100 table table-striped border-0">
                            <thead>
                                <tr>
                                    <th>photo</th>
                                    <th>username</th>
                                    <th>name</th>
                                    <th>email</th>
                                    <th>role</th>
                                    <th>actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allAdmins && allAdmins.map((admin, index) => (
                                    <tr key={index}>
                                        <td style={{ "width": "18%" }}>
                                            <div className='partnerLogoBoredr'>
                                                <img className='me-0 userPhoto' src={`${api_host}/${admin?.image?.image}`} alt="admin" />
                                            </div>
                                        </td>
                                        <td>{admin.username}</td>
                                        <td style={{ "width": "22%" }}>{admin.first_name} {admin.last_name}</td>
                                        <td style={{ "width": "18%" }}>{admin.email}</td>
                                        <td style={{ "width": "15%" }}>
                                            <div className={`${admin.role ? `adminRoleLabel` : ''} p-2`}>
                                                {admin.role && admin.role.name ? admin.role.name : 'no role'}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {Permissions.admin.edit && <Tooltip title="edit">
                                                    <Link to={`${api_basename}/dashboard/users/update-user/${admin.id}`}>
                                                        <img src={edit_icon} alt="edit" />
                                                    </Link>
                                                </Tooltip>}
                                                {Permissions.admin.delete && <Tooltip title="delete">
                                                    <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={() => getId(admin.id)} />
                                                </Tooltip>}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <DeleteModal onSubmitDelete={onSubmitDelete} name='admin'></DeleteModal>
                </div>
            </section>
            }
        </>
    )
}
