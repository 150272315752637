import React, { useContext, useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { api_basename } from '../../../Configuration/Config';
import TitleContext from '../../../TitleContext';
import { setAdminSecurityData } from './formHandler';
import formModel from './formModel';

export default function Form(props) {
    const { pathname } = useLocation();
    let {changeTitle} = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(()=>{
        changeTitle(`security settings`);
        // eslint-disable-next-line
    } , []);

    // onchange
    const setFormData = (e, property) => {
        setAdminSecurityData(model, setModel, e, property);
    }

    // onsubmit
    const confirmAdminSecurityInfo = async (e , model)=>{
        e.preventDefault();
        setLoading(true);

        // send request
        let submitProps = await props.onSubmit(e, model);
        if(submitProps === 'done'){
            setLoading(false);
            navigate(`${api_basename}/dashboard/users/all-admins`);
        }else{
            setLoading(false);
            setErrors(submitProps);
        }
    }

    return (
        <>
        <section className='componentGlobalWrapper'>
            {/*** change logged in admin password ***/}
            <div className='mb-5'>
                <h5 className='pageSubtitle'>change password</h5>
            </div>

            <form className='pagesForm' onSubmit={(e) => confirmAdminSecurityInfo(e, model)}>
                <div className='mb-4'>
                    <div className='d-flex flex-column'>
                        <label htmlFor='old-password' className='formLabel'>Current Password</label>
                        <input minLength={8} name="current_password" id='old-password' type='password' placeholder='Current Paassword' onChange={(e) => setFormData(e, 'current_password')} required pattern="\S(.*\S)?" title="This field is required"/>
                    </div>
                    {errors && errors.current_password && <div className="validateToDelete text-danger mt-1">{errors.current_password[0]}</div>}
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='new-password' className='formLabel'>New Password</label>
                    <input minLength={8} name="password" id='new-password' type='password' placeholder='New Paassword' onChange={(e) => setFormData(e, 'password')} required pattern="\S(.*\S)?" title="This field is required"/>
                </div>
                <div className='mb-4'>
                    <div className='d-flex flex-column'>
                        <label htmlFor='confirm-password' className='formLabel'>confirm Password</label>
                        <input minLength={8} name="password_confirmation" id='confirm-password' type='password' placeholder='Confirm Paassword' onChange={(e) => setFormData(e, 'password_confirmation')} required pattern="\S(.*\S)?" title="This field is required"/>
                    </div>
                    {errors && errors.password && <div className="validateToDelete text-danger mt-1">{errors.password[0]}</div>}
                </div>

                {!loading ? 
                    <button className='confirmBtn' type='submit'>save changes</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }
            </form>
        </section>
        </>
    )
}
