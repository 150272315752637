import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import TitleContext from '../../../TitleContext';
import uploadImage from "../../../assets/icons/uploadimage.svg";
import deleteIcon from "../../../assets/icons/removeIcon.svg";
import Tooltip from '@material-ui/core/Tooltip';
import "../../News/News.scss";
import formModel from './formModel';
import { api_basename, api_host } from '../../../Configuration/Config';
import { setMemberData } from './formHandler';
import { getMember } from '../../../Apis/teamMembersApi';
import { filterLanguage } from '../../../utilities/filterLang';


export default function Form(props) {
    const { pathname } = useLocation();
    let {changeTitle} = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const {id} = useParams();
    let memberImageRef = useRef();
    let [memberImage , setMemberImage] = useState('');
    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(()=>{
        changeTitle(`${id ? `edit` : `add`} team member`);
        // eslint-disable-next-line
    } , []);

    useEffect(() => {
        if (id) {
            getMember(id).then((res) => {
                let x = res.data.data;
                setModel({...x});
                if(x.status){
                    document.getElementById('statusCheck').checked = true;
                }
                setMemberImage(`${api_host}/${x.image}`);
            })
        }else{
            setModel({
                locales: [],
                image: '',
                status: 0
            })
        }
    }, [id]);

    // onchange
    const setFormData = (e, property , locale=false , language=null) => {
        setMemberData(model, setModel, e, property , locale , language);

        if(e.target.name === 'image'){
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setMemberImage(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
            // image validation
            let classes = Array.from(memberImageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                memberImageRef.current.nextSibling.classList.add("d-none");
            }
        }
        console.log(model)
    }

    // remove image
    function removeImgHandler(){
        setMemberImage('');
        let obj = { ...model };
        obj.image = '';
        setModel(obj)
    }

    // onsubmit
    const confirmMember = async (e , model)=>{
        e.preventDefault();
        let formData = new FormData();

        if (memberImage === '') {
            memberImageRef.current.nextSibling.classList.remove("d-none");
        }

        for(const key in model){
            if (key === 'locales') {
                for (let i = 0; i < model.locales.length; i++) {
                    for (const key in model.locales[i]) {
                        formData.append(`locales[${i}][${key}]`, model.locales[i][key]);
                    }
                }
            }else if(key === 'image'){
                if(memberImage !== `${api_host}/${model.image}`){
                    formData.append("image", model.image);
                }else{
                    delete model.image;
                }                
            }else if(key === 'status'){
                if(model.status === true || model.status === 1){
                    formData.append("status", 1);
                }else{
                    formData.append("status", 0)
                }
            }else{
                formData.append(key, model[key]);
            }
        }

        if (memberImage !== ''){
            setLoading(true);
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if(submitProps === "done"){
                setLoading(false);
                navigate(`${api_basename}/dashboard/users/all-members`);
                if(!id){
                    setModel({});
                    setMemberImage("");
                }
            }else{
                setLoading(false);
            }
        }
    }

    return (
        <>
        <section className='componentGlobalWrapper addBlog'>
            <form id="memberForm" onSubmit={(e) => confirmMember(e, model)}>
                <div className='d-flex justify-content-start'>
                    <div style={{"width" : "40%"}} className="me-4">
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='name' className='formLabel'>name (english)</label>
                            <input name="name" id='name' type='text' placeholder='name in english' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"en").length) ? filterLanguage(model.locales ,"en")[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , 'en')} required pattern="\S(.*\S)?" title="This field is required"/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='nameAr' className='formLabel'>name (arabic)</label>
                            <input name="name" id='nameAr' type='text' placeholder='name in arabic' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"ar").length) ? filterLanguage(model.locales ,"ar")[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , 'ar')} required pattern="\S(.*\S)?" title="This field is required"/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='title' className='formLabel'>job title (english)</label>
                            <input name="job_title" id='title' type='text' placeholder='job_title in english' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"en").length) ? filterLanguage(model.locales ,"en")[0].job_title : ""} onChange={(e) => setFormData(e, 'job_title' , true , 'en')} required pattern="\S(.*\S)?" title="This field is required"/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='title' className='formLabel'>job title (arabic)</label>
                            <input name="job_title" id='title' type='text' placeholder='job_title in arabic' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"ar").length) ? filterLanguage(model.locales ,"ar")[0].job_title : ""} onChange={(e) => setFormData(e, 'job_title' , true , 'ar')} required pattern="\S(.*\S)?" title="This field is required"/>
                        </div>
                        <div className='d-flex align-items-center mb-5'>                        
                            <label htmlFor='active_status' className='formLabel mb-0 me-3'>view on website</label>
                            <label className="switch mb-0">
                                <input type="checkbox" id='statusCheck' name='status' onChange={(e) => setFormData(e, 'status')}/>
                                <span className="slider round" />
                            </label>
                        </div>
                    </div>
                    <div style={{"width" : "20%"}} className="ms-5">
                        {/** admin image **/}
                        <div className='d-flex flex-column mb-4 w-100'>
                            { !memberImage ?
                                <div>
                                    <label ref={memberImageRef} htmlFor="uploadImage" style={{"width" : "100%" , "position" : "relative"}}>
                                        <div className='individualImageWrapper'>
                                            <input type='file' id='uploadImage' name='image' className="d-none" onChange={(e) => setFormData(e, 'image')}/>
                                            <img src={uploadImage} alt="upload"/>
                                            <span>Upload Image</span>
                                        </div>
                                    </label>
                                    <div className='validateToDelete text-danger d-none'>this field is required!</div>
                                </div>
                                :
                                <div>
                                    <div className="individualImageWrapper position-relative ">
                                        <Tooltip title='remove image'>
                                            <label htmlFor='viewImage' className='reloadAnotherIcon' onClick={removeImgHandler}>
                                                <img src={deleteIcon} alt="delete" width='23px' height='23px'/>
                                                {/* <input type='file' id='viewImage' name='image' className="d-none"/> */}
                                            </label>
                                        </Tooltip>
                                        <div className='previewedImg' style={{backgroundImage: `url(${memberImage})`}}>
                                        </div>
                                    </div>
                                    <div className='validateToDelete text-danger d-none'>this field is required!</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {!loading ? 
                    <button className='confirmBtn' type='submit'>{id ? `save changes` : `add member`}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }
            </form>
        </section>
        </>
    )
}
