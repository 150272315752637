import React from 'react';
import ReactPaginate from 'react-paginate';
import arrowRight from '../../assets/icons/arrowRight.svg';
import arrowLeft from '../../assets/icons/arrowLeft.svg';
import './pagination.css'
export default function Pagination({pagesNumber,pageChangeHandler,page}){
    return(
        <ReactPaginate
            previousLabel = { 
                <img src={arrowRight} alt='previous'/>
            }
            nextLabel = {
                <img src={arrowLeft} alt='after'/>
            }
            breakLabel = {'...'}
            pageCount = {pagesNumber}
            marginPagesDisplayed = {2}
            initialPage = {0}
            pageRangeDisplayed = {3}
            containerClassName = {'pagination justify-content-center'}
            onPageChange = {pageChangeHandler}
        /> 
    )
}
