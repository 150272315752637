let obj = {
    locales: [],
    category: '',
    city:'',
    area: '',
    type: '',
    facilities: [],
    specifications: [],
    youtube: '',
    latitude: 25.22544119246961,
    longitude: 55.36216696875,
    whatsapp: '',
    featured: 0,
    status: 0,
    images: [],
    old_images:[],
    price:'',
    developer:''
}
export default obj;