import React, { useContext, useEffect, useState } from 'react'
import TitleContext from '../../../TitleContext';
import "../Aboutus/Aboutus.scss";
import edit_icon from "../../../assets/icons/edit.svg";
import delete_icon from "../../../assets/icons/delete.svg";
import Tooltip from '@material-ui/core/Tooltip';
import { useLocation } from 'react-router';
import Form from './../../../Components/PageHeaderForm/PageHeaderForm';
import { getPageHeaderAndContent, updatePageHeaders } from '../../../Apis/pageHeadersApi';
import { deleteFaq, getFAQs } from '../../../Apis/FAQsApi';
import { Link } from 'react-router-dom';
import { api_basename } from '../../../Configuration/Config';
import DeleteModal from '../../../utilities/deleteModal';

export default function Faq() {
    let {changeTitle} = useContext(TitleContext);
    const { pathname } = useLocation();
    const [pageHeadersId ,setPageHeadersId] = useState({});
    let [allFAQs , setAllFAQs] = useState([]);
    const [faqId ,setFaqId] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get faq id
    function getId(id){
        setFaqId(id)
    }

    // get all FAQs
    function getAllFAQs(){
        let myallFAQs = [...allFAQs];
        getFAQs().then(res => {
            myallFAQs = res.data.data;
            setAllFAQs(myallFAQs);
        }).catch(err => 
            console.log(err))
    }

    // get faqs page headers
    useEffect(()=>{
        changeTitle(`faqs`);
        getAllFAQs();
        getPageHeaderAndContent('faqs-page').then(res => {
            let mypageHeadersId = res.data.data.headers['faqs-page'].id;
            setPageHeadersId(mypageHeadersId);
        }).catch(err => 
            console.log(err.response)
        )
        // eslint-disable-next-line
    } , []);

    // onsubmit page headers
    const onSubmit = async (e, data) => {
        e.preventDefault();
        data.append("_method", "patch");
        //to send request
        return await updatePageHeaders(pageHeadersId , data).then(res => {
            return "done";          
        }).catch(err =>{
            return err.response.data.errors;
        })
    };

    // delete FAQ
    const onSubmitDelete = async ()=>{
        return await deleteFaq(faqId).then(res =>{
            getAllFAQs();
            return 'done';
        }).catch(err => {
            return err.response.data.errors;
        })
    }

    return (
        <>
        <section className='componentGlobalWrapper aboutus'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>page title</h5>
            </div>
            <Form onSubmit={onSubmit} name={'faqs-page'}/>
        </section>

        <section className='componentGlobalWrapper aboutus'>
            <div className='mb-3 d-flex justify-content-between align-items-center'>
                <h5 className='pageSubtitle mb-0'>queries</h5>
                <div>
                    <Link to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/faq/add`} className='confirmBtn' type='submit'>add new</Link>
                </div>
            </div>

            <div>
                {allFAQs && allFAQs.map((faq , index) =>(
                    <div key={index} className='singleQuery'>
                        <p>{(faq && faq.locales && faq.locales.length)? faq.locales[0].name : ""}</p>
                        <div>                       
                            <Tooltip title="edit">
                                <Link to={`${api_basename}/dashboard/appearance/pages/faq/edit-faq/${faq.id}`}>
                                    <img src={edit_icon} alt="edit"/>
                                </Link>
                            </Tooltip>
                            <Tooltip title="delete">
                                <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={()=>getId(faq.id)}/>
                            </Tooltip>
                        </div>
                    </div>
                ))}
            </div>
        </section>

        <DeleteModal onSubmitDelete={onSubmitDelete} name='question'></DeleteModal>
        </>
    )
}
