export const setRoleData = (model, setModel, ele, property, locale=false, language=null) =>{
    let newData = Object.assign({}, model);
    if(locale){
        let existsLocales = model.locales.filter((i) => {
            return i.locale === language;
        })
        if(existsLocales.length){
            existsLocales[0][ele.target.name] = ele.target.value;
        }else{
            model.locales.push({'locale': language});
            model.locales.map((i) => {
                if(i.locale === language){
                    i[ele.target.name] = ele.target.value;
                }
                return true;
            });
        }
    }else{
        if(ele.target.checked) {
            newData.permissions.push(Number(ele.target.value));
        }else {
            let x = newData.permissions.indexOf(Number(ele.target.value));
            console.log(Number(ele.target.value))
            console.log(newData.permissions.indexOf(Number(ele.target.value)))
            newData.permissions.splice(x, 1)
        }
    }
    setModel(newData);
}