export const setPropertyData = (model, setModel, ele, property, locale=false, language=null , specification=false) =>{
    let newData = Object.assign({}, model);

    if(locale){
        let existsLocales = newData.locales.filter((i) => {
            return i.locale === language;
        })
        if(existsLocales.length){
            existsLocales[0][ele.target.name] = ele.target.value;
        }else{
            newData.locales.push({'locale': language });
            newData.locales.map((i) => {
                if(i.locale === language){
                    i[ele.target.name] = ele.target.value;
                }
                return true;
            });
        }
    }else if(specification){
        let existsObj = newData.specifications.filter((i) => {
            return i.key === Number(ele.target.name);
        })
        if(existsObj.length){
            existsObj[0]['value'] = Number(ele.target.value);
        }else{
            newData.specifications.push({'key': Number(ele.target.name) ,'value':Number(ele.target.value)});
        }
    }else{
        if (ele.target.name === 'image') {
            if(ele.target.files.length){
                newData['image'] = ele.target.files[0];
            }
        }else if(ele.target.name === 'facility'){
            if(!newData.facilities.includes(Number(ele.target.value))) {
                newData.facilities.push(Number(ele.target.value));
            }else {
                let x = newData.facilities.indexOf(Number(ele.target.value));
                newData.facilities.splice(x, 1)
            }
        }else if(ele.target.name === 'status' || ele.target.name === 'featured'){
            newData[property] = ele.target.checked ? 1 : 0;
        }else{
            newData[property] = ele.target.value;
        }
    }
    console.log(newData);

    setModel(newData);
}