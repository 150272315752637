import React, { useContext, useEffect } from 'react'
import { createNewBlog } from '../../../Apis/news';
import TitleContext from '../../../TitleContext';
import Form from './Form';

export default function AddPost() {
    let {changeTitle} = useContext(TitleContext);
    useEffect(()=>{
        changeTitle(`add post`);
        // eslint-disable-next-line
    } , []);

    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await createNewBlog(data).then(res => {
            return 'done';          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };

  return (
    <div>
        <Form onSubmit={onSubmit}/>
    </div>
  )
}
