import React from 'react';
import { Route, Routes } from "react-router-dom";
import Facilities from '../Pages/Facilities/Facilities';
import Categories from '../Pages/Categories/Categories';
import WebsitePages from '../Pages/WebsitePages/Allpages/WebsitePages';
import Aboutus from '../Pages/WebsitePages/Aboutus/Aboutus';
import Contactus from '../Pages/WebsitePages/Contactus/Contactus';
import PrivacyPolicy from '../Pages/WebsitePages/PrivacyPolicy/PrivacyPolicy';
import Faq from '../Pages/WebsitePages/Faq/Faq';
import TermsConditions from '../Pages/WebsitePages/TermsConditions/TermsConditions';
import HomeSections from '../Pages/WebsitePages/HomeSections/HomeSections';
import NewsList from '../Pages/News/NewsList';
import LeadsRequests from '../Pages/Leads/LeadsRequests';
import ContactRequests from '../Pages/Leads/ContactRequests';
import OurPartners from '../Pages/WebsitePages/OurPartners/OurPartners';
import PropertiesList from '../Pages/Properties/PropertiesList';
import UsersList from '../Pages/Users/UsersList';
import AddUser from '../Pages/Users/UserForm/Add';
import Scripts from '../Pages/Scripts/Scripts';
import MembersList from '../Pages/Members/MembersList';
import Roles from '../Pages/Roles/Roles';
import AddPage from '../Pages/WebsitePages/AddPage/AddPage';
import Types from '../Pages/Types/Types';
import CitiesAndAreas from '../Pages/CitiesAndAreas/CitiesAndAreas';
import AddArea from '../Pages/CitiesAndAreas/AddArea';
import Footer from '../Pages/Footer/Footer';
/* import Navbar from '../Pages/Navbar/Navbar'; */
import Add from '../Pages/Properties/PropertyForm/Add';
import EditArea from '../Pages/CitiesAndAreas/EditArea';
import Edit from '../Pages/Properties/PropertyForm/Edit';
import AddPost from './../Pages/News/NewsForm/Add';
import EditPost from './../Pages/News/NewsForm/Edit';
import EditUser from '../Pages/Users/UserForm/Edit';
import AddMember from './../Pages/Members/MemberForm/Add';
import EditMember from '../Pages/Members/MemberForm/Edit';
import EditGeneralSettings from './../Pages/GeneralSettings/GeneralSettingsForm/Edit';
import EditAccountSettings from '../Pages/AccountSettings/accountSettingsForm/Edit';
import EditSecuritySettings from '../Pages/SecuritySettings/SecuritySettingsForm/Edit';
import Addpartner from '../Pages/WebsitePages/OurPartners/PartnerForm/Add';
import EditPartner from '../Pages/WebsitePages/OurPartners/PartnerForm/Edit';
import AddSlider from '../Pages/WebsitePages/HomeSections/SliderForm/Add';
import EditSlider from '../Pages/WebsitePages/HomeSections/SliderForm/Edit';
import AddFaq from '../Pages/WebsitePages/Faq/FaqForm/Add';
import EditFaq from '../Pages/WebsitePages/Faq/FaqForm/Edit';
import Overview from '../Pages/overview/overview';
import DeveloperList from '../Pages/Developers/DevelopersList';
import AddDeveloper from '../Pages/Developers/developerForm/addDeveloper';
import EditDeveloper from '../Pages/Developers/developerForm/editDeveloper';
import DevelopersPage from '../Pages/WebsitePages/Developer/developersPage';

export default function AllRoutes() {
    return (
        <>
            <Routes>
                {/**** overview *****/}
                <Route path='overview' element={<Overview />} />
                <Route path='property-settings/' >
                    {/* <Route path='cities/*' element={<Cities />} />
                <Route path='areas/*' element={<Areas />} /> */}
                    <Route path='cities_areas/*' element={<CitiesAndAreas />} />
                    <Route path='cities_areas/add_area' element={<AddArea />} />
                    <Route path='cities_areas/edit/:id' element={<EditArea />} />
                    <Route path='facilities/*' element={<Facilities />} />
                    <Route path='categories/*' element={<Categories />} />
                    <Route path='types/*' element={<Types />} />
                </Route>
                {/**** properties *****/}
                <Route path='properties/' >
                    <Route path='properties-list' element={<PropertiesList />} />
                    <Route path='add-property' element={<Add />} />
                    <Route path='edit-property/:id' element={<Edit />} />
                </Route>
                {/**** developers *****/}
                <Route path='developers/' >
                    <Route path='developers-list' element={<DeveloperList />} />
                    <Route path='add-developer' element={<AddDeveloper />} />
                    <Route path='edit-developer/:id' element={<EditDeveloper />} />
                </Route>
                {/**** leads *****/}
                <Route path='leads/' >
                    <Route path='leads-requests' element={<LeadsRequests />} />
                    <Route path='contact-requests' element={<ContactRequests />} />
                </Route>
                {/**** news *****/}
                <Route path='news/' >
                    <Route path='news-list' element={<NewsList />} />
                    <Route path='add-new-post' element={<AddPost />} />
                    <Route path='update-post/:id' element={<EditPost />} />
                </Route>
                {/**** appearance *****/}
                <Route path='appearance/' >
                    {/* <Route path='navbar' element={<Navbar />} /> */}
                    <Route path='footer' element={<Footer />} />
                    <Route path='pages/'>
                        <Route path='' element={<WebsitePages />} />
                        <Route path='add' element={<AddPage />} />
                        <Route path='home/*' element={<HomeSections />} />
                        <Route path='home/add-slider' element={<AddSlider />} />
                        <Route path='home/edit-slider/:id' element={<EditSlider />} />
                        <Route path='about-us' element={<Aboutus />} />
                        <Route path='developers' element={<DevelopersPage />} />
                        <Route path='contact-us' element={<Contactus />} />
                        <Route path='privacy-policy' element={<PrivacyPolicy />} />
                        <Route path='terms-conditions' element={<TermsConditions />} />
                        <Route path='faq/*' element={<Faq />} />
                        <Route path='faq/add' element={<AddFaq />} />
                        <Route path='faq/edit-faq/:id' element={<EditFaq />} />
                        <Route path='partners/*' element={<OurPartners />} />
                        <Route path='partners/add' element={<Addpartner />} />
                        <Route path='partners/edit-partner/:id' element={<EditPartner />} />
                    </Route>
                </Route>
                {/**** users *****/}
                <Route path='users/' >
                    <Route path='all-admins' element={<UsersList />} />
                    <Route path='add-admin' element={<AddUser />} />
                    <Route path='update-user/:id' element={<EditUser />} />
                    <Route path='all-members' element={<MembersList />} />
                    <Route path='add-member' element={<AddMember />} />
                    <Route path='update-member/:id' element={<EditMember />} />
                    <Route path='roles/*' element={<Roles />} />
                </Route>
                {/**** settings *****/}
                <Route path='settings/' >
                    <Route path='general' element={<EditGeneralSettings />} />
                    <Route path='account' element={<EditAccountSettings />} />
                    <Route path='security' element={<EditSecuritySettings />} />
                    <Route path='scripts' element={<Scripts />} />
                </Route>
            </Routes>
        </>
    )
}
