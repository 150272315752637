import { useState } from 'react';
import { useNavigate } from "react-router";
import "./Login.scss";
import logo from '../../../assets/images/loginLogo.png';
import loginImg from "../../../assets/images/pexels-kevin-villaruz-1589237.jpg";
import closedeye from "../../../assets/icons/closeeye.svg";
import openeye from "../../../assets/icons/openeye.svg";
import { NavLink } from 'react-router-dom';
import { api_basename } from './../../../Configuration/Config';
import { adminLogin } from '../../../Apis/loginApi';

export default function Login() {
    const [passType, setPasstype] = useState('password');
    let navigate = useNavigate();
    let [loading , setLoading] = useState(false);
    let [error , setError] = useState('');
    let [Admin , setAdmin] = useState({email: "", password: ""});

    function showPasswordHandler() {
        if (passType === 'password') {
            setPasstype('text');
        } else {
            setPasstype('password');
        }
    }

    function adminHandler(e){
        let mainAdmin = {...Admin};
        mainAdmin[e.target.name] = e.target.value;
        setAdmin(mainAdmin);
    }

    function submitAdminLogin(e){
        e.preventDefault();
		setLoading(true);
        // to send request
        adminLogin(Admin).then(res => {
            console.log(res.data.data);
            const Admin_Token = res.data.data.token;
            const Admin_Name = res.data.data.admin.first_name;
            localStorage.setItem('rh_Admin_Token' , Admin_Token);
            localStorage.setItem('alomran_Admin_Name' , Admin_Name);
            setLoading(false);
            navigate(`${api_basename}/dashboard/overview`);
            window.location.reload();
        })
        .catch(err => {
            setLoading(false);
            console.log(err.response)
            error = err.response.data.errors.error;
            setError(error);
        })
    }


    return (
        <div className='position-relative h-100'>
            <div className="login-bg-img">
                <section className='login-wrapper'>
                    <img className='login-form-image d-none d-lg-block' src={loginImg} alt="login form"/>
                    <div className='login-form'>
                        <div className='formHeader'>
                            <img src={logo} alt="logo"/>
                            {/* <h1>AL-OMRAN</h1> */}
                            <p>REAL ESTATE</p>
                        </div>
                        <form className='w-100' onSubmit={submitAdminLogin}>
                            {error && <div className="alert alert-danger py-1">{error}</div>}
                            <div className='d-flex flex-column mb-4'>
                                <label htmlFor='username' className='formLabel'>Email</label>
                                <input onChange={adminHandler} name="email" className='formInput' id='username' type='email' placeholder='username' required/>
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label htmlFor='pass' className='formLabel mb-0'>password</label>
                                    <NavLink to={`${process.env.REACT_APP_BASENAME}/reset-password`} className='align-self-end forgetPass'>Forgot password?</NavLink>
                                </div>
                                <div className='position-relative'>
                                    <input onChange={adminHandler} name="password" className='formInput' id='pass' type={passType} placeholder='password' minLength='8' required/>
                                    <span
                                        onClick={showPasswordHandler}
                                        className={`eyeIcon`}
                                    >
                                        {passType === "password" ? (
                                            <img src={closedeye} alt="eye icon"/>
                                        )
                                            :
                                        (
                                            <img src={openeye} alt='eye icon'/>
                                        )
                                        }
                                    </span>
                        
                                </div>
                        
                                <div className='d-grid mt-5'>
                                    {!loading ? 
                                        <button type='submit' className='defaultBtn'>Log In </button>
                                        :
                                        <button className='defaultBtn' disabled>
                                        
                                            Login <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> ...
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
            <div className='login-bg-layer'></div>
        </div>
    )
}
