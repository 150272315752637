import React, { useContext, useEffect, useRef, useState } from 'react'
import TitleContext from '../../../../TitleContext';
import uploadImage from "../../../../assets/icons/uploadimage.svg";
/* import deleteIcon from "../../../../assets/icons/deleteIcon.svg"; */
import "../../../News/News.scss";
import { useLocation, useNavigate, useParams } from 'react-router';
import formModel from './formModel';
import { setPartnerData } from './formHandler';
import { api_basename, api_host, api_url } from '../../../../Configuration/Config';
import { getPartner } from '../../../../Apis/partnersApi';
import { filterLanguage } from '../../../../utilities/filterLang';
import Tooltip from '@material-ui/core/Tooltip';
import removeIcon from "../../../../assets/icons/removeIcon.svg";
import axios from 'axios';

export default function Form(props) {
    const { pathname } = useLocation();
    let {changeTitle} = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState([]);
    const {id} = useParams();
    let imageRef = useRef();
    let [partnerImage , setPartnerImage] = useState('');
    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(()=>{
        changeTitle(`our partners`);
        // eslint-disable-next-line
    } , []);

    useEffect(() => {
        if (id) {
            getPartner(id).then((res) => {
                let x = res.data.data;
                setPartnerImage(`${api_host}/${x.image?.image}`);
                let obj = {...x}
                obj.image = x?.image?.id
                setModel(obj);

                if(x.status){
                    document.getElementById('statusCheck').checked = true;
                }
            })
        }else{
            setModel({
                locales: [],
                image: '',
                status: 0,
                link: ''
            })
        }
    }, [id]);
    let [progress, setProgress] = useState(0)
    /* upload images */
    const token = `Bearer ` + localStorage.getItem('rh_Admin_Token');
    function addImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }

        axios.post(`${api_url}/admin/upload`, formData, config).then((res) => {
            let result = res.data.data;
            setPartnerImage(`${api_host}/${result.image}`)
            setProgress(0);
            let obj = { ...model };
            obj.image = result.id;
            setModel(obj)
            // image validation
            let classes = Array.from(imageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                imageRef.current.nextSibling.classList.add("d-none");
            }
        }).catch((err) => {
            console.log(err.response)
            setProgress(0);
        })
    }
    // onchange
    const setFormData = (e, property , locale=false , language=null) => {
        setPartnerData(model, setModel, e, property , locale , language);
    }

    // remove image
    function removeImgHandler(){
        setPartnerImage('');
        let obj = { ...model };
        obj.image = '';
        setModel(obj)
    }

    // onsubmit
    const confirmPartner = async (e , model)=>{
        e.preventDefault();
        let formData = new FormData();

        if (partnerImage === '') {
            imageRef.current.nextSibling.classList.remove("d-none");
        }

        for(const key in model){
            if (key === 'locales') {
                for (let i = 0; i < model.locales.length; i++) {
                    for (const key in model.locales[i]) {
                        formData.append(`locales[${i}][${key}]`, model.locales[i][key]);
                    }
                }
            }else if(key === 'image'){
                if(partnerImage !== `${api_host}/${model.image}`){
                    formData.append("image", model.image);
                }else{
                    delete model.image;
                }                
            }else if(key === 'status'){
                if(model.status === true || model.status === 1){
                    formData.append("status", 1);
                }else{
                    formData.append("status", 0)
                }
            }else{
                formData.append(key, model[key]);
            }
        }

        if (partnerImage !== ''){
            setLoading(true);
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if(submitProps === "done"){
                setLoading(false);
                navigate(`${api_basename}/dashboard/appearance/pages/partners`);
                if(!id){
                    setModel({});
                    setPartnerImage("");
                }
            }else{
                setLoading(false);
                setErrors(submitProps);
            }
        }
    }


    return (
        <>
        <section className='componentGlobalWrapper addBlog'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>add new partner</h5>
            </div>

            <form className="mt-5" id="partnerForm" onSubmit={(e) => confirmPartner(e, model)}>
                <div className='selectForm'>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_en' className='formLabel'>partner name in english</label>
                        <input name="name" id='name_en' type='text' placeholder='name in english' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"en").length) ? filterLanguage(model.locales ,"en")[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , 'en')} required pattern="\S(.*\S)?" title="This field is required"/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_ar' className='formLabel'>partner name in arabic</label>
                        <input name="name" id='name_ar' type='text' placeholder='name in arabic' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"ar").length) ? filterLanguage(model.locales ,"ar")[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , 'ar')} required pattern="\S(.*\S)?" title="This field is required"/>
                    </div>
                    <div className='mb-4'>
                        <div className='d-flex flex-column'>
                            <label htmlFor='link' className='formLabel'>partner url</label>
                            <input name="link" id='link' type='text' placeholder='link' value={(model && model.link) ? model.link : ""} onChange={(e) => setFormData(e, 'link')} required pattern="\S(.*\S)?" title="This field is required"/>
                        </div>
                        {errors && errors.link && <div className="validateToDelete text-danger mt-1">{errors.link[0]}</div>}
                    </div>
                    
                    {/** partner logo **/}
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='logo' className='formLabel'>image</label>
                        { !partnerImage ?
                            <div>
                                <label ref={imageRef} htmlFor="uploadImage" className='position-relative'>
                                    <div className='globalImageWrapper'>
                                        <input onChange={(e) => addImage(e)}  type='file' id='uploadImage' name='image' className="d-none"/>
                                        <img className='cloudIcon' src={uploadImage} alt="upload"/>
                                        <span>Upload Image</span>
                                    </div>
                                </label>
                                {progress !== 0 && (
                                <div className="progress mt-2 p-0" style={{ width: '11.25rem' }}>
                                    <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                        aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                        {progress}%
                                    </div>
                                </div>)}
                                <div className='validateToDelete text-danger d-none'>This field is required!</div>

                            </div>
                            :
                            <div className='globalImageWrapper position-relative'>
                                <Tooltip title='Remove Image'>
                                    <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                        <img src={removeIcon} alt="delete" onClick={removeImgHandler}/>
                                    </label>
                                </Tooltip>
                                <div className='globalPreviewedImage' style={{backgroundImage: `url(${partnerImage})` , 'backgroundSize': 'contain', 'width' : '80%' , 'height' : '80%'}}></div>
                            </div>
                        }
                    </div>
                    
                    {/** active status ***/}
                    <div className='d-flex align-items-center mb-5'>                        
                        <label htmlFor='active_status' className='formLabel mb-0 me-3'>view on website</label>
                        <label className="switch mb-0">
                            <input type="checkbox" id='statusCheck' name='status' onChange={(e) => setFormData(e, 'status')}/>
                            <span className="slider round" />
                        </label>
                    </div>
                </div>

                {!loading ? 
                    <button className='confirmBtn' type='submit'>{id ? `save changes` : `add partner`}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                }
            </form>
        </section>
        </>
    )
}
