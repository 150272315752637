import React, { useContext, useEffect, useRef, useState } from 'react';
import TitleContext from '../../TitleContext';
import { useLocation } from 'react-router';
import "../News/News.scss";
import search from "../../assets/icons/search.svg";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import { createNewCity, deleteCity, editCity, getCities, getCity } from '../../Apis/citiesApi';
import { filterLanguage } from './../../utilities/filterLang';
import { deleteArea, getAreas } from '../../Apis/areasApi';
import { api_basename, api_host } from './../../Configuration/Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import permissionsContext from '../../permissionsContext';

export default function CitiesAndAreas() {
    let { Permissions } = useContext(permissionsContext);
    const [tabSelect,setTabSelect] = useState('cities');
    let { changeTitle } = useContext(TitleContext);
    const { pathname } = useLocation();
    let [errors, setErrors] = useState([]);
    const [filterObj, setfilterObj] = useState({ search: '', per_page: '10', page: '1', city: '' })
    let closeEditCityModal = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // city variables
    const [loading, setLoading] = useState(false);
    let [allCities, setAllCities] = useState([]);
    let [cityNumber, setCityNumber] = useState("");
    let [newCity, setnewCity] = useState({ locales: [], country: '1' });

    let [oneCity, setOneCity] = useState({});
    let [cityId, setCityId] = useState("");
    let [validateDelete, setValidateDelete] = useState("");
    let deleteModalRef = useRef();
    let deleteAreaModalRef = useRef();
    let closeDeleteCityModalRef = useRef();

    // area variables
    let [allAreas, setAllAreas] = useState([]);
    let [areaNumber, setAreaNumber] = useState("");
    let [areaId, setAreaId] = useState("");
    let closeDeletAreaModalRef = useRef();

    // get all cities
    function getAllCities() {
        let myallCities = [...allCities];
        getCities().then(res => {
            myallCities = res.data.data;
            setAllCities(myallCities);
            cityNumber = myallCities.length;
            setCityNumber(cityNumber);
            /*  console.log(res.data.data); */
        }).catch(err =>
            console.log(err))
    }

    // get all areas
    function getAllAreas() {
        let obj = `search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}&page=${filterObj &&
            filterObj.page}&city=${filterObj && filterObj.city}`;
        let myallAreas = [...allAreas];
        console.log(obj)
        getAreas(obj).then(res => {
            myallAreas = res.data.data;
            console.log(res.data.data)
            setAllAreas(myallAreas);
            areaNumber = myallAreas.length;
            setAreaNumber(areaNumber);
        }).catch(err =>
            console.log(err))
    }

    useEffect(() => {
        changeTitle(`cities`);
        getAllCities();
        getAllAreas();
        console.log(tabSelect)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        changeTitle((tabSelect && tabSelect === "areas") ? "areas" : "cities");
        // eslint-disable-next-line
    }, [tabSelect])

    // get one city
    function getOneCity(d) {
        cityId = d;
        setCityId(cityId);
        getCity(d).then(res => {
            let x = res.data.data;
            oneCity = { ...x, "locales": x.locales, "country": x.country ? x.country.id : "1", "slug": x.slug };
            setOneCity(oneCity);
        }).catch(err =>
            console.log(err))
    }

    // add city
    function handleCityChange(e) {
        let myNewCity = { ...newCity };
        let language = e.target.getAttribute('data-lang');

        let exists = myNewCity.locales.filter((i) => {
            return i.locale === language;
        })
        if (exists.length) {
            exists[0][e.target.name] = e.target.value;
        } else {
            myNewCity.locales.push({ 'locale': language });
            myNewCity.locales.map((i) => {
                if (i.locale === language) {
                    i[e.target.name] = e.target.value;
                }
                return true;
            });
        }
        setnewCity(myNewCity);
    }

    function addCitySubmit(e) {
        e.preventDefault();
        setLoading(true);
        if (newCity !== "") {
            createNewCity(newCity).then(res => {
                setLoading(false);
                document.getElementById('addCityForm').reset();
                getAllCities();
            }).catch(err => {
                setLoading(false);
                console.log(err.response)
            })
        }
    }

    // edit city
    function editCityChange(e) {
        let myoneCity = { ...oneCity };
        let language = e.target.getAttribute('data-lang');
        let exists = filterLanguage(myoneCity.locales, language);
        if (exists.length) {
            exists[0][e.target.name] = e.target.value;
        }
        setOneCity(myoneCity);
    }

    function editCitySubmit(e) {
        e.preventDefault();
        setLoading(true);
        let id = oneCity.id;

        editCity(oneCity, id).then(res => {
            setLoading(false);
            getAllCities();
            closeEditCityModal.current.click();
        }).catch(err => {
            setLoading(false);
            console.log(err.response)
        })
    }

    // to validate before deleting
    function deleteValidation(e) {
        validateDelete = e.target.value;
        setValidateDelete(validateDelete);
        if (validateDelete === "DELETE") {
            deleteModalRef.current.classList.add("d-none");
            deleteAreaModalRef.current.classList.add("d-none");
        }
    }

    // delete city
    function confirmDeleteCity(e) {
        e.preventDefault();
        let id = cityId;

        if (validateDelete === "DELETE") {
            setLoading(true);
            // send request
            deleteCity(id).then(res => {
                setLoading(false);
                closeDeleteCityModalRef.current.click();
                document.getElementById('deleteCityForm').reset();
                getAllCities();
                console.log(res.data.data);
            }).catch(err => {
                setLoading(false);
                let x = err.response.data.errors;
                setErrors(x);
            })
        } else {
            deleteModalRef.current.classList.remove("d-none");
        }
    }

    // delete area
    function confirmDeleteArea(e) {
        e.preventDefault();
        let id = areaId;

        if (validateDelete === "DELETE") {
            setLoading(true);
            // send request
            deleteArea(id).then(res => {
                setLoading(false);
                closeDeletAreaModalRef.current.click();
                document.getElementById('deleteAreaForm').reset();
                getAllAreas();
                console.log(res.data.data);
            }).catch(err => {
                setLoading(false);
                let x = err.response.data.errors;
                setErrors(x);
            })
        } else {
            deleteAreaModalRef.current.classList.remove("d-none");
        }
    }

    // reset delete modal data
    function resetInput() {
        document.getElementById('deleteAreaForm').reset();
        document.getElementById('deleteCityForm').reset();
        deleteModalRef.current.classList.add("d-none");
        deleteAreaModalRef.current.classList.add("d-none");
        setValidateDelete("");
        setErrors([]);
    }

    /* change filter obj */
    function changeFilterObj(e) {
        let thisObj = { ...filterObj }
        thisObj[e.target.name] = e.target.value;
        thisObj.page = 1
        setfilterObj(thisObj);
    }

    /* submit search */
    function getFilteredNews(e) {
        getAllAreas();
    }

    function selectAreaTab(){
        changeTitle(`areas`);
        setTabSelect('areas')

    }
    return (
        <>
            <section className='componentGlobalWrapper'>
                <div>
                    <div className='tabs-container nav nav-tabs mb-4 mt-2' id="myTab" role="tablist">
                        <div className={`tablinks ${tabSelect === "cities" ? 'active' : ""}`} id="cities-tab" data-bs-toggle="tab" data-bs-target="#cities" onClick={() => changeTitle(`cities`)}>cities <span className='tabcounter'>({`${cityNumber}`})</span></div>
                        <span className='separator'></span>
                        <div className={`tablinks ${(tabSelect === "areas") ? "active" : ""}`} id="areas-tab" data-bs-toggle="tab" data-bs-target="#areas" onClick={selectAreaTab}> areas <span className='tabcounter'>({`${areaNumber}`})</span></div>
                    </div>

                    <div className='tab-content' id="myTabContent">
                        {/*** cities tab content *****/}
                        {Permissions?.city && <div className={`tab-pane fade show active`} id="cities" aria-labelledby="cities-tab">
                            {Permissions.city?.create && <div className='d-flex justify-content-end mb-4'>
                                <button className='confirmBtn tabsBarBtn' data-bs-toggle="modal" data-bs-target="#addCityModal">add city</button>
                            </div>}
                            <div className='tableWrapper'>
                                <table className="w-100 table table-striped">
                                    <thead>
                                        <tr>
                                            <th>city</th>
                                            <th>areas</th>
                                            <th>country</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allCities && allCities.map((city, index) => (
                                            <tr key={index}>
                                                <td style={{ "width": "30%" }}>{(city && city.locales) ? city.locales[0].name : ""}</td>
                                                <td style={{ "width": "25%" }}>{city.areas_count}</td>
                                                <td style={{ "width": "30%" }}>
                                                    UAE
                                                </td>
                                                <td>
                                                    <div>
                                                        {Permissions.city.edit && <img src={edit_icon} alt="edit" data-bs-toggle="modal" data-bs-target='#editCityModal' onClick={() => getOneCity(city.id)} />}
                                                        {Permissions.city.delete && <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteCityModal' onClick={() => setCityId(city.id)} />}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>}

                        {/*** areas tab content *****/}
                        {Permissions?.area && <div className={`tab-pane fade `} id="areas" aria-labelledby="areas-tab">
                            
                            <div className='searchActionsBar justify-content-between align-items-center w-100 mt-0'>
                                <div className='searchActionsBar mt-2'>
                                    {/*** search input ***/}
                                    <div className='searchInputWrapper me-0'>
                                        <div>
                                            <input name='search' onChange={changeFilterObj} type='text' className='barSearchInput' />
                                            {/*  <span className="inputPlaceholder">search</span> */}
                                        </div>
                                        <img src={search} alt='search-icon' className='barSearchIcon' />
                                    </div>
                                    {/* filter city */}
                                    <div className='cityFilter'>
                                        <select name='city' onChange={changeFilterObj} className='form-select citySelect' defaultValue=''>
                                            <option value=''>All cities</option>
                                            {allCities && allCities.map((city, index) => {
                                                return (<option key={index} value={city.id}>{filterLanguage(city.locales, 'en') ? filterLanguage(city.locales, 'en')[0].name
                                                    : filterLanguage(city.locales, 'ar')[0].name}</option>)
                                            })
                                            }
                                        </select>
                                    </div>
                                    {/*** apply search btn  ***/}
                                    <button className='applySearchBtn' onClick={getFilteredNews}>apply</button>
                                </div>
                                {Permissions.area?.create && <div className='d-flex justify-content-end mb-0'>
                                <Link to={`${api_basename}/dashboard/property-settings/cities_areas/add_area`} className='confirmBtn tabsBarBtn mt-0'>add area</Link>
                            </div>}
                            </div>
                            <div className='tableWrapper'>
                                <table className="w-100 table table-striped">
                                    <thead>
                                        <tr>
                                            <th>image</th>
                                            <th>name</th>
                                            <th>city</th>
                                            <th>no.</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allAreas && allAreas.map((area, index) => (
                                            <tr key={index}>
                                                <td className='sliderImg' style={{ "width": "23%" }}>
                                                    {area?.image ?
                                                        <img src={`${api_host}/${area?.image?.image}`} alt="slider" />
                                                        :'-'
                                                    }
                                                </td>
                                                <td style={{ "width": "20%" }}>
                                                    {(area && area.locales) ? area.locales[0].name : ""}
                                                </td>
                                                <td style={{ "width": "13%" }}>
                                                    {(area.city && area.city.locales) ? area.city.locales[0].name : ""}
                                                </td>
                                                <td style={{ "width": "20%" }}>{area.properties_count}</td>
                                                <td>
                                                    <div>
                                                        {/* <label>
                                                        <span className={`badge ${area.featured ? `featuredBadge` : `notFeaturedBadge`} `}>Featured</span>
                                                    </label> */}
                                                        {Permissions.area.edit && <Tooltip title='edit'>
                                                            <Link to={`${api_basename}/dashboard/property-settings/cities_areas/edit/${area.id}`}>
                                                                <img src={edit_icon} alt="edit" />
                                                            </Link>
                                                        </Tooltip>}
                                                        {Permissions.area.delete && <Tooltip title='delete'>
                                                            <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteAreaModal' onClick={() => setAreaId(area.id)} />
                                                        </Tooltip>}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>}
                    </div>

                    {/**** add city modal ****/}
                    <div className="modal fade" id="addCityModal" tabIndex={-1} aria-labelledby="addCityModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" >
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={addCitySubmit} id="addCityForm">
                                        <div className='formInputsWrapper'>
                                            <div className='d-flex flex-column mb-4'>
                                                <label htmlFor='name_en' className='formLabel'>name in english</label>
                                                <input onChange={handleCityChange} name="name" data-lang='en' id='name_en' type='text' pattern="^(.*)[^ ](.*)$" required />
                                            </div>
                                            <div className='d-flex flex-column mb-4'>
                                                <label htmlFor='name_ar' className='formLabel'>name in arabic</label>
                                                <input onChange={handleCityChange} name="name" data-lang='ar' id='name_ar' type='text' pattern="^(.*)[^ ](.*)$" required />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            {!loading ?
                                                <button className='confirmBtn' type='submit'>add city</button>
                                                :
                                                <button className='confirmBtn' disabled>
                                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    saving...
                                                </button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/**** edit city modal ****/}
                    <div className="modal fade" id="editCityModal" tabIndex={-1} aria-labelledby="editCityModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div ref={closeEditCityModal} type="button" className="closeModalIcon" data-bs-dismiss="modal" aria-label="close" >
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={editCitySubmit}>
                                        <div className='formInputsWrapper'>
                                            <div className='d-flex flex-column mb-4'>
                                                <label htmlFor='name_en' className='formLabel'>name in english</label>
                                                <input onChange={editCityChange} value={(oneCity && oneCity.locales && oneCity.locales.length) ? filterLanguage(oneCity.locales, "en")[0].name : ""} name="name" data-lang='en' id='name_en' type='text' pattern="^(.*)[^ ](.*)$" required />
                                            </div>
                                            <div className='d-flex flex-column mb-4'>
                                                <label htmlFor='name_ar' className='formLabel'>name in arabic</label>
                                                <input onChange={editCityChange} value={(oneCity && oneCity.locales && oneCity.locales.length) ? filterLanguage(oneCity.locales, "ar")[0].name : ""} name="name" data-lang='ar' id='name_ar' type='text' pattern="^(.*)[^ ](.*)$" required />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            {!loading ?
                                                <button className='confirmBtn' type='submit'>edit city</button>
                                                :
                                                <button className='confirmBtn' disabled>
                                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    saving...
                                                </button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/**** delete city modal ****/}
                    <div className="modal fade" id="deleteCityModal" tabIndex={-1} aria-labelledby="deleteCityModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div ref={closeDeleteCityModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={resetInput}>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <form id="deleteCityForm" onSubmit={confirmDeleteCity}>
                                        <div className='formInputsWrapper'>
                                            <p>Do you want to delete this city ?</p>
                                            <div className='mb-4'>
                                                <div className="form-floating">
                                                    <input onChange={deleteValidation} className='w-100 form-control' name="validate" id='floatingInput' type='text' placeholder='delete' required />
                                                    <label htmlFor="floatingInput">please type <span>"DELETE"</span> to confirm</label>
                                                </div>
                                                <div ref={deleteModalRef} className='validateToDelete d-none text-danger mt-1'>
                                                    please type "DELETE" in capital letters.
                                                </div>
                                                {(errors && errors.length) ? <div className="validateToDelete text-danger mt-1">{errors[0]}</div> : ''}
                                            </div>
                                            {/*** modal footer ***/}
                                            <div className='d-flex justify-content-end'>
                                                <div className='cancelBtn' onClick={resetInput} data-bs-dismiss="modal">cancel</div>
                                                {!loading ?
                                                    <button className={validateDelete === "DELETE" ? `deleteBtn` : `confirmBtn`} type='submit'>delete</button>
                                                    :
                                                    <button className='confirmBtn deleteBtn' disabled>
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                        deleting...
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/**** delete area modal ****/}
                    <div className="modal fade" id="deleteAreaModal" tabIndex={-1} aria-labelledby="deleteAreaModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div ref={closeDeletAreaModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={resetInput}>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <form id="deleteAreaForm" onSubmit={confirmDeleteArea}>
                                        <div className='formInputsWrapper'>
                                            <p>Do you want to delete this area ?</p>
                                            <div className='mb-4'>
                                                <div className="form-floating">
                                                    <input onChange={deleteValidation} className='w-100 form-control' name="validate" id='floatingInput2' type='text' placeholder='delete' required />
                                                    <label htmlFor="floatingInput2">please type <span>"DELETE"</span> to confirm</label>
                                                </div>
                                                <div ref={deleteAreaModalRef} className='validateToDelete d-none text-danger mt-1'>
                                                    please type "DELETE" in capital letters.
                                                </div>
                                                {(errors && errors.length) ? <div className="validateToDelete text-danger mt-1">{errors[0]}</div> : ''}
                                            </div>
                                            {/*** modal footer ***/}
                                            <div className='d-flex justify-content-end'>
                                                <div className='cancelBtn' onClick={resetInput} data-bs-dismiss="modal">cancel</div>
                                                {!loading ?
                                                    <button className={validateDelete === "DELETE" ? `deleteBtn` : `confirmBtn`} type='submit'>delete</button>
                                                    :
                                                    <button className='confirmBtn deleteBtn' disabled>
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                        deleting...
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
