import React from 'react';
import { createNewType } from '../../../Apis/typesApi';
import Form from './Form';

export default function Add(props) {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await createNewType(data).then(res => {
            props.getAll();
            console.log(res.data.data);
            return "done";          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };

    return (
        <>
            <section className='actionCardSideWrapper'>
                <div>
                    <h5>add new type</h5>
                </div>
                <Form onSubmit={onSubmit}></Form>
            </section>
        </>
    )
}
