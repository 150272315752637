import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get Social
export async function getSocial(){
    return await AxiosClone.get(`${api_url}/admin/socials`);
}

//get Social
export async function createSocial(data){
    return await AxiosClone.post(`${api_url}/admin/socials`,data);
}

//update Social
export async function UpdateSocial(id,data){
    return await AxiosClone.post(`${api_url}/admin/socials/${id}`, data);
}

//update Social
export async function deleteSocial(id){
    return await AxiosClone.delete(`${api_url}/admin/socials/${id}`);
}

//get copyrights
export async function getSettings(){
    return await AxiosClone.get(`${api_url}/admin/settings`);
}