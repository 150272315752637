import React, { useContext, useEffect, useState } from 'react';
import TitleContext from '../../TitleContext';
import uploadImage from "../../assets/icons/uploadimage.svg";
import { useLocation } from 'react-router';
import expandIcon from "../../assets/icons/expand.svg";
import "./Socials.scss";
import plus from "../../assets/icons/plus.svg";
import { createSocial, deleteSocial, getSocial, UpdateSocial } from '../../Apis/footer';
import { filterLanguage } from '../../utilities/filterLang';
import { api_host } from '../../Configuration/Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import removeIcon from "../../assets/icons/removeIcon.svg";
import Tooltip from '@material-ui/core/Tooltip';
import DeleteModal from './../../utilities/deleteModal';

export default function Footer() {
    let {changeTitle} = useContext(TitleContext);
    const [socialMedia ,setSocialMedia] = useState([]);
    const [imagesSrcs ,setImagesSrcs] = useState([]);
    const [openAddForm ,setAddForm] = useState(false);
    const [model, setModel] = useState({locales : [{locale:'en' ,name:''}] ,image:'' ,link:''});
    const [errors ,setErrors] = useState({link:'',image:'',locales:{}});
    const [errorsArr ,setErrorsArr] = useState([]);
    const [socialImg ,setSocialImg] = useState('');
    const [loaders, setLoaders] = useState([]);
    const [addLoader, setAddLoader] = useState(false);
    // const [rightsLoader, setRightsLoader] = useState(false);
    // const [copyrights ,setCopyrights] = useState({id:'', locales:[{description:'' ,locale:''}]})
    
    useEffect(()=>{
        changeTitle(`footer`);
        getAllSocial();
        // eslint-disable-next-line
    } , []);

    function getAllSocial(){
        getSocial().then(res=>{
            let socials = [...socialMedia];
            socials = res.data.data
            setSocialMedia(socials);
            let arr = [...errorsArr];
            let images = [...imagesSrcs];
            let flags = [...loaders];
            socials.map((s)=>{
                let obj = {id:s.id ,src:`${api_host}/${s.image}`};
                images.push(obj);
                let err = {link:'',image:''};
                arr.push(err);
                flags.push(false);
                return true;
            })
            setLoaders(flags);
            setImagesSrcs(images);
            setErrorsArr(arr)
        })
    }

    // function getCopyRights(){
    //     getSettings().then((res)=>{
    //         let data =  res.data.data;
    //         let filtered = data.filter((obj)=>(obj.slug === 'rights'))[0];
    //         let rights = {...copyrights};
    //         rights.id = filtered.id;
    //         rights.locales = filtered.locales;
    //         setCopyrights(rights);
    //     })
    // }

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    /* ************************ update copy rights ******************************* */
    function changeHandler(e ,id){
        let social = socialMedia.map((el)=>({...el}));  
        let images = imagesSrcs.map((el)=>({...el}))
        social.map((ele)=>{
            if(ele.id === id){
                if(e.target.name === 'name'){
                    console.log(ele.locales[e.target.name]);
                    let existsLocales = ele.locales.filter((i) => {
                        return i.locale === 'en';
                    })
                    existsLocales[0][e.target.name] = e.target.value;
                    console.log(social);
                    /* setSocialMedia(social); */
                }else if(e.target.name === 'image'){
                    ele[e.target.name] = e.target.files[0];
                    images.map((img)=>{
                        if(img.id === id){
                            img.src = URL.createObjectURL(e.target.files[0]);
                        }
                        return true
                    })
                }else{
                    ele[e.target.name] = e.target.value
                }
            }
            return true
        })
        setImagesSrcs(images);
        setSocialMedia(social);
    }

    //remove image handler
    function removeImgHandler(id){
        let images = imagesSrcs.map((el)=>({...el}));
        let social = socialMedia.map((el)=>({...el}));

        images.map((img)=>{
            if(img.id === id){
                img.src = '';
            }
            return true
        });

        social.map((ele)=>{
            if(ele.id === id){
                ele.image = ''
            }
            return true
        })
        setImagesSrcs(images);
        setSocialMedia(social);
        
    }

    function confirmChanges(e ,id ,index){
        let flags = [...loaders];
        flags[index] = true;
        setLoaders(flags);
        e.preventDefault();
        let formData = new FormData();
        let x = socialMedia.filter((ele)=>(ele.id === id))[0];
        let img = imagesSrcs.filter((img)=>(img.id === id))[0];

        for(const key in x){
            if (key === 'locales') {
                for (let i = 0; i < x.locales.length; i++) {
                    for (const key2 in x.locales[i]){
                            formData.append(`locales[${i}][${key2}]`, x.locales[i][key2]);
                        }
                }
            }else if(key === 'image'){
                if(`${api_host}/${x.image}` !== img.src){
                    formData.append("image", x.image);
                }               
            }else if(key === 'link'){
                formData.append(key, x[key]);
            }
        }

        formData.append("_method", "patch");
        for (var pair of formData.entries()){
            console.log(pair[0]+ ', ' + pair[1]); 
        }

        UpdateSocial(id,formData).then((res)=>{
            let arr = [...errorsArr];
            arr[index] = {link:'',image:'',locales:{}};
            setErrorsArr(arr);
            console.log(res.data.data);
            let flags = [...loaders];
            flags[index] = false;
            setLoaders(flags);
        }).catch((err) => {
            let arr = [...errorsArr];
            //let obj = arr.filter((a,i)=>(i === index))[0];
            //setErrorsArr([...errorsArr])
            arr[index] = err.response.data.errors;
            setErrorsArr(arr);
            let flags = [...loaders];
            flags[index] = false;
            setLoaders(flags);
        })

    }
    
    /* ****************************** add social ******************************* */
    function addSocial(){
        if(openAddForm){
            setAddForm(false)
        }else{
            setAddForm(true)
        }
    }

    function addHandler(e){
        let thisModel = {...model}
        if(e.target.name === 'name'){
            let existsLocales = thisModel.locales.filter((i) => {
                return i.locale === 'en';
            })
            existsLocales[0][e.target.name] = e.target.value;
        }else if(e.target.name === 'image'){
            thisModel[e.target.name] = e.target.files[0];
            setSocialImg(URL.createObjectURL(e.target.files[0]));
            
        }else{
            thisModel[e.target.name] = e.target.value
        }
        setModel(thisModel);
    }

    function removeAddImgHandler(){
        setSocialImg('');
        let m ={...model};
        m.image = '';
        setModel(m)
    }

    function confirmAdding(e){
        e.preventDefault();
        setAddLoader(true)
        let formData = new FormData();
        for(const key in model){
            if (key === 'locales') {
                for (let i = 0; i < model.locales.length; i++) {
                    for (const key2 in model.locales[i]){
                            formData.append(`locales[${i}][${key2}]`, model.locales[i][key2]);
                        }
                }
            }else {
                formData.append(key, model[key]);
            }
        }
        for (var pair of formData.entries()){
            console.log(pair[0]+ ', ' + pair[1]); 
        }

        createSocial(formData).then((res)=>{
            getAllSocial();
            setAddForm(false);
            setAddLoader(false);
            setSocialImg('');
            let m ={locales : [{locale:'en' ,name:''}] ,image:'' ,link:''}
            setModel(m);
            setErrors({link:'',image:'',locales:{}});
        }).catch((err)=>{
            setSocialImg('');
            setErrors(err.response.data.errors);
            setAddLoader(false)
        })

    }

    /* **************************** delete  ****************************** */
    const[deletedId ,setDeletedId] = useState('');

    const onSubmitDelete = async ()=>{
        return await deleteSocial(deletedId).then(res =>{
            getAllSocial();
            return "done";
        }).catch(err => {
            return err.response.data.errors;
        })
    }

    /* *************************** copy rights ****************************** */
    // function changeRightsHandler(e){
    //     let thisRights = {...copyrights}
    //     let existsLocales = thisRights.locales.filter((i) => {
    //         return i.locale === 'en';
    //     })
    //     existsLocales[0][e.target.name] = e.target.value;
    //     setCopyrights(thisRights);
    // }


    // function editCopyRights(e){
    //     let thisRights = {...copyrights}
    //     setRightsLoader(true);
    //     e.preventDefault();
    //     updatePageContent(thisRights.id,{...copyrights,'_method':'patch'}).then((res=>{
    //         console.log(res.data.data);
    //         setRightsLoader(false);
    //     })).catch((err) =>{
    //         setRightsLoader(false);
    //         console.log(err.response.data)
    //     })
    // }
    return (
        <>
            <section className='componentGlobalWrapper'>
                <div className='d-flex justify-content-between align-items-center mb-5'>
                    <h5 className='pageSubtitle'>social links</h5>
                </div>

                {socialMedia && socialMedia.map((social , i)=>{
                    let en = filterLanguage(social.locales, 'en')[0];
                    let img = imagesSrcs.filter((img)=>(img.id === social.id))[0]; 
                    return(
                    <div key={i} className='accordion' style={{ "width": "43%" }}>
                        {/**** socials *****/}
                        <div className="accordion-item socials_accordion">
                            <h2 className="accordion-header">
                                <div className='accordion_header_container'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <img src={expandIcon} alt="expand"/>
                                        <p className='mb-0'>{social.slug}</p>
                                    </div>
                                    <button className="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#${social.slug}`} aria-expanded="false" aria-controls="facebook"/>
                                </div>
                            </h2>
                            <div id={social.slug} className="accordion-collapse collapse" >
                                <div className="accordion-body">
                                    <form onSubmit={(e)=>{confirmChanges(e,social.id ,i)}}>
                                        <div className='d-flex flex-column mb-4'>
                                            <label htmlFor='name2' className='formLabel'>social networks</label>
                                            <input name="name" id='name2' type='text'
                                            onChange={(e)=>changeHandler(e,social.id)} placeholder='facebook' value={en.name}/>
                                        </div>
                                        <div className='d-flex flex-column mb-4'>
                                            <label htmlFor='link' className='formLabel'>url</label>
                                            <input name="link" id='link' type='text' placeholder='social link' onChange={(e)=>changeHandler(e,social.id)} value={social.link} />
                                            {
                                                errorsArr && errorsArr[i] && errorsArr[i].link &&
                                                <div className='text-danger validateToDelete'>
                                                    {errorsArr[i].link}
                                                </div>
                                            }
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <label htmlFor='favicon' className='formLabel'>icon</label>
                                            <div className='imageWrapper p-0 imageWrapper2' style={{"width" : "50px" , "height" : "50px"}}>
                                                { !social.image ?
                                                    <label className='mb-0' htmlFor="uploadImage" style={{"width" : "100%" ,"height" : "100%" , "position" : "relative"}}>
                                                        <input onChange={(e)=>changeHandler(e,social.id)} type='file' id='uploadImage' name='image' className="d-none"/>
                                                        <img className='UploadFooterIcon' src={uploadImage} alt="upload" />
                                                    </label>
                                                    :
                                                    <div className='h-100 w-100 position-relative' >
                                                        <Tooltip title='Remove Image'>
                                                            <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                                                <img className='removeFooterIcon' src={removeIcon} alt="delete" onClick={()=>removeImgHandler(social.id)} />
                                                            </label>
                                                        </Tooltip>
                                                        <div className='d-flex flex-column align-items-center h-100 w-100' style={{backgroundImage: `url(${img && img.src})` ,'backgroundSize':'contain' ,'backgroundRepeat':'no-repeat','backgroundPosition': 'center'}}>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            errorsArr && errorsArr[i] && errorsArr[i].image &&
                                            <div className='text-danger validateToDelete mb-2'>
                                                {errorsArr[i].image}
                                            </div>
                                        }
                                        <div className='d-flex justify-content-end'>
                                            <div  data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={()=>setDeletedId(social.id)} className='confirmBtn deleteBtn'>delete</div>
                                            
                                            { loaders && !loaders[i] ?
                                                <button className='confirmBtn' type='submit'>save changes</button>
                                                :
                                                <button className='confirmBtn' disabled>
                                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    saving...
                                                </button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )})}

                { openAddForm ?
                    <div className="accordion-body socials_accordion mt-3" style={{'width':'43%'}}>
                        <div className='accordion_header_container p-0 mb-4 pb-2 '>
                            <div className='d-flex justify-content-between align-items-center'>
                                Add New Social
                            </div>
                            <div onClick={addSocial} style={{'cursor':'pointer'}}>
                                <FontAwesomeIcon icon={faXmark}/>
                            </div>
                        </div>
                        <form onSubmit={confirmAdding}>
                            <div className='d-flex flex-column mb-4'>
                                <label htmlFor='name' className='formLabel'>social networks</label>
                                <input name="name" id='name' type='text' placeholder='facebook' value={filterLanguage(model.locales, 'en')[0].name} onChange={addHandler} />
                            </div>
                            <div className='d-flex flex-column mb-4'>
                                <label htmlFor='link' className='formLabel'>url</label>
                                <input name="link" id='link' type='text' placeholder='social link' value={model.link} onChange={addHandler} />
                                {
                                    errors && errors.link &&
                                    <div className='text-danger validateToDelete'>
                                        {errors.link}
                                    </div>
                                }
                            </div>

                            <div className='d-flex flex-column'>
                                <label htmlFor='favicon' className='formLabel'>icon</label>
                                <div className='imageWrapper p-0 imageWrapper2' style={{"width" : "50px" , "height" : "50px"}}>
                                    { !socialImg ?
                                        <label className='mb-0' htmlFor="uploadImage" style={{"width" : "100%" ,"height" : "100%" , "position" : "relative"}}>
                                            <input onChange={addHandler} type='file' id='uploadImage' name='image' className="d-none"/>
                                            <img className='UploadFooterIcon' src={uploadImage} alt="upload" />
                                        </label>
                                        :
                                        <div className='h-100 w-100 position-relative' >
                                            <Tooltip title='Remove Image'>
                                                <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                                    <img className='removeFooterIcon' src={removeIcon} alt="delete" onClick={removeAddImgHandler} />
                                                </label>
                                            </Tooltip>
                                            <div className='d-flex flex-column align-items-center h-100 w-100' style={{backgroundImage: `url(${socialImg})` ,'backgroundSize':'contain' ,'backgroundRepeat':'no-repeat','backgroundPosition': 'center'}}>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                errors && errors.image &&
                                <div className='text-danger validateToDelete'>
                                    {errors.image}
                                </div>
                            }
                            <div className='d-flex justify-content-end'>
                                { !addLoader ?
                                    <button className='confirmBtn' type='submit'>Add</button>
                                    :
                                    <button className='confirmBtn' disabled>
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        saving...
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                    :
                    <div className='addSocial' onClick={addSocial} style={{'cursor':'pointer'}}>
                        <img src={plus} alt="plus"/>
                    </div>
                }

                {/**** footer copyrights *****/}
                {/* <div className='mb-5' style={{"marginTop" : "100px"}}>
                    <h5 className='pageSubtitle'>footer text</h5>
                </div> */}
{/* 
                <form style={{"width" : "40%"}} onSubmit={editCopyRights}>
                    { 
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='desc' className='formLabel'>copyrights text</label>
                            <input value={copyrights && copyrights.locales && filterLanguage(copyrights.locales, 'en')[0]&& filterLanguage(copyrights.locales, 'en')[0].description} onChange={changeRightsHandler} name="description" id='desc' type='text' placeholder='tagline in english' />
                        </div>
                    }

                    <div>
                        { !rightsLoader ?
                            <button className='confirmBtn' type='submit'>save changes</button>
                        :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                saving...
                            </button>
                        }
                    </div>
                </form> */}
            </section>

            <DeleteModal onSubmitDelete={onSubmitDelete} name='post'></DeleteModal>
        </>
    )
}
