import React from 'react'
import { createNewFacility } from '../../../Apis/facilitiesApi';
import Form from './Form'

export default function Add(props) {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        console.log(data)
        //to send request
        return await createNewFacility(data).then(res => {
            props.getAll();
            console.log(res.data.data);
            return "done";          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };


    return (
        <>
        <section className='actionCardSideWrapper'>
            <div>
                <h5>add new facility</h5>
            </div>
            <Form onSubmit={onSubmit}></Form>
        </section>
        </>
    )
}
