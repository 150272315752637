import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get cities
export async function getCities(){
    return await AxiosClone.get(`${api_url}/admin/cities`)
}

//get one city
export async function getCity(id){
    return await AxiosClone.get(`${api_url}/admin/cities/${id}`)
}

//create city
export async function createNewCity(data){
    return await AxiosClone.post(`${api_url}/admin/cities`, data);
}

//edit city
export async function editCity(data , id){
    return await AxiosClone.patch(`${api_url}/admin/cities/${id}`, data)
}

//delete city
export async function deleteCity(id){
    return await AxiosClone.delete(`${api_url}/admin/cities/${id}`)
}