import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get sliders
export async function getSliders(){
    return await AxiosClone.get(`${api_url}/admin/sliders`);
}

//get one slider
export async function getSlider(id){
    return await AxiosClone.get(`${api_url}/admin/sliders/${id}`);
}

//create slider
export async function createNewSlider(data){
    return await AxiosClone.post(`${api_url}/admin/sliders`, data);
}

//toggle active slider
export async function toggleActiveSlider(id ,data){
    return await AxiosClone.post(`${api_url}/admin/sliders/${id}`, data);
}

//edit slider
export async function editSlider(id ,data){
    return await AxiosClone.post(`${api_url}/admin/sliders/${id}`, data);
}

//delete slider
export async function deleteSlider(id){
    return await AxiosClone.delete(`${api_url}/admin/sliders/${id}`);
}

// get home content
export async function getHome(){
    return await AxiosClone.get(`${api_url}/admin/settings/apperance/home`);
}


//edit header
export async function editHeaders(id ,data){
    return await AxiosClone.post(`${api_url}/admin/headers/${id}`, data);
}