import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import overview from '../../assets/icons/overview.svg';
import leads from '../../assets/icons/Leads.svg';
import property from '../../assets/icons/property.svg';
import developers from '../../assets/icons/real-estate.png';
import propertySettings from '../../assets/icons/propertySettings.svg';
import blogs from '../../assets/icons/blogs.svg';
import appearance from '../../assets/icons/appearance.svg';
import users from '../../assets/icons/users.svg';
import settings from '../../assets/icons/settings.svg';
import "./Aside.scss";
import { useContext } from 'react';
/* import { useEffect } from 'react'; */
import UnReadLeadsContext from '../../notificationContext';
import { api_basename } from '../../Configuration/Config';
import PermissionsContext from '../../permissionsContext';
/* import PropertiesList from './../../Pages/Properties/PropertiesList'; */

export default function Aside() {
    let { unReadPropertyRequest, unReadContactUs } = useContext(UnReadLeadsContext);
    let { Permissions } = useContext(PermissionsContext);
    let closeRef = useRef();
    function closeOffCanvas() {
        if (window.innerWidth <992) {
            closeRef.current.click()
        }
    }
    return (
        <div className='asideWrapper'>
            <div className='d-flex  justify-content-around  justify-content-lg-center mb-4'>
                <img className='asideLogo' src={logo} alt="" />
                <div className='d-flex justify-content-end'>
                    <button ref={closeRef} type="button" className="btn-close text-reset sideBarClose px-4 d-lg-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
            </div>
            <div className='d-flex '>
                <button ref={closeRef} type="button" className="btn-close text-reset sideBarClose px-4 d-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <ul className='asideList'>
                <li>
                    <NavLink className={`navLink`} to={`${api_basename}/dashboard/overview`} onClick={closeOffCanvas}>
                        <img src={overview} alt="icon" />
                        <span className='mx-3'>Overview</span>
                    </NavLink>
                </li>

                {((Permissions && Permissions.contact && Permissions.contact.view) || (Permissions && Permissions.enquiry && Permissions.enquiry.view)) && <li>
                    <NavLink className={`navLink`} to={`${api_basename}/dashboard/leads`} data-bs-toggle="collapse" data-bs-target="#leadsCollapse" >
                        <img src={leads} alt="icon" />
                        <span className='mx-3'>Leads</span>
                    </NavLink>
                    <ul className="collapse asideDropdown" id='leadsCollapse'>
                        {Permissions && Permissions.enquiry && Permissions.enquiry.view && <li>
                            <NavLink to={`${api_basename}/dashboard/leads/leads-requests`} className="nestedNavLink" onClick={closeOffCanvas}>leads requests {unReadPropertyRequest ? <span className='badge badgeNumber ms-3' >{unReadPropertyRequest}</span> : ''}</NavLink>
                        </li>}
                        {Permissions && Permissions.contact && Permissions.contact.view && <li>
                            <NavLink to={`${api_basename}/dashboard/leads/contact-requests`} className="nestedNavLink" onClick={closeOffCanvas}>
                                contact requests {unReadContactUs ? <span className='badgeNumber ms-3' >{unReadContactUs}</span> : ''}
                            </NavLink>
                        </li>}
                    </ul>
                </li>}

                <div className='asideSplitter'></div>

                {((Permissions && Permissions.property && Permissions.property.view) || (Permissions && Permissions.property && Permissions.property.create)) && <li>
                    <NavLink className={`navLink`} to={`${api_basename}/dashboard/properties`} data-bs-toggle="collapse" data-bs-target="#propertiesCollapse">
                        <img src={property} alt="icon" />
                        <span className='mx-3'>Properties</span>
                    </NavLink>
                    <ul className="collapse asideDropdown" id='propertiesCollapse'>
                        {Permissions && Permissions.property && Permissions.property.create && <li>
                            <NavLink to={`${api_basename}/dashboard/properties/add-property`} className="nestedNavLink" onClick={closeOffCanvas}>add property</NavLink>
                        </li>}
                        {Permissions && Permissions.property && Permissions.property.view && <li>
                            <NavLink to={`${api_basename}/dashboard/properties/properties-list`} className="nestedNavLink" onClick={closeOffCanvas}>view properties</NavLink>
                        </li>}
                    </ul>
                </li>}

                {((Permissions && Permissions.city && Permissions.city.view) || (Permissions && Permissions.area && Permissions.area.view) || (Permissions && Permissions.type && Permissions.type.view) || (Permissions && Permissions.category && Permissions.category.view)) &&
                    <li>
                        <NavLink className={`navLink`} to={`${api_basename}/dashboard/Property-settings`} data-bs-toggle="collapse" data-bs-target="#PropertySettingsCollapse">
                            <img src={propertySettings} alt="icon" />
                            <span className='mx-3'>Property Settings</span>
                        </NavLink>
                        <ul className="collapse asideDropdown" id='PropertySettingsCollapse'>
                            {((Permissions && Permissions.city && Permissions.city.view) || (Permissions && Permissions.area && Permissions.area.view)) && <li>
                                <NavLink to={`${api_basename}/dashboard/property-settings/cities_areas`} className="nestedNavLink" onClick={closeOffCanvas}>cities &#38; areas</NavLink>
                            </li>}
                            {(Permissions && Permissions.facility && Permissions.facility.view) && <li>
                                <NavLink to={`${api_basename}/dashboard/property-settings/facilities/add`} className="nestedNavLink" onClick={closeOffCanvas}>facilities</NavLink>
                            </li>}
                            {Permissions && Permissions.category && Permissions.category.view && <li>
                                <NavLink to={`${api_basename}/dashboard/property-settings/categories`} className="nestedNavLink" onClick={closeOffCanvas}>categories</NavLink>
                            </li>}
                            {Permissions && Permissions.type && Permissions.type.view && <li>
                                <NavLink to={`${api_basename}/dashboard/property-settings/types/add`} className="nestedNavLink" onClick={closeOffCanvas}>property type</NavLink>
                            </li>}
                        </ul>
                    </li>}
                {Permissions?.developer?.view && <NavLink className={`navLink`} to={`${api_basename}/dashboard/developers`} data-bs-toggle="collapse" data-bs-target="#developersCollapse">
                    <img src={developers} alt="icon" />
                    <span className='mx-3'>Developers</span>
                </NavLink>}
                <ul className="collapse asideDropdown" id='developersCollapse'>
                    <li>
                        <NavLink to={`${api_basename}/dashboard/developers/add-developer`} className="nestedNavLink" onClick={closeOffCanvas}>Add developer</NavLink>
                    </li>
                    <li>
                        <NavLink to={`${api_basename}/dashboard/developers/developers-list`} className="nestedNavLink" onClick={closeOffCanvas}>View developers</NavLink>
                    </li>
                </ul>
                {((Permissions && Permissions.blog && Permissions.blog.view) || (Permissions && Permissions.blog && Permissions.blog.create)) && <li>
                    <NavLink className={`navLink`} to={`${api_basename}/dashboard/news`} data-bs-toggle="collapse" data-bs-target="#blogsCollapse">
                        <img src={blogs} alt="icon" />
                        <span className='mx-3'>News</span>
                    </NavLink>
                    <ul className="collapse asideDropdown" id='blogsCollapse'>
                        {Permissions && Permissions.blog && Permissions.blog.view && <li>
                            <NavLink to={`${api_basename}/dashboard/news/news-list`} className="nestedNavLink" onClick={closeOffCanvas}>news list</NavLink>
                        </li>}
                        {Permissions && Permissions.blog && Permissions.blog.create && <li>
                            <NavLink to={`${api_basename}/dashboard/news/add-new-post`} className="nestedNavLink" onClick={closeOffCanvas}>add new post</NavLink>
                        </li>}
                    </ul>
                </li>}
            </ul>

            <div className='asideSplitter' ></div>

            <ul className='asideList'>
                {Permissions && Permissions.apperance && Permissions.apperance.control && <li>
                    <NavLink className={`navLink`} to={`${api_basename}/dashboard/appearance`} data-bs-toggle="collapse" data-bs-target="#appearanceCollapse">
                        <img src={appearance} alt="icon" />
                        <span className='mx-3'>Appearance</span>
                    </NavLink>
                    <ul className="collapse asideDropdown" id='appearanceCollapse'>
                        {/* <li>
                            <NavLink to={`${api_basename}/dashboard/appearance/navbar`} className="nestedNavLink">navbar</NavLink>
                        </li> */}
                        {<li>
                            <NavLink to={`${api_basename}/dashboard/appearance/footer`} className="nestedNavLink" onClick={closeOffCanvas}>footer</NavLink>
                        </li>}
                        {<li>
                            <NavLink to={`${api_basename}/dashboard/appearance/pages`} className="nestedNavLink" onClick={closeOffCanvas}>pages</NavLink>
                        </li>}
                    </ul>
                </li>}

                {((Permissions && Permissions.admin && Permissions.admin.create)
                    || (Permissions && Permissions.admin && Permissions.admin.view)
                    || (Permissions && Permissions.role && Permissions.role.view)
                    || (Permissions && Permissions.member && Permissions.member.view)) && <li>
                        <NavLink className={`navLink`} data-bs-toggle="collapse" data-bs-target="#usersCollapse" to={`${api_basename}/dashboard/users`}>
                            <img src={users} alt="icon" />
                            <span className='mx-3'>Users</span>
                        </NavLink>
                        <ul className="collapse asideDropdown" id='usersCollapse'>
                            {Permissions && Permissions.admin && Permissions.admin.create && <li>
                                <NavLink to={`${api_basename}/dashboard/users/add-admin`} className="nestedNavLink" onClick={closeOffCanvas}>add admin</NavLink>
                            </li>}
                            {Permissions && Permissions.admin && Permissions.admin.view && <li>
                                <NavLink to={`${api_basename}/dashboard/users/all-admins`} className="nestedNavLink" onClick={closeOffCanvas}>all admins</NavLink>
                            </li>}
                            {Permissions && Permissions.role && Permissions.role.view && <li>
                                <NavLink to={`${api_basename}/dashboard/users/roles/add`} className="nestedNavLink" onClick={closeOffCanvas}>roles</NavLink>
                            </li>}
                            {Permissions && Permissions.member && Permissions.member.view && <li>
                                <NavLink to={`${api_basename}/dashboard/users/all-members`} className="nestedNavLink" onClick={closeOffCanvas}>our team</NavLink>
                            </li>}
                        </ul>
                    </li>}

                {<li>
                    <NavLink className={`navLink`} data-bs-toggle="collapse" data-bs-target="#settingsCollapse" to={`${api_basename}/dashboard/settings`}>
                        <img src={settings} alt="icon" />
                        <span className='mx-3'>Settings</span>
                    </NavLink>
                    <ul className="collapse asideDropdown" id='settingsCollapse'>
                        {/* Permissions && Permissions.setting && Permissions.setting.control */  false && <li>
                            <NavLink to={`${api_basename}/dashboard/settings/general`} className="nestedNavLink" onClick={closeOffCanvas}>general</NavLink>
                        </li>}
                        <li>
                            <NavLink to={`${api_basename}/dashboard/settings/security`} className="nestedNavLink" onClick={closeOffCanvas}>security</NavLink>
                        </li>
                        <li>
                            <NavLink to={`${api_basename}/dashboard/settings/account`} className="nestedNavLink" onClick={closeOffCanvas}>account</NavLink>
                        </li>
                        {Permissions && Permissions.setting && Permissions.setting.control && <li>
                            <NavLink to={`${api_basename}/dashboard/settings/scripts`} className="nestedNavLink" onClick={closeOffCanvas}>script page</NavLink>
                        </li>}
                    </ul>
                </li>}
            </ul>
        </div>
    )
}
