import React from 'react'
import { updateRole } from '../../../Apis/rolesApi';
import Form from './Form';
import { useParams } from 'react-router';

export default function EditRole(props) {
    let { id } = useParams();
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await updateRole(id,{...data,"_method": "patch"}).then(res => {
            props.getAll();
            console.log(props)
            return 'done';          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };

    return (
        <div className='actionCardSideWrapper'>
            <Form onSubmit={onSubmit} getAll={props.getAll} />
        </div>
    )
}
