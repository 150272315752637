import React, { useContext, useEffect, useState } from 'react';
import TitleContext from '../../../TitleContext';
import "../../News/News.scss";
import edit_icon from "../../../assets/icons/edit.svg";
import delete_icon from "../../../assets/icons/delete.svg";
import { deletePartner, getPartners } from '../../../Apis/partnersApi';
import { useLocation } from 'react-router';
import { api_basename, api_host } from '../../../Configuration/Config';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import DeleteModal from '../../../utilities/deleteModal';

export default function OurPartners() {
    let { changeTitle } = useContext(TitleContext);
    let [allPartners, setAllPartners] = useState([]);
    const [partnerId, setPartnerId] = useState('');

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get partner id
    function getId(id) {
        setPartnerId(id)
    }

    // get all partners
    function getAllPartners() {
        let myallPartners = [...allPartners];
        getPartners().then(res => {
            myallPartners = res.data.data;
            setAllPartners(myallPartners);
        }).catch(err =>
            console.log(err))
    }

    useEffect(() => {
        changeTitle(`our partners`, { url: 'appearance/pages/partners/add', text: 'Add New partner' });
        getAllPartners();
        // eslint-disable-next-line
    }, []);

    // delete partner
    const onSubmitDelete = async () => {
        return await deletePartner(partnerId).then(res => {
            getAllPartners();
            return "done";
        }).catch(err => {
            return err.response.data.errors;
        })
    }


    return (
        <>
            <section className='componentGlobalWrapper'>

                {/* <div className='searchActionsBar mt-0'>
                <div className='searchInputWrapper'>
                    <div>
                        <input type='text' className='barSearchInput' />
                    </div>
                    <img src={search} alt='search-icon' className='barSearchIcon' />
                </div>
                <div>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/partners/add`} className='confirmBtn filterBarBtn'>add new partner</NavLink>
                </div>
            </div> */}
                <div className='tableWrapper'>
                    <table className="w-100 table table-striped">
                        <thead>
                            <tr>
                                <th>logo</th>
                                <th>partners</th>
                                <th>created at</th>
                                <th>actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allPartners && allPartners.map((partner, index) => (
                                <tr key={index}>
                                    <td style={{ "width": "28%" }}>
                                        <div className='partnerLogoBoredr'>
                                            <img className='me-0 userPhoto' src={`${api_host}/${partner.image?.image}`} alt="partner" />
                                        </div>
                                    </td>
                                    <td style={{ "width": "28%" }}>
                                        {(partner && partner.locales) ? partner.locales[0].name : ""}
                                    </td>
                                    <td style={{ "width": "28%" }}>{moment(partner.created_at).format('DD-MM-YY h:mm A')}</td>
                                    <td>
                                        <div>
                                            <Tooltip title="edit">
                                                <Link to={`${api_basename}/dashboard/appearance/pages/partners/edit-partner/${partner.id}`}>
                                                    <img src={edit_icon} alt="edit" />
                                                </Link>
                                            </Tooltip>
                                            <Tooltip title="delete">
                                                <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={() => getId(partner.id)} />
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <DeleteModal onSubmitDelete={onSubmitDelete} name='admin'></DeleteModal>
            </section>
        </>
    )
}
