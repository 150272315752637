import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import "../Login/Login.scss";
import logo from '../../../assets/images/loginLogo.png';
import loginImg from "../../../assets/images/pexels-kevin-villaruz-1589237.jpg";

export default function ResetPassword() {
    let [showForgotPassField , setshowForgotPassField] = useState(true);
    let [showPincode , setshowPincode] = useState(false);
    let [showNewpass , setShowNewpass] = useState(false);
    let [successMsg , setSuccessMsg] = useState(false);

    const [email, setEmail] = useState("");
    const [pincode, setPincode] = useState("");

    const [loading, setLoading] = useState(false);
    const [invalidMessage, setinvalidMessage] = useState('');
    const [resetAdminData, setresetAdminData] = useState({email : email, pin_code: pincode, password: '', password_confirmation: '' });
    const [Match, Setmatch] = useState(true);
    const [errors, setErros] = useState({ passwordErr: '', pinErr: '' });


    //forget password
    function forgetAdminPassword(e) {
        e.preventDefault();
        setLoading(true);
        if (email) {
            axios({
                url: `${process.env.REACT_APP_API}/admin/auth/forget_password/code`,
                data: { email: email },
                method: 'post',
            })
            .then((res) => {
                setinvalidMessage('');
                setLoading(false);
                setshowPincode(true);
                setshowForgotPassField(false)                
            })
            .catch((err) => {
                console.log(err.response)
                setinvalidMessage(err.response.data.errors.email);
                setLoading(false);
            })
        }
    }

    //pincode submit
    function submitPinCode(e){
        e.preventDefault();
        setLoading(true);
        console.log(pincode);
        if (pincode && pincode.length === 6) {
            axios({
                url: `${process.env.REACT_APP_API}/admin/auth/forget_password/check`,
                data: { code: pincode , email: email},
                method: 'post',
            })
            .then((res) => {
                setinvalidMessage('');
                setLoading(false);
                setShowNewpass(true);
                setshowPincode(false);             
            })
            .catch((res) => {
                console.log(res.response.data.message);
                setinvalidMessage(res.response.data.message);
                setLoading(false);
            })
        }else{
            setinvalidMessage('Please enter valid pin code');
            setLoading(false);
        }

    }

    // reset password handler
    function resetAdminDataHandler(e) {
        let reset = { ...resetAdminData };
        reset[e.target.name] = e.target.value;
        setresetAdminData(reset);
        if (reset.password.length > 8) {
            setErros({ errors , passwordErr: '' })
        }else{
            setErros({ errors , passwordErr: 'Password must be at least 8 characters long' })
        }
    }

    // password match
    function passwordMatch() {
        if (resetAdminData.password !== " " && resetAdminData.password_confirmation !== " ") {
            if (resetAdminData.password === resetAdminData.password_confirmation) {
                Setmatch(true)
            }
            else {
                Setmatch(false)
            }
        }
    }

    // to submit password
    function passwordConfirmation(e){
        e.preventDefault();
        setLoading(true);
        console.log(resetAdminData)
        if (resetAdminData.password.length >= 8 && resetAdminData.password_confirmation.length >= 8 ) {
            console.log(resetAdminData);
            axios({
                url: `${process.env.REACT_APP_API}/admin/auth/forget_password/reset_password`,
                data: { code: pincode , email: email ,password:resetAdminData.password, password_confirmation:resetAdminData.password_confirmation} ,
                method: 'post'
            })
            .then((res) => {
                setLoading(false);
                setShowNewpass(false);
                setSuccessMsg(true);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.response.data);
                console.log(err)
                setErros({ ...errors, passwordErr: 'Password not matched' });
            })
        
        } else {
            setLoading(false);
            /* setmatch(true) */
            setErros({ ...errors, passwordErr: 'Password length must be at least 8'})
        }
    }


    return (
        <div className='position-relative'>
            <div className="login-bg-img">
                <section className='login-wrapper'>
                    <img className='login-form-image d-none d-lg-block' src={loginImg} alt="login form" />
                    <div className='login-form'>
                        <div className='formHeader'>
                            <img src={logo} alt="logo"/>
                            <p>REAL ESTATE</p>
                        </div>
                        {/** forgot password form **/}               
                        { showForgotPassField && 
                            <form className='w-100' onSubmit={forgetAdminPassword}>
                                <p>Enter the email associated with your account to recieve the code to reset your password.</p>
                                <div className='d-flex flex-column'>
                                    <label htmlFor='username' className='formLabel'>email</label>
                                    <input onChange={(e) => setEmail(e.target.value.trim() ? e.target.value.trim() : '')} name="email" className='formInput' id='username' type='text' placeholder='email' required/>
                                    <div className='validateToDelete text-danger'> {invalidMessage}</div>
                                </div>
                                <div className='d-grid mt-5'>
                                        {!loading ? 
                                            <button type='submit' className='defaultBtn'>next</button>
                                            :
                                            <button className='defaultBtn' disabled>
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                loading...
                                            </button>
                                        }
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <NavLink to={`${process.env.REACT_APP_BASENAME}/`} className="backLogin">go back to login</NavLink>
                                </div>
                            </form>
                        }

                        {/** Pin code form **/}
                        { showPincode && 
                            <form className='w-100' onSubmit={submitPinCode}>
                                <div className='d-flex flex-column'>
                                    <label htmlFor='username' className='formLabel'>enter code</label>
                                    <input onChange={(e) => setPincode(e.target.value.trim() ? e.target.value.trim() : '')} name="email" className='formInput' id='username' type='text' placeholder='Code' required/>
                                </div>
                                <div className='validateToDelete text-danger'>{invalidMessage}</div>
                                <div className='d-grid mt-5'>
                                    {!loading ? 
                                        <button type='submit' className='defaultBtn'>continue</button>
                                        :
                                        <button className='defaultBtn' disabled>
                                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            loading...
                                        </button>
                                    }
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <NavLink to={`${process.env.REACT_APP_BASENAME}/`} className="backLogin">go back to login</NavLink>
                                </div>
                            </form>
                        }

                        {/** confirm new pass form **/}
                        { showNewpass && 
                            <form className='w-100' onSubmit={passwordConfirmation}>
                                <div className='d-flex flex-column mb-4'>
                                    <label htmlFor='newpassword' className='formLabel'>New Password</label>
                                    <input onChange={resetAdminDataHandler} name="password" className='formInput' id='newpassword' type='text' placeholder='new password' />
                                </div>
                                <div className='d-flex flex-column'>
                                    <label htmlFor='confirmpassword' className='formLabel'>Confirm Password</label>
                                    <input onChange={resetAdminDataHandler} name="password_confirmation" className='formInput' id='confirmpassword' type='text' placeholder='confirm password' onBlur={passwordMatch}/>
                                </div>
                                { !Match && 
                                    <div className='validateToDelete text-danger'>Password does not match</div>
                                }
                                <div className='validateToDelete text-danger'>{errors && errors.passwordErr}</div>
                                <div className='d-grid mt-5'>
                                    {!loading ? 
                                        <button type='submit' className='defaultBtn' >reset password</button>
                                        :
                                        <button className='defaultBtn' disabled>
                                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            loading...
                                        </button>
                                    }
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <NavLink to={`${process.env.REACT_APP_BASENAME}/`} className="backLogin">go back to login</NavLink>
                                </div>
                            </form>
                        }

                        {/** success message **/}               
                        { successMsg && 
                            <form className='w-100' onSubmit={forgetAdminPassword}>
                                <h5>Password reset successful!</h5>
                                <p>You can now use your new password to login to your account.</p>
                                <div className='d-grid mt-5'>                                     
                                    <button type='submit' className='defaultBtn'>
                                        <NavLink to={`${process.env.REACT_APP_BASENAME}/`} >go back to login</NavLink>
                                    </button>                                          
                                </div>
                            </form>
                        }
                    </div>
                </section>
            </div>
            <div className='login-bg-layer'></div>
        </div>
    )
}
