import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import TitleContext from '../../TitleContext';
import "../News/News.scss";
import search from "../../assets/icons/search.svg";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import undelete from "../../assets/icons/undelete.svg";
import { Link, useSearchParams } from 'react-router-dom';
import { deleteProperty, getProperties, undeleteProperty, updateProperty } from '../../Apis/propertiesApi';
import { api_basename, api_host } from '../../Configuration/Config';
import moment from 'moment';
import DeleteModal from './../../utilities/deleteModal';
import Tooltip from '@material-ui/core/Tooltip';
import { filterLanguage } from '../../utilities/filterLang';
import { getCities } from '../../Apis/citiesApi';
import { getAreasByCity } from '../../Apis/areasApi';
import { getTypes } from '../../Apis/typesApi';
import Pagination from './../../Components/Pagination/Pagination';
import { getStatistics } from '../../Apis/overviewApi';
import permissionsContext from '../../permissionsContext';

export default function PropertiesList() {
    let { changeTitle } = useContext(TitleContext);
    let { Permissions } = useContext(permissionsContext);
    let [allProperties, setAllProperties] = useState([]);
    let [allCities, setAllCities] = useState([]);
    let [allAreas, setAllAreas] = useState([]);
    let [allTypes, setAllTypes] = useState([]);
    const [propertyId, setPropertyId] = useState('');
    const [filterObj, setfilterObj] = useState({ search: '', per_page: '10', page: '1', area: '', city: '', type: '', trashed: '', status: '' });
    const [selectedCity, setSelectedCity] = useState(filterObj.city);
    const [pageCount, setPagecount] = useState(1);
    /* const [pageNum,setPageNum]=useState(0); */
    const [totalProps, setTotalProps] = useState(0);
    const [totalPublish, setTotalPublish] = useState(0);
    const [totalTrashed, setTotalTrashed] = useState(0);
    const areaRef = useRef();
    const allPropsRef = useRef();
    const [searchParams] = useSearchParams();
    const [params] = useState(searchParams.get("status"));
    const activeProp = useRef();
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    //searchParams.get("status");
    /* console.log(searchParams.get("status")) */
    // get property id
    function getId(id) {
        setPropertyId(id)
    }

    // get all properties
    function getAllProperties() {
        let myallProperties = [...allProperties];
        let filter = { ...filterObj };
        filter.status = '';
        filter.trashed = '';
        filter.page = 1;
        setfilterObj(filter)
        let obj = `search=${filter && filter.search}&per_page=${filter && filter.per_page}&page=1&city=${filter && filter.city}&area=${filter && filter.area}&type=${filter && filter.type}&trashed=&status=`;
        getProperties(obj).then(res => {
            myallProperties = res.data.data;
            setAllProperties(myallProperties);
            let total = res.data.pagination.total;
            /* setPageNum(0); */
            setTotalProps(total);
            setPagecount(Math.ceil(total / filterObj.per_page));
            myallProperties.map((property) => {
                if (property.status) {
                    document.getElementById(property.id).checked = true;
                } else {
                    document.getElementById(property.id).checked = false;
                }
                return true
            });
        }).catch(err =>
            console.log(err.response))
    }

    // get all cities
    function getAllCities() {
        let myallCities = [...allCities];
        getCities().then(res => {
            myallCities = res.data.data;
            setAllCities(myallCities);
        }).catch(err =>
            console.log(err))
    }

    function getAllTypes() {
        let myallTypes = [...allTypes];
        getTypes().then(res => {
            myallTypes = res.data.data;
            setAllTypes(myallTypes);
        }).catch(err =>
            console.log(err))
    }

    useEffect(() => {
        if (selectedCity !== '') {
            getAreasByCity(selectedCity).then(res => {
                setAllAreas(res.data.data);
                areaRef.current.value = ''
            })
        } else {
            setAllAreas([]);
        }
        // eslint-disable-next-line 
    }, [selectedCity])

    useEffect(() => {
        changeTitle(`properties`, Permissions && Permissions.property && Permissions.property.create && { url: 'properties/add-property', text: 'Add property' });
        let filter = { ...filterObj }
        let obj = `search=${filter && filter.search}&per_page=${filter && filter.per_page}&city=${filter && filter.city}&area=${filter && filter.area}&type=${filter && filter.type}`;
        getAllProperties(obj);
        getAllCities();
        getAllTypes();
        getStatistics().then((res) => {
            setTotalPublish(res.data.data.overview.active_properties);
            setTotalTrashed(res.data.data.overview.trashed_properties);
        })

        if (params && Permissions?.property?.view) {
            activeProp.current.click();
        }

        // eslint-disable-next-line
    }, [Permissions, params]);

    function toggleStatus(id, status) {
        let obj;
        let myallProperties = [...allProperties];
        if (status) {
            obj = { status: 0 };
            updateProperty(id, { ...obj, "_method": "patch" }).then((res) => {
                myallProperties.map((property) => {
                    if (property.id === id) {
                        property.status = false;
                    }
                    return true;
                })
            })
        } else {
            obj = { status: 1 };
            updateProperty(id, { ...obj, "_method": "patch" }).then((res) => {
                myallProperties.map((property) => {
                    if (property.id === id) {
                        property.status = true
                    }
                    return true
                })
            })
        }

        getStatistics().then((res) => {
            setTotalPublish(res.data.data.overview.active_properties);
            setTotalTrashed(res.data.data.overview.trashed_properties);
        });
        /* let obj2=`search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}&page=1&city=${filterObj && filterObj.city}&area=${filterObj && filterObj.area}&type=${filterObj && filterObj.type}&trashed=${filterObj && filterObj.trashed}&status=${filterObj && filterObj.status}`;
        console.log(obj2);
        getProperties(obj2).then(res => {
            myallProperties = res.data.data;
            setAllProperties(myallProperties);
            let total = res.data.pagination.total;
            setTotalProps(total);
            setPagecount(Math.ceil(total/filterObj.per_page)); 
            setPageNum(0);
            myallProperties.map((property) => {
                if(property.status){
                    document.getElementById(property.id).checked = true;
                }else{
                    document.getElementById(property.id).checked = false;
                }
                return true
            });
        }).catch(err => 
            console.log(err.response)) */
    }

    // delete property
    const onSubmitDelete = async () => {
        let myallProperties = [...allProperties];
        return await deleteProperty(propertyId).then(res => {
            let obj2 = `search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}&page=1&city=${filterObj && filterObj.city}&area=${filterObj && filterObj.area}&type=${filterObj && filterObj.type}&trashed=${filterObj && filterObj.trashed}&status=${filterObj && filterObj.status}`;

            getProperties(obj2).then(res => {
                myallProperties = res.data.data;

                setAllProperties(myallProperties);
                let total = res.data.pagination.total;
                /* setPageNum(0); */
                setTotalProps(total);
                setPagecount(Math.ceil(total / filterObj.per_page));
                myallProperties.map((property) => {
                    if (property.status) {
                        document.getElementById(property.id).checked = true;
                    } else {
                        document.getElementById(property.id).checked = false;
                    }
                    return true
                });
            }).catch(err =>
                console.log(err.response)
            )

            getStatistics().then((res) => {
                setTotalPublish(res.data.data.overview.active_properties);
                if (res.data.data.overview.active_properties === 0) {
                    allPropsRef.current.click();
                }
                setTotalTrashed(res.data.data.overview.trashed_properties);
                if (res.data.data.overview.trashed_properties === 0) {
                    allPropsRef.current.click();
                }
            });
            return "done";
        }).catch(err => {
            return err.response.data.errors;
        })

    }

    function changefilterObj(e) {
        let thisObj = { ...filterObj }
        thisObj[e.target.name] = e.target.value.trim();
        thisObj.page = 1;
        setfilterObj(thisObj);
        if (e.target.name === 'city') {
            setSelectedCity(e.target.value);
        }
    }

    function getFilteredProp(e) {
        getAllProperties();
    }

    const handlepageChange = async data => {
        /* let filter = {...filterObj};
        filter.page = data.selected+1;
        setfilterObj(filter); */
        let obj = `search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}&page=${data.selected + 1}&city=${filterObj && filterObj.city}&area=${filterObj && filterObj.area}&type=${filterObj && filterObj.type}&publish=${filterObj && filterObj.publish}&trashed=${filterObj && filterObj.trashed}`;
        getProperties(obj).then(
            res => {
                setAllProperties(res.data.data);
                res.data.data.map((property) => {
                    if (property.status) {
                        document.getElementById(property.id).checked = true;
                    } else {
                        document.getElementById(property.id).checked = false;
                    }
                    return true
                });
            }
        )
    }

    function getPublish() {
        let filter = { ...filterObj };
        filter.status = 1;
        filter.trashed = '';
        filter.page = 1;
        setfilterObj(filter);
        let obj = `search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}
        &page=1&trashed=&status=1&city=${filter && filter.city}&area=${filter && filter.area}&type=${filter && filter.type}`;
        getProperties(obj).then(res => {
            let myallProperties = [...allProperties];
            myallProperties = res.data.data;
            setAllProperties(myallProperties);
            /* setPageNum(0); */
            let total = res.data.pagination.total;
            setPagecount(Math.ceil(total / filterObj.per_page));
            myallProperties.map((property) => {
                if (property.status) {
                    document.getElementById(property.id).checked = true;
                } else {
                    document.getElementById(property.id).checked = false;
                }
                return true
            });
        })
    }

    function getTrashed() {
        let filter = { ...filterObj };
        filter.trashed = 1;
        filter.status = '';
        filter.page = 1;
        setfilterObj(filter);
        let obj = `search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}
        &page=1&trashed=1&status=&city=${filter && filter.city}&area=${filter && filter.area}&type=${filter && filter.type}`;
        getProperties(obj).then(res => {
            let myallProperties = [...allProperties];
            /* setPageNum(0); */
            myallProperties = res.data.data;
            setAllProperties(myallProperties);
            let total = res.data.pagination.total;
            setPagecount(Math.ceil(total / filterObj.per_page));
        })

    }

    function restore(id) {
        undeleteProperty(id).then((res) => { console.log(res.data.data) });
        getTrashed();
        getStatistics().then((res) => {
            setTotalPublish(res.data.data.overview.active_properties);
            setTotalTrashed(res.data.data.overview.trashed_properties);
        });
    }

    return (
        <>
            {Permissions && Permissions.property && Permissions.property.view && <section className='componentGlobalWrapper'>
                <div>
                    <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                        <div ref={allPropsRef} onClick={getAllProperties} className='tablinks active' id="Gallery-tab" data-bs-toggle="tab" data-bs-target="#allnews">all Properties <span className='tabcounter'>({totalProps})</span></div>
                        <span className='separator'></span>
                        <div ref={activeProp} onClick={getPublish} className='tablinks' id="published-tab" data-bs-toggle="tab" data-bs-target="#published-Img" >published <span className='tabcounter'>({totalPublish})</span></div>
                        <span className='separator'></span>
                        <div onClick={getTrashed} className='tablinks' id="trashed-tab" data-bs-toggle="tab" data-bs-target="#trashed-Img">trashed <span className='tabcounter'>({totalTrashed})</span></div>
                    </div>

                    <div className='searchActionsBar w-100'>
                        <div className='searchActionsBar mt-0 mb-0'>
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' className='barSearchInput' name='search' onChange={changefilterObj} />
                                    {/*  <span className="inputPlaceholder">search</span> */}
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon' />
                            </div>
                        </div>

                        {/* filter city */}
                        <div className='cityFilter'>
                            <select name='city' onChange={changefilterObj} className='form-select citySelect' defaultValue=''>
                                <option value=''>All cities</option>
                                {allCities && allCities.map((city, index) => {
                                    return (<option key={index} value={city.id}>{filterLanguage(city.locales, 'en') ? filterLanguage(city.locales, 'en')[0].name
                                        : filterLanguage(city.locales, 'ar')[0].name}</option>)
                                })
                                }
                            </select>
                        </div>

                        {/* filter area */}
                        <div className='cityFilter'>
                            <select name='area' ref={areaRef} className='form-select citySelect' defaultValue='' onChange={changefilterObj}>
                                <option value=''>All areas</option>
                                {allAreas && allAreas.map((area, index) => (
                                    <option value={area.id} key={index}>
                                        {filterLanguage(area.locales, 'en') ? filterLanguage(area.locales, 'en')[0].name
                                            : filterLanguage(area.locales, 'ar')[0].name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* filter type */}
                        <div className='cityFilter'>
                            <select name='type' className='form-select citySelect' defaultValue='' onChange={changefilterObj}>
                                <option value=''>All types</option>
                                {allTypes && allTypes.map((type, index) => (
                                    <option value={type.id} key={index}>
                                        {filterLanguage(type.locales, 'en') ? filterLanguage(type.locales, 'en')[0].name
                                            : filterLanguage(type.locales, 'ar')[0].name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* show select */}
                        <div className='perPageContainer'>
                            <label htmlFor='selectShow' className='perPageLabel'>
                                show
                            </label>
                            <select name='per_page' onChange={changefilterObj} id={`selectShow`} className='form-select selectShow' defaultValue='10'>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='15'>15</option>
                                <option value='20'>20</option>
                            </select>
                        </div>

                        {/*** apply search btn  ***/}
                        <button className='applySearchBtn' onClick={getFilteredProp}>apply</button>
                        {/* <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/properties/add-property`} className='confirmBtn ms-auto'>add property</NavLink> */}
                    </div>
                    <div className='tableWrapper'>
                        <table className="w-100 table table-striped">
                            <thead>
                                <tr>
                                    <th>image</th>
                                    <th>name</th>
                                    <th>type</th>
                                    <th>city</th>
                                    <th>area</th>
                                    <th>date</th>
                                    <th>actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allProperties && allProperties.map((property, index) => {
                                    let en = filterLanguage(property.locales, 'en')
                                    let ar = filterLanguage(property.locales, 'ar')
                                    return <tr key={index}>
                                        <td className='sliderImg' style={{ "width": "14%" }}>
                                            {property.images && property.images.length ?
                                            <img src={`${api_host}/${property.images && property.images.length && property.images[0].image ? property.images[0].image : ''}`} alt="property" style={{ "width": "90%" }} />
                                :'-'}
                                        </td>
                                        <td className='propNameAtTable'>{en && en.length ? en[0].name : ar && ar.length ? ar[0].name : ''}</td>
                                        <td>{(property && property.type && property.type.locales && property.type.locales.length && filterLanguage(property.type.locales, "en").length) ? filterLanguage(property.type.locales, "en")[0].name : ""}</td>
                                        <td>{(property && property.area && property.area.city && property.area.city.locales.length && filterLanguage(property.area.city.locales, "en").length) ? filterLanguage(property.area.city.locales, "en")[0].name : ""}</td>
                                        <td className='propAreaAtTable'>{(property && property.area && property.area.locales && property.area.locales.length && filterLanguage(property.area.locales, "en")) ? filterLanguage(property.area.locales, "en")[0].name : ""}</td>
                                        <td>{moment(property.created_at).format('DD-MM-YY h:mm A')}</td>
                                        <td>
                                            {filterObj && filterObj.trashed !== 1 ? <div>
                                                {Permissions && Permissions.property && Permissions.property.edit && <Tooltip title={property.status ? 'deactivate' : 'activate'}>
                                                    <label className="switch mb-0">
                                                        <input type="checkbox" id={property.id} onChange={() => toggleStatus(property.id, property.status)} />
                                                        <span className="slider round" />
                                                    </label>
                                                </Tooltip>}
                                                {Permissions && Permissions.property && Permissions.property.edit && <Tooltip title="edit">
                                                    <Link to={`${api_basename}/dashboard/properties/edit-property/${property.id}`}>
                                                        <img src={edit_icon} alt="edit" />
                                                    </Link>
                                                </Tooltip>}
                                                {Permissions && Permissions.property && Permissions.property.delete && <Tooltip title="delete">
                                                    <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={() => getId(property.id)} />
                                                </Tooltip>}
                                            </div>
                                                :
                                                <div>
                                                    {Permissions && Permissions.property && Permissions.property.restore && <Tooltip title="restore">
                                                        <img width='18px' height='20px' src={undelete} alt="undelete" onClick={() => restore(property.id)} />
                                                    </Tooltip>}
                                                    {Permissions && Permissions.property && Permissions.property.delete && <Tooltip title="delete">
                                                        <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={() => getId(property.id)} />
                                                    </Tooltip>}
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='d-flex justify-content-end mt-auto'>
                        <div className=''>
                            <Pagination pagesNumber={pageCount} page={0} pageChangeHandler={handlepageChange} />
                        </div>
                    </div>

                    <DeleteModal onSubmitDelete={onSubmitDelete} name='property'></DeleteModal>
                </div>
            </section>}
        </>
    )
}
