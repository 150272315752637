import React, { useContext, useEffect, useRef, useState } from 'react';
import TitleContext from '../../TitleContext';
import "../News/News.scss";
import search from "../../assets/icons/search.svg";
import delete_icon from "../../assets/icons/delete.svg";
import ip_icon from "../../assets/icons/ip.svg";
import blocked_ip_icon from "../../assets/icons/blockedIp.svg";
import arrow from "../../assets/images/ExpandArrow.png";
import { useLocation } from 'react-router';
import moment from 'moment';
import { deleteContactRequest, getContactRequests, markAsRead } from '../../Apis/contactRequestsApi';
import Tooltip from '@material-ui/core/Tooltip';
import { toggleBlockIps } from '../../Apis/LeadsRequestsApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { BsEnvelope, BsEnvelopeOpen } from "react-icons/bs";
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import Pagination from './../../Components/Pagination/Pagination';
import { useReactToPrint } from 'react-to-print';
import { useSearchParams } from 'react-router-dom';
import UnReadLeadsContext from '../../notificationContext';
import permissionsContext from '../../permissionsContext';

export default function ContactRequests() {
    let { changeUnReadContactUs, unReadContactUs } = useContext(UnReadLeadsContext);
    let { Permissions } = useContext(permissionsContext);

    /* ***************************************** */
    const componentRef = useRef();
    let cloneRef = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {
            cloneRef = componentRef.current.cloneNode(true);
            cloneRef.firstChild.firstChild.firstChild.classList.add('d-none');
            cloneRef.firstChild.firstChild.lastChild.classList.add('d-none');
            for (let i = 0; i < cloneRef.lastChild.childNodes.length; i++) {
                if (i % 2 === 0) {
                    cloneRef.lastChild.childNodes[i].firstChild.classList.add('d-none');
                    cloneRef.lastChild.childNodes[i].lastChild.classList.add('d-none');
                }
            }
        },
        content: () => cloneRef,
    });
    /* ***************************************** */
    let { changeTitle } = useContext(TitleContext);
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    let [allContacts, setAllContacts] = useState([]);
    let [allContactsNumber, setallContactsNumber] = useState("");
    let [newContactsNumber, setNewContactsNumber] = useState("");
    let [oldContactsNumber, setOldContactsNumber] = useState("");
    // delete contact message , block and unblock ips vars
    let [contactId, setContactId] = useState("");
    let [ip, setIp] = useState("");
    let [validateDelete, setValidateDelete] = useState("");
    let [checkedInputs, setCheckedInputs] = useState([]);
    let [fireModalToDeleteBulk, setFireModalToDeleteBulk] = useState(false);
    let [fireModalToBlockBulk, setFireModalToBlockBulk] = useState(false);
    let [fireModalToUnblockBulk, setFireModalToUnblockBulk] = useState(false);
    let closeDeleteContactModalRef = useRef();
    let closeDeleteBulkContactModalRef = useRef();
    let closeBlockIpModalRef = useRef();
    let [modalObj, setModalObj] = useState({});
    let [blockedIpsArr, setBlockedIpsArr] = useState([]);
    let deleteModalRef = useRef();
    let deleteModalBulkRef = useRef();
    const [pageCount, setPagecount] = useState(0);
    const [Total, setTotal] = useState();
    const [filterObj, setfilterObj] = useState({ search: '', per_page: '10', page: '1', status: '', date_from: '', date_to: '' });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])
    const [searchParams] = useSearchParams();
    const [params] = useState(searchParams.get("status"));
    const unreadContact = useRef();

    // onchange multiple checkboxs
    const toggleCheckbox = (e, item) => {
        let arr = [...checkedInputs];
        let bockedArr = [...blockedIpsArr];

        if (e.target.checked) {
            arr.push(item.id);
            setCheckedInputs(arr);
            bockedArr.push(item.ip);
            setBlockedIpsArr(bockedArr);
            setFireModalToDeleteBulk(true);
            setFireModalToBlockBulk(true);
            setFireModalToUnblockBulk(true);

            console.log(arr);
            console.log(bockedArr);
        } else {
            let x = arr.indexOf(item.id);
            let y = bockedArr.indexOf(item.ip);

            if (x > -1) {
                arr = arr.filter((i) => i !== item.id);
                setCheckedInputs(arr);
                console.log(arr);
            }
            if (y > -1) {
                bockedArr = bockedArr.splice(y, 1);
                setBlockedIpsArr(bockedArr);
                console.log(bockedArr);
            }

            if (arr && arr.length === 0) {
                setFireModalToDeleteBulk(false);
                setFireModalToBlockBulk(false);
                setFireModalToUnblockBulk(false);
            }
        }
    };

    // onchange select all rows
    const toggleSelectAllCheck = (e) => {
        let mycheckedInputs = [...checkedInputs];
        let myblockedIpsArr = [...blockedIpsArr];

        if (e.target.checked) {
            allContacts.map((c) => {
                mycheckedInputs.push(c.id);
                myblockedIpsArr.push(c.ip);
                return true
            });
            setFireModalToDeleteBulk(true);
            setFireModalToBlockBulk(true);
            setFireModalToUnblockBulk(true);
        } else {
            mycheckedInputs.length = 0;
            myblockedIpsArr.length = 0;
            setFireModalToDeleteBulk(false);
            setFireModalToBlockBulk(false);
            setFireModalToUnblockBulk(false);
        }
        setCheckedInputs(mycheckedInputs);
        setBlockedIpsArr(myblockedIpsArr);
        console.log(mycheckedInputs);
        console.log(myblockedIpsArr);
    }

    function isActionDisabled() {
        if (checkedInputs && checkedInputs.length) {
            setFireModalToDeleteBulk(true)
        }
    }

    // get all contacts requests
    function getAllContactsRequests(stat) {
        let obj = `search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}&page=${filterObj && filterObj.page}&date_from=${filterObj && filterObj.date_from}&date_to=${filterObj && filterObj.date_to}&status=${stat}`;
        let myallContacts = [...allContacts];
        console.log(obj)
        getContactRequests(obj).then(res => {
            myallContacts = res.data.data.contacts;
            setAllContacts(myallContacts);
            allContactsNumber = res.data.data.contacts_count;
            newContactsNumber = res.data.data.inactive_contacts_count;
            oldContactsNumber = res.data.data.active_contacts_count;
            setallContactsNumber(allContactsNumber);
            setNewContactsNumber(newContactsNumber);
            setOldContactsNumber(oldContactsNumber);
            let total = res.data.pagination.total;
            setTotal(res.data.pagination.total);
            setPagecount(Math.ceil(total / filterObj.per_page));
        }).catch(err =>
            console.log(err.response))
    }

    useEffect(() => {
        changeTitle(`contact requests`);
        getAllContactsRequests('');
        if (params) {
            unreadContact.current.click();
        }
        // eslint-disable-next-line
    }, []);

    // validate before delete contact request
    function deleteValidation(e) {
        validateDelete = e.target.value;
        setValidateDelete(validateDelete);
        if (validateDelete === "DELETE") {
            deleteModalRef.current.classList.add("d-none");
            deleteModalBulkRef.current.classList.add("d-none");
        }
    }

    // confirm delete single or multiple contact request
    function confirmDelete(e, type) {
        e.preventDefault();

        if (validateDelete === "DELETE") {
            // send request
            if (contactId || (checkedInputs && checkedInputs.length)) {
                setLoading(true);
                deleteContactRequest({ _method: 'delete', contacts: type === 1 ? [contactId] : checkedInputs }).then(res => {
                    setLoading(false);
                    document.getElementById('deleteContactForm').reset();
                    document.getElementById('deleteContactBulkForm').reset();
                    getAllContactsRequests(filterObj.status);
                    closeDeleteContactModalRef.current.click();
                    closeDeleteBulkContactModalRef.current.click();
                }).catch(err => {
                    setLoading(false);
                })
            }
        } else {
            deleteModalRef.current.classList.remove("d-none");
            deleteModalBulkRef.current.classList.remove("d-none");
        }
    }

    function resetInput() {
        document.getElementById('deleteContactForm').reset();
        document.getElementById('deleteContactBulkForm').reset();
        deleteModalRef.current.classList.add("d-none");
        deleteModalBulkRef.current.classList.add("d-none");
        setValidateDelete("");
    }

    // type 0 >> bulk
    // type 1 >> single
    // action true >> block
    // action false >> unblock
    function toggleBlock(action, type, leadIp) {
        ip = leadIp;
        setIp(ip);
        setModalObj({ ...modalObj, "action": action, "type": type });
        if (checkedInputs && checkedInputs.length) {
            setFireModalToBlockBulk(true);
            setFireModalToUnblockBulk(true);
        }
    }

    // toggle block submit
    function confirmToggleBlockIps(e) {
        e.preventDefault();
        let arrData = [];

        if (modalObj) {
            switch (modalObj.type) {
                case 1:
                    arrData = [ip]
                    break;
                case 0:
                    arrData = blockedIpsArr
                    break;
                default:
            }
            // to block or unblock single or multiple ips
            if (modalObj.action === true) {
                console.log(arrData)
                // send request to block
                if (arrData && arrData.length) {
                    setLoading(true);
                    let unique = arrData.filter((v, i, a) => a.indexOf(v) === i);
                    toggleBlockIps({ blockedIps: unique }).then(res => {
                        setLoading(false);
                        getAllContactsRequests(filterObj.status);
                        closeBlockIpModalRef.current.click();
                        console.log(res.data.data);
                    }).catch(err => {
                        setLoading(false);
                        console.log(err.response);
                    })
                }
            } else if (modalObj.action === false) {
                // send request to unblock
                if (arrData && arrData.length) {
                    setLoading(true);
                    let unique = arrData.filter((v, i, a) => a.indexOf(v) === i);
                    toggleBlockIps({ _method: 'delete', blockedIps: unique }).then(res => {
                        setLoading(false);
                        getAllContactsRequests(filterObj.status);
                        closeBlockIpModalRef.current.click();
                        console.log(res.data.data);
                    }).catch(err => {
                        setLoading(false);
                        console.log(err.response)
                    })
                }
            }
        }
    }

    // mark contact message as read
    function markContactMsgRead(id, status) {
        let myallContacts = [...allContacts];
        if (!status) {
            markAsRead(id, { "status": 1, "_method": "patch" }).then((res) => {
                /* getAllLeadsRequests(filterObj.status); */
                changeUnReadContactUs(unReadContactUs - 1);
                myallContacts.map((msg) => {
                    if (msg.id === id) {
                        msg.status = true;
                    }
                    return true
                })
                setAllContacts(myallContacts);
            })
        }
    }

    // get readMsgs
    function getReadMsgs() {
        let filter = { ...filterObj };
        filter.status = 1;
        setfilterObj(filter);
        let obj = `search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}
        &page=1&trashed=&status=1`;
        console.log(obj)
        getContactRequests(obj).then(res => {
            let thisallContacts = [...allContacts];
            thisallContacts = res.data.data.contacts;
            console.log(thisallContacts)
            let total = res.data.pagination.total;
            setTotal(total);
            setPagecount(Math.ceil(total / filterObj.per_page));
            setAllContacts(thisallContacts);
        })
    }

    // get unreadMsgs
    function getUneadMsgs() {
        let filter = { ...filterObj };
        filter.status = 0;
        setfilterObj(filter);
        let obj = `search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}
        &page=1&trashed=&status=0`;
        console.log(obj)
        getContactRequests(obj).then(res => {
            let thisallContacts = [...allContacts];
            thisallContacts = res.data.data.contacts;
            let total = res.data.pagination.total;
            setTotal(total);
            setPagecount(Math.ceil(total / filterObj.per_page));
            setAllContacts(thisallContacts);
        })
    }

    // filters on contact us
    function changeFilterObj(e) {
        let thisObj = { ...filterObj }
        thisObj[e.target.name] = e.target.value.trim();
        setfilterObj(thisObj);
    }

    function getFilteredNews(e) {
        getAllContactsRequests(filterObj.status)
    }

    function getDateValue(data) {
        let thisObj = { ...filterObj }
        if (data !== null) {
            thisObj.date_from = moment(data[0]).format('YYYY-MM-DD');
            thisObj.date_to = moment(data[1]).format('YYYY-MM-DD');
        } else {
            thisObj.date_from = '';
            thisObj.date_to = '';
        }
        setfilterObj(thisObj);
    }

    const handlepageChange = async data => {
        let filter = { ...filterObj };
        filter.page = data.selected + 1;
        setfilterObj(filter);
        let obj = `search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}&page=${data.selected + 1}&status=${filterObj && filterObj.status}&date_to=${filterObj && filterObj.date_to}&date_from=${filterObj && filterObj.date_from}`;

        getContactRequests(obj).then(
            res => {
                setAllContacts(res.data.data.contacts)
            }
        )
    }


    return (
        <>
            <section className='contact-requests componentGlobalWrapper d-flex flex-column justify-content-between pb-0 mb-4'>
                <div>
                    <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                        <div className='tablinks active' id="Gallery-tab" data-bs-toggle="tab" onClick={() => getAllContactsRequests('')}>all requests <span className='tabcounter'>({allContactsNumber})</span></div>
                        <span className='separator'></span>
                        <div ref={unreadContact} className='tablinks' id="published-tab" data-bs-toggle="tab" onClick={getUneadMsgs}>unread requests <span className='tabcounter'>({newContactsNumber})</span></div>
                        <span className='separator'></span>
                        <div className='tablinks' id="trashed-tab" data-bs-toggle="tab" onClick={getReadMsgs}>read requests <span className='tabcounter'>({oldContactsNumber})</span></div>
                    </div>

                    <div className='searchActionsBar w-100 justify-content-between'>
                        <div className=" d-flex align-items-center flex-wrap">
                            {/*** bulk actions ***/}
                            <div>
                                <button className='position-relative languageDropdown bulkDropdown' data-bs-toggle="dropdown">
                                    <span>bulk actions</span>
                                    <img src={arrow} alt="arrow" />
                                </button>
                                <ul className="dropdown-menu custom-bulk-dropdown pt-0" aria-labelledby="dropdownMenuButton2">
                                    {Permissions && Permissions.contact && Permissions.contact.delete && <li className={fireModalToDeleteBulk ? `` : 'notSelected'}>
                                        <span className={`dropdownItem px-2 py-1`} {...(fireModalToDeleteBulk ? { 'data-bs-target': '#deleteBulkContactModal', 'data-bs-toggle': "modal" } : {})} onClick={isActionDisabled}>
                                            delete selected
                                        </span>
                                    </li>}

                                    {Permissions && Permissions.blocked_ip && Permissions.blocked_ip.create && <li className={fireModalToBlockBulk ? `` : 'notSelected'}>
                                        <span className="dropdownItem px-2 py-1" {...(fireModalToBlockBulk ? { 'data-bs-target': '#toggleblockIpModal', 'data-bs-toggle': "modal" } : {})} onClick={() => toggleBlock(true, 0)}>
                                            block selected
                                        </span>
                                    </li>}

                                    {Permissions && Permissions.blocked_ip && Permissions.blocked_ip.delete && <li className={fireModalToUnblockBulk ? `` : 'notSelected'}>
                                        <span className="dropdownItem px-2 py-1" {...(fireModalToUnblockBulk ? { 'data-bs-target': '#toggleblockIpModal', 'data-bs-toggle': "modal" } : {})} onClick={() => toggleBlock(false, 0)}>
                                            unblock selected
                                        </span>
                                    </li>}
                                </ul>
                            </div>
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' name='search' onChange={changeFilterObj} className='barSearchInput' />
                                    {/*  <span className="inputPlaceholder">search</span> */}
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon' />
                            </div>
                            <div className='dataRange'>
                                <DateRangePicker appearance="default" placeholder="from : to" onChange={(value) => getDateValue(value)} style={{ width: 230 }} />
                            </div>
                            {/* show select */}
                            <div className='perPageContainer'>
                                <label htmlFor='selectShow' className='perPageLabel'>
                                    show
                                </label>
                                <select name='per_page' onChange={changeFilterObj} id={`selectShow`} className='form-select selectShow' defaultValue='10'>
                                    <option value='10'>10</option>
                                    <option value='15'>15</option>
                                    <option value='20'>20</option>
                                </select>
                            </div>
                            {/*** apply search btn  ***/}
                            <button className='applySearchBtn' onClick={getFilteredNews}>apply</button>
                        </div>
                        {/*** export as pdf ***/}
                        <div className="dropdown">
                            <button className="dropdown-toggle exportDropdown confirmBtn" type="button" id="exportButton" data-bs-toggle="dropdown" aria-expanded="false">
                                export
                            </button>
                            <ul className="dropdown-menu export-dropdown-menu" aria-labelledby="exportButton">
                                {/* <PDFDownloadLink document={<PDFFile />} fileName="lead.pdf">
                                <li>pdf</li>
                            </PDFDownloadLink> */}
                                <li onClick={handlePrint}>pdf</li>
                                {/* <li>xml</li> */}
                            </ul>
                        </div>
                    </div>
                    <div className='tableWrapper'>
                        <table ref={componentRef} className="w-100 table">
                            <thead>
                                <tr>
                                    <th>
                                        <Tooltip title="select all">
                                            <input type="checkbox" onChange={toggleSelectAllCheck} />
                                        </Tooltip>
                                    </th>
                                    <th>leads details</th>
                                    {/* <th>phone</th>
                            <th>email</th> */}
                                    <th>subject</th>
                                    <th>message</th>
                                    <th>date</th>
                                    <th>actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allContacts && allContacts.map((contact, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td className='pe-0 hideAtPrint' style={{ 'maxWidth': '2rem' }}>
                                                <input type="checkbox" checked={checkedInputs.find((p) => p === contact.id) ? true : false} onChange={(e) => toggleCheckbox(e, contact)} />
                                            </td>
                                            <td>
                                                <div className='row m-0 p-0 mb-2'>
                                                    <div className='col-4 ps-0'>
                                                        name
                                                    </div>
                                                    <div className='col-6 ps-0'>
                                                        {contact.name}
                                                    </div>
                                                </div>
                                                <div className='row m-0 p-0 mb-2'>
                                                    <div className='col-4 ps-0'>
                                                        phone
                                                    </div>
                                                    <div className='col-6 ps-0'>
                                                        {contact.phone}
                                                    </div>
                                                </div>
                                                <div className='row m-0 p-0 mb-2'>
                                                    <div className='col-4 ps-0'>
                                                        email
                                                    </div>
                                                    <div className='col-6 ps-0'>
                                                        {contact.email}
                                                    </div>
                                                </div>
                                            </td>
                                            {/* <td>
                                    {contact.phone}
                                </td>
                                <td>
                                    {contact.email}
                                </td> */}
                                            <td>
                                            <div> {contact.subject}</div>
                                            </td>
                                            <td style={{ "width": "22%" }}>
                                               <div> {`${contact.message.substring(0, 25)}...`} </div> 
                                            </td>
                                            <td>
                                                <div className='d-flex'>
                                                    {moment(contact.created_at).format('DD-MM-YY h:mm A')}
                                                </div>
                                            </td>
                                            <td className='hideAtPrint'>
                                                <div className='d-flex'>
                                                    {contact.is_blocked ?
                                                        <Tooltip title='unblock'>
                                                            <img src={blocked_ip_icon} alt="unblock ip" data-bs-toggle="modal" data-bs-target='#toggleblockIpModal' onClick={() => toggleBlock(false, 1, contact.ip)} />
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title='block'>
                                                            <img src={ip_icon} alt="block ip" data-bs-toggle="modal" data-bs-target='#toggleblockIpModal' onClick={() => toggleBlock(true, 1, contact.ip)} />
                                                        </Tooltip>
                                                    }
                                                    <Tooltip title="view message">
                                                        <div data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls="collapseExample1" onClick={() => markContactMsgRead(contact.id, contact.status)}>
                                                            <span className='envelopWrapper'>
                                                                {contact.status ? <BsEnvelopeOpen /> : <BsEnvelope />}
                                                            </span>
                                                        </div>
                                                    </Tooltip>
                                                    {Permissions && Permissions.contact && Permissions.contact.delete && <Tooltip title="delete">
                                                        <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteContactModal' onClick={() => setContactId(contact.id)} />
                                                    </Tooltip>}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="collapse contactMsgRow" id={`collapse${index}`}>
                                            <td colSpan="8">{contact.message}</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/**** delete single contact modal ****/}
                    <div className="modal fade" id="deleteContactModal" tabIndex={-1} aria-labelledby="deleteContactModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div ref={closeDeleteContactModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={resetInput}>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <form id="deleteContactForm" onSubmit={(e) => confirmDelete(e, 1)}>
                                        <div className='formInputsWrapper'>
                                            <p>Do you want to delete this message ?</p>
                                            <div className='mb-4'>
                                                <div className="form-floating">
                                                    <input onChange={deleteValidation} className='w-100 form-control' name="validate" id='floatingInput' type='text' placeholder='delete' required />
                                                    <label htmlFor="floatingInput">please type <span>"DELETE"</span> to confirm</label>
                                                </div>
                                                <div ref={deleteModalRef} className='validateToDelete d-none text-danger mt-1'>
                                                    please type "DELETE" in capital letters.
                                                </div>
                                            </div>
                                            {/*** modal footer ***/}
                                            <div className='d-flex justify-content-end'>
                                                <div className='cancelBtn' onClick={resetInput} data-bs-dismiss="modal">cancel</div>
                                                {!loading ?
                                                    <button className={validateDelete === "DELETE" ? `deleteBtn` : `confirmBtn`} type='submit'>delete</button>
                                                    :
                                                    <button className='confirmBtn deleteBtn' disabled>
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                        deleting...
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/**** delete multiple contact modal ****/}
                    <div className="modal fade" id="deleteBulkContactModal" tabIndex={-1} aria-labelledby="deleteBulkContactModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div ref={closeDeleteBulkContactModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={resetInput}>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <form id="deleteContactBulkForm" onSubmit={(e) => confirmDelete(e, 0)}>
                                        <div className='formInputsWrapper'>
                                            <p>Do you want to delete selected messages ?</p>
                                            <div className='mb-4'>
                                                <div className="form-floating">
                                                    <input onChange={deleteValidation} className='w-100 form-control' name="validate" id='floatingInput' type='text' placeholder='delete' required />
                                                    <label htmlFor="floatingInput">please type <span>"DELETE"</span> to confirm</label>
                                                </div>
                                                <div ref={deleteModalBulkRef} className='validateToDelete d-none text-danger mt-1'>
                                                    please type "DELETE" in capital letters.
                                                </div>
                                            </div>
                                            {/*** modal footer ***/}
                                            <div className='d-flex justify-content-end'>
                                                <div className='cancelBtn' onClick={resetInput} data-bs-dismiss="modal">cancel</div>
                                                {!loading ?
                                                    <button className={validateDelete === "DELETE" ? `deleteBtn` : `confirmBtn`} type='submit'>delete</button>
                                                    :
                                                    <button className='confirmBtn deleteBtn' disabled>
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                        deleting...
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/**** toggle block modal ****/}
                    <div className="modal fade" id="toggleblockIpModal" tabIndex={-1} aria-labelledby="toggleblockIpModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div ref={closeBlockIpModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <form id="blockBulkIpsForm" onSubmit={confirmToggleBlockIps}>
                                        <div className='formInputsWrapper'>
                                            <p className='mb-4'>{`Do you want to ${modalObj.action === true ? `block` : `unblock`} ${modalObj.type === 1 ? `selected item ?` : `selected items ?`}`}</p>
                                            {/*** modal footer ***/}
                                            <div className='d-flex justify-content-end'>
                                                <div className='cancelBtn' data-bs-dismiss="modal">cancel</div>
                                                {!loading ?
                                                    <button className='deleteBtn' type='submit'>{`${modalObj.action === true ? `block` : `unblock`}`}</button>
                                                    :
                                                    <button className='deleteBtn' disabled>
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                        saving...
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {Total > filterObj?.per_page && <div className='d-flex justify-content-end mt-auto'>
                    <div className=''>
                        <Pagination pagesNumber={pageCount} pageChangeHandler={handlepageChange} />
                    </div>
                </div>}
            </section>
        </>
    )
}
