import React, { useContext, useEffect , useState } from 'react'
import Subside from '../../Components/SubSide/Subside';
import { useLocation } from 'react-router';
import { Route, Routes } from "react-router-dom";
import TitleContext from '../../TitleContext';
import { getFacilities } from '../../Apis/facilitiesApi';
import Add from './FacilityForm/Add';
import Edit from './FacilityForm/Edit';
import permissionsContext from '../../permissionsContext';

export default function Facilities() {
    let {changeTitle} = useContext(TitleContext);
    let {Permissions} = useContext(permissionsContext);
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    let [allFacilities , setAllFacilities] = useState([]);

    // get all Facilities
    function getAllFacilities(){
        let myallFacilities = [...allFacilities];
        getFacilities().then(res => {
            myallFacilities = res.data.data;
            setAllFacilities(myallFacilities);
            console.log(res.data.data);
        }).catch(err => 
            console.log(err))
    }

    useEffect(() => {
        changeTitle(`facilities`);
        getAllFacilities();
        // eslint-disable-next-line
    }, [])


    return (
        <>
        {Permissions && Permissions.facility && <section className='subside_action_wrapper'>
            {Permissions.facility.view && <Subside items={allFacilities} btnText="+ add facility" btnUrl="property-settings/facilities"/>}
            <Routes>
                {Permissions.facility.create && <Route path='add' element={<Add getAll={getAllFacilities}/>}/>}
                {Permissions.facility.edit && <Route path='edit/:id' element={<Edit getAll={getAllFacilities}/>}/>}
            </Routes>
        </section>}
        </>
    )
}
