import React, { useState , useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { editArea, getArea } from '../../Apis/areasApi';
import { api_host, api_basename,api_url } from './../../Configuration/Config';
import uploadImage from "../../assets/icons/uploadimage.svg";
import Tooltip from '@material-ui/core/Tooltip';
import removeIcon from "../../assets/icons/removeIcon.svg";
import { getCities } from '../../Apis/citiesApi';
import { filterLanguage } from './../../utilities/filterLang';
import axios from 'axios';

export default function EditArea() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    let [oneArea , setOneArea] = useState({});
    let [editedArea , setEditedArea] = useState({locales : []});
    let [options , setOptions] = useState([]);
    let [areaImage , setAreaImage] = useState('');
    const imageRef = useRef();
    const cityRef = useRef();
    /* const checkRef = useRef(); */
    let navigate = useNavigate();

    // get all cities to fill selectbox with options   
    function getAllCitiesOptions(){
        let myOptions = {...options}
        // to send request
        getCities().then( res =>{
            myOptions = res.data.data;
            setOptions(myOptions);
        })
        .catch( err =>
            console.log (err.response)
        )
    }

    useEffect(()=>{
        getAllCitiesOptions();
        // eslint-disable-next-line
    } , []);

    // get area data
    useEffect(() => {
        getArea(id).then((res) => {
            let myOneArea ={...oneArea};
            myOneArea = res.data.data;
            setAreaImage(`${api_host}/${myOneArea?.image?.image}`);
            console.log(myOneArea)
            myOneArea.image=myOneArea?.image?.id
            console.log(myOneArea)
            setOneArea(myOneArea);
            // setAreaImageObj(myOneArea.image);
        })
        // eslint-disable-next-line
    }, [id]);
    let [progress, setProgress] = useState(0)
    /* upload images */
    const token = `Bearer ` + localStorage.getItem('rh_Admin_Token');
    function addImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }

        axios.post(`${api_url}/admin/upload`, formData, config).then((res) => {
            let result = res.data.data;
            setAreaImage(`${api_host}/${result.image}`)
            setProgress(0);
            let obj = { ...editedArea };
            obj.image = result.id;
            setEditedArea(obj)
            // image validation
            let classes = Array.from(imageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                imageRef.current.nextSibling.classList.add("d-none");
            }
        }).catch((err) => {
            console.log(err.response)
            setProgress(0);
        })
    }

    // remove image
    function removeImgHandler(){
        setAreaImage('');
        let obj = { ...oneArea };
        obj.image = '';
        setOneArea(obj)
    }

    // edit area
    function editAreaChange(e){
        let myoneArea = {...oneArea};
        let myEditArea = {...editedArea};
        // if (e.target.name === 'image') {
        //     myoneArea[e.target.name] = e.target.files[0];
        //     myEditArea[e.target.name] = e.target.files[0];
        //     setAreaImageObj(e.target.files[0]);
        //     const reader = new FileReader();
        //     reader.addEventListener("load", () => {
        //         setAreaImage(reader.result);
        //     });
        //     reader.readAsDataURL(e.target.files[0]);
        //     // image validation
        //     let classes = Array.from(imageRef.current.nextSibling.classList);
        //     if (!classes.includes("d-none")) {
        //         imageRef.current.nextSibling.classList.add("d-none");
        //     }
        // }/* else if(e.target.name === 'featured'){
        //     myEditArea = {...myEditArea , "featured" : e.target.checked ? 1 : 0}           
        // } */else
         if(e.target.name === 'name' || e.target.name === 'description'){
            let language = e.target.getAttribute('data-lang');
            myoneArea.locales.map((l) => {
                if (l.locale === language) {
                    l[e.target.name] = e.target.value;
                }
                return true;
            })
            let exists = myEditArea.locales.filter((i) => {
                return i.locale === language;
            })
            if(exists.length){
                exists[0][e.target.name] = e.target.value;
            }else{
                myEditArea.locales.push({'locale': language});
                myEditArea.locales.map((i) => {
                    if(i.locale === language){
                        i[e.target.name] = e.target.value;
                    }
                    return true;
                });
            }
        }else{
            myoneArea[e.target.name] = e.target.value;
            myEditArea[e.target.name] = e.target.value;
        }
        setOneArea(myoneArea);
        setEditedArea(myEditArea);
    }

    function editAreaSubmit(e){
        e.preventDefault();
        /* let Obj = {...oneArea}; */
        let formData = new FormData();

        if (areaImage === '') {
            imageRef.current.nextSibling.classList.remove("d-none");
        }

        for (const key in editedArea) {
            if (key === 'locales') {
                for (let i = 0; i < editedArea.locales.length; i++) {
                    for (const key in editedArea.locales[i]) {
                        formData.append(`locales[${i}][${key}]`, editedArea.locales[i][key]);
                    }
                }
            }
            else {
                formData.append(key, editedArea[key]);
            }
        }
        formData.append("_method", "patch");

        // for (var pair of formData.entries()){
        //     console.log(pair[0] + ',' + pair[1]); 
        // } 

        if(areaImage !== ''){
        setLoading(true);
        // to send request
        editArea(id,formData).then(res =>{
            setLoading(false);
            navigate(`${api_basename}/dashboard/property-settings/cities_areas?tabSelect=areas`);
            console.log(res.data.data);            
        }).catch(err => {
            setLoading(false);
            console.log(err.response)
        })}
    }


    return (
        <>
        <section className='componentGlobalWrapper addArea'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>edit area</h5>
            </div>

            <form onSubmit={editAreaSubmit}>
                <div className='formInputsWrapper'>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='title' className='formLabel'>image</label>
                        { !areaImage ?
                            <div>
                                <label ref={imageRef} htmlFor="uploadImage" className='position-relative'>
                                    <div className='globalImageWrapper'>
                                        <input onChange={addImage} type='file' id='uploadImage' name='image' className="d-none"/>
                                        <img className='cloudIcon' src={uploadImage} alt="upload"/>
                                        <span>Upload Image</span>
                                    </div>
                                </label>
                                {progress !== 0 && (
                                <div className="progress mt-2 p-0" style={{width: '11.25rem'}}>
                                    <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                        aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                        {progress}%
                                    </div>
                                </div>)}
                                <div className='validateToDelete text-danger d-none'>This field is required!</div>
                            </div>
                            :
                            <div className='globalImageWrapper position-relative'>
                                <Tooltip title='Remove Image'>
                                    <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                        <img src={removeIcon} alt="delete" onClick={removeImgHandler}/>
                                    </label>
                                </Tooltip>
                                <div className='globalPreviewedImage' style={{backgroundImage: `url(${areaImage})`}}></div>
                            </div>
                        }
                    </div>
                    <div className='d-flex flex-column mb-4 w-100'>
                        <label htmlFor='city' className='formLabel'>city</label>
                        <div ref={cityRef} className='selectParent'>
                            <select value={oneArea && oneArea.city && oneArea.city.id} defaultValue={oneArea && oneArea.city && oneArea.city.id} onChange={editAreaChange}  name='city' required>
                                {options && options.map((option , index)=>(
                                    <option key={index} value={option.id}>{option.locales ? option.locales[0].name : ""}</option>
                                ))}
                            </select>
                        </div>
                        <div className='validateToDelete text-danger d-none'>this field is required!</div>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_en' className='formLabel'>name in english</label>
                        <input maxLength='255' value={(oneArea && oneArea.locales && oneArea.locales.length) ? filterLanguage(oneArea.locales ,"en")[0].name : ""} onChange={editAreaChange} name="name" data-lang='en' id='name_en' type='text' placeholder='name in english' pattern="^(.*)[^ ](.*)$" required/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_ar' className='formLabel'>name in arabic</label>
                        <input maxLength='255' value={(oneArea && oneArea.locales && oneArea.locales.length) ? filterLanguage(oneArea.locales ,"ar")[0].name : ""} onChange={editAreaChange} name="name" data-lang='ar' id='name_ar' type='text' placeholder='name in arabic' pattern="^(.*)[^ ](.*)$" required/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='description_en' className='formLabel'>description in english</label>
                        <textarea maxLength='255' value={(oneArea && oneArea.locales && oneArea.locales.length && filterLanguage(oneArea.locales ,"en")[0].description) ? filterLanguage(oneArea.locales ,"en")[0].description : ""} onChange={editAreaChange} name="description" data-lang='en' id='description_en' type='text'/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='description_ar' className='formLabel'>description in arabic</label>
                        <textarea maxLength='255' value={(oneArea && oneArea.locales && oneArea.locales.length && filterLanguage(oneArea.locales ,"ar")[0].description) ? filterLanguage(oneArea.locales ,"ar")[0].description : ""} onChange={editAreaChange} name="description" data-lang='ar' id='description_ar' type='text'/>
                    </div>
                    {/* <div className='d-flex align-items-center mb-4'>                        
                        <label htmlFor='active_status' className='formLabel mb-0 me-3'>feature area</label>
                        <label className="switch mb-0">
                            <input ref={checkRef} onChange={editAreaChange} type="checkbox" name='featured'/>
                            <span className="slider round" />
                        </label>
                    </div> */}
                </div>
                <div>
                    {!loading ? 
                        <button type='submit'>save changes</button>
                        :
                        <button className='confirmBtn' disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            saving...
                        </button>
                    }
                </div>                
            </form>
        </section>
        </>
    )
}
