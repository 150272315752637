import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router';
import Subside from '../../Components/SubSide/Subside';
import { Route, Routes } from "react-router-dom";
import TitleContext from '../../TitleContext';
import AddRole from './RolesForm/Add';
import EditRole from './RolesForm/Edit';
import { useState } from 'react';
import { getRoles } from '../../Apis/rolesApi';
import permissionsContext from '../../permissionsContext';

export default function Roles() {
    const { pathname } = useLocation();
    let {Permissions} = useContext(permissionsContext);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    let {changeTitle} = useContext(TitleContext);
    const [allRoles ,setAllRoles] = useState([])
    useEffect(()=>{
        changeTitle(`roles`);
        getAllRoles()
        // eslint-disable-next-line
    } , [])

    function getAllRoles(){
        getRoles().then(res=>{
            setAllRoles(res.data.data);
        })
    }
    return (
        <>
            {Permissions && Permissions.role && <section className='subside_action_wrapper'>
                { Permissions.role.view && <Subside items={allRoles} btnText="+ add role" btnUrl="users/roles"/>}
                <Routes>
                    {Permissions.role.create && <Route path='add' element={<AddRole getAll={getAllRoles}/>}/>}
                    {Permissions.role.edit && <Route path='edit/:id' element={<EditRole getAll={getAllRoles} />} />}
                </Routes>
            </section>}
        </>
    )
}
