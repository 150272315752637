import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get sliders
export async function getAllNotifications(){
    return await AxiosClone.get(`${api_url}/admin/notifications`);
}

export async function  readNotifications(id){
    return await AxiosClone.post(`${api_url}/admin/notifications/${id}/activate`);
}