import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get categories
export async function getCategories(){
    return await AxiosClone.get(`${api_url}/admin/categories`)
}

//get one category
export async function getCategory(id){
    return await AxiosClone.get(`${api_url}/admin/categories/${id}`)
}

//edit category
export async function editCategory(id,data){
    return await AxiosClone.post(`${api_url}/admin/categories/${id}`, data)
}