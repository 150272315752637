import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get properties
export async function getProperties(obj){
    return await AxiosClone.get(`${api_url}/admin/properties?${obj}`)
}

//get property
export async function getProperty(id){
    return await AxiosClone.get(`${api_url}/admin/properties/${id}`)
}

//delete property
export async function deleteProperty(id){
    return await AxiosClone.delete(`${api_url}/admin/properties/${id}`);
}

//undelete Prop
export async function undeleteProperty(id){
    return await AxiosClone.patch(`${api_url}/admin/properties/${id}/restore`);
}

//toggle active property
export async function updateProperty(id ,data){
    return await AxiosClone.post(`${api_url}/admin/properties/${id}`, data);
}

//create property
export async function createNewProperty(data){
    return await AxiosClone.post(`${api_url}/admin/properties`, data);
}