import React from 'react';
import { useParams } from 'react-router';
import { editMember } from '../../../Apis/teamMembersApi';
import Form from './Form';

export default function EditMember() {
    const { id } = useParams();
    // onsubmit
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        data.append("_method", "patch");
        return await editMember(id , data).then(res => {
            return "done";          
        }).catch(err => {
            console.log(err.response);
            return err.response.data.errors;
        })
    };
    
    return (
        <Form onSubmit={onSubmit}></Form>
    )
}
