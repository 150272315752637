import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get roles
export async function getRoles(){
    return await AxiosClone.get(`${api_url}/admin/roles`);
}

//get role by id
export async function getRole(id){
    return await AxiosClone.get(`${api_url}/admin/roles/${id}`);
}

//get all permssions
export async function getRolePermissions(){
    return await AxiosClone.get(`${api_url}/admin/permissions`);
}

// create role
export async function createRole(data){
    return await AxiosClone.post(`${api_url}/admin/roles`,data);
}

// update role
export async function updateRole(id,data){
    return await AxiosClone.post(`${api_url}/admin/roles/${id}`,data);
}

// update role
export async function deleteRole(id){
    return await AxiosClone.delete(`${api_url}/admin/roles/${id}`);
}
