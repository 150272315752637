import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get 
export async function getPageHeaderAndContent(pageName){
    return await AxiosClone.get(`${api_url}/admin/settings/apperance/${pageName}`)
}
//get headers
export async function getPageHeaders(id){
    return await AxiosClone.get(`${api_url}/admin/headers/${id}`)
}
//update headers
export async function updatePageHeaders(id,data){
    return await AxiosClone.post(`${api_url}/admin/headers/${id}`,data)
}