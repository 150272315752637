import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get admins
export async function getAdmins(){
    return await AxiosClone.get(`${api_url}/admin/admins`);
}

//get one admin
export async function getAdmin(id){
    return await AxiosClone.get(`${api_url}/admin/admins/${id}`);
}

//create admin
export async function createNewAdmin(data){
    return await AxiosClone.post(`${api_url}/admin/admins`, data);
}

//edit admin
export async function editAdmin(id ,data){
    return await AxiosClone.post(`${api_url}/admin/admins/${id}`, data);
}

//delete admin
export async function deleteAdmin(id){
    return await AxiosClone.delete(`${api_url}/admin/admins/${id}`);
}