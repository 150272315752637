import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get developers
export async function getDevelopers(obj=null){
    return await AxiosClone.get(`${api_url}/admin/developers?${obj}`)
}
//get developer 
export async function getDeveloperDetails(id){
    return await AxiosClone.get(`${api_url}/admin/developers/${id}`)
}
//create developer
export async function createNewdeveloper(data){
    return await AxiosClone.post(`${api_url}/admin/developers`, data);
}
//update developer
export async function updatedeveloper(id ,data){
    return await AxiosClone.post(`${api_url}/admin/developers/${id}`, data);
}
//delete developer
export async function deleteDevelopers(id){
    return await AxiosClone.delete(`${api_url}/admin/developers/${id}`);
}

//undelete Prop
export async function undeleteDevelopers(id){
    return await AxiosClone.patch(`${api_url}/admin/developers/${id}/restore`);
}