import { useState , useEffect , React} from "react";
import { Outlet } from "react-router-dom";
import { getAdminProfileInfo } from "./Apis/profileApi";
import Loader from "./Components/loader/loader";
import Login from './Layout/Authentication/Login/Login';


export default function ProtectedRoute() {
    let [authorized , setauthorized] = useState('');
    useEffect(() =>{
        getAdminProfileInfo().then(() => {
            setauthorized('authorized')
        }).catch(()=>(setauthorized('not-authorized')))
    } , []);

    if(authorized === 'authorized'){
        return <Outlet/> 
    }else if(authorized === 'not-authorized'){
        return <Login/>;
    }else{
        return <Loader/>
    };
}