import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import "../../Facilities/AddEditCardSide.scss";
import { filterLanguage } from '../../../utilities/filterLang';
import "../AddEditCardSide.scss";
import uploadImage from "../../../assets/icons/uploadimage.svg";
/* import deleteIcon from "../../../assets/icons/deleteIcon.svg"; */
import Tooltip from '@material-ui/core/Tooltip';
import { getFacility } from '../../../Apis/facilitiesApi';
import { setFacilityData } from './formHandler';
import formModel from './formModel';
import { api_host, api_url } from '../../../Configuration/Config';
import removeIcon from "../../../assets/icons/removeIcon.svg";
import axios from 'axios';

export default function Form(props) {
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const {id} = useParams();
    let iconRef = useRef();
    let [facilityImage , setFacilityImage] = useState('');

    useEffect(() => {
        if (id) {
            getFacility(id).then((res) => {
                let x = res.data.data;
                if(x.image){
                    setFacilityImage(`${api_host}/${x.image?.image}`);
                }
                let facil = {...x}
                facil.image = x?.image?.id
                setModel(facil)
            })
        }else{
            setModel({
                locales: [],
                image: ''
            })
        }
    }, [id]);

    let [progress, setProgress] = useState(0);
    let [imgError,setImgerror]=useState('')
    /* upload images */
    const token = `Bearer ` + localStorage.getItem('rh_Admin_Token');

    function addImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }
        setImgerror('')
        axios.post(`${api_url}/admin/upload`, formData, config).then((res) => {
            let result = res.data.data;
            setFacilityImage(`${api_host}/${result.image}`)
            setProgress(0);
            let obj = { ...model };
            obj.image = result.id;
            setModel(obj)
            // image validation
            let classes = Array.from(iconRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                iconRef.current.nextSibling.classList.add("d-none");
            }
        }).catch((err) => {
            setImgerror(err?.response?.data?.errors?.image)
            setProgress(0);
        })
    }
    // onchange
    const setFormData = (e, property , locale=false , language=null) => {
        setFacilityData(model, setModel, e, property , locale , language);
    }

    // remove image
    function removeImgHandler(){
        setFacilityImage('');
        let obj = { ...model };
        obj.image = '';
        setModel(obj)
    }

    // onsubmit
    const confirmFacility = async (e , model)=>{
        e.preventDefault();
        let formData = new FormData();

        if (facilityImage === '') {
            iconRef.current.nextSibling.classList.remove("d-none");
        }

        for(const key in model){
            if (key === 'locales') {
                for (let i = 0; i < model.locales.length; i++) {
                    for (const key in model.locales[i]) {
                        formData.append(`locales[${i}][${key}]`, model.locales[i][key]);
                    }
                }
            }
            else {
                formData.append(key, model[key]);
            }
        }
        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ', ' + pair[1]);
        // }
        if (facilityImage !== ''){
            setLoading(true);
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if(submitProps === "done"){
                setLoading(false);
                if(!id){
                    setModel({
                        locales: [],
                        image: ''
                    });
                    setFacilityImage("");
                }
            }else{
                console.log(submitProps)
                setImgerror(submitProps.image)
                setLoading(false);
                // if(!id){
                //     setModel({
                //         locales: [],
                //         image: ''
                //     });
                //     setFacilityImage("");
                // }
            }
        }
    }

    return (
        <>
        <form id='facilityForm' onSubmit={(e) => confirmFacility(e, model)}>
            <div className='formInputsWrapper'>
                <div className='d-flex flex-column mb-4 w-100'>
                    <label htmlFor='name_en' className='formLabel'>facility icon</label>
                    { !facilityImage ?
                        <div>
                            <label ref={iconRef} htmlFor="uploadImage" style={{"width" : "80px" , "position" : "relative"}}>
                                <div className='roundedIimageWrapper'>
                                    <input  onChange={(e) => addImage(e)} type='file' id='uploadImage' name='image' className="d-none"/>
                                    <img src={uploadImage} style={{"width" : "40px" , "height" : "30px"}} alt="upload"/>
                                    <span style={{"fontSize" : "6px"}}>Upload Image</span>
                                </div>
                            </label>
                            {progress !== 0 && (
                                <div className="progress mt-2 p-0"  style={{"width" : "80px"}}>
                                    <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                        aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                        {progress}%
                                    </div>
                                </div>)}
                            <div className='validateToDelete text-danger d-none'>this field is required!</div>
                            <div className='validateToDelete text-danger'>{imgError}</div>
                        </div>
                        :
                        <div>
                            <div className='roundedIimageWrapper position-relative'>
                                <Tooltip title='remove image'>
                                    <label htmlFor='removeicon' className='reloadAnotherIcon'>
                                        <img className='removeFooterIcon' src={removeIcon} alt="delete" onClick={removeImgHandler} />
                                        {/* <img src={deleteIcon} alt="delete" onClick={removeImgHandler}/> */}
                                        <input type='file' id='viewImage' name='removeicon' className="d-none"/>
                                    </label>
                                </Tooltip>
                                <div className='previewedImg' style={{backgroundImage: `url(${facilityImage})`}}></div>
                            </div>
                            <div className='validateToDelete text-danger d-none'>this field required!</div>
                            <div className='validateToDelete text-danger'>{imgError}</div>
                        </div>
                    }
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='name_en' className='formLabel'>name in english</label>
                    <input maxLength='255' type='text' name="name" className='titleInput' id='name_en' placeholder='name' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"en").length) ? filterLanguage(model.locales ,"en")[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , 'en')} required pattern="^(.*)[^ ](.*)$" title="This field is required"/>
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='name_ar' className='formLabel'>name in arabic</label>
                    <input maxLength='255' type='text' name="name" className='titleInput' id='name_ar' placeholder='name' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"ar").length) ? filterLanguage(model.locales ,"ar")[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , 'ar')} required pattern="^(.*)[^ ](.*)$" title="This field is required"/>
                </div>
            </div>
            <div className='d-flex'>
                {id ?
                    <div style={{"width" : "fitContent" , "cursor" : "pointer"}} data-bs-toggle="modal" data-bs-target='#deleteFacilityModal' className='confirmBtn deleteBtn'>delete</div>
                : ''}
                {!loading ? 
                    <button className='confirmBtn' type='submit'>{id ? `save changes` : `add facility`}</button>
                    :
                    <button className='confirmBtn' disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        saving...
                    </button>
                } 
            </div>
        </form>
        </>
    )
}
