import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get contact requests
export async function getContactRequests(obj){
    return await AxiosClone.get(`${api_url}/admin/contacts?${obj}`)
}

//delete contact requests
export async function deleteContactRequest(data){
    return await AxiosClone.post(`${api_url}/admin/contacts` , data)
}

//mark message as read
export async function markAsRead(id,data){
    return await AxiosClone.post(`${api_url}/admin/contacts/${id}` , data)
}