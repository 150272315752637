import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import TitleContext from '../../TitleContext';
import "../News/News.scss";
import search from "../../assets/icons/search.svg";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import undelete from "../../assets/icons/undelete.svg";
import { Link, useSearchParams } from 'react-router-dom';
import { api_basename, api_host } from '../../Configuration/Config';
import moment from 'moment';
import DeleteModal from '../../utilities/deleteModal';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '../../Components/Pagination/Pagination';
import { getStatistics } from '../../Apis/overviewApi';
import permissionsContext from '../../permissionsContext';
import { deleteDevelopers, getDevelopers, undeleteDevelopers, updatedeveloper } from '../../Apis/developersApi';

export default function DeveloperList() {
    let { changeTitle } = useContext(TitleContext);
    let { Permissions } = useContext(permissionsContext);
    let [allDevelopers, setallDevelopers] = useState([]);
    const [propertyId, setPropertyId] = useState('');
    const [filterObj, setfilterObj] = useState({ search: '', per_page: '10', page: '1', trashed: '', status: '' });
    const [pageCount, setPagecount] = useState(1);
    const [totalDev, settotalDev] = useState(0);
    const [totalPublish, setTotalPublish] = useState(0);
    const [totalTrashed, setTotalTrashed] = useState(0);
    const allPropsRef = useRef();
    const [searchParams] = useSearchParams();
    const [params] = useState(searchParams.get("status"));
    const activeProp = useRef();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get property id
    function getId(id) {
        setPropertyId(id)
    }

   function getDeveloperStatistcs(){
    getStatistics().then((res) => {
        console.log(res.data.data.overview)
        settotalDev(res.data.data.overview.developers)
        setTotalPublish(res.data.data.overview.active_developers);
        setTotalTrashed(res.data.data.overview.trashed_developers);
    })
    }
    useEffect(() => {
        changeTitle(`Developers`, Permissions && Permissions.property && Permissions.property.create && { url: 'developers/add-developer', text: 'Add Developer' });
        filterDevelopers('all');
        getDeveloperStatistcs()
        if (params && Permissions?.developer?.view) {
            activeProp.current.click();
        }

        // eslint-disable-next-line
    }, [Permissions, params]);

    function toggleStatus(id, status) {
        let obj;
        let myallDevelopers = [...allDevelopers];
        if (status) {
            obj = { status: 0 };
            updatedeveloper(id, { ...obj, "_method": "patch" }).then((res) => {
                myallDevelopers.map((property) => {
                    if (property.id === id) {
                        property.status = false;
                    }
                    return true;
                })
            })
        } else {
            obj = { status: 1 };
            updatedeveloper(id, { ...obj, "_method": "patch" }).then((res) => {
                myallDevelopers.map((property) => {
                    if (property.id === id) {
                        property.status = true
                    }
                    return true
                })
            })
        }
        getDeveloperStatistcs()
     
    }

    // delete property
    const onSubmitDelete = async () => {
        return await deleteDevelopers(propertyId).then(res => {
            filterDevelopers('all')
            getStatistics().then((res) => {
                setTotalPublish(res.data.data.overview.active_developers);
                if (res.data.data.overview.active_developers === 0) {
                    allPropsRef.current.click();
                }
                setTotalTrashed(res.data.data.overview.trashed_developers);
                if (res.data.data.overview.trashed_developers === 0) {
                    allPropsRef.current.click();
                }
            });
            return "done";
        }).catch(err => {
            return err.response.data.errors;
        })

    }

    function changefilterObj(e) {
        let thisObj = { ...filterObj }
        thisObj[e.target.name] = e.target.value.trim();
        thisObj.page = 1;
        setfilterObj(thisObj);
    }

    const handlepageChange = async data => {
        let obj = `search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}&page=${data.selected + 1}&publish=${filterObj && filterObj.publish}&trashed=${filterObj && filterObj.trashed}`;
        getDevelopers(obj).then(
            res => {
                setallDevelopers(res.data.data);
                res.data.data.map((property) => {
                    if (property.status) {
                        document.getElementById(property.id).checked = true;
                    } else {
                        document.getElementById(property.id).checked = false;
                    }
                    return true
                });
            }
        )
    }

    function filterDevelopers(type) {
        let filter = { ...filterObj };
        if (type === 'publish') {
            filter.status = 1;
            filter.trashed = '';

        } else if (type === 'trash') {
            filter.trashed = 1;
            filter.status = '';
        } else {
            filter.trashed = '';
            filter.status = '';
        }
        filter.page = 1;
        setfilterObj(filter);
        let obj = `search=${filterObj && filterObj.search}&per_page=${filterObj && filterObj.per_page}
        &page=1&trashed=${filter?.trashed}&status=${filter?.status}`;
        getDevelopers(obj).then(res => {
            let myallDevelopers = [...allDevelopers];
            myallDevelopers = res.data.data;
            setallDevelopers(myallDevelopers);
            /* setPageNum(0); */
            let total = res.data.pagination.total;
          
            setPagecount(Math.ceil(total / filterObj.per_page));
            myallDevelopers.map((dev) => {
                //console.log(dev)
                if (dev.status) {
                    document.getElementById(dev.id).checked = true;
                } else {
                    document.getElementById(dev.id).checked = false;
                }
                return true
            });
        })
    }

    function restore(id) {
        undeleteDevelopers(id).then((res) => { console.log(res.data.data) });
        filterDevelopers('trash');
        getDeveloperStatistcs()
    }

    return (
        <>
            {Permissions && Permissions.developer && Permissions.developer.view && <section className='componentGlobalWrapper'>
                <div>
                    <div className='tabs-container nav nav-tabs d-flex align-items-center' id="myTab" role="tablist">
                        <div ref={allPropsRef} onClick={() => filterDevelopers('all')} className='tablinks active' id="Gallery-tab" data-bs-toggle="tab" data-bs-target="#allnews">all developers <span className='tabcounter'>({totalDev})</span></div>
                        <span className='separator'></span>
                        <div ref={activeProp} onClick={() => filterDevelopers('publish')} className='tablinks' id="published-tab" data-bs-toggle="tab" data-bs-target="#published-Img" >published <span className='tabcounter'>({totalPublish})</span></div>
                        <span className='separator'></span>
                        <div onClick={() => filterDevelopers('trash')} className='tablinks' id="trashed-tab" data-bs-toggle="tab" data-bs-target="#trashed-Img">trashed <span className='tabcounter'>({totalTrashed})</span></div>
                    </div>

                    <div className='searchActionsBar w-100'>
                        <div className='searchActionsBar mt-0 mb-0'>
                            {/*** search input ***/}
                            <div className='searchInputWrapper'>
                                <div>
                                    <input type='text' className='barSearchInput' name='search' onChange={changefilterObj} />
                                    {/*  <span className="inputPlaceholder">search</span> */}
                                </div>
                                <img src={search} alt='search-icon' className='barSearchIcon' />
                            </div>
                        </div>

                        {/* show select */}
                        <div className='perPageContainer'>
                            <label htmlFor='selectShow' className='perPageLabel'>
                                show
                            </label>
                            <select name='per_page' onChange={changefilterObj} id={`selectShow`} className='form-select selectShow' defaultValue='10'>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                                <option value='15'>15</option>
                                <option value='20'>20</option>
                            </select>
                        </div>

                        {/*** apply search btn  ***/}
                        <button className='applySearchBtn' onClick={() => filterDevelopers(filterObj)}>apply</button>
                    </div>
                    <div className='tableWrapper'>
                        <table className="w-100 table table-striped">
                            <thead>
                                <tr>
                                    <th>image</th>
                                    <th>name</th>
                                    <th>properties count</th>
                                    <th>date</th>
                                    <th>actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allDevelopers && allDevelopers.map((property, index) => {
                                    return <tr key={index}>
                                        <td className='sliderImg' style={{ "width": "14%" }}>
                                            {property.image &&
                                                <img src={`${api_host}/${property?.image?.image}`} alt="property" style={{ "width": "90%" }} />
                                            }
                                        </td>
                                        <td className='propNameAtTable'>{property?.name}</td>
                                        <td className='propNameAtTable'>{property?.properties_count}</td>
                                        <td>{moment(property.created_at).format('DD-MM-YY h:mm A')}</td>
                                        <td>
                                            {filterObj && filterObj.trashed !== 1 ? <div>
                                                {Permissions && Permissions.developer && Permissions.developer.edit &&
                                                    <Tooltip title={property.status ? 'deactivate' : 'activate'}>
                                                        <label className="switch mb-0">
                                                            <input type="checkbox" id={property.id} onChange={() => toggleStatus(property.id, property.status)} />
                                                            <span className="slider round" />
                                                        </label>
                                                    </Tooltip>
                                                }
                                                {Permissions && Permissions.developer && Permissions.developer.edit &&
                                                    <Tooltip title="edit">
                                                        <Link to={`${api_basename}/dashboard/developers/edit-developer/${property.id}`}>
                                                            <img src={edit_icon} alt="edit" />
                                                        </Link>
                                                    </Tooltip>
                                                }
                                                {Permissions && Permissions.developer && Permissions.developer.delete &&
                                                    <Tooltip title="delete">
                                                        <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={() => getId(property.id)} />
                                                    </Tooltip>
                                                }
                                            </div>
                                                :
                                                <div>
                                                    {Permissions && Permissions.developer && Permissions.developer.restore &&
                                                        <Tooltip title="restore">
                                                            <img width='18px' height='20px' src={undelete} alt="undelete" onClick={() => restore(property.id)} />
                                                        </Tooltip>
                                                    } 
                                                    {Permissions && Permissions.developer && Permissions.developer.delete && <Tooltip title="delete">
                                                        <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={() => getId(property.id)} />
                                                    </Tooltip>}
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='d-flex justify-content-end mt-auto'>
                        <div className=''>
                            <Pagination pagesNumber={pageCount} page={0} pageChangeHandler={handlepageChange} />
                        </div>
                    </div>

                    <DeleteModal onSubmitDelete={onSubmitDelete} name='developer'></DeleteModal>
                </div>
            </section>}
        </>
    )
}
