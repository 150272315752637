import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get partners
export async function getPartners(){
    return await AxiosClone.get(`${api_url}/admin/partners`);
}


//get one partner
export async function getPartner(id){
    return await AxiosClone.get(`${api_url}/admin/partners/${id}`);
}

//create partner
export async function createNewPartner(data){
    return await AxiosClone.post(`${api_url}/admin/partners`, data);
}

//edit partner
export async function editPartner(id ,data){
    return await AxiosClone.post(`${api_url}/admin/partners/${id}`, data);
}

//delete partnet
export async function deletePartner(id){
    return await AxiosClone.delete(`${api_url}/admin/partners/${id}`);
}