import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get leads requests
export async function getLeadsRequests(obj){
    return await AxiosClone.get(`${api_url}/admin/enquiries?${obj}`)
}

//delete lead request
export async function deleteLeadRequest(data){
    return await AxiosClone.post(`${api_url}/admin/enquiries` , data)
}

//toggleblock ip
export async function toggleBlockIps(data){
    return await AxiosClone.post(`${api_url}/admin/blockedIps` , data)
}

//mark lead as read
export async function markLeadAsRead(id,data){
    return await AxiosClone.post(`${api_url}/admin/enquiries/${id}` , data)
}