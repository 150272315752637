import React, { useContext, useEffect, useState } from 'react';
import TitleContext from '../../../TitleContext';
import "../Aboutus/Aboutus.scss";
import '../../News/News.scss'
import { updateContactUsPage } from '../../../Apis/contactUsPage';
import { filterLanguage } from './../../../utilities/filterLang';
import { map_key } from './../../../Configuration/Config';
import { GoogleMap, Marker ,Autocomplete, LoadScript} from '@react-google-maps/api';
import Form from './../../../Components/PageHeaderForm/PageHeaderForm';
import { getPageHeaderAndContent, updatePageHeaders } from '../../../Apis/pageHeadersApi';
import { updateGeneralSettings } from '../../../Apis/generalSettingsApi';
import { useLocation } from 'react-router';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default function Contactus() {
    let {changeTitle} = useContext(TitleContext);
    const [pageHeadersId ,setPageHeadersId] = useState({});
    const [contactUsInfoData ,setContactUsInfoData] = useState({locales : []});
    const [contactUsQuickEnquiryData ,setContactUsQuickEnquiryData] = useState({locales : []});
    const [contactData ,setContactData] = useState({});

    const [libraries] = useState(['places']);
    let [coords , setCoords] = useState({ lat: 25.22544119246961, lng: 55.36216696875});
    let [autocomplete ,setAutocomplete]=useState(null);
    const containerStyle = { width: '100%' ,height: '400px' };

    const [loading2 ,setLoading2] = useState(false);
    const [loading3 ,setLoading3] = useState(false);
    const [loading4 ,setLoading4] = useState(false);

    useEffect(()=>{
        changeTitle(`contact us`);
        getPageHeaderAndContent('contact-us-page').then((res=>{
            let mypageHeadersId = res.data.data.headers['contact-us-page'].id;
            setPageHeadersId(mypageHeadersId);
            
            //info section;
            setContactUsInfoData(res.data.data.headers[`contact-info-section`]);
            // enquiry
            setContactUsQuickEnquiryData(res.data.data.headers[`quick-enquiry-section`]);
            //contact information
            setContactData(res.data.data.settings);
            setCoords({lat: Number(res.data.data.settings.lat.value) , lng: Number(res.data.data.settings.long.value)})
            console.log(res.data.data.settings);
        }));
        // eslint-disable-next-line
    } , []);

    const {pathname} = useLocation();
    useEffect(()=> {
        window.scrollTo(0, 0);
    }, [pathname])

    // onsubmit page headers
    const onSubmit = async (e, data) => {
        e.preventDefault();
        data.append("_method", "patch");
        //to send request
        return await updatePageHeaders(pageHeadersId , data).then(res => {
            console.log(res.data.data);
            return "done";          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };


    /* ********************************** */
    /* info section */
    function updateDataInfo(e){
        let info = {...contactUsInfoData}
        let language = e.target.getAttribute('data-lang');
        let exists = info.locales.filter((i) => {
            return i.locale === language;
        })
        if(exists.length){
            exists[0][e.target.name] = e.target.value;
        }else{
            info.locales.push({'locale': language});
            info.locales.map((l) => {
                if (l.locale === language) {
                    l[e.target.name] = e.target.value;
                }
                return true;
            })
        }
        setContactUsInfoData(info)
    }

    function submitInfoSection(e){
        e.preventDefault();
        setLoading2(true);
        delete contactUsInfoData.status
        delete contactUsInfoData.image;
        updateContactUsPage(contactUsInfoData.id,{...contactUsInfoData,"_method": "patch"}).then(res=>{
            setLoading2(false)
        }).catch(err =>{
            console.log(err.response)
            setLoading2(false)
            })
    }

    /* ********************************** */
    // quick info section
    function updateQuickDataInfo(e){
        let quick = {...contactUsQuickEnquiryData}
        let language = e.target.getAttribute('data-lang');
        let exists = quick.locales.filter((i) => {
            return i.locale === language;
        })
        if(exists.length){
            exists[0][e.target.name] = e.target.value;
        }else{
            quick.locales.push({'locale': language});
            quick.locales.map((l) => {
                if (l.locale === language) {
                    l[e.target.name] = e.target.value;
                }
                return true;
            })
        }
        setContactUsQuickEnquiryData(quick)
    }

    function submitQuickSection(e){
        e.preventDefault();
        setLoading3(true);
        delete contactUsQuickEnquiryData.status
        delete contactUsQuickEnquiryData.image;
        updateContactUsPage(contactUsQuickEnquiryData.id,{...contactUsQuickEnquiryData,"_method": "patch"}).then(res=>{
            console.log(res.data.data);
            setLoading3(false)
        }).catch(err =>
            console.log(err.response))
            setLoading3(false)
    }

    /* *************** contact us data >> email, phone, fax ... (settings) *****************/
    // map 
    function onMarkDragged(e){
        let newCoords = {...coords};
        newCoords = {
            lat: Number(e.latLng.lat()),
            lng: Number(e.latLng.lng())
        }
        setCoords(newCoords);
        let info = {...contactData};
        info['lat'].value = Number(e.latLng.lat());
        info['long'].value = Number(e.latLng.lng());
        setContactData(info);
    }
    
    function onPlaceChanged(){
        if (autocomplete !== null) {
            let newCoords = {...coords};
            if(autocomplete.getPlace().geometry){
            newCoords = {
                lng: Number(autocomplete.getPlace().geometry.location.lng()),
                lat: Number(autocomplete.getPlace().geometry.location.lat())
            }
            }
            setCoords(newCoords);
            let info = {...contactData};
            info['lat'].value = Number(autocomplete.getPlace().geometry.location.lat());
            info['long'].value = Number(autocomplete.getPlace().geometry.location.lng());
            setContactData(info);
        }
    }

    function onLoad(autocomplete){
        setAutocomplete(autocomplete);
    }
    
    // onchange contact info data
    function changeContactData(e){
        let info = {...contactData}
        let language = e.target.getAttribute('data-lang');
        console.log(info);
        if(e.target.name === 'address'){
            let exists = info.address.locales.filter((i) => {
                return i.locale === language;
            })
            if(exists.length){
                exists[0][`description`] = e.target.value;
            }else{
                info.address.locales.push({'locale': language});
                info.address.locales.map((l) => {
                    if (l.locale === language) {
                        l[`description`] = e.target.value;
                    }
                    return true;
                })
            }
        }else{
            info[e.target.name].value = e.target.value;
        }
        setContactData(info)
    }

    // onsubmit contact info data
    function submitContactData(e){
        e.preventDefault();
        setLoading4(true);
        let formData = new FormData();
        let contactArr = [];
        for(const key in contactData){
            contactArr.push(contactData[key]);
        }
        console.log(contactArr);

        contactArr.map((ele ,index)=>{
            for(const key in ele){
                if(ele[`slug`] === 'address'){
                    if (key === 'locales') {
                        for (let i = 0; i < ele.locales.length; i++) {
                            for (const key2 in ele.locales[i]) {
                                if(ele.locales[i][key2] !== null && ele.locales[i][key2] !== ''){
                                    formData.append(`settings[${index}][locales][${i}][${key2}]`, ele.locales[i][key2]);
                                }
                            }
                        }
                    }
                }else{
                    if(key === 'value'){
                        if(ele[key] !== null && ele[key] !== '' && ele[key] !== 'null'){
                            formData.append(`settings[${index}][${key}]`, ele[key]);
                        }else{
                            formData.append(`settings[${index}][${key}]`, '');
                        }
                    }
                }

                if(key === 'id'){
                    formData.append(`settings[${index}][${key}]`, ele[key]);
                }
            }
            return true
        }
        )
        formData.append('_method','patch')
       for (var pair of formData.entries()){
            console.log(pair[0]+ ', ' + pair[1]); 
        } 

        updateGeneralSettings(formData).then((res)=>{
            console.log(res.data.data);
            setLoading4(false);
        }).catch((err)=>{
            console.log(err);
            setLoading4(false);}
        ) 
    }

    return (
        <>
            {/* contact us page header */}
            <section className='componentGlobalWrapper aboutus addBlog'>
                <div className='mb-3'>
                    <h5 className='pageSubtitle'>page title</h5>
                </div>
                <Form onSubmit={onSubmit} name={'contact-us-page'}/>
            </section>

            {/* contact us page info name and description */}
            <section className='componentGlobalWrapper aboutus'>
                <div className='mb-3'>
                    <h5 className='pageSubtitle'>page Info</h5>
                </div>
                <form onSubmit={submitInfoSection}>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_en' className='formLabel'>Name in English</label>
                        <input name="name" id='name_en' type='text' data-lang='en' value={contactUsInfoData && contactUsInfoData.locales && contactUsInfoData.locales.length && filterLanguage(contactUsInfoData.locales,'en')[0] && filterLanguage(contactUsInfoData.locales,'en')[0].name ? filterLanguage(contactUsInfoData.locales,'en')[0].name : ''} onChange={updateDataInfo} maxLength='255'/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_ar' className='formLabel'>Name in Arabic</label>
                        <input name="name" id='name_ar' type='text' data-lang='ar' onChange={updateDataInfo} value={contactUsInfoData && contactUsInfoData.locales && contactUsInfoData.locales.length && filterLanguage(contactUsInfoData.locales,'ar')[0] && filterLanguage(contactUsInfoData.locales,'ar')[0].name ?filterLanguage(contactUsInfoData.locales,'ar')[0].name :''} maxLength='255'/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='description_en' className='formLabel'>description in english</label>
                        <textarea name="description" id='description_en' data-lang='en' onChange={updateDataInfo} value={contactUsInfoData && contactUsInfoData.locales && contactUsInfoData.locales.length && filterLanguage(contactUsInfoData.locales,'en')[0] && filterLanguage(contactUsInfoData.locales,'en')[0].description ?filterLanguage(contactUsInfoData.locales,'en')[0].description :''} maxLength='255'/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='description_ar' className='formLabel'>description in arabic</label>
                        <textarea name="description" id='description_ar' type='text' data-lang='ar' onChange={updateDataInfo} value={contactUsInfoData && contactUsInfoData.locales && contactUsInfoData.locales.length && filterLanguage(contactUsInfoData.locales,'ar')[0] && filterLanguage(contactUsInfoData.locales,'ar')[0].description ?filterLanguage(contactUsInfoData.locales,'ar')[0].description :''} maxLength='255' />
                    </div>
                    <div>
                    { !loading2 ?
                        <button className='confirmBtn' type='submit'>update</button>
                        :
                        <button className='confirmBtn' disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            saving...
                        </button>
                    }
                </div>
                </form>
            </section>

            {/* contact us page contact information -- email , phone ... */}
            <section className='componentGlobalWrapper aboutus'>
                <div className='mb-3'>
                    <h5 className='pageSubtitle'>page content</h5>
                </div>
                <form onSubmit={submitContactData}>
                    {/* address */}
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='address' className='formLabel'>address (english)</label>
                        <input name="address" id='address' type='text' data-lang='en' onChange={changeContactData} value={contactData && contactData.address && contactData.address.locales && contactData.address.locales.length && filterLanguage(contactData.address.locales,'en')[0] && filterLanguage(contactData.address.locales,'en')[0].description ?filterLanguage(contactData.address.locales,'en')[0].description :''} />
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='address_ar' className='formLabel'>address (arabic)</label>
                        <input name="address" id='address_ar' type='text' data-lang='ar' onChange={changeContactData} value={contactData && contactData.address && contactData.address.locales && contactData.address.locales.length && filterLanguage(contactData.address.locales,'ar')[0] && filterLanguage(contactData.address.locales,'ar')[0].description ?filterLanguage(contactData.address.locales,'ar')[0].description :''} />
                    </div>
                    {/* phone */}
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='phone' className='formLabel'>phone</label>
                        <PhoneInput countryCodeEditable={false} country={'ae'} onlyCountries={['ae']} 
                        {...(contactData && contactData.phone && contactData.phone.value ? {'value': contactData && contactData.phone && contactData.phone.value }:{})} className='w-100 phoneInput' id="phone" name='phone' onChange={(e) => contactData['phone'].value=e} />
                    </div>
                    {/* fax  */}
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='fax' className='formLabel'>Whatsapp</label>
                        <PhoneInput countryCodeEditable={false} country={'ae'} onlyCountries={['ae']} 
                        {...(contactData && contactData.fax && contactData.fax.value ? {'value': contactData && contactData.fax && contactData.fax.value }:{})} className='w-100 phoneInput' id="fax" name='fax' onChange={(e) => contactData['fax'].value=e} />
                    </div>
                    {/* email */}
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='email' className='formLabel'>email</label>
                        <input name="email" id='email' type='email' onChange={changeContactData} value={contactData && contactData.email && contactData.email.value ? contactData.email.value  : ''} />
                    </div>
                    {/* lat and long */}
                    <div className='d-flex flex-column mb-4'>
                        <label className="form-label labels mt-3 mb-4">Set locations on map </label>
                        <LoadScript id="script-loader" googleMapsApiKey={map_key} libraries={libraries}>
                            <GoogleMap mapContainerStyle={containerStyle} center={coords} zoom={18} draggable={true}>
                                <Marker position={coords} draggable={true} onDragEnd={onMarkDragged} />
                                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                                    <input type="text" placeholder="Customized your placeholder"className='inputMapStyle'/>
                                </Autocomplete>
                            </GoogleMap>
                        </LoadScript>
                    </div>
                    <div>
                        { !loading4 ?
                            <button className='confirmBtn' type='submit'>update</button>
                            :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                saving...
                            </button>
                        }
                    </div>
                </form>
            </section>

            {/* contact us page enquiry */}
            <section className='componentGlobalWrapper aboutus'>
                <div className='mb-3'>
                    <h5 className='pageSubtitle'>quick inquiry</h5>
                </div>
                <form onSubmit={submitQuickSection}>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='name_en' className='formLabel'>Name in English</label>
                    <input name="name" id='name_en' type='text' data-lang='en' value={contactUsQuickEnquiryData && contactUsQuickEnquiryData.locales && contactUsQuickEnquiryData.locales.length && filterLanguage(contactUsQuickEnquiryData.locales,'en')[0] && filterLanguage(contactUsQuickEnquiryData.locales,'en')[0].name ? filterLanguage(contactUsQuickEnquiryData.locales,'en')[0].name : ''} onChange={updateQuickDataInfo}/>
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='name_ar' className='formLabel'>Name in Arabic</label>
                    <input name="name" id='name_ar' type='text' data-lang='ar' onChange={updateQuickDataInfo} value={contactUsQuickEnquiryData && contactUsQuickEnquiryData.locales && contactUsQuickEnquiryData.locales.length && filterLanguage(contactUsQuickEnquiryData.locales,'ar')[0] && filterLanguage(contactUsQuickEnquiryData.locales,'ar')[0].name ? filterLanguage(contactUsQuickEnquiryData.locales,'ar')[0].name :''}/>
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='description_en' className='formLabel'>Description in English</label>
                    <textarea name="description" id='description_en' type='text' data-lang='en' onChange={updateQuickDataInfo} value={contactUsQuickEnquiryData && contactUsQuickEnquiryData.locales && contactUsQuickEnquiryData.locales.length && filterLanguage(contactUsQuickEnquiryData.locales,'en')[0] && filterLanguage(contactUsQuickEnquiryData.locales,'en')[0].description ?
                    filterLanguage(contactUsQuickEnquiryData.locales,'en')[0].description :''}/>
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='description_ar' className='formLabel'>Description in Arabic</label>
                    <textarea name="description" id='description_ar' type='text' data-lang='ar' onChange={updateQuickDataInfo} value={contactUsQuickEnquiryData && contactUsQuickEnquiryData.locales && contactUsQuickEnquiryData.locales.length && filterLanguage(contactUsQuickEnquiryData.locales,'ar')[0] && filterLanguage(contactUsQuickEnquiryData.locales,'ar')[0].description ? filterLanguage(contactUsQuickEnquiryData.locales,'ar')[0].description :''} />
                </div>
                <div>
                    { !loading3 ?
                        <button className='confirmBtn' type='submit'>update</button>
                        :
                        <button className='confirmBtn' disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            saving...
                        </button>
                    }
                </div>
                </form>
            </section>
        </>
    )
}
