import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get scripts
export async function getScripts(){
    return await AxiosClone.get(`${api_url}/admin/scripts`);
}

//get script by id
export async function getScript(id){
    return await AxiosClone.get(`${api_url}/admin/scripts/${id}`);
}

//update script
export async function updateScript(id,data){
    return await AxiosClone.post(`${api_url}/admin/scripts/${id}`,data);
}