import React, { useContext, useEffect, useRef, useState } from 'react';
import TitleContext from '../../TitleContext';
import uploadImage from "../../assets/icons/uploadimage.svg";
import "./CitiesAndAreas.scss";
import Tooltip from '@material-ui/core/Tooltip';
import removeIcon from "../../assets/icons/removeIcon.svg";
import { getCities } from '../../Apis/citiesApi';
import { createNewArea } from '../../Apis/areasApi';
import { useNavigate } from 'react-router';
import { api_basename, api_host, api_url } from './../../Configuration/Config';
import axios from 'axios';

export default function AddArea() {
    let {changeTitle} = useContext(TitleContext);
    const [loading, setLoading] = useState(false);
    let [options , setOptions] = useState([]);
    let [newArea , setNewArea] = useState({locales :[] , city: ""});
    const [areaImage , setAreaImage] = useState('');
    const imageRef = useRef();
    const cityRef = useRef();
    let navigate = useNavigate();

    // get all cities to fill selectbox with options   
    function getAllCitiesOptions(){
        let myOptions = {...options}
        // to send request
        getCities().then( res =>{
            myOptions = res.data.data;
            setOptions(myOptions);
        })
        .catch( err =>
            console.log (err.response)
        )
    }

    useEffect(()=>{
        changeTitle(`add area`);
        getAllCitiesOptions();
        // eslint-disable-next-line
    } , []);

    // remove image
    function removeImgHandler(){
        setAreaImage('');
    }
    let [progress, setProgress] = useState(0)
    /* upload images */
    const token = `Bearer ` + localStorage.getItem('rh_Admin_Token');
    function addImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }

        axios.post(`${api_url}/admin/upload`, formData, config).then((res) => {
            let result = res.data.data;
            setAreaImage(`${api_host}/${result.image}`)
            setProgress(0);
            let obj = { ...newArea };
            obj.image = result.id;
            setNewArea(obj)
            // image validation
            let classes = Array.from(imageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                imageRef.current.nextSibling.classList.add("d-none");
            }
        }).catch((err) => {
            console.log(err.response)
            setProgress(0);
        })
    }

    // add area handler
    function handleAreaChange(e){
        let mynewArea = {...newArea};
        let language = e.target.getAttribute('data-lang');

        // if (e.target.name === 'image') {
        //     mynewArea[e.target.name] = e.target.files[0];
        //     const reader = new FileReader();
        //     reader.addEventListener("load", () => {
        //         setAreaImage(reader.result);
        //     });
        //     reader.readAsDataURL(e.target.files[0]);
        //     // image validation
        //     let classes = Array.from(imageRef.current.nextSibling.classList);
        //     if (!classes.includes("d-none")) {
        //         imageRef.current.nextSibling.classList.add("d-none");
        //     }
        // }else
         if(e.target.name === 'city'){
            mynewArea.city = e.target.value;
            // city validation
            let classes = Array.from(cityRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                cityRef.current.nextSibling.classList.add("d-none");
            }
        }else if(e.target.name === 'featured'){
            mynewArea = {...mynewArea , "featured" : e.target.checked ? 1 : 0}           
        }else{
            let exists = mynewArea.locales.filter((i) => {
                return i.locale === language;
            })
            if(exists.length){
                exists[0][e.target.name] = e.target.value;
            }else{
                mynewArea.locales.push({'locale': language});
                mynewArea.locales.map((i) => {
                    if(i.locale === language){
                        i[e.target.name] = e.target.value;
                    }
                    return true;
                });
            }
        }
        setNewArea(mynewArea);
        console.log(mynewArea)
    }
    
    // submit adding new area
    function addNewAreaSubmit(e) {
        e.preventDefault();
        let formData = new FormData();
        if (areaImage === '') {
            imageRef.current.nextSibling.classList.remove("d-none");
        }
        if (newArea.city === '') {
            cityRef.current.nextSibling.classList.remove("d-none");
        }
        // to validate before sending request
        if (areaImage !== '' && newArea.city !== '') {
            for (const key in newArea) {
                if (key === 'locales') {
                    for (let i = 0; i < newArea.locales.length; i++) {
                        for (const key in newArea.locales[i]) {
                            formData.append(`locales[${i}][${key}]`, newArea.locales[i][key]);
                        }
                    }
                } else {
                    formData.append(key, newArea[key])
                }
            }
            // to send request
            setLoading(true);
            createNewArea(formData).then((res) => {
                setLoading(false);
                document.getElementById('addAreaForm').reset();
                navigate(`${api_basename}/dashboard/property-settings/cities_areas?tabSelect=areas`);
                setAreaImage('');
            }).catch((err) => {
                setLoading(false);
            })
        }
    }

    return (
        <>
        <section className='componentGlobalWrapper addArea'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>add new area</h5>
            </div>

            <form onSubmit={addNewAreaSubmit} id="addAreaForm">
                <div className='formInputsWrapper'>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='title' className='formLabel'>image</label>
                        { !areaImage ?
                            <div>
                                <label ref={imageRef} htmlFor="uploadImage" className='position-relative'>
                                    <div className='globalImageWrapper'>
                                        <input onChange={addImage} type='file' id='uploadImage' name='image' className="d-none"/>
                                        <img className='cloudIcon' src={uploadImage} alt="upload"/>
                                        <span>Upload Image</span>
                                    </div>
                                </label>
                                {progress !== 0 && (
                                <div className="progress mt-2 p-0" style={{    width: '11.25rem '}}>
                                    <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                        aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                        {progress}%
                                    </div>
                                </div>)}

                                <div className='validateToDelete text-danger d-none'>This field is required!</div>
                            </div>
                            :
                            <div className='globalImageWrapper position-relative'>
                                <Tooltip title='Remove Image'>
                                    <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                        <img src={removeIcon} alt="delete" onClick={removeImgHandler}/>
                                    </label>
                                </Tooltip>
                                <div className='globalPreviewedImage' style={{backgroundImage: `url(${areaImage})`}}></div>
                            </div>
                        }
                    </div>
                    <div className='d-flex flex-column mb-4 w-100'>
                        <label htmlFor='city' className='formLabel'>city</label>
                        <div ref={cityRef} className='selectParent'>
                            <select defaultValue={0} name='city' onChange={handleAreaChange} required>
                                <option value={0} hidden disabled>choose city</option>
                                {options && options.map((option , index)=>(
                                    <option key={index} value={option.id}>{option.locales ? option.locales[0].name : ""}</option>
                                ))}
                            </select>
                        </div>
                        <div className='validateToDelete text-danger d-none'>this field is required!</div>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_en' className='formLabel'>name in english</label>
                        <input maxLength='255' onChange={handleAreaChange} name="name" data-lang='en'  id='name_en' type='text' placeholder='name in english' required pattern="^(.*)[^ ](.*)$" title="This field is required"/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_ar' className='formLabel'>name in arabic</label>
                        <input maxLength='255' onChange={handleAreaChange} name="name" data-lang='ar' id='name_ar' type='text' placeholder='name in arabic' required  pattern="^(.*)[^ ](.*)$" title="This field is required"/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='description_en' className='formLabel'>description in english</label>
                        <textarea maxLength='255' onChange={handleAreaChange} name="description" data-lang='en' id='description_en' type='text'/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='description_ar' className='formLabel'>description in arabic</label>
                        <textarea maxLength='255' onChange={handleAreaChange} name="description" data-lang='ar' id='description_ar' type='text'/>
                    </div>
                    {/* <div className='d-flex align-items-center mb-4'>                        
                        <label htmlFor='active_status' className='formLabel mb-0 me-3'>feature area</label>
                        <label className="switch mb-0">
                            <input type="checkbox" name='featured' onChange={handleAreaChange}/>
                            <span className="slider round" />
                        </label>
                    </div> */}
                </div>
                <div>
                    {!loading ? 
                        <button type='submit'>add area</button>
                        :
                        <button className='confirmBtn' disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            saving...
                        </button>
                    }
                </div>                
            </form>
        </section>
        </>
    )
}
