import React, { useContext, useEffect, useRef, useState } from 'react';
import TitleContext from '../../../TitleContext';
import { getTypes } from '../../../Apis/typesApi';
import { getCategories } from '../../../Apis/CategoriesApi';
import { getCities } from '../../../Apis/citiesApi';
import { getAreasByCity } from '../../../Apis/areasApi';
import { getSpecifications } from '../../../Apis/createPropertyApi';
import { getFacilities } from '../../../Apis/facilitiesApi';
import { useParams, useNavigate, useLocation } from 'react-router';
import { filterLanguage } from '../../../utilities/filterLang';
import formModel from './formModel';
import { setPropertyData } from './formHandler';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from 'draft-convert';
import { GoogleMap, Marker, Autocomplete, LoadScript } from '@react-google-maps/api';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { textEditorValidation } from './../../../utilities/HandleTextEditorValidation';
import uploadImage from "../../../assets/icons/uploadimage.svg";
import price from "../../../assets/icons/price2.svg";
import removeTag from "../../../assets/icons/removetag.svg";
import { getProperty } from '../../../Apis/propertiesApi';
import { map_key, api_basename, api_host, api_url } from './../../../Configuration/Config';
import { supportedLanguages } from './../../../utilities/SupportedLanguages';
import { filterSpcifications } from './../../../utilities/filterSpcifications';
import removeIcon from "../../../assets/icons/removeIcon.svg";
import Tooltip from '@material-ui/core/Tooltip';
import "../../News/News.scss"
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getDevelopers } from '../../../Apis/developersApi';
import axios from 'axios';

export default function Form(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    let navigate = useNavigate()
    let { changeTitle } = useContext(TitleContext);
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const errorDivRef = useRef();
    const metaKeywordsRef = useRef();
    const [keywords, setKeywords] = useState([]);
    const { id } = useParams();
    let [allTypes, setAllTypes] = useState([]);
    let [allCategories, setAllCategories] = useState([]);
    let [allCities, setAllCities] = useState([]);
    let [selectedCity, setSelectedCity] = useState('');
    let [allAreas, setAllAreas] = useState([]);
    let [allSpecifications, setAllSpecifications] = useState([]);
    let [allFacilities, setAllFacilities] = useState([]);
    let [alldevelopers, setAlldevelopers] = useState([]);
    let [errors, setErrors] = useState({ category: [], area: [], youtube: [], whatsapp: [], type: [] });
    let [images, setImages] = useState([]);

    // get all types options
    function getAllTypes() {
        let myallTypes = [...allTypes];
        getTypes().then(res => {
            myallTypes = res.data.data;
            setAllTypes(myallTypes);
        }).catch(err =>
            console.log(err))
    }
    // get all categories options
    function getAllCategories() {
        let myallCategories = [...allCategories];
        getCategories().then(res => {
            myallCategories = res.data.data;
            setAllCategories(myallCategories);
        }).catch(err =>
            console.log(err))
    }
    // get all cities
    function getAllCities() {
        let myallCities = [...allCities];
        getCities().then(res => {
            myallCities = res.data.data;
            setAllCities(myallCities);
        }).catch(err =>
            console.log(err))
    }
    // get all specifications
    function getAllSpecifications() {
        let myallSpecifications = [...allSpecifications];
        getSpecifications().then(res => {
            myallSpecifications = res.data.data;
            setAllSpecifications(myallSpecifications);
        }).catch(err =>
            console.log(err))
    }
    // get all facilities
    function getAllFacilities() {
        let myallFacilities = [...allFacilities];
        getFacilities().then(res => {
            myallFacilities = res.data.data;
            setAllFacilities(myallFacilities);
        }).catch(err =>
            console.log(err.response)
        )
    }
    function getAllDevelopers() {
        let myalldevelopers = [...alldevelopers];
        getDevelopers().then(res => {
            myalldevelopers = res.data.data;
            console.log(myalldevelopers)
            setAlldevelopers(myalldevelopers);
        }).catch(err =>
            console.log(err))
    }

    useEffect(() => {
        changeTitle(`add property`);
        getAllTypes();
        getAllCategories();
        getAllCities();
        getAllSpecifications();
        getAllFacilities();
        getAllDevelopers();
        // eslint-disable-next-line 
    }, [])

    // get areas depending on city onchange
    function fetchAreas(e) {
        setSelectedCity(Number(e.target.value));
        let thisModel = { ...model }
        thisModel.city = Number(e.target.value);
        setModel(thisModel);
    }

    useEffect(() => {
        if (selectedCity !== '') {
            getAreasByCity(selectedCity).then(res => {
                setAllAreas(res.data.data);
            })
        }
        // eslint-disable-next-line 
    }, [selectedCity])

    useEffect(() => {
        if (id) {
            getProperty(id).then((res) => {
                console.log(res.data.data)
                let x = { ...res.data.data };
                setImages(x.images);
                let arr = []
                x.images.map((img) => {
                    arr.push(img.id);
                    return true
                });
                x.images = arr
                console.log(x)
                if (x.status) {
                    document.getElementById('statusCheck').checked = true;
                }
                if (x.featured) {
                    document.getElementById('featuredCheck').checked = true;
                }
                let thisEditors = { ...editors };
                let thiskeywords = { ...keywords };
                supportedLanguages.map((language) => {
                    let lang = filterLanguage(x.locales, language.slug);
                    if (lang.length) {
                        //set values at editor state
                        const contentDataStateAr = ContentState.createFromBlockArray(convertFromHTML(`${lang[0].description}`));
                        const editorDataState = EditorState.createWithContent(contentDataStateAr);
                        thisEditors[language.slug] = editorDataState;
                        
                        // set keywords
                        if (lang[0].meta_keywords && lang[0].meta_keywords !== 'null') {
                            thiskeywords[language.slug] = lang[0].meta_keywords.replace(/ /g, '').split(',');
                        }
                        setKeywords(thiskeywords);
                    }
                    setEditors(thisEditors);
                    return true
                })
                setCoords({ lat: Number(x.latitude), lng: Number(x.longitude) });
                setSelectedCity(x.city);
                setModel(x)
            })
        } else {
            setModel({
                locales: [],
                category: '',
                area: '',
                city: '',
                type: '',
                price: '',
                facilities: [],
                specifications: [],
                youtube: '',
                latitude: 25.22544119246961,
                longitude: 55.36216696875,
                whatsapp: '',
                featured: '',
                status: '',
                images: [],
                developer: ''
            })
        }
        // eslint-disable-next-line 
    }, [id]);

    /************************* text editor **********************/
    let [editors, setEditors] = useState({});
    /* convert body to html */
    const convertContentToHTML = (state) => {
        return convertToHTML(state.getCurrentContent());
    }

    // onchange editor handler
    const handleEditorChange = (state, lang) => {
        textEditorValidation(lang)
        let thisEditors = { ...editors }
        if (!thisEditors[lang]) {
            thisEditors[lang] = EditorState.createEmpty()
        }
        thisEditors[lang] = state;
        setEditors(thisEditors);
        let m = { ...model }
        let existsLocales = m.locales.filter((i) => {
            return i.locale === lang;
        })
        if (existsLocales.length) {
            existsLocales[0]['description'] = convertContentToHTML(state);
        } else {
            m.locales.push({ 'locale': lang });
            m.locales.map((i) => {
                if (i.locale === lang) {
                    i['description'] = convertContentToHTML(state);
                }
                return true;
            });
        }
        setModel(m)
    }

    /********************* meta keywords ************************/
    function AddKeywordHandler(e, lang) {
        let propKeyword = { ...keywords };
        if (e.target.value.length > 30) {
            if (e.keyCode !== 8) {
                e.preventDefault();
            }
        }
        if (e.keyCode === 32) {
            if (!propKeyword[lang]) {
                propKeyword[lang] = []
            }
            if (e.target.value.trim()) {
                propKeyword[lang] = [...propKeyword[lang], e.target.value.trim()];
                setKeywords(propKeyword)
                e.target.value = "";

                let m = { ...model }
                let existsLocales = m.locales.filter((i) => {
                    return i.locale === lang;
                })
                if (existsLocales.length) {
                    existsLocales[0]['meta_keywords'] = propKeyword[lang].join();
                } else {
                    m.locales.push({ 'locale': lang });
                    m.locales.map((i) => {
                        if (i.locale === lang) {
                            i['meta_keywords'] = propKeyword[lang].join();;
                        }
                        return true;
                    });
                }
            }
        }

    }

    /* to delete tag to tags array*/
    function removeKeywordHandler(i, lang) {
        let propKeyword = { ...keywords };
        propKeyword[lang] = propKeyword[lang].filter((key) => propKeyword[lang].indexOf(key) !== i);
        setKeywords(propKeyword)

        let m = { ...model }
        let existsLocales = m.locales.filter((i) => {
            return i.locale === lang;
        })
        if (existsLocales.length) {
            existsLocales[0]['meta_keywords'] = propKeyword[lang].join();
        } else {
            m.locales.push({ 'locale': lang });
            m.locales.map((i) => {
                if (i.locale === lang) {
                    i['meta_keywords'] = propKeyword[lang].join();;
                }
                return true;
            });
        }
    }

    /*************************  map ******************************/
    const [libraries] = useState(['places']);
    let [coords, setCoords] = useState({ lat: 25.22544119246961, lng: 55.36216696875 });
    function onMarkDragged(e) {
        let newCoords = { ...coords };
        newCoords = {
            lng: Number(e.latLng.lng()),
            lat: Number(e.latLng.lat())
        }
        setCoords(newCoords);
        let thisModel = { ...model };
        thisModel['latitude'] = Number(e.latLng.lat());
        thisModel['longitude'] = Number(e.latLng.lng());
        setModel(thisModel);
    }
    let [autocomplete, setAutocomplete] = useState(null);
    const containerStyle = { width: '100%', height: '400px' };
    function onPlaceChanged() {
        if (autocomplete !== null) {
            let newCoords = { ...coords };
            if (autocomplete.getPlace().geometry) {
                newCoords = {
                    lng: Number(autocomplete.getPlace().geometry.location.lng()),
                    lat: Number(autocomplete.getPlace().geometry.location.lat())
                }
            }
            setCoords(newCoords);
            let thisModel = { ...model };
            thisModel['latitude'] = Number(autocomplete.getPlace().geometry.location.lat());
            thisModel['longitude'] = Number(autocomplete.getPlace().geometry.location.lng());
            setModel(thisModel);
        }
    }
    function onLoad(autocomplete) {
        setAutocomplete(autocomplete);
    }

    /************************ multiple images ************************/
    const imgRef = useRef();
    let [progress, setProgress] = useState(0)

    const token = `Bearer ` + localStorage.getItem('rh_Admin_Token');

    function addImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        /* for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        } */
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }

        let err = { ...errors };
        err['media'] = '';
        err['image'] = '';
        setErrors(err)
        imgRef.current.nextSibling.classList.add("d-none");
        axios.post(`${api_url}/admin/upload`, formData, config).then((res) => {
            console.log(res.data.data);
            let adImages = [...images];
            let obj = res.data.data
            let x = adImages.concat(obj)
            setImages(x);

            let thisModel = { ...model }
            thisModel.images.push(obj.id)
            setModel(thisModel)
            setProgress(0);
        }).catch((err) => {
            let x = err.response.data.errors.media;
            let e = { ...errors };
            e['image'] = x;
            setErrors(e)
            setProgress(0);

        })
    }
    //add multipule image handler
    // function onChangeImageHandler(e) {
    //     let thisModel = { ...model }
    //     if (e.target.name === 'images') {
    //         let imagesSrc = [...propImgsSrc];
    //         let srcObj;
    //         for (let i = 0; i < e.target.files.length; i++) {
    //             srcObj = { file: e.target.files[i], src: URL.createObjectURL(e.target.files[i]) }
    //             imagesSrc.push(srcObj);
    //             thisModel.images.push(e.target.files[i]);
    //         }
    //         setPropImgsSrc(imagesSrc);
    //         let classes = Array.from(imgRef.current.nextSibling.classList);
    //         if (!classes.includes('d-none')) {
    //             imgRef.current.nextSibling.classList.add("d-none");
    //         }
    //         setModel(thisModel);
    //     }
    // }

    //delete selected image
    function deleteImageHandler(file) {
        let imagesSrc = [...images];
        let newSrcs = imagesSrc.filter((m) => (m.id !== file));
        setImages(newSrcs);

        let thisModel = { ...model };
        let deletedImg = thisModel.images.indexOf(file);
        console.log(deletedImg)
        if (deletedImg >= 0) {
            thisModel.images.splice(deletedImg, 1);
        }
        setModel(thisModel);
    }

    /**************************** form onChange function *********************************/
    const setFormData = async (e, property, locale = false, language = null, specification = false) => {
        setPropertyData(model, setModel, e, property, locale, language, specification);
    }

    /**************************** form onSubmit function *********************************/
    async function confirmProp(e, model) {
        e.preventDefault();
        let formData = new FormData();
        let count = 0;
        let thisModel = { ...model }
        if (thisModel.locales.length) {
            supportedLanguages.map((language) => {
                let locale = filterLanguage(thisModel.locales, language.slug);
                if (locale.length) {
                    if (locale[0].name && locale[0].name.length >= 3 && editors[language.slug]) {
                        count++;
                    } else {
                        if (!locale[0].name) {
                            let input = Array.from(document.querySelectorAll(`.requiredName${language.slug}`));
                            input[0].nextSibling.innerText = "This field is required";
                            input[0].nextSibling.classList.remove("d-none");
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX,
                                input[0].getBoundingClientRect().top + window.scrollY - 200);
                        } else if (locale[0].name.length < 3) {
                            let input = Array.from(document.querySelectorAll(`.requiredName${language.slug}`));
                            input[0].nextSibling.innerText = "Name must be at least 5 characters";
                            input[0].nextSibling.classList.remove("d-none");
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX,
                                input[0].getBoundingClientRect().top + window.scrollY - 200);
                        }
                        if (!locale[0].description) {
                            console.log('missing content')
                            let input = Array.from(document.querySelectorAll(`.requiredDescription${language.slug}`));
                            input[0].nextSibling.classList.remove("d-none");
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX,
                                input[0].getBoundingClientRect().top + window.scrollY - 200);
                        }
                    }
                }
                return true
            })
        } else {
            let classes = Array.from(errorDivRef.current.classList);
            if (classes.includes('d-none')) {
                errorDivRef.current.classList.remove('d-none');
                window.scrollTo(errorDivRef.current.getBoundingClientRect().left + window.scrollX,
                    errorDivRef.current.getBoundingClientRect().top + window.scrollY - 150);
            }
        }
        if (images.length === 0) {
            imgRef.current.nextSibling.classList.remove("d-none");
        }

        if (images.length !== 0 && (count === thisModel.locales.length)) {
            setLoading(true);
            for (const key in model) {
                if (key === 'locales') {
                    for (let i = 0; i < thisModel.locales.length; i++) {
                        for (const key2 in thisModel.locales[i]) {
                            if (thisModel.locales[i][key2] !== null && thisModel.locales[i][key2] !== '') {
                                formData.append(`locales[${i}][${key2}]`, thisModel.locales[i][key2]);
                            }
                        }
                    }
                } else if (key === 'images') {
                    for (let i = 0; i < thisModel.images.length; i++) {
                        formData.append(`images[${i}]`, thisModel.images[i]);
                    }
                } else if (key === 'status') {
                    if (thisModel.status === true || thisModel.status === 1) {
                        formData.append("status", 1);
                    } else {
                        formData.append("status", 0)
                    }
                } else if (key === 'featured') {
                    if (thisModel.featured === true || thisModel.featured === 1) {
                        formData.append("featured", 1);
                    } else {
                        formData.append("featured", 0)
                    }
                } else if (key === 'facilities') {
                    for (let i = 0; i < thisModel.facilities.length; i++) {
                        formData.append(`facilities[${i}]`, thisModel.facilities[i]);
                    }
                } else if (key === 'specifications') {
                    for (let i = 0; i < thisModel.specifications.length; i++) {
                        formData.append(`specifications[${i}][key]`, thisModel.specifications[i]['key']);
                        formData.append(`specifications[${i}][value]`, thisModel.specifications[i]['value']);
                    }
                } else if (key === 'youtube') {
                    if (thisModel[key] !== '' && thisModel[key] !== null) {
                        formData.append(key, thisModel[key]);
                    }
                } else {
                    if (thisModel[key] !== '' && thisModel[key] !== null) {
                        formData.append(key, thisModel[key]);
                    } else {
                        formData.append(key, '');
                    }
                    /*formData.append(key, thisModel[key]);*/
                }
            }
            for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if (submitProps === 'done') {
                setLoading(false);
                console.log('done', submitProps);
                navigate(`${api_basename}/dashboard/properties/properties-list`);
            } else {
                setLoading(false);
                setErrors(submitProps);
            }
        }
    }

    function preventEnter(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    /* **************************** Limit number functions ********************** */
    function limitNumber(e) {
        let name = e.target.getAttribute('data-specname')
        if (name === 'area' || name === 'price') {
            if (e.target.value > 100000000) {
                e.target.value = 999999999.99
            }
        } else {
            if (e.target.value > 30) {
                e.target.value = 30
            }
        }
    }

    function preventMinus(e) {
        if (e.keyCode === 189 || e.keyCode === 109) {
            e.preventDefault()
        }
    }

    return (
        <>
            <section className='componentGlobalWrapper'>

                <form onKeyDown={preventEnter} onSubmit={(e) => confirmProp(e, model)}>
                    {/*** name & description  ***/}
                    <div className={`tab-content`} id="pills-tabContent">

                        <div>
                            {supportedLanguages.map((language, index) => (
                                <div className='formWidth'>
                                    <div className='d-flex flex-column mb-4'>
                                        <label htmlFor='name' className='formLabel'>name ({language.name})</label>
                                        <input maxLength='255' className={`requiredName${language.slug}`} name="name" type='text' placeholder='name' value={(model && model.locales && model.locales.length && filterLanguage(model.locales, language.slug).length) ? filterLanguage(model.locales, language.slug)[0].name : ""} onChange={(e) => setFormData(e, 'name', true, language.slug)} />
                                        <div className='validateToDelete text-danger d-none'>
                                            This field is required!
                                        </div>
                                    </div>
                                </div>
                            ))
                            }
                            {/** description **/}
                            {supportedLanguages.map((language, index) => (

                                <div className='formWidth'>
                                    <div className='d-flex flex-column mb-5'>
                                        <label htmlFor='description' className='formLabel'>description ({language.name})</label>
                                        <div className={`requiredDescription${language.slug}`}>
                                            <Editor
                                                editorState={editors[language.slug]}
                                                onEditorStateChange={(state) => handleEditorChange(state, language.slug)}
                                            />
                                        </div>
                                        <div className='validateToDelete text-danger d-none'>
                                            This field is required!
                                        </div>
                                    </div>
                                </div>
                            ))
                            }
                            {/** meta keywords  ***/}
                            {supportedLanguages.map((language, index) => (

                                <div className='d-flex flex-column mb-4 formWidth' >
                                    <label htmlFor='metaKeyword' className='formLabel'>meta keywords ({language.name})</label>
                                    <div ref={metaKeywordsRef} className={`inputGroup LikeInput d-flex align-items-center mw-100 `}>
                                        <div className="">
                                            {

                                                keywords[language.slug] && keywords[language.slug].map((keyword, index) => (
                                                    <div key={index} className={`tagContainer`}>
                                                        {keyword}
                                                        <img src={removeTag} onClick={() => removeKeywordHandler(index, language.slug)} className='removeTag' alt='remove' />
                                                    </div>
                                                ))
                                            }
                                            <input onKeyDown={(e) => AddKeywordHandler(e, language.slug)} maxLength='30' type="text" name="metaKeyword" className={`tagInput border-0`} id="tags" />
                                        </div>
                                    </div>
                                </div>
                            ))
                            }
                            {/** meta description ***/}
                            {supportedLanguages.map((language, index) => (

                                <div className='d-flex flex-column mb-4 formWidth'>
                                    <label htmlFor='meta-desription' className='formLabel'>meta description ({language.name})</label>
                                    <textarea maxLength='255' name="meta_description" value={(model && model.locales && model.locales.length && filterLanguage(model.locales, language.slug).length) ? filterLanguage(model.locales, language.slug)[0].meta_description : ""} onChange={(e) => setFormData(e, 'meta_description', true, language.slug)} />
                                </div>
                            ))
                            }
                        </div>

                    </div>

                    {/*** type & category  ***/}
                    <div className='propertySettings'>
                        <div className='d-flex flex-column mb-4 w-100'>
                            <label htmlFor='type' className='formLabel'>property type <span className='text-danger'>*</span></label>
                            <div className='selectParent'>
                                <select defaultValue={model.type ? model.type : '0'} value={model.type ? model.type : '0'} name='type' placeholder='type' onChange={(e) => setFormData(e, 'type')}>
                                    <option value={'0'} hidden disabled>choose type</option>
                                    {allTypes && allTypes.map((type, index) => (
                                        <option value={type.id} key={index}>{filterLanguage(type.locales, 'en') ? filterLanguage(type.locales, 'en')[0].name
                                            : filterLanguage(type.locales, 'ar')[0].name}</option>
                                    ))}
                                </select>
                            </div>
                            {errors.type && errors.type.length ? <div className='validateToDelete text-danger '>
                                This field is required!
                            </div> : ''}
                        </div>

                        <div className='d-flex flex-column mb-4 w-100'>
                            <label htmlFor='category' className='formLabel'>property category <span className='text-danger'>*</span></label>
                            <div className='selectParent'>
                                <select value={model.category ? model.category : 0} name='category' placeholder='category' onChange={(e) => setFormData(e, 'category')}>
                                    <option value={0} hidden disabled>choose category</option>
                                    {allCategories && allCategories.map((category, index) => (
                                        <option value={category.id} key={index}>{category.locales[0].name}</option>
                                    ))}
                                </select>
                            </div>
                            {errors.category && errors.category.length ? <div className='validateToDelete text-danger '>
                                This field is required!
                            </div> : ''}
                        </div>

                        <div className='d-flex flex-column mb-4 w-100'>
                            <label htmlFor='developer' className='formLabel'>property developer </label>
                            <div className='selectParent'>
                                <select value={model.developer ? model.developer : 0} name='developer' placeholder='developer' onChange={(e) => setFormData(e, 'developer')}>
                                    <option value={0} hidden disabled>choose developer</option>
                                    {alldevelopers && alldevelopers.map((developer, index) => (
                                        <option value={developer.id} key={index}>{developer.name}</option>
                                    ))}
                                </select>
                            </div>
                            {errors.developer && errors.developer.length ? <div className='validateToDelete text-danger '>
                                {errors?.developer}
                            </div> : ''}
                        </div>
                        <div className="d-flex flex-column mb-5 w-100 ">
                            <label htmlFor="whatsapp" className={`form-label`}>whatsapp phone <span className='text-danger'>*</span></label>
                            <PhoneInput countryCodeEditable={false} country={'ae'} onlyCountries={['ae']} {...(model && model.whatsapp ? { 'value': model && model.whatsapp } : {})} className='w-100 phoneInput' id="phone" name='phone' onChange={(e) => model.whatsapp = e} />
                            {errors.whatsapp && errors.whatsapp.length ? <div className='validateToDelete text-danger'>
                                This field is required!
                            </div> : ''}
                        </div>
                    </div>

                    <div className='formWidth'>
                        {/***** location city & area *****/}
                        <h5 className='pageSubtitle mb-4'>location and map</h5>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <div className='d-flex flex-column' style={{ "width": "48%" }}>
                                <label htmlFor='city' className='formLabel'>city</label>
                                <div className='selectParent'>
                                    <select value={model.city ? model.city : 0} name='city' placeholder='city' onChange={fetchAreas}>
                                        <option value={0} hidden disabled>choose city</option>
                                        {allCities && allCities.map((city, index) => (
                                            <option value={city.id} key={index}>{filterLanguage(city.locales, 'en') ? filterLanguage(city.locales, 'en')[0].name
                                                : filterLanguage(city.locales, 'ar')[0].name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='d-flex flex-column' style={{ "width": "48%" }}>
                                <label htmlFor='area' className='formLabel'>area <span className='text-danger'>*</span> </label>
                                <div className='selectParent'>
                                    <select value={model.area ? model.area : 0} name='area' placeholder='area' onChange={(e) => setFormData(e, 'area')}>
                                        <option value={0} hidden disabled>choose area</option>
                                        {allAreas && allAreas.map((area, index) => (
                                            <option value={area.id} key={index}>
                                                {filterLanguage(area.locales, 'en') ? filterLanguage(area.locales, 'en')[0].name
                                                    : filterLanguage(area.locales, 'ar')[0].name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {errors.area && errors.area.length ? <div className='validateToDelete text-danger '>
                                    This field is required!
                                </div> : ''}
                            </div>
                        </div>

                        {/* location in map  */}
                        <div className="mb-5">
                            <label className="form-label labels mt-3 mb-4">Set locations on map </label>
                            <LoadScript id="script-loader" googleMapsApiKey={map_key} libraries={libraries}>
                                <GoogleMap mapContainerStyle={containerStyle} center={coords} zoom={18} draggable={true}>
                                    <Marker position={coords} draggable={true} onDragEnd={onMarkDragged} />
                                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                                        <input type="text" placeholder="Customized your placeholder" className='inputMapStyle' />
                                    </Autocomplete>
                                </GoogleMap>
                            </LoadScript>
                        </div>

                        {/***** specifications *****/}
                        <h5 className='pageSubtitle mb-4'>property details</h5>
                        <div className='row row-cols-2 mb-4'>
                            <div className='col d-flex flex-column mb-4'>
                                <label htmlFor={'price'} className='formLabel'><img width='24px' height='24px' src={price} className="me-2" alt="Icon" />Price</label>
                                <input id={'price'} name={'price'} min={1} type='number' data-specname={'price'} onInput={limitNumber} onKeyDown={preventMinus} value={model && model.price} onChange={(e) => setFormData(e, 'price')} />
                                {errors.price && errors.price.length ? <div className='validateToDelete text-danger'>
                                    {errors.price[0]}
                                </div> : ''}
                            </div>
                            {allSpecifications && allSpecifications.map((s, index) => {
                                return (
                                    <div key={index} className='col d-flex flex-column mb-4'>
                                        <label htmlFor={s.slug} className='formLabel'><img height='24px' src={`${api_host}/${s.image}`} className="me-2" alt="Icon" />{s.locales && s.locales.length ? s.locales[0].name : `specification
                                `}</label>
                                        <input id={s.slug} name={s.id} min={0} type='number' data-specname={s.slug} onInput={limitNumber} onKeyDown={preventMinus} value={(model && model.specifications.length && filterSpcifications(model.specifications, s.id).length) ? filterSpcifications(model.specifications, s.id)[0].value : ''} onChange={(e) => setFormData(e, 'specifications', false, null, true)} />
                                    </div>
                                )
                            })}
                        </div>

                        {/***** facilities *****/}
                        <h5 className='pageSubtitle mb-5'>property features</h5>
                        <div className='w-100 permissionsWrapper mt-0'>
                            <div className='row row-cols-4 m-0 p-0'>
                                {allFacilities && allFacilities.map((f, index) => {
                                    return (
                                        <div key={index} className='col mb-3'>
                                            <div className='checkLabelWrapper'>
                                                <input {...(model && model.facilities && model.facilities.length &&
                                                    model.facilities.includes(f.id) ? { 'checked': true } : {})}
                                                    className='permissionsCheckbox' type="checkbox" name='facility' id={f.slug} value={f.id} onChange={(e) => setFormData(e, 'facility')}
                                                />
                                                <label htmlFor={f.slug}>{f.locales && f.locales.length ? f.locales[0].name : `feature`}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        {/*** vedio link ***/}
                        <h5 className='pageSubtitle mt-5 mb-4'>photos &#38; vedios</h5>
                        <div className='d-flex flex-column mb-5' style={{ "width": "48%" }}>
                            <label htmlFor='vedio' className='formLabel'>vedio </label>
                            <input name="youtube" id='vedio' type='text' placeholder='link' value={model && model.youtube ? model.youtube : ''} onChange={(e) => setFormData(e, 'youtube')} />
                            {errors.youtube && errors.youtube.length ? <div className='validateToDelete text-danger'>
                                {errors.youtube[0]}
                            </div> : ''}
                        </div>

                        {/*** images ***/}
                        <div className="row m-0 mb-5 d-flex align-items-start ">
                            <label className='formLabel ps-0'>images <span className='text-danger'>*</span> </label>
                            {images &&
                                images.map((img, i) => {
                                    return (
                                        <div key={i} className='imageWrapper p-0 me-3 mt-2 position-relative' style={{ "width": "180px", "height": "140px" }}>
                                            <Tooltip title='Remove Image'>
                                                <label htmlFor='viewImage' className={`position-absolute d-flex align-items-center justify-content-center deletePropImg`}>
                                                    <img src={removeIcon} className='propDeleteIcon' alt="delete" onClick={() => deleteImageHandler(img.id)} />
                                                    {/* <input type='file' id='viewImage' name='image' className="d-none"/> */}
                                                </label>
                                            </Tooltip>
                                            <div className='propertySingleImage' style={{ backgroundImage: `url(${api_host}/${img.image})` }}></div>
                                        </div>
                                    )
                                })
                            }
                            <div style={{ "width": "180px" }}>
                                <div ref={imgRef} className='imageWrapper p-0 mt-2'>
                                    <label htmlFor='propertyImg' className='h-100 d-flex flex-column justify-content-center align-items-center'>
                                        <img width='90px' height='80px' src={uploadImage} alt='upload' />
                                        <input onChange={addImage} multiple name="images" type="file" className={`d-none`} id="propertyImg" />
                                        <span>upload image </span>
                                    </label>
                                </div>
                                <div className='text-danger mt-1 d-none'>
                                    upload at least 1 image required!
                                </div>
                                {progress !== 0 && (
                                    <div className="progress mt-2 p-0" >
                                        <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                            aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                            {progress}%
                                        </div>
                                    </div>)}
                            </div>

                            {
                                errors && errors['images.0'] ? <div className='text-danger validateToDelete'>{errors['images.0'][0]}</div> : ''
                            }
                            {errors.images && errors.images.length ? <div className='validateToDelete text-danger'>
                                {errors.images[0]}
                            </div> : ''}
                        </div>
                        <div className='d-flex mb-4'>
                            <div className='me-5 d-flex align-items-center'>
                                <label className="switch mb-0">
                                    <input type="checkbox" name='status'
                                        id='statusCheck' onChange={(e) => setFormData(e, 'status')} />
                                    <span className="slider round" />
                                </label>
                                <label htmlFor='active_status' className='formLabel mb-0 ms-3'>publish property</label>
                            </div>

                            <div className='d-flex align-items-center'>
                                <label className="switch mb-0">
                                    <input id='featuredCheck' type="checkbox" name='featured' onChange={(e) => setFormData(e, 'featured')} />
                                    <span className="slider round" />
                                </label>
                                <label htmlFor='active_feature' className='formLabel mb-0 ms-3'>feature property</label>
                            </div>
                        </div>

                        <div ref={errorDivRef} className="row mb-4 d-none">
                            <div className='alert alert-danger validateToDelete'>
                                you should enter the property data in one language at least!
                            </div>
                        </div>

                        {/* loader and submit btn */}
                        <div>
                            {
                                !loading ?
                                    <button className='confirmBtn propSubmit' type='submit'>{id ? 'save changes' : 'add'}</button>
                                    :
                                    <button className='confirmBtn' disabled>
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        saving...
                                    </button>
                            }
                        </div>
                    </div>
                </form>
            </section>
        </>
    )
}
