import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get 
export async function getContactUsPage(){
    return await AxiosClone.get(`${api_url}/admin/settings/apperance/contact-us-page`)
}

//update 
export async function updateContactUsPage(id,data){
    return await AxiosClone.post(`${api_url}/admin/headers/${id}`,data)
}