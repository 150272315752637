import React, { useContext, useEffect, useState } from 'react';
import TitleContext from '../../../TitleContext';
import "./Aboutus.scss";
import Form from './../../../Components/PageHeaderForm/PageHeaderForm';
import EditorForm from './../../../Components/PageContentForm/PageContentForm';
import WhatWeProvideForm from './../../../Components/WhatWeProvideSectionForm/SectionForm';
import { getPageHeaderAndContent, updatePageHeaders } from '../../../Apis/pageHeadersApi';
import { updatePageContent } from '../../../Apis/pageContentApi';

export default function Aboutus() {
    let {changeTitle} = useContext(TitleContext);
    const [pageHeadersId ,setPageHeadersId] = useState({});
    const [whatWeProvideId ,setWhatWeProvideId] = useState({});
    const [ourStrategyId ,setOurStrategyId] = useState({});
    const [ourMissiontId ,setOurMissiontId] = useState({});

    // get about us page headers and content ids
    useEffect(()=>{
        changeTitle(`about us`);
        getPageHeaderAndContent('about-us-page').then(res => {
            let mypageHeadersId = res.data.data.headers['about-us-page'].id;
            let mywhatWeProvideId = res.data.data.settings['what-we-provide'].id;
            let myourStrategyId = res.data.data.settings['our-strategy'].id;
            let myourMissiontId = res.data.data.settings['our-mission'].id;
            setPageHeadersId(mypageHeadersId);
            setWhatWeProvideId(mywhatWeProvideId);
            setOurStrategyId(myourStrategyId);
            setOurMissiontId(myourMissiontId);
        }).catch(err => 
            console.log(err.response)
        )
        // eslint-disable-next-line
    } , []);

    // onsubmit page headers
    const onSubmit = async (e, data) => {
        e.preventDefault();
        data.append("_method", "patch");
        //to send request
        return await updatePageHeaders(pageHeadersId , data).then(res => {
            console.log(res.data.data);
            return "done";          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };

    // onsubmit what we provide section
    const submitWhatWeProvide = async (e, data) =>{
        e.preventDefault();
        data.append("_method", "patch");
        // to send request
        return await updatePageContent(whatWeProvideId, data).then(res=>{
            console.log(res.data.data);
            return "done"; 
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    }

    // onsubmit our strategy
    const submitOurStrategy = async (e, data) =>{
        e.preventDefault();
        return await updatePageContent(ourStrategyId,{...data , "_method" : "patch"}).then(res=>{
            console.log(res.data.data);
            return "done"; 
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    }

    // onsubmit our strategy
    const submitOurMission = async (e, data) =>{
        e.preventDefault();
        return await updatePageContent(ourMissiontId,{...data , "_method" : "patch"}).then(res=>{
            console.log(res.data.data);
            return "done"; 
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    }
    
    return (
        <>
        <section className='componentGlobalWrapper aboutus'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>page title</h5>
            </div>
            <Form onSubmit={onSubmit} name={'about-us-page'}/>
        </section>

        <section className='componentGlobalWrapper aboutus'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>page content</h5>
            </div>

            <WhatWeProvideForm onSubmit={submitWhatWeProvide} name={'about-us-page'} nestedObjKey={'what-we-provide'}/>
        </section>

        {/*** our strategy ***/}
        <section className='componentGlobalWrapper aboutus'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>our strategy</h5>
            </div>
            <EditorForm onSubmit={submitOurStrategy} name={'about-us-page'} objKey={'about-us'} nestedObjKey={'our-strategy'}/>
        </section>

        {/*** our mission ***/}
        <section className='componentGlobalWrapper aboutus'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>our mission and values</h5>
            </div>
            <EditorForm onSubmit={submitOurMission} name={'about-us-page'} objKey={'about-us'} nestedObjKey={'our-mission'}/>
        </section>
        </>
    )
}
