import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Subside from '../../Components/SubSide/Subside';
import { Route , Routes } from "react-router-dom";
import TitleContext from '../../TitleContext';
import { getTypes } from '../../Apis/typesApi';
import Add from './TypeForm/Add';
import Edit from './TypeForm/Edit';
import permissionsContext from '../../permissionsContext';

export default function Types() {
    let {changeTitle} = useContext(TitleContext);
    let {Permissions} = useContext(permissionsContext);
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    let [allTypes , setAllTypes] = useState([]);

    // get all types
    function getAllTypes(){
        let myallTypes = [...allTypes];
        getTypes().then(res => {
            myallTypes = res.data.data;
            setAllTypes(myallTypes);
            console.log(res.data.data);
        }).catch(err => 
            console.log(err))
    }

    useEffect(() => {
        changeTitle(`property types`);
        getAllTypes();
        // eslint-disable-next-line
    }, [])

    
    return (
        <>
        {Permissions && Permissions.type &&  <section className='subside_action_wrapper'>
            { Permissions.type.view && <Subside items={allTypes} btnText="+ add type" btnUrl="property-settings/types"/>}
            <Routes>
                {Permissions.type.create && <Route path='add' element={<Add getAll={getAllTypes}/>}/> }
                {Permissions.type.edit && <Route path='edit/:id' element={<Edit getAll={getAllTypes}/>} /> }
            </Routes>
        </section>}
        </>
    )
}


