import React, { useRef ,useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { deleteValidation, resetInput } from './deleteHandler';
import { api_basename } from './../Configuration/Config';
import {  useNavigate } from 'react-router';

export default function DeleteModal(props) {
    const [loading, setLoading] = useState(false);
    const [validateDelete , setValidateDelete] = useState("");
    const deleteModalRef = useRef();
    const closeDeleteModalRef = useRef();
    let [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const confirmDelete = async (e) =>{
        e.preventDefault();
        if (validateDelete === "DELETE"){
            setLoading(true);
            let submitProps = await props.onSubmitDelete();
            console.log(submitProps)
            if(submitProps === "done"){
                setLoading(false);
                closeDeleteModalRef.current.click();
                document.getElementById('deleteForm').reset();

            }else if(submitProps === 'deleteRole'){
                setLoading(false);
                closeDeleteModalRef.current.click();
                navigate(`${api_basename}/dashboard/users/roles/add`);
            }else{
                setLoading(false);
                setErrors(submitProps);
            }
        }else{
            deleteModalRef.current.classList.remove("d-none");
        }
    }

    return (
        <div>
            <div className="modal fade" id="deleteModal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div ref={closeDeleteModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={()=> resetInput("deleteForm" ,deleteModalRef ,setValidateDelete , setErrors)}>
                                <FontAwesomeIcon icon={faXmark}/>
                            </div>
                        </div>
                        <div className="modal-body">
                            <form id="deleteForm" onSubmit={confirmDelete} style={{'width':'100%'}}>
                                <div className='formInputsWrapper' style={{'width':'100%'}}>
                                    <p>Do you want to delete this {props.name} ?</p>
                                    <div className='mb-4'>
                                        <div className="form-floating">
                                            <input onChange={(e)=> deleteValidation(e , setValidateDelete  ,deleteModalRef)} className='w-100 form-control' name="validate" id='floatingInput2' type='text' placeholder='delete' required pattern="\S(.*\S)?" title="This field is required"/>
                                            <label htmlFor="floatingInput2">please type <span>"DELETE"</span> to confirm</label>
                                        </div>
                                        <div ref={deleteModalRef} className='validateToDelete d-none text-danger mt-1'>
                                            please type "DELETE" in capital letters.
                                        </div>
                                        {(errors && errors.length)? <div className="validateToDelete text-danger mt-1">{errors[0]}</div> : ''}
                                    </div>
                                    {/*** modal footer ***/}
                                    <div className='d-flex justify-content-end'>
                                        <div className='cancelBtn' onClick={()=> resetInput("deleteForm" ,deleteModalRef ,setValidateDelete , setErrors)} data-bs-dismiss="modal">cancel</div>
                                        {!loading ? 
                                            <button className={validateDelete === "DELETE" ? `deleteBtn` : `confirmBtn`} type='submit'>delete</button>
                                            :
                                            <button className='confirmBtn deleteBtn' disabled>
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                deleting...
                                            </button>
                                        }                                            
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
