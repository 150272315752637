import React, { useContext, useEffect } from 'react'
import TitleContext from '../../../TitleContext';
import Form from './Form';
import { updateBlog } from '../../../Apis/news';
import { useParams } from 'react-router';

export default function EditPost() {
    const { id } = useParams();
    let {changeTitle} = useContext(TitleContext);
    useEffect(()=>{
        changeTitle(`update post`);
        // eslint-disable-next-line
    } , []);

    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        data.append("_method", "patch");
        return await updateBlog(id,data).then(res => {
            return 'done';          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };

    return (
        <div>
            <Form onSubmit={onSubmit}/>
        </div>
    )
}
