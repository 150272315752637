import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get faqs
export async function getFAQs(){
    return await AxiosClone.get(`${api_url}/admin/faqs`);
}

//get one faq
export async function getFaq(id){
    return await AxiosClone.get(`${api_url}/admin/faqs/${id}`);
}

//delete faq
export async function deleteFaq(id){
    return await AxiosClone.delete(`${api_url}/admin/faqs/${id}`);
}

//create faq
export async function createNewFaq(data){
    return await AxiosClone.post(`${api_url}/admin/faqs`, data);
}

//edit faq
export async function editFaq(id ,data){
    return await AxiosClone.post(`${api_url}/admin/faqs/${id}`, data);
}