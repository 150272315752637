import React, { useContext, useEffect } from 'react';
import Aside from '../../Components/Aside/Aside';
import Header from '../../Components/Header/Header';
import "./Dashboard.scss";
import AllRoutes from '../../Routes/AllRoutes';
import PermissionsContext from '../../permissionsContext';
import { getAdminProfileInfo } from '../../Apis/profileApi'

export default function Dashboard() {
    let { changePermissions } = useContext(PermissionsContext);


    useEffect(() => {
        getAdminProfileInfo().then((res) => {
            let permission = res.data.data.admin.permissions;
            changePermissions(permission);
        })
        // eslint-disable-next-line
    }, [])

    return (
        <div className='d-flex mx-0 px-0'>
            <div className={`asidePart offcanvas d-lg-none offcanvas-start`} data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling">
                <Aside />
            </div>
            <div className='asidePart d-none d-lg-block'>
                <Aside />
            </div>
            <div className='remainPart'>
                <div><Header /></div>
                <div className='renderdComponents'>
                    <AllRoutes />
                </div>
            </div>
        </div>
    )
}
