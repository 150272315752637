import React, { useContext, useEffect } from 'react';
import TitleContext from '../../../TitleContext';
import "../Aboutus/Aboutus.scss";
import "./HomeSections.scss";
import { Link, NavLink } from 'react-router-dom';
import { useLocation} from 'react-router';
import edit_icon from "../../../assets/icons/edit.svg";
import delete_icon from "../../../assets/icons/delete.svg";
import { useState } from 'react';
import { deleteSlider , toggleActiveSlider, getHome, editHeaders} from '../../../Apis/homeApi.js'
import { api_host, api_basename } from './../../../Configuration/Config';
import DeleteModal from './../../../utilities/deleteModal';
import Tooltip from '@material-ui/core/Tooltip';
import { filterLanguage } from '../../../utilities/filterLang';

export default function HomeSections() {
    let {changeTitle} = useContext(TitleContext);
    const { pathname } = useLocation();
    let [allSliders , setAllSliders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [popularAreasloading, setPopularAreasloading] = useState(false);
    const [featuredPropertiesloading, setFeaturedPropertiesloading] = useState(false);
    const [ourPartnersloading, setOurPartnersloading] = useState(false);
    const [latestNewsloading, setLatestNewsloading] = useState(false);
    
    const [sliderId ,setSliderId] = useState('');
    const [latestProjects ,setLatestProjects] = useState({});
    const [latestNews ,setLatestNews] = useState({});
    const [popularAreas ,setPopularAreas] = useState({});
    const [featuredProperties ,setFeaturedProperties] = useState({});
    const [ourPartners ,setOurPartners] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get slider id
    function getId(id){
        setSliderId(id)
    }

    // get home content
    function getHomeContent(){
        let myallSliders = [...allSliders];
        let mylatestProjects = {...latestProjects};
        let mylatestNews = {...latestNews};
        let mypopularAreas = {...popularAreas};
        let myfeaturedProperties = {...featuredProperties};
        let myourPartners = {...ourPartners};

        getHome().then(res => {
            myallSliders = res.data.data.sliders;
            mylatestProjects = res.data.data.headers['latest-projects'];
            mylatestNews = res.data.data.headers['latest-news'];
            mypopularAreas = res.data.data.headers['popular-areas'];
            myfeaturedProperties = res.data.data.headers['featured-properties'];
            myourPartners = res.data.data.headers['our-partners'];
            setAllSliders(myallSliders);
            setLatestProjects(mylatestProjects);
            setLatestNews(mylatestNews);
            setPopularAreas(mypopularAreas);
            setFeaturedProperties(myfeaturedProperties);
            setOurPartners(myourPartners);
            // slider status
            myallSliders.map((slider) => {
                if(slider.status){
                    document.getElementById(slider.id).checked = true;
                }
                return true
            });
            // sections statusCheck
            if(mylatestProjects.status){
                document.getElementById('projectsStatus').checked = true;
            }
            if(mypopularAreas.status){
                document.getElementById('areasStatus').checked = true;
            }
            if(myfeaturedProperties.status){
                document.getElementById('propertiesStatus').checked = true;
            }
            if(myourPartners.status){
                document.getElementById('partnersStatus').checked = true;
            }
            if(mylatestNews.status){
                document.getElementById('newsStatus').checked = true;
            }
        }).catch(err => 
            console.log(err.response))
    }

    useEffect(()=>{
        changeTitle(`home`);
        getHomeContent();
        // eslint-disable-next-line
    } , []);

    function toggleStatus(id,status){
        let obj;
        let myallSliders = [...allSliders];
        if(status){
            obj = {status: 0};
            toggleActiveSlider(id,{...obj , "_method" : "patch"}).then((res)=>{
                myallSliders.map((slider)=>{
                    if(slider.id === id){
                        slider.status = false;
                    }
                    return true;
                }) 
            })
        }else{
            obj = {status: 1};
            toggleActiveSlider(id,{...obj , "_method" : "patch"}).then((res)=>{
                myallSliders.map((slider)=>{
                    if(slider.id === id){
                        slider.status = true
                    }
                    return true
                }) 
            })
        }
    }

    // delete slider
    const onSubmitDelete = async ()=>{
        return await deleteSlider(sliderId).then(res =>{
            getHomeContent();
            return "done";;
        }).catch(err => {
            return err.response.data.errors;
        })
    }

    /******************************* latest projects ******************************/
    // onchange latestProjects section
    function setLatestProjectsData(e){
        let mylatestProjects = {...latestProjects};
        if(e.target.name === 'name' || e.target.name === 'description'){
            let language = e.target.getAttribute('data-lang');
            let exists = mylatestProjects.locales.filter((i) => {
                return i.locale === language;
            })
            if(exists.length){
                exists[0][e.target.name] = e.target.value;
            }else{
                mylatestProjects.locales.push({'locale': language});
                mylatestProjects.locales.map((i) => {
                    if(i.locale === language){
                        i[e.target.name] = e.target.value;
                    }
                    return true;
                });
            }
        }else if(e.target.name === 'status'){
            mylatestProjects[e.target.name] = e.target.checked ? 1 : 0;
        }
        setLatestProjects(mylatestProjects);
        console.log(mylatestProjects)
    }

    // onsubmit latestProjects section
    function latestProjectsSubmit(e){
        e.preventDefault();
        setLoading(true);

        // to send request
        delete latestProjects.image;
        editHeaders(latestProjects.id , {...latestProjects , "_method" : "patch"}).then(res =>{
            setLoading(false);
            console.log(res.data.data);            
        }).catch(err => {
            setLoading(false);
            console.log(err.response)
        })
    }

    /******************************* popular areas *****************************/
    // onchange popular areas section
    function setPopularAreasData(e){
        let mypopularAreas = {...popularAreas};
        if(e.target.name === 'name' || e.target.name === 'description'){
            let language = e.target.getAttribute('data-lang');
            let exists = mypopularAreas.locales.filter((i) => {
                return i.locale === language;
            })
            if(exists.length){
                exists[0][e.target.name] = e.target.value;
            }else{
                mypopularAreas.locales.push({'locale': language});
                mypopularAreas.locales.map((i) => {
                    if(i.locale === language){
                        i[e.target.name] = e.target.value;
                    }
                    return true;
                });
            }
        }else if(e.target.name === 'status'){
            mypopularAreas[e.target.name] = e.target.checked ? 1 : 0;
        }
        setPopularAreas(mypopularAreas);
        console.log(mypopularAreas)
    }

    // onsubmit popular areas section
    function popularAreasSubmit(e){
        e.preventDefault();
        setPopularAreasloading(true);

        // to send request
        delete popularAreas.image;
        editHeaders(popularAreas.id , {...popularAreas , "_method" : "patch"}).then(res =>{
            setPopularAreasloading(false);
            console.log(res.data.data);            
        }).catch(err => {
            setPopularAreasloading(false);
            console.log(err.response)
        })
    }

    /******************************* featured Properties *****************************/
    // onchange featured Properties section
    function setFeaturedPropertiesData(e){
        let myfeaturedProperties = {...featuredProperties};
        if(e.target.name === 'name' || e.target.name === 'description'){
            let language = e.target.getAttribute('data-lang');
            let exists = myfeaturedProperties.locales.filter((i) => {
                return i.locale === language;
            })
            if(exists.length){
                exists[0][e.target.name] = e.target.value;
            }else{
                myfeaturedProperties.locales.push({'locale': language});
                myfeaturedProperties.locales.map((i) => {
                    if(i.locale === language){
                        i[e.target.name] = e.target.value;
                    }
                    return true;
                });
            }
        }else if(e.target.name === 'status'){
            myfeaturedProperties[e.target.name] = e.target.checked ? 1 : 0;
        }
        setFeaturedProperties(myfeaturedProperties);
        console.log(myfeaturedProperties)
    }

    // onsubmit featured Properties section
    function featuredPropertiesSubmit(e){
        e.preventDefault();
        setFeaturedPropertiesloading(true);

        // to send request
        delete featuredProperties.image;
        editHeaders(featuredProperties.id , {...featuredProperties , "_method" : "patch"}).then(res =>{
            setFeaturedPropertiesloading(false);
            console.log(res.data.data);            
        }).catch(err => {
            setFeaturedPropertiesloading(false);
            console.log(err.response)
        })
    }

    /******************************* our partners *****************************/
    // onchange our partners section
    function setOurPartnersData(e){
        let myourPartners = {...ourPartners};
        if(e.target.name === 'name' || e.target.name === 'description'){
            let language = e.target.getAttribute('data-lang');
            let exists = myourPartners.locales.filter((i) => {
                return i.locale === language;
            })
            if(exists.length){
                exists[0][e.target.name] = e.target.value;
            }else{
                myourPartners.locales.push({'locale': language});
                myourPartners.locales.map((i) => {
                    if(i.locale === language){
                        i[e.target.name] = e.target.value;
                    }
                    return true;
                });
            }
        }else if(e.target.name === 'status'){
            myourPartners[e.target.name] = e.target.checked ? 1 : 0;
        }
        setOurPartners(myourPartners);
        console.log(myourPartners)
    }

    // onsubmit our partners section
    function ourPartnersSubmit(e){
        e.preventDefault();
        setOurPartnersloading(true);

        // to send request
        delete ourPartners.image;
        editHeaders(ourPartners.id , {...ourPartners , "_method" : "patch"}).then(res =>{
            setOurPartnersloading(false);
            console.log(res.data.data);            
        }).catch(err => {
            setOurPartnersloading(false);
            console.log(err.response)
        })
    }

    /******************************* latest news *****************************/
    // onchange our partners section
    function setLatestNewsData(e){
        let mylatestNews = {...latestNews};
        if(e.target.name === 'name' || e.target.name === 'description'){
            let language = e.target.getAttribute('data-lang');
            let exists = mylatestNews.locales.filter((i) => {
                return i.locale === language;
            })
            if(exists.length){
                exists[0][e.target.name] = e.target.value;
            }else{
                mylatestNews.locales.push({'locale': language});
                mylatestNews.locales.map((i) => {
                    if(i.locale === language){
                        i[e.target.name] = e.target.value;
                    }
                    return true;
                });
            }
        }else if(e.target.name === 'status'){
            mylatestNews[e.target.name] = e.target.checked ? 1 : 0;
        }
        setLatestNews(mylatestNews);
    }

    // onsubmit our partners section
    function latestNewsSubmit(e){
        e.preventDefault();
        setLatestNewsloading(true);

        // to send request
        delete latestNews.image;
        editHeaders(latestNews.id , {...latestNews , "_method" : "patch"}).then(res =>{
            setLatestNewsloading(false);
            console.log(res.data.data);            
        }).catch(err => {
            setLatestNewsloading(false);
            console.log(err.response)
        })
    }

    return (
        <>
        <section>
            <div className='componentGlobalWrapper border-bottom-0 mb-0 aboutus'>
                <div className='mb-3'>
                    <h5 className='pageSubtitle mb-0' style={{"lineHeight" : "1.25rem"}}>home settings</h5>
                </div>
            </div>
            {/***** slider section ******/}
            <div className='homeSectionWrapper' style={{"borderTop" : "0"}}>
                <div className='sectionTitle mb-2'>
                    <h5 className='mb-0'>slider</h5>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/home/add-slider`} className='confirmBtn' type='submit'>add new slider</NavLink>
                </div>
                <div className='tableWrapper'>
                    <table className="w-100 table table-striped">
                        <thead>
                            <tr>
                                <th>image</th>
                                <th>title</th>
                                <th>description</th>
                                <th>actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allSliders && allSliders.map((slider , index)=> (
                                <tr key={index}>
                                    <td className='sliderImg' /* style={{"width" : "20%"}} */>
                                        <img src={`${api_host}/${slider.image?.image}`} alt="slider" /* style={{"width" : "59%"}} */ />
                                    </td>
                                    <td>{(slider && slider.locales && slider.locales.length && slider.locales[0].name)? slider.locales[0].name : "no title"}</td>
                                    <td style={{"width" : "35%"}}>{(slider && slider.locales && slider.locales.length && slider.locales[0].description)? `${slider.locales[0].description.substring(0, 25)} ...` : 'no description'}</td>
                                    <td>
                                        <div>
                                            <Tooltip title={slider.status ? 'deactivate' : 'activate'}>
                                                <label className="switch mb-0">
                                                    <input type="checkbox" id={slider.id} onChange={()=> toggleStatus(slider.id ,slider.status)}/>
                                                    <span className="slider round" />
                                                </label>
                                            </Tooltip>
                                            <Tooltip title="edit">
                                                <Link to={`${api_basename}/dashboard/appearance/pages/home/edit-slider/${slider.id}`}>
                                                    <img src={edit_icon} alt="edit"/>
                                                </Link>
                                            </Tooltip>
                                            <Tooltip title="delete">
                                                <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={()=>getId(slider.id)}/>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/**** latest projects  *****/}
            <div className='homeSectionWrapper'>
                <form onSubmit={latestProjectsSubmit}>
                    <div className='sectionTitle mb-2'>
                        <div className='d-flex align-items-center'>
                            <h5 className='formLabel mb-0 me-3'>latest projects</h5>                      
                            <label className="switch mb-0">
                                <input type="checkbox" id='projectsStatus' name='status' onChange={setLatestProjectsData}/>
                                <span className="slider round"/>
                            </label>
                        </div>
                        {!loading ? 
                            <button className='confirmBtn updateBtn' type='submit'>update</button>
                            :
                            <button className='confirmBtn updateBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                saving...
                            </button>
                        }
                    </div>

                    <div className='formContainer'>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='name_en' className='formLabel'>name (english)</label>
                            <input name="name" id='name_en' type='text' data-lang='en' value={(latestProjects && latestProjects.locales && latestProjects.locales.length && filterLanguage(latestProjects.locales ,"en").length) ? filterLanguage(latestProjects.locales ,"en")[0].name : ""} onChange={setLatestProjectsData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='name_ar' className='formLabel'>name (arabic)</label>
                            <input name="name" id='name_ar' type='text' data-lang='ar' value={(latestProjects && latestProjects.locales && latestProjects.locales.length && filterLanguage(latestProjects.locales ,"ar").length) ? filterLanguage(latestProjects.locales ,"ar")[0].name : ""} onChange={setLatestProjectsData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='description_en' className='formLabel'>description (english)</label>
                            <textarea name="description" id='description_en' data-lang='en' value={(latestProjects && latestProjects.locales && latestProjects.locales.length && filterLanguage(latestProjects.locales ,"en").length) ? filterLanguage(latestProjects.locales ,"en")[0].description : ""} onChange={setLatestProjectsData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='description_ar' className='formLabel'>description (arabic)</label>
                            <textarea name="description" id='description_ar' data-lang='ar' value={(latestProjects && latestProjects.locales && latestProjects.locales.length && filterLanguage(latestProjects.locales ,"ar").length) ? filterLanguage(latestProjects.locales ,"ar")[0].description : ""} onChange={setLatestProjectsData}/>
                        </div>
                    </div>
                </form>
            </div>
            {/**** popular Area’s  *****/}
            <div className='homeSectionWrapper'>
                <form onSubmit={popularAreasSubmit}>
                    <div className='sectionTitle mb-2'>
                        <div className='d-flex align-items-center'>
                            <h5 className='formLabel mb-0 me-3'>popular Area’s</h5>                      
                            <label className="switch mb-0">
                                <input type="checkbox" id='areasStatus' name='status' onChange={setPopularAreasData}/>
                                <span className="slider round"/>
                            </label>
                        </div>
                        {!popularAreasloading ? 
                            <button className='confirmBtn updateBtn' type='submit'>update</button>
                            :
                            <button className='confirmBtn updateBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                saving...
                            </button>
                        }
                    </div>

                    <div className='formContainer'>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='name_en' className='formLabel'>name (english)</label>
                            <input name="name" id='name_en' type='text' data-lang='en' value={(popularAreas && popularAreas.locales && popularAreas.locales.length && filterLanguage(popularAreas.locales ,"en").length) ? filterLanguage(popularAreas.locales ,"en")[0].name : ""} onChange={setPopularAreasData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='name_ar' className='formLabel'>name (arabic)</label>
                            <input name="name" id='name_ar' type='text' data-lang='ar' value={(popularAreas && popularAreas.locales && popularAreas.locales.length && filterLanguage(popularAreas.locales ,"ar").length) ? filterLanguage(popularAreas.locales ,"ar")[0].name : ""} onChange={setPopularAreasData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='description_en' className='formLabel'>description (english)</label>
                            <textarea name="description" id='description_en' data-lang='en' value={(popularAreas && popularAreas.locales && popularAreas.locales.length && filterLanguage(popularAreas.locales ,"en").length) ? filterLanguage(popularAreas.locales ,"en")[0].description : ""} onChange={setPopularAreasData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='description_ar' className='formLabel'>description (arabic)</label>
                            <textarea name="description" id='description_ar' data-lang='ar' value={(popularAreas && popularAreas.locales && popularAreas.locales.length && filterLanguage(popularAreas.locales ,"ar").length) ? filterLanguage(popularAreas.locales ,"ar")[0].description : ""} onChange={setPopularAreasData}/>
                        </div>
                    </div>
                </form>
            </div>
            {/**** Featured properties *****/}
            <div className='homeSectionWrapper'>
                <form onSubmit={featuredPropertiesSubmit}>
                    <div className='sectionTitle mb-2'>
                        <div className='d-flex align-items-center'>
                            <h5 className='formLabel mb-0 me-3'>Featured properties </h5>                      
                            <label className="switch mb-0">
                                <input type="checkbox" id='propertiesStatus' name='status' onChange={setFeaturedPropertiesData}/>
                                <span className="slider round"/>
                            </label>
                        </div>
                        {!featuredPropertiesloading ? 
                            <button className='confirmBtn updateBtn' type='submit'>update</button>
                            :
                            <button className='confirmBtn updateBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                saving...
                            </button>
                        }
                    </div>

                    <div className='formContainer'>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='name_en' className='formLabel'>name (english)</label>
                            <input name="name" id='name_en' type='text' data-lang='en' value={(featuredProperties && featuredProperties.locales && featuredProperties.locales.length && filterLanguage(featuredProperties.locales ,"en").length) ? filterLanguage(featuredProperties.locales ,"en")[0].name : ""} onChange={setFeaturedPropertiesData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='name_ar' className='formLabel'>name (arabic)</label>
                            <input name="name" id='name_ar' type='text' data-lang='ar' value={(featuredProperties && featuredProperties.locales && featuredProperties.locales.length && filterLanguage(featuredProperties.locales ,"ar").length) ? filterLanguage(featuredProperties.locales ,"ar")[0].name : ""} onChange={setFeaturedPropertiesData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='description_en' className='formLabel'>description (english)</label>
                            <textarea name="description" id='description_en' data-lang='en' value={(featuredProperties && featuredProperties.locales && featuredProperties.locales.length && filterLanguage(featuredProperties.locales ,"en").length) ? filterLanguage(featuredProperties.locales ,"en")[0].description : ""} onChange={setFeaturedPropertiesData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='description_ar' className='formLabel'>description (arabic)</label>
                            <textarea name="description" id='description_ar' data-lang='ar' value={(featuredProperties && featuredProperties.locales && featuredProperties.locales.length && filterLanguage(featuredProperties.locales ,"ar").length) ? filterLanguage(featuredProperties.locales ,"ar")[0].description : ""} onChange={setFeaturedPropertiesData}/>
                        </div>
                    </div>
                </form>
            </div>
            {/**** Our Partners *****/}
            <div className='homeSectionWrapper'>
                <form onSubmit={ourPartnersSubmit}>
                    <div className='sectionTitle mb-2'>
                        <div className='d-flex align-items-center'>
                            <h5 className='formLabel mb-0 me-3'>Our Partners</h5>                      
                            <label className="switch mb-0">
                                <input type="checkbox" id='partnersStatus' name='status' onChange={setOurPartnersData}/>
                                <span className="slider round"/>
                            </label>
                        </div>
                        {!ourPartnersloading ? 
                            <button className='confirmBtn updateBtn' type='submit'>update</button>
                            :
                            <button className='confirmBtn updateBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                saving...
                            </button>
                        }
                    </div>

                    <div className='formContainer'>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='name_en' className='formLabel'>name (english)</label>
                            <input name="name" id='name_en' type='text' data-lang='en' value={(ourPartners && ourPartners.locales && ourPartners.locales.length && filterLanguage(ourPartners.locales ,"en").length) ? filterLanguage(ourPartners.locales ,"en")[0].name : ""} onChange={setOurPartnersData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='name_ar' className='formLabel'>name (arabic)</label>
                            <input name="name" id='name_ar' type='text' data-lang='ar' value={(ourPartners && ourPartners.locales && ourPartners.locales.length && filterLanguage(ourPartners.locales ,"ar").length) ? filterLanguage(ourPartners.locales ,"ar")[0].name : ""} onChange={setOurPartnersData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='description_en' className='formLabel'>description (english)</label>
                            <textarea name="description" id='description_en' data-lang='en' value={(ourPartners && ourPartners.locales && ourPartners.locales.length && filterLanguage(ourPartners.locales ,"en").length) ? filterLanguage(ourPartners.locales ,"en")[0].description : ""} onChange={setOurPartnersData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='description_ar' className='formLabel'>description (arabic)</label>
                            <textarea name="description" id='description_ar' data-lang='ar' value={(ourPartners && ourPartners.locales && ourPartners.locales.length && filterLanguage(ourPartners.locales ,"ar").length) ? filterLanguage(ourPartners.locales ,"ar")[0].description : ""} onChange={setOurPartnersData}/>
                        </div>
                    </div>
                </form>
            </div>
            {/**** Latest News *****/}
            <div className='homeSectionWrapper'>
                <form onSubmit={latestNewsSubmit}>
                    <div className='sectionTitle mb-2'>
                        <div className='d-flex align-items-center'>
                            <h5 className='formLabel mb-0 me-3'>Latest News</h5>                      
                            <label className="switch mb-0">
                                <input type="checkbox" id='newsStatus' name='status' onChange={setLatestNewsData}/>
                                <span className="slider round"/>
                            </label>
                        </div>
                        {!latestNewsloading ? 
                            <button className='confirmBtn updateBtn' type='submit'>update</button>
                            :
                            <button className='confirmBtn updateBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                saving...
                            </button>
                        }
                    </div>

                    <div className='formContainer'>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='name_en' className='formLabel'>name (english)</label>
                            <input name="name" id='name_en' type='text' data-lang='en' value={(latestNews && latestNews.locales && latestNews.locales.length && filterLanguage(latestNews.locales ,"en").length) ? filterLanguage(latestNews.locales ,"en")[0].name : ""} onChange={setLatestNewsData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='name_ar' className='formLabel'>name (arabic)</label>
                            <input name="name" id='name_ar' type='text' data-lang='ar' value={(latestNews && latestNews.locales && latestNews.locales.length && filterLanguage(latestNews.locales ,"ar").length) ? filterLanguage(latestNews.locales ,"ar")[0].name : ""} onChange={setLatestNewsData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='description_en' className='formLabel'>description (english)</label>
                            <textarea name="description" id='description_en' data-lang='en' value={(latestNews && latestNews.locales && latestNews.locales.length && filterLanguage(latestNews.locales ,"en").length) ? filterLanguage(latestNews.locales ,"en")[0].description : ""} onChange={setLatestNewsData}/>
                        </div>
                        <div className='d-flex flex-column mb-4'>
                            <label htmlFor='description_ar' className='formLabel'>description (arabic)</label>
                            <textarea name="description" id='description_ar' data-lang='ar' value={(latestNews && latestNews.locales && latestNews.locales.length && filterLanguage(latestNews.locales ,"ar").length) ? filterLanguage(latestNews.locales ,"ar")[0].description : ""} onChange={setLatestNewsData}/>
                        </div>
                    </div>
                </form>
            </div>

            <DeleteModal onSubmitDelete={onSubmitDelete} name='slider'></DeleteModal>
        </section>
        </>
    )
}
