import React, { useEffect, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { supportedLanguages } from './../../utilities/SupportedLanguages';
import formModel from './formModel';
import { getPageHeaderAndContent } from '../../Apis/pageHeadersApi';
import { filterLanguage } from '../../utilities/filterLang';

export default function PageContentForm(props) {
    const [loading , setLoading] = useState(false);
    const [model, setModel] = useState(formModel);
    let [editors , setEditors ]= useState({});
    const pageName = props.name;
    const objKey = props.objKey;
    let [nestedObjKey ]= useState(`${props.nestedObjKey ? props.nestedObjKey : ''}`);

    useEffect(() => {
        getPageHeaderAndContent(pageName).then((res) => {
            let thisEditors = {...editors};
            let mypageContent;

            if(props.nestedObjKey){
                mypageContent = res.data.data.settings[`${nestedObjKey}`];
            }else{
                mypageContent = res.data.data.settings[`${objKey}`];
            }
            delete mypageContent.value;
            delete mypageContent.image;
            setModel({...mypageContent});
            // to preview data in text editors
            supportedLanguages.map((language)=>{
                let lang = filterLanguage(mypageContent.locales, language.slug);
                if(lang.length){
                    //set values at editor state
                    const contentDataStateAr = ContentState.createFromBlockArray(convertFromHTML(`${lang[0].description}`));
                    const editorDataState = EditorState.createWithContent(contentDataStateAr);
                    thisEditors[language.slug] = editorDataState;
                }
                setEditors(thisEditors);
                return true
            })
        })
        // eslint-disable-next-line
    }, []);

    // convert body to html
    const convertContentToHTML = (state) => {
        return convertToHTML(state.getCurrentContent());
    }

    // onchange editor handler
    const handleEditorChange = (state,lang) => {
        let thisEditors = {...editors}
        if(!thisEditors[lang]){ 
            thisEditors[lang]=EditorState.createEmpty()
        } 
        thisEditors[lang]=state;
        setEditors(thisEditors);
        
        let m ={...model}
        let existsLocales = m.locales.filter((i) => {
            return i.locale === lang;
        })
        if(existsLocales.length){
            existsLocales[0]['description'] = convertContentToHTML(state);
        }else{
            m.locales.push({'locale': lang});
            m.locales.map((i) => {
                if(i.locale === lang){
                    i['description'] = convertContentToHTML(state);
                }
                return true;
            });
        }
        setModel(m)
    }

    // onsubmit page headers
    const confirmPageContent = async (e , model)=>{
        e.preventDefault();
        setLoading(true);

        // send request
        let submitProps = await props.onSubmit(e, model);
        if(submitProps === "done"){
            setLoading(false);
        }else{
            setLoading(false);
        }
    }

    return (
        <>
            <form onSubmit={(e) => confirmPageContent(e, model)}>
                {supportedLanguages.map((language , index)=>(
                    <div key={index} className='d-flex flex-column mb-4'>
                        <label htmlFor='content' className='formLabel'>{`content in ${language.name}`}</label>
                        <div {...(language.slug === 'ar' ? {'dir':'rtl'}:{})}>
                            <Editor 
                                editorState={model && model.locales && model.locales.length && editors[language.slug]}
                                onEditorStateChange={(state)=>handleEditorChange(state,language.slug)}
                            />
                        </div>
                    </div>
                ))}
                <div>
                    { !loading ?
                        <button className='confirmBtn' type='submit'>update</button>
                        :
                        <button className='confirmBtn' disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            saving...
                        </button>
                    }
                </div>
            </form>
        </>
    )
}
