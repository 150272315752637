import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import TitleContext from '../../../TitleContext';
import "./WebsitePages.scss";
import rightarrow from "../../../assets/icons/rightarrow.svg"

export default function WebsitePages() {
    let {changeTitle} = useContext(TitleContext);
    useEffect(()=>{
        changeTitle(`pages`);
        // eslint-disable-next-line
    } , []);

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <>
        <section className='componentGlobalWrapper websitePages'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>pages settings</h5>
            </div>

            <div>
                <div className='overviewPage'>
                    <p>Home</p>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/home`}>
                        <img src={rightarrow} alt="arrow"/>
                    </NavLink>
                </div>
                <div className='overviewPage'>
                    <p>Developers</p>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/developers`}>
                        <img src={rightarrow} alt="arrow"/>
                    </NavLink>
                </div>
                <div className='overviewPage'>
                    <p>About Us</p>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/about-us`}>
                        <img src={rightarrow} alt="arrow"/>
                    </NavLink>
                </div>
                <div className='overviewPage'>
                    <p>our Partners</p>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/partners`}>
                        <img src={rightarrow} alt="arrow"/>
                    </NavLink>
                </div>
                <div className='overviewPage'>
                    <p>Contact Us</p>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/contact-us`}>
                        <img src={rightarrow} alt="arrow"/>
                    </NavLink>
                </div>
                {/* <div className='overviewPage'>
                    <p>FAQ</p>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/faq`}>
                        <img src={rightarrow} alt="arrow"/>
                    </NavLink>
                </div> */}
                <div className='overviewPage'>
                    <p>privacy policy</p>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/privacy-policy`}>
                        <img src={rightarrow} alt="arrow"/>
                    </NavLink>
                </div>
                <div className='overviewPage'>
                    <p>terms &#38; conditions</p>
                    <NavLink to={`${process.env.REACT_APP_BASENAME}/dashboard/appearance/pages/terms-conditions`}>
                        <img src={rightarrow} alt="arrow"/>
                    </NavLink>
                </div>
            </div>

        </section>
        </>
    )
}
