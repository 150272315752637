import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router';
import { updatedeveloper } from '../../../Apis/developersApi';
import TitleContext from '../../../TitleContext';
import DeveloperForm from './developerForm';

export default function EditDeveloper() {
    let { changeTitle } = useContext(TitleContext);
    useEffect(() => {
        changeTitle(`add developer`);
        // eslint-disable-next-line
    }, []);
    const { id } = useParams();

    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        console.log(data);
        data.append("_method", "patch");
        return await updatedeveloper(id,data).then(res => {
            console.log(res)
            return 'done';
        }).catch(err => {
            console.log(err.response);
            return err.response.data.errors;
        })
    };

    return (
        <DeveloperForm onSubmit={onSubmit}/>
    )
}
