import React from 'react';
import { useParams } from 'react-router';
import { editSlider } from '../../../../Apis/homeApi';
import Form from './Form';

export default function EditSlider() {
    const { id } = useParams();

    // onsubmit
    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        data.append("_method", "patch");
        return await editSlider(id , data).then(res => {
            return 'done';      
        }).catch(err => {
            console.log(err.response);
            return err.response.data.errors;
        })
    };

    return (
        <Form onSubmit={onSubmit}></Form>
    )
}
