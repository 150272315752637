import React, { useContext, useEffect  , useState} from 'react'
import { useLocation } from 'react-router';
import TitleContext from '../../../TitleContext';
import uploadImage from "../../../assets/icons/uploadimage.svg";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


export default function AddPage() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    let {changeTitle} = useContext(TitleContext);

    useEffect(()=>{
        changeTitle(`add new page`);
        // eslint-disable-next-line
    } , [])

    const [sideImage] = useState('');

    /**** text editor *****/
    let [editorStateEn, setEditorStateEn] = useState(() => EditorState.createEmpty());
    let [editorStateAr, setEditorStateAr] = useState(() => EditorState.createEmpty());
    let [convertedEnContent , setConvertedEnContent] = useState("");
    let [convertedArContent , setConvertedArContent] = useState("");

    // convert english body to html
    const convertEnContentToHTML = () => {
        convertedEnContent = convertToHTML(editorStateEn.getCurrentContent());
        setConvertedEnContent(convertedEnContent);
        return convertedEnContent;
    }
    // convert arabic body to html
    const convertArContentToHTML = () => {
        convertedArContent = convertToHTML(editorStateAr.getCurrentContent());
        setConvertedArContent(convertedArContent);
        return convertedArContent;
    }
    // onchange editor handler
    const handleEnEditorChange = (state) => {
        setEditorStateEn(state);
        convertEnContentToHTML();
    }
    const handlArEditorChange = (state) => {
        setEditorStateAr(state);
        convertArContentToHTML();
    }


    return (
        <>
        <section className='componentGlobalWrapper aboutus'>
            <div className='mb-4'>
                <h5 className='pageSubtitle'>add new page</h5>
            </div>

            <form>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='name_en' className='formLabel'>title in English</label>
                    <input name="name_en" id='name_en' type='text' />
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='name_ar' className='formLabel'>title in Arabic</label>
                    <input name="name_ar" id='name_ar' type='text' />
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='name_en' className='formLabel'>page url</label>
                    <input name="name_en" id='name_en' type='text' />
                </div>

                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='content' className='formLabel'>content in English</label>
                    <div>
                        <Editor
                            editorState={editorStateEn}
                            onEditorStateChange={handleEnEditorChange}  
                        />
                    </div>
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='content' className='formLabel'>content in arabic</label>
                    <div>
                        <Editor
                            editorState={editorStateAr}
                            onEditorStateChange={handlArEditorChange}  
                        />
                    </div>
                </div>

                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='favicon' className='formLabel'>side image</label>
                    { !sideImage ?
                        <label htmlFor="uploadImage" style={{"width" : "100%" , "position" : "relative"}}>
                            <div className='imageWrapper' style={{"width" : "180px" , "height" : "140px"}}>
                                <input type='file' id='uploadImage' name='image' className="d-none"/>
                                <img src={uploadImage} alt="upload"/>
                                <span>Upload Image</span>
                            </div>
                        </label>
                        :
                        <div style={{"width" : "100%" , "position" : "relative"}}>
                            {/* <Tooltip title='change image'> */}
                                <label htmlFor='viewImage' className={`position-absolute d-flex align-items-center justify-content-center`}>
                                    {/* <FontAwesomeIcon icon={faRotateRight}/> */}
                                    <input type='file' id='viewImage' name='image' className="d-none"/>
                                </label>
                            {/* </Tooltip> */}
                            <div className='d-flex flex-column align-items-center' style={{backgroundImage: `url(${sideImage})`}}>
                            </div>
                        </div>
                    }
                </div>

                <div>
                    <button className='confirmBtn' type='submit'>add</button>
                </div>

            </form>
        </section>
        </>
    )
}
