import { createContext } from "react";
import { useState } from "react";

let UnReadLeadsContext = createContext('');
export function UnReadLeadsContextProvider(props){ 
    let[unReadPropertyRequest, setUnReadPropertyRequest] = useState(0);
    let[unReadContactUs, setUnReadContactUs] = useState(0);

    function changeUnReadPropertyRequest(num){
        //console.log(num)
        setUnReadPropertyRequest(num);
    }


    function changeUnReadContactUs(num){
        setUnReadContactUs(num)
    }

    return (
        <UnReadLeadsContext.Provider value={{unReadPropertyRequest , changeUnReadPropertyRequest ,unReadContactUs , changeUnReadContactUs}}>
            {props.children}
        </UnReadLeadsContext.Provider>
    )
}
export default UnReadLeadsContext;