import React, { useState , useEffect, useRef } from 'react';
import "../Facilities/AddEditCardSide.scss";
import uploadImage from "../../assets/icons/uploadimage.svg";
import { useParams } from 'react-router';
import { editCategory, getCategory } from '../../Apis/CategoriesApi';
import { api_host, api_url } from './../../Configuration/Config';
import Tooltip from '@material-ui/core/Tooltip';
import { filterLanguage } from './../../utilities/filterLang';
import removeIcon from "../../assets/icons/removeIcon.svg";
import axios from 'axios';

export default function EditCategory(props) {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    let [oneCategory , setOneCategory] = useState({});
    const [categoryImage , setCategoryImage] = useState('');
    let imageRef = useRef();

    // get category data
    useEffect(() => {
        getCategory(id).then((res) => {
            let x=res.data.data;
            setCategoryImage(`${api_host}/${x?.image?.image}`);
            let myOneCategory ={...oneCategory};
            myOneCategory = res.data.data;
            myOneCategory.image=x?.image?.id;
            setOneCategory(myOneCategory);
        })
    //eslint-disable-next-line
    }, [id]);

    let [progress, setProgress] = useState(0)
    /* upload images */
    const token = `Bearer ` + localStorage.getItem('rh_Admin_Token');
    function addImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }

        axios.post(`${api_url}/admin/upload`, formData, config).then((res) => {
            let result = res.data.data;
            setCategoryImage(`${api_host}/${result.image}`)
            setProgress(0);
            let obj = { ...oneCategory };
            obj.image = result.id;
            setOneCategory(obj)
            // image validation
            let classes = Array.from(imageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                imageRef.current.nextSibling.classList.add("d-none");
            }
        }).catch((err) => {
            setProgress(0);
        })
    }
    // remove image
    function removeImgHandler(){
        setCategoryImage('');
        let obj = { ...oneCategory };
        obj.image = '';
        setOneCategory(obj)
    }

    // edit category
    function editCategoryChange(e){
        let myoneCategory = {...oneCategory};
            let language = e.target.getAttribute('data-lang');
            myoneCategory.locales.map((l) => {
                if (l.locale === language) {
                    l[e.target.name] = e.target.value;
                }
                return true;
            })
        setOneCategory(myoneCategory);
    }

    function editCategorySubmit(e){
        e.preventDefault();
        let Obj = {...oneCategory};
        let formData = new FormData();

        if (categoryImage === '') {
            imageRef.current.nextSibling.classList.remove("d-none");
        }

        for (const key in Obj) {
            if (key === 'locales') {
                for (let i = 0; i < Obj.locales.length; i++) {
                    for (const key in Obj.locales[i]) {
                        formData.append(`locales[${i}][${key}]`, Obj.locales[i][key]);
                    }
                }
            }
            else {
                formData.append(key, Obj[key]);
            }
        }
        formData.append("_method", "patch");

        if(categoryImage !== ''){
            setLoading(true);
            // to send request
            editCategory(id,formData).then(res =>{
                setLoading(false);
                props.getAll();
            }).catch(err => {
                setLoading(false);
            })
        }
    }

    return (
        <>
        <section className='actionCardSideWrapper'>
            <div className='d-flex justify-content-between align-items-center'>
                <h5>edit category</h5>
            </div>
            <form onSubmit={editCategorySubmit}>
                <div className='formInputsWrapper'>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='title' className='formLabel'>image</label>
                        { !categoryImage ?
                            <div>
                                <label ref={imageRef} htmlFor="uploadImage" style={{"width" : "33%" , "position" : "relative"}}>
                                    <div className='imageWrapper'>
                                        <input onChange={addImage} type='file' id='uploadImage' name='image' className="d-none"/>
                                        <img src={uploadImage} alt="upload"/>
                                        <span>Upload Image</span>
                                    </div>
                                </label>
                                {progress !== 0 && (
                                <div className="progress mt-2 p-0"  style={{"width" : "33%"}} >
                                    <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                        aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                        {progress}%
                                    </div>
                                </div>)}
                                <div className='validateToDelete text-danger d-none'>this field is required!</div>
                            </div>
                            :
                            <div style={{"width" : "33%" , "position" : "relative"}} className='imageWrapper'>
                                <Tooltip title='Remove Image'>
                                    <label htmlFor='viewImage' className={`position-absolute d-flex align-items-center justify-content-center reloadAnotherIcon`}>
                                        <img src={removeIcon} alt="delete" onClick={removeImgHandler}/>
                                        <input type='file' id='viewImage' name='image' className="d-none"/>
                                    </label>
                                </Tooltip>
                                <div style={{backgroundImage: `url(${categoryImage})`}}></div>
                            </div>
                        }
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_en' className='formLabel'>name in english</label>
                        <input onChange={editCategoryChange} value={(oneCategory && oneCategory.locales && oneCategory.locales.length) ? filterLanguage(oneCategory.locales ,"en")[0].name : ""} name="name" data-lang='en' id='name_en' type='text' pattern="^(.*)[^ ](.*)$" required/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_ar' className='formLabel'>name in arabic</label>
                        <input onChange={editCategoryChange} value={(oneCategory && oneCategory.locales && oneCategory.locales.length) ? filterLanguage(oneCategory.locales ,"ar")[0].name : ""} name="name" data-lang='ar' id='name_ar' type='text' pattern="^(.*)[^ ](.*)$" required/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='description_en' className='formLabel'>description in english</label>
                        <textarea onChange={editCategoryChange} value={(oneCategory && oneCategory.locales && oneCategory.locales.length) ? filterLanguage(oneCategory.locales ,"en")[0].description : ""} name="description" data-lang='en' id='description_en' type='text' pattern="^(.*)[^ ](.*)$"required/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='description_ar' className='formLabel'>description in arabic</label>
                        <textarea onChange={editCategoryChange} value={(oneCategory && oneCategory.locales && oneCategory.locales.length) ? filterLanguage(oneCategory.locales ,"ar")[0].description : ""} name="description" data-lang='ar' id='description_ar' type='text' placeholder='description'
                        pattern="^(.*)[^ ](.*)$" required/>
                    </div>
                </div>
                <div>
                    {!loading ? 
                        <button className='confirmBtn' type='submit'>save changes</button>
                        :
                        <button className='confirmBtn' disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            saving...
                        </button>
                    }
                </div>
                
            </form>
        </section>
        </>
    )
}
