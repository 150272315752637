import React, { useContext, useEffect, useRef, useState } from 'react';
import TitleContext from '../../../TitleContext';
import uploadImage from "../../../assets/icons/uploadimage.svg";
import removeTag from "../../../assets/icons/removetag.svg";
import "../GeneralSettings.scss";
import { useLocation } from 'react-router';
import deleteIcon from "../../../assets/icons/removeIcon.svg";
import Tooltip from '@material-ui/core/Tooltip';
import { getGeneralSettings, updateGeneralSettings } from '../../../Apis/generalSettingsApi';
/* import formModel from './formModel'; */
import { api_host } from '../../../Configuration/Config';
import { supportedLanguages } from '../../../utilities/SupportedLanguages';
import { filterLanguage } from '../../../utilities/filterLang';
/* import { setGeneralSettingsData } from './formHandler'; */

export default function Form() {
    let {changeTitle} = useContext(TitleContext);
    const [model, setModel] = useState([]);
    let [favIcon , setFavIcon] = useState('');
    let [logoImage , setLogoImage] = useState('');
    const favIconRef = useRef();
    const logoRef = useRef();
    const { pathname } = useLocation();
    const [loader , setLoader] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    function filterModel(model , slug){
        let objs = model.filter((l)=>(l.slug === slug))
        /* console.log(objs[0]) */
        return objs.length ? objs[0] : null
    }

    useEffect(() => {
        changeTitle(`general settings`);
        getGeneralSettings().then((res) => {
            let generalSettings =  res.data.data.settings;
            let modifiedModel = [];
            for(let item in generalSettings){
                /* console.log(item) */
                modifiedModel.push({
                    slug: generalSettings[item].slug,
                    id: generalSettings[item].id,
                    locales: generalSettings[item].locales,
                    image: ''
                })
            }
            /* console.log(modifiedModel)*/
            setModel(modifiedModel ,console.log(modifiedModel));

            /* res.data.data.settings.favicon.image  */
            setFavIcon(`${api_host}/${res.data.data.settings.favicon.image}`)
            setLogoImage(`${api_host}/${res.data.data.settings.logo.image}`)
        })
        // eslint-disable-next-line
    }, []);

    
    useEffect(()=>{
        if(model && model.length){
            // set keywords
            let thiskeywords = {...keywords};
            supportedLanguages.map((language)=>{
                let obj = filterModel(model , "meta-keywords");
                let lang = obj && obj.locales? filterLanguage(obj.locales , language.slug) : '';
                if(lang.length){
                    // set keywords
                    if(lang[0].description && lang[0].description !== 'null'){
                        thiskeywords[language.slug] = lang[0].description.replace(/ /g, '').split(',');
                    }
                    setKeywords(thiskeywords);
                }
                return true
            })
        }
        // eslint-disable-next-line
    }, [model])

/****************** set keywords ************************/
    let metaKeywordsRef = useRef();
    let [keywords , setKeywords] = useState({});

    /****************** set keywords ************************/
    function AddKeywordHandler(e,id,lang) {
        let propKeyword = {...keywords};
        /* let propKeywordAr = [...keywordsAr]; */
        if(e.target.value.length > 30 ){
            e.preventDefault();
        }
        if(e.keyCode === 32 ){
            if(!propKeyword[lang]){ 
                propKeyword[lang]=[]
            } 
            let thisModel = [...model];
            if(e.target.value.trim()){
                propKeyword[lang]=[...propKeyword[lang],e.target.value.trim()];
                setKeywords(propKeyword)
                e.target.value = ""; 
    
                let filteredObj = thisModel.filter((obj)=>(obj.id === id))[0];
                let existsLocales = filteredObj.locales.filter((obj)=>(obj.locale === lang));

                if(existsLocales.length){
                    existsLocales[0]['description'] =  propKeyword[lang].join();
                }else{
                    filteredObj.locales.push({'locale': lang});
                    filteredObj.locales.map((i) => {
                        if(i.locale === lang){
                            i['description'] =  propKeyword[lang].join();
                        }
                        return true;
                    });
                }
                console.log(thisModel);
            }
            setModel(thisModel);
        }  
    }

    /* to delete tag to tags array*/
    function removeKeywordHandler(i,id,lang ) {
        let propKeyword = {...keywords};
        propKeyword[lang] = propKeyword[lang].filter((key)=>propKeyword[lang].indexOf(key) !== i );
        setKeywords(propKeyword)

        let thisModel = [...model];
        let filteredObj = thisModel.filter((obj)=>(obj.id === id))[0];
        let existsLocales = filteredObj.locales.filter((obj)=>(obj.locale === lang));
        
        /* let m ={...model}
        let existsLocales = m.locales.filter((i) => {
            return i.locale === lang;
        }) */

        if(existsLocales.length){
            existsLocales[0]['description'] =  propKeyword[lang].join();
        }else{
            filteredObj.locales.push({'locale': lang});
            filteredObj.locales.map((i) => {
                if(i.locale === lang){
                    i['description'] =  propKeyword[lang].join();
                }
                return true;
            });
        }
    }

    /* function changeHandler(e ,id ,lang){
        let thisModel = [...model];
        let filteredObj = thisModel.filter((obj)=>(obj.id === id))[0];
        let existsLocale = filteredObj.locales.filter((obj)=>(obj.locale === lang))[0];
        existsLocale[e.target.name] = e.target.value;
        setModel(thisModel);
    } */

    function changeHandler(e ,id ,lang=null){
        let thisModel = [...model];
        let filteredObj = thisModel.filter((obj)=>(obj.id === id))[0];
        if(lang !== null){
            if(!filteredObj.locales){
                filteredObj['locales'] = [];
            }
            let existsLocale = filteredObj.locales.filter((obj)=>(obj.locale === lang))[0];
            if(existsLocale){
                existsLocale[e.target.name] = e.target.value;
            }else{
                let obj = {locale:lang}
                filteredObj.locales.push(obj);
                filteredObj.locales.map((i) => {
                    if(i.locale === lang){
                        i[e.target.name] = e.target.value;
                    }
                    return true;
                });
            }
        }else{
            filteredObj['value'] = e.target.value;
        }
        setModel(thisModel);
    }

    function changeImages(e){
        let thisModel = [...model];
        console.log(thisModel)
        let imageObj = filterModel(thisModel , e.target.name);
        imageObj.image = e.target.files[0];
        setModel(thisModel);
        
        if(e.target.name === 'favicon'){
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setFavIcon(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }else if(e.target.name === 'logo'){
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setLogoImage(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
            /* setLogoImage(URL.createObjectURL(e.target.files[0])); */
        }

        console.log(thisModel)
    }

    function removeImage(name){
        let thisModel = [...model];
        /* let filteredObj = thisModel.filter((obj)=>(obj.id === id))[0]; */
        let imageObj = filterModel(thisModel , name);
        imageObj.image = '';
        setModel(thisModel);

        if(name === 'favicon'){
            setFavIcon('');
        }else if(name === 'logo'){
            setLogoImage('');
        }
    }

    function onConfirmEdit(e){
        e.preventDefault();
        setLoader(true);
        if(!favIcon){
            window.scrollTo(favIconRef.current.getBoundingClientRect().left + window.scrollX ,
            favIconRef.current.getBoundingClientRect().top + window.scrollY - 150);
            favIconRef.current.nextSibling.classList.remove("d-none");
        } 
        if(!logoImage){
            window.scrollTo(logoRef.current.getBoundingClientRect().left + window.scrollX ,
            logoRef.current.getBoundingClientRect().top + window.scrollY - 150);
            logoRef.current.nextSibling.classList.remove("d-none");
        } 
        if(logoImage && favIcon){
            let formData = new FormData();
            let thisModel = [...model];
            thisModel.map((ele ,index)=>{
                for(const key in ele){
                    if (key === 'locales') {
                        for (let i = 0; i < ele.locales.length; i++) {
                            for (const key2 in ele.locales[i]) {
                                if(ele.locales[i][key2] !== null && ele.locales[i][key2] !== ''){
                                    formData.append(`settings[${index}][locales][${i}][${key2}]`, ele.locales[i][key2]);
                                }else{
                                        formData.append(`settings[${index}][locales][${i}][${key2}]`, '');
                                    }
                                }
                            }
                    }else if(key === 'image'){
                        if(ele.image){
                            formData.append(`settings[${index}][image]`, ele.image);
                        }
                    }else if(key === 'id'){
                        formData.append(`settings[${index}][${key}]`, ele[key]);
                    }
                }
                return true
            }
            )
            formData.append('_method','patch')
            for (var pair of formData.entries()){
                console.log(pair[0]+ ', ' + pair[1]); 
            }

            updateGeneralSettings(formData).then((res)=>{
                console.log(res.data.data);
                setLoader(false);
            }).catch((err)=>{
                console.log(err);
                setLoader(false);
            }
            )
        }
    }

    function preventEnter(e) {
        if(e.keyCode===13){
            e.preventDefault();
        }
    }

    return (
        <>
        <section className='componentGlobalWrapper generalSettings addBlog' >
            <div className='mb-5'>
                <h5 className='pageSubtitle'>site settings</h5>
            </div>

            {(model && model.length) ?
                <form onSubmit={onConfirmEdit} onKeyDown={preventEnter}>
                    <div className='generalSettingsForm'>
                        <div className='d-flex justify-content-between align-items-center'>
                            {/** site title  ***/}
                            <div className='formWidth topWidth'>
                                {supportedLanguages.map((language , index)=>
                                    {
                                        let obj = filterModel(model , "title");
                                        let lang = obj && obj.locales? filterLanguage(obj.locales , language.slug) : '';
                                    return(
                                        <div key={index} className='d-flex flex-column mb-4'>
                                            <label htmlFor={`title${language}`} className='formLabel'>{`site title in ${language.name}`}</label>
                                            <input name="description" id={`title${language.slug}`} onChange={(e)=>changeHandler(e ,obj.id ,language.slug)}  type='text' value={(lang && lang.length) ? lang[0].description : ""} /* onChange={(e) => setFormData(e, 'name' , true , language.slug)} *//>
                                        </div>
                                    )})
                                }
                            </div>
                            {/** favicon **/}
                            <div className='d-flex flex-column mb-4'>
                                <label htmlFor='favicon' className='formLabel'>favicon</label>
                                { !favIcon ?
                                    <div>
                                        <label ref={favIconRef} htmlFor="uploadImage" className='position-relative'>
                                            <div className='globalImageWrapper'>
                                                <input onChange={changeImages} type='file' id='uploadImage' name='favicon' className="d-none"/>
                                                <img className='cloudIcon' src={uploadImage} alt="upload"/>
                                                <span>Upload Image</span>
                                            </div>
                                        </label>
                                        <div className='validateToDelete text-danger d-none'>This field is required!</div>
                                    </div>
                                    :
                                    <div className='globalImageWrapper position-relative'>
                                        <Tooltip title='Remove Image'>
                                            <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                                <img src={deleteIcon} alt="delete" onClick={()=>removeImage('favicon')}/>
                                            </label>
                                        </Tooltip>
                                        <div className='globalPreviewedImage' style={{backgroundImage: `url(${favIcon})` , 'backgroundSize': 'contain', 'width' : '60%' , 'height' : '60%'}}></div>
                                    </div>
                                }
                            </div>
                        </div>
                        {/*** site tagline and logo ****/}
                        <div className='d-flex justify-content-between align-items-center'>
                            {/** site tagline ***/}
                            <div className='formWidth topWidth'>
                                {supportedLanguages.map((language , index)=>
                                    {
                                        let obj = filterModel(model , "tagline");
                                        let lang = obj && obj.locales? filterLanguage(obj.locales , language.slug) : '';
                                        
                                    return(
                                        <div key={index} className='d-flex flex-column mb-4'>
                                            <label htmlFor={`title${language}`} className='formLabel'>{`site tagline in ${language.name}`}</label>
                                            <input maxLength='255' name="description" id={`tagline${language.slug}`} type='text' value={(lang && lang.length) ? lang[0].description : ""} onChange={(e)=>changeHandler(e ,obj.id ,language.slug)} /* onChange={(e) => setFormData(e, 'name' , true , language.slug)} *//>
                                        </div>
                                    )})
                                }
                            </div>
                            {/** logo **/}
                            <div className='d-flex flex-column mb-4'>
                                <label htmlFor='logoimage' className='formLabel'>logo</label>
                                { !logoImage ?
                                    <div>
                                        <label ref={logoRef} htmlFor="uploadImage" className='position-relative'>
                                            <div className='globalImageWrapper'>
                                                <input onChange={changeImages} type='file' id='uploadImage' name='logo' className="d-none"/>
                                                <img className='cloudIcon' src={uploadImage} alt="upload"/>
                                                <span>Upload Image</span>
                                            </div>
                                        </label>
                                        <div className='validateToDelete text-danger d-none'>This field is required!</div>
                                    </div>
                                    :
                                    <div className='globalImageWrapper position-relative'>
                                        <Tooltip title='Remove Image'>
                                            <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                                <img src={deleteIcon} alt="delete" onClick={()=>removeImage('logo')}/>
                                            </label>
                                        </Tooltip>
                                        <div className='globalPreviewedImage' style={{backgroundImage: `url(${logoImage})` , 'backgroundSize': 'contain', 'width' : '60%' , 'height' : '60%'}}></div>
                                    </div>
                                }
                            </div>
                        </div>

                        {/** meta keywords  ***/}
                        {supportedLanguages.map((language , index)=>{
                            let obj = filterModel(model , "meta-keywords");
                            /* let lang = obj && obj.locales? filterLanguage(obj.locales , language.slug) : ''; */
                            return(
                                <div key={index} className='d-flex flex-column mb-4 formWidth'>
                                    <label htmlFor='metaKeyword' className='formLabel'>meta keywords ({language.name})</label>
                                    <div ref={metaKeywordsRef} className={`inputGroup LikeInput d-flex align-items-center mw-100 `}>
                                        <div className="">
                                            {
                                                keywords[language.slug] && keywords[language.slug].map((keyword,index)=>(
                                                    <div key={index} className={`tagContainer`}>
                                                        {keyword}
                                                        <img src={removeTag} onClick={() => removeKeywordHandler(index,obj.id, language.slug)} className='removeTag' alt='remove' />
                                                    </div>
                                                ))
                                            }
                                            <input onKeyDown={(e)=>AddKeywordHandler(e ,obj.id ,language.slug)} maxLength='30'type="text" name="metaKeyword" className={`tagInput border-0`}  id="tags" />
                                        </div>
                                    </div>
                                </div>
                            )})
                        }
                        
                        {/*** meta description ***/}
                        <div className='d-flex flex-column mb-4 formWidth'>
                            {supportedLanguages.map((language , index)=>
                                {
                                    let obj = filterModel(model , "meta-description");
                                    let lang = obj && obj.locales? filterLanguage(obj.locales , language.slug) : '';
                                    
                                return(
                                    <div key={index} className='d-flex flex-column mb-4'>
                                        <label htmlFor={`meta-description${language}`} className='formLabel'>{`site tagline in ${language.name}`}</label>
                                        <textarea maxLength='255' name="description" id={`meta-description${language.slug}`} type='text' value={(lang && lang.length) ? lang[0].description : ""} 
                                        onChange={(e)=>changeHandler(e ,obj.id ,language.slug)} /* onChange={(e) => setFormData(e, 'name' , true , language.slug)} *//>
                                    </div>
                                )})
                            }
                        </div>
                        {!loader ?
                            <div>
                                <button className='confirmBtn' type='submit'>save changes</button>
                            </div>
                            :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                saving...
                            </button>
                        }
                    </div>
                </form> 
                : ''
            }
        </section>
        </>
    )
}
