import React, { useContext, useEffect } from 'react'
import { createNewdeveloper } from '../../../Apis/developersApi';
import TitleContext from '../../../TitleContext';
import DeveloperForm from './developerForm';

export default function AddDeveloper() {
    let { changeTitle } = useContext(TitleContext);
    useEffect(() => {
        changeTitle(`add developer`);
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request

        return await createNewdeveloper(data).then(res => {
            console.log(res)
            return 'done';
        }).catch(err => {
            console.log(err.response);
            return err.response.data.errors;
        })
    };

    return (
        <DeveloperForm onSubmit={onSubmit} />
    )
}
