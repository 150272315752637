import React, { useEffect, useRef, useState } from 'react';
import "../../Pages/WebsitePages/Aboutus/Aboutus.scss";
import uploadImage from "../../assets/icons/uploadimage.svg";
import removeIcon from "../../assets/icons/removeIcon.svg";
import { api_host, api_url } from './../../Configuration/Config';
import Tooltip from '@material-ui/core/Tooltip';
import { filterLanguage } from '../../utilities/filterLang';
import { setPageHeaderData } from './formHandler';
import formModel from './formModel';
import { getPageHeaderAndContent, getPageHeaders } from '../../Apis/pageHeadersApi';
import axios from 'axios';

export default function PageHeaderForm(props) {
    const [loading, setLoading] = useState(false);
    const pageImageRef = useRef();
    const [model, setModel] = useState(formModel);
    const [pageImage, setPageImage] = useState('');
    const pageName = props.name;

    // get data when component renders
    useEffect(() => {
        if (props.developers) {
            getPageHeaders(16).then(res => {
                let mypageHeaders = res.data.data;
                delete mypageHeaders.status;   
                if (mypageHeaders.image) {
                    setPageImage(`${api_host}/${mypageHeaders.image?.image}`);
                }
                let obj = {...mypageHeaders}
                obj.image = mypageHeaders?.image?.id
                setModel(obj);
            })
        } else {
            getPageHeaderAndContent(pageName).then(res => {
                let mypageHeaders = res.data.data.headers[`${pageName}`];
                delete mypageHeaders.status;
                if (mypageHeaders.image) {
                    setPageImage(`${api_host}/${mypageHeaders.image?.image}`);
                }
                let obj = {...mypageHeaders}
                obj.image = mypageHeaders?.image?.id
                setModel(obj);
            }).catch(err =>
                console.log(err.response)
            )
        }
        // eslint-disable-next-line
    }, []);

    let [progress, setProgress] = useState(0)
    /* upload images */
    const token = `Bearer ` + localStorage.getItem('rh_Admin_Token');
    function addImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }

        axios.post(`${api_url}/admin/upload`, formData, config).then((res) => {
            let result = res.data.data;
            setPageImage(`${api_host}/${result.image}`)
            setProgress(0);
            let obj = { ...model };
            obj.image = result.id;
            setModel(obj)
            // image validation
            let classes = Array.from(pageImageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                pageImageRef.current.nextSibling.classList.add("d-none");
            }
        }).catch((err) => {
            console.log(err.response)
            setProgress(0);
        })
    }
    // onchange page headers
    const setFormData = (e, property, locale = false, language = null) => {
        setPageHeaderData(model, setModel, e, property, locale, language);

        if (e.target.name === 'image') {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setPageImage(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
            // image validation
            let classes = Array.from(pageImageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                pageImageRef.current.nextSibling.classList.add("d-none");
            }
        }
        // eslint-disable-next-line
    }

    // remove image
    function removeImgHandler() {
        setPageImage('');
        let obj = { ...model };
        obj.image = '';
        setModel(obj)
    }

    // onsubmit page headers
    const confirmPageHeader = async (e, model) => {
        e.preventDefault();
        let formData = new FormData();

        if (pageImage === '') {
            pageImageRef.current.nextSibling.classList.remove("d-none");
        }

        for (const key in model) {
            if (key === 'locales') {
                for (let i = 0; i < model.locales.length; i++) {
                    for (const key in model.locales[i]) {
                        if (model.locales[i][key] !== null) {
                            formData.append(`locales[${i}][${key}]`, model.locales[i][key]);
                        } else {
                            formData.append(`locales[${i}][${key}]`, '');
                        }
                    }
                }
            } else if (key === 'image') {
                if (pageImage !== `${api_host}/${model.image}`) {
                    formData.append("image", model.image);
                } else {
                    delete model.image;
                }
            } else {
                formData.append(key, model[key]);
            }
        }

        if (pageImage !== '') {
            setLoading(true);
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if (submitProps === "done") {
                setLoading(false);
            } else {
                setLoading(false);
            }
        }
    }

    return (
        <>
            <form onSubmit={(e) => confirmPageHeader(e, model)}>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='name_en' className='formLabel'>Name in English</label>
                    <input name="name" id='name_en' type='text' value={(model && model.locales && model.locales.length && filterLanguage(model.locales, "en").length) ? filterLanguage(model.locales, "en")[0].name : ""} onChange={(e) => setFormData(e, 'name', true, 'en')} maxLength='50' />
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='name_ar' className='formLabel'>Name in Arabic</label>
                    <input name="name" id='name_ar' type='text' value={(model && model.locales && model.locales.length && filterLanguage(model.locales, "ar").length) ? filterLanguage(model.locales, "ar")[0].name : ""} onChange={(e) => setFormData(e, 'name', true, 'ar')} maxLength='50' />
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='description_en' className='formLabel'>Description in English</label>
                    <textarea name="description" id='description_en' type='text' value={(model && model.locales && model.locales.length && filterLanguage(model.locales, "en").length) ? filterLanguage(model.locales, "en")[0].description : ""} onChange={(e) => setFormData(e, 'description', true, 'en')} maxLength='200' />
                </div>
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='description_ar' className='formLabel'>Description in Arabic</label>
                    <textarea name="description" id='description_ar' type='text' value={(model && model.locales && model.locales.length && filterLanguage(model.locales, "ar").length) ? filterLanguage(model.locales, "ar")[0].description : ""} onChange={(e) => setFormData(e, 'description', true, 'ar')} maxLength='200' />
                </div>

                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='favicon' className='formLabel'>image</label>
                    {!pageImage ?
                        <div>
                            <label ref={pageImageRef} htmlFor="uploadImage" className='position-relative'>
                                <div className='globalImageWrapper'>
                                    <input onChange={(e) => addImage(e)} type='file' id='uploadImage' name='image' className="d-none" />
                                    <img className='cloudIcon' src={uploadImage} alt="upload" />
                                    <span>Upload Image</span>
                                </div>
                            </label>
                            {progress !== 0 && (
                                <div className="progress mt-2 p-0" style={{ width: '11.25rem' }}>
                                    <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                        aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                        {progress}%
                                    </div>
                                </div>)}
                            <div className='validateToDelete text-danger d-none'>This field is required!</div>
                        </div>
                        :
                        <div className='globalImageWrapper position-relative'>
                            <Tooltip title='Remove Image'>
                                <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                    <img src={removeIcon} alt="delete" onClick={removeImgHandler} />
                                </label>
                            </Tooltip>
                            <div className='globalPreviewedImage' style={{ backgroundImage: `url(${pageImage})` }}></div>
                        </div>
                    }
                </div>
                <div>
                    {!loading ?
                        <button className='confirmBtn' type='submit'>update</button>
                        :
                        <button className='confirmBtn' disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            saving...
                        </button>
                    }
                </div>
            </form>
        </>
    )
}
