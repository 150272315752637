export const setFacilityData = (model, setModel, ele, property, locale=false, language=null) =>{
    let newData = Object.assign({}, model);

    if(locale){
        let existsLocales = model.locales.filter((i) => {
            return i.locale === language;
        })
        if(existsLocales.length){
            existsLocales[0][ele.target.name] = ele.target.value;
        }else{
            model.locales.push({'locale': language});
            model.locales.map((i) => {
                if(i.locale === language){
                    i[ele.target.name] = ele.target.value;
                }
                return true;
            });
        }
    }else{
        if (ele.target.name === 'image') {
            if(ele.target.files.length){
                newData['image'] = ele.target.files[0];
            }
        }else{
            newData[property] = ele.target.value;
        }
    }
    setModel(newData);
}