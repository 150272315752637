import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get types
export async function getTypes(){
    return await AxiosClone.get(`${api_url}/admin/types`)
}

//get one type
export async function getType(id){
    return await AxiosClone.get(`${api_url}/admin/types/${id}`)
}

//create type
export async function createNewType(data){
    return await AxiosClone.post(`${api_url}/admin/types`, data);
}

//edit type
export async function editType(data , id){
    return await AxiosClone.patch(`${api_url}/admin/types/${id}`, data)
}

//delete type
export async function deleteType(id){
    return await AxiosClone.delete(`${api_url}/admin/types/${id}`)
}