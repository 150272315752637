import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import TitleContext from '../../TitleContext';
import "./SubSide.scss"
import { api_basename, api_host } from './../../Configuration/Config';
import { filterLanguage } from '../../utilities/filterLang';

export default function Subside({items , btnText , btnUrl}) {
    let {Title} = useContext(TitleContext);

    return (
        <>
        <section className='subside_wrapper'>          
            <h5>{`${Title}`}</h5>
            <div>
                {items && items.map((item , index)=>(
                    <div key={index} className="oneItemWrapper">
                        {item.image ?
                            <img style={{"width" : "20px"}} src={item.image ? `${api_host}/${item.image?.image}` : ""} alt="icon"/>
                        : ""}
                        <NavLink className="arrayItems" to={`${api_basename}/dashboard/${btnUrl}/edit/${item.id}`}>{(item && item.locales && item.locales.length) ? filterLanguage(item.locales ,"en")[0].name : item.name}</NavLink>
                    </div>
                ))
                }
            </div>
            <NavLink className="addLabel" to={`${api_basename}/dashboard/${btnUrl}/add`}>{btnText}</NavLink>
        </section>
        </>
    )
}
