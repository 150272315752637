import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getType } from '../../../Apis/typesApi';
import "../../Facilities/AddEditCardSide.scss";
import {setData} from "./formHandler";
import formModel from './formModel';
import { filterLanguage } from '../../../utilities/filterLang';

export default function Form(props) {
    const [model, setModel] = useState(formModel);
    const [loading, setLoading] = useState(false);
    const {id} = useParams();

    useEffect(() => {
        if (id) {
            getType(id).then((res) => {
                let x = res.data.data;
                setModel({...x});
            })
        }else{
            setModel({
                locales: []
            })
        }
    }, [id]);

    const setFormData = (e, property , locale=false , language=null) => {
        setData(model, setModel, e, property , locale , language)
    }

    const confirmType = async (e , model)=>{
        setLoading(true);
        let submitProps = await props.onSubmit(e, model);
        if(submitProps === "done"){
            setLoading(false);
            //note to reset form
            if(!id){
                setModel({
                    locales: []
                });
            }
        }else{
            setLoading(false);
        }
    }
    
    return (
        <>
            <form onSubmit={(e) => confirmType(e, model)} id="addTypeForm">
                <div className='formInputsWrapper'>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_en' className='formLabel'>name in english</label>
                        <input type='text' name='name' id='name_en' placeholder='name in english' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"en").length) ? filterLanguage(model.locales ,"en")[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , 'en')} required pattern="^(.*)[^ ](.*)$" title="This field is required"/>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name_ar' className='formLabel'>name in arabic</label>
                        <input type='text' name='name' id='name_ar' placeholder='name in arabic' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,"ar").length) ? filterLanguage(model.locales ,"ar")[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , 'ar')} required pattern="^(.*)[^ ](.*)$" title="This field is required"/>
                    </div>
                </div>
                <div className='d-flex'>
                    {id ?
                        <div style={{"width" : "fitContent" , "cursor" : "pointer"}} data-bs-toggle="modal" data-bs-target='#deleteTypeModal' className='confirmBtn deleteBtn'>delete</div>
                    : ''}
                    {!loading ? 
                        <button className='confirmBtn' type='submit'>{id ? `save changes` : `add type`}</button>
                        :
                        <button className='confirmBtn' disabled>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            saving...
                        </button>
                    }
                </div>
            </form>
        </>
    )
}
