import React, { useContext, useEffect } from 'react';
import TitleContext from '../../../TitleContext';
import Form from './../../../Components/PageHeaderForm/PageHeaderForm';
import { getPageHeaders, updatePageHeaders } from '../../../Apis/pageHeadersApi';

export default function DevelopersPage() {
    let {changeTitle} = useContext(TitleContext);

    // get about us page headers and content ids
    useEffect(()=>{
        changeTitle(`developers`);
        getPageHeaders(16).then(res => {
            console.log(res.data.data)
        }).catch(err => 
            console.log(err.response)
        )
        // eslint-disable-next-line
    } , []);

    // onsubmit page headers
    const onSubmit = async (e, data) => {
        e.preventDefault();
        data.append("_method", "patch");
        //to send request
        return await updatePageHeaders(16 , data).then(res => {
            console.log(res.data.data);
            return "done";          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };
    return (
        <>
        <section className='componentGlobalWrapper aboutus'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>Page Header</h5>
            </div>
            <Form onSubmit={onSubmit} name={'about-us-page'} developers={true}/>
        </section>
        </>
    )
}
