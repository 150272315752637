import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get facilities
export async function getFacilities(){
    return await AxiosClone.get(`${api_url}/admin/facilities`)
}

//create facility
export async function createNewFacility(data){
    return await AxiosClone.post(`${api_url}/admin/facilities`, data);
}

//get one facility
export async function getFacility(id){
    return await AxiosClone.get(`${api_url}/admin/facilities/${id}`)
}

//edit facility
export async function editFacility(id,data){
    return await AxiosClone.post(`${api_url}/admin/facilities/${id}`, data)
}

//delete facility
export async function deleteFacility(id){
    return await AxiosClone.delete(`${api_url}/admin/facilities/${id}`)
}