import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get areas
export async function getAreas(obj){
    return await AxiosClone.get(`${api_url}/admin/areas?${obj}`)
}

//get areas at selected city
export async function getAreasByCity(city){
    return await AxiosClone.get(`${api_url}/admin/areas?city=${city}`)
}

//create area
export async function createNewArea(data){
    return await AxiosClone.post(`${api_url}/admin/areas`, data);
}

//delete area
export async function deleteArea(id){
    return await AxiosClone.delete(`${api_url}/admin/areas/${id}`)
}

//get one area
export async function getArea(id){
    return await AxiosClone.get(`${api_url}/admin/areas/${id}`)
}

//edit area
export async function editArea(id,data){
    return await AxiosClone.post(`${api_url}/admin/areas/${id}`, data)
}