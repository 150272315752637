import React, { useContext, useEffect, useState } from 'react';
import TitleContext from '../../../TitleContext';
import "../Aboutus/Aboutus.scss";
import Form from './../../../Components/PageHeaderForm/PageHeaderForm';
import EditorForm from './../../../Components/PageContentForm/PageContentForm';
import { getPageHeaderAndContent, updatePageHeaders } from '../../../Apis/pageHeadersApi';
import { updatePageContent } from '../../../Apis/pageContentApi';
import { useLocation } from 'react-router';

export default function TermsConditions() {
    let {changeTitle} = useContext(TitleContext);
    const [pageHeadersId ,setPageHeadersId] = useState({});
    const [pageContentId ,setPageContentId] = useState({});

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get terms and conditions page content and headers id
    useEffect(()=>{
        changeTitle(`terms and conditions`);
        getPageHeaderAndContent('terms-conditions-page').then(res => {
            let mypageHeadersId = res.data.data.headers['terms-conditions-page'].id;
            let mypageContentId = res.data.data.settings['terms-conditions'].id;
            setPageHeadersId(mypageHeadersId);
            setPageContentId(mypageContentId);
        }).catch(err => 
            console.log(err.response)
        )
        // eslint-disable-next-line
    } , []);
    
    // onsubmit page headers
    const onSubmit = async (e, data) => {
        e.preventDefault();
        data.append("_method", "patch");
        //to send request
        return await updatePageHeaders(pageHeadersId , data).then(res => {
            console.log(res.data.data);
            return "done";          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };

    // onsubmit page content
    const submitPageContent = async (e, data) =>{
        e.preventDefault();
        return await updatePageContent(pageContentId,{...data , "_method" : "patch"}).then(res=>{
            console.log(res.data.data);
            return "done"; 
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    }

    return (
        <>
        <section className='componentGlobalWrapper aboutus'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>page title</h5>
            </div>
            <Form onSubmit={onSubmit} name={'terms-conditions-page'}/>
        </section>

        <section className='componentGlobalWrapper aboutus'>
            <div className='mb-3'>
                <h5 className='pageSubtitle'>page content</h5>
            </div>

            <EditorForm onSubmit={submitPageContent} name={'terms-conditions-page'} objKey={'terms-conditions'}/>
        </section>
        </>
    )
}
