import React, { createContext } from 'react';
import { Route , Routes } from 'react-router';
import ProtectedRoute from './ProtectedRoute';
import Login from './Layout/Authentication/Login/Login.jsx';
import './App.css';
import { TitleContextProvider } from './TitleContext';
import ResetPassword from './Layout/Authentication/ResetPassword/ResetPassword';
import Dashboard from './Layout/Dashboard/Dashboard.jsx';
import { UnReadLeadsContextProvider } from './notificationContext';
import { PermissionsContextProvider } from './permissionsContext';
import { api_basename } from './Configuration/Config';
export const companyContext = createContext();

function App() {
  return (
    <React.Fragment>
      <PermissionsContextProvider>
        <TitleContextProvider>
          <UnReadLeadsContextProvider>
            <Routes>
              {/* ----- Admin Login ------*/}
              <Route path={`${api_basename}/`} element={<Login />} />  
              <Route path={`${api_basename}/reset-password`} end={true} element={<ResetPassword />} />  
              
              {/* protected routes */}
              <Route element={<ProtectedRoute/>}>
                <Route path={`${api_basename}/dashboard/*`} element={<Dashboard />} />
              </Route>
            </Routes>
          </UnReadLeadsContextProvider>
        </TitleContextProvider>
      </PermissionsContextProvider>
    </React.Fragment>
  );
}

export default App;
