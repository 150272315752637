import React, { useContext, useEffect } from 'react'
import { createNewProperty } from '../../../Apis/propertiesApi';
import TitleContext from '../../../TitleContext';
import Form from './Form';

export default function Add() {
    let {changeTitle} = useContext(TitleContext);
    useEffect(()=>{
        changeTitle(`add property`);
        // eslint-disable-next-line
    } , []);

    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request

        return await createNewProperty(data).then(res => {
            console.log(res)
            return 'done';          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };

    return (
        <>
            <Form onSubmit={onSubmit} />
        </>
    )
}
