import axios from "axios";

const AdminToken = `Bearer ` + localStorage.getItem('rh_Admin_Token');

export let AxiosClone = axios.create({
    headers : {
        Authorization: AdminToken,
        AccessControlAllowOrigin: '*'
    }
})
