import React, { useEffect, useRef, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../News.scss"
import uploadImage from "../../../assets/icons/uploadimage.svg";
import removeTag from "../../../assets/icons/removetag.svg";
import formModel from './formModel';
import { setBlogData } from './formHandler';
import { useParams, useNavigate, useLocation } from 'react-router';
import { filterLanguage } from './../../../utilities/filterLang';
import { api_host, api_basename, api_url } from './../../../Configuration/Config';
import { getBlog } from '../../../Apis/news';
import { textEditorValidation } from './../../../utilities/HandleTextEditorValidation';
import removeIcon from "../../../assets/icons/removeIcon.svg";
import Tooltip from '@material-ui/core/Tooltip';
import { supportedLanguages } from './../../../utilities/SupportedLanguages';
import axios from 'axios';

export default function Form(props) {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const { id } = useParams();
    let navigate = useNavigate()
    const [model, setModel] = useState(formModel);
    const [blogImage, setBlogImage] = useState('');
    const [loading, setLoading] = useState(false);
    /* const [errors ,setErrors]=useState({}) */
    const errorDivRef = useRef();

    /* const englishContentRef = useRef();
    const arabicContentRef = useRef(); */
    const imageRef = useRef();
    const metaKeywordsRef = useRef();
    const [keywords, setKeywords] = useState({});
    const [errors, setErrors] = useState({ image: '' })

    useEffect(() => {
        if (id) {
            getBlog(id).then((res) => {
                let x = res.data.data;
                setBlogImage(`${api_host}/${x?.image?.image}`);
                let data={...model}
                data=x
                if(x?.image){
                    data.image=x.image.id
                }
                setModel(data);
                if (x.status) {
                    document.getElementById('statusCheck').checked = true;
                }
                let thisEditors = { ...editors };
                let thiskeywords = { ...keywords };
                supportedLanguages.map((language) => {
                    let lang = filterLanguage(x.locales, language.slug);
                    if (lang.length) {
                        //set values at editor state
                        const contentDataStateAr = ContentState.createFromBlockArray(convertFromHTML(`${lang[0].description}`));
                        const editorDataState = EditorState.createWithContent(contentDataStateAr);
                        thisEditors[language.slug] = editorDataState;
                        
                        // set keywords
                        if (lang[0].meta_keywords && lang[0].meta_keywords !== 'null') {
                            thiskeywords[language.slug] = lang[0].meta_keywords.replace(/ /g, '').split(',');
                        }
                    }
                    return true
                })
                setKeywords(thiskeywords);
                setEditors(thisEditors);
            })
        } else {
            setModel({
                locales: [],
                image: '',
                status: 0,
                featured: 0
            })
        }
        // eslint-disable-next-line
    }, [id]);
    let [progress, setProgress] = useState(0)
    /* upload images */
    const token = `Bearer ` + localStorage.getItem('rh_Admin_Token');
    function addImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }

        axios.post(`${api_url}/admin/upload`, formData, config).then((res) => {
            let result = res.data.data;
            setBlogImage(`${api_host}/${result.image}`)
            setProgress(0);
            let obj = { ...model };
            obj.image = result.id;
            setModel(obj)
            // image validation
            let classes = Array.from(imageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                imageRef.current.nextSibling.classList.add("d-none");
            }
        }).catch((err) => {
            setProgress(0);
        })
    }

    // onchange
    const setFormData = (e, property, locale = false, language = null) => {
        setBlogData(model, setModel, e, property, locale, language);
        // if (e.target.name === 'image') {
        //     const reader = new FileReader();
        //     reader.addEventListener("load", () => {
        //         setBlogImage(reader.result);
        //     });
        //     reader.readAsDataURL(e.target.files[0]);
        //     //image validation
        //     let classes = Array.from(imageRef.current.nextSibling.classList);
        //     if (!classes.includes("d-none")) {
        //         imageRef.current.nextSibling.classList.add("d-none");
        //     }
        // }
        if (locale) {
            let classes = Array.from(errorDivRef.current.classList);
            if (!classes.includes('d-none')) {
                errorDivRef.current.classList.add('d-none');
            }
            let classesOfNextSibling = Array.from(e.target.nextSibling.classList);
            if (!classesOfNextSibling.includes("d-none")) {
                e.target.nextSibling.classList.add("d-none");
            }
        }
    }
    // remove image
    function removeImgHandler() {
        setBlogImage('');
        let obj = { ...model };
        obj.image = '';
        setModel(obj)
    }

    /***** text editor *****/
    let [editors, setEditors] = useState({})
    /* convert body to html */
    const convertContentToHTML = (state) => {
        return convertToHTML(state.getCurrentContent());
    }

    // onchange editor handler
    const handleEditorChange = (state, lang) => {
        textEditorValidation(lang)
        let thisEditors = { ...editors }
        if (!thisEditors[lang]) {
            thisEditors[lang] = EditorState.createEmpty()
        }
        thisEditors[lang] = state;
        setEditors(thisEditors);

        let m = { ...model }
        let existsLocales = m.locales.filter((i) => {
            return i.locale === lang;
        })
        if (existsLocales.length) {
            existsLocales[0]['description'] = convertContentToHTML(state);
        } else {
            m.locales.push({ 'locale': lang });
            m.locales.map((i) => {
                if (i.locale === lang) {
                    i['description'] = convertContentToHTML(state);
                }
                return true;
            });
        }
        setModel(m)
    }

    /****************** set keywords ************************/
    function AddKeywordHandler(e, lang) {
        let propKeyword = { ...keywords };
        /* let propKeywordAr = [...keywordsAr]; */
        if (e.target.value.length > 30) {
            if (e.keyCode !== 8) {
                e.preventDefault();
            }
        }
        if (e.keyCode === 32) {
            if (!propKeyword[lang]) {
                propKeyword[lang] = []
            }
            if (e.target.value.trim()) {
                propKeyword[lang] = [...propKeyword[lang], e.target.value.trim()];
                setKeywords(propKeyword)
                e.target.value = "";

                let m = { ...model }
                let existsLocales = m.locales.filter((i) => {
                    return i.locale === lang;
                })
                if (existsLocales.length) {
                    existsLocales[0]['meta_keywords'] = propKeyword[lang].join();
                } else {
                    m.locales.push({ 'locale': lang });
                    m.locales.map((i) => {
                        if (i.locale === lang) {
                            i['meta_keywords'] = propKeyword[lang].join();;
                        }
                        return true;
                    });
                }
            }
        }
    }

    /* to delete tag to tags array*/
    function removeKeywordHandler(i, lang) {
        let propKeyword = { ...keywords };
        propKeyword[lang] = propKeyword[lang].filter((key) => propKeyword[lang].indexOf(key) !== i);
        setKeywords(propKeyword)

        let m = { ...model }
        let existsLocales = m.locales.filter((i) => {
            return i.locale === lang;
        })
        if (existsLocales.length) {
            existsLocales[0]['meta_keywords'] = propKeyword[lang].join();
        } else {
            m.locales.push({ 'locale': lang });
            m.locales.map((i) => {
                if (i.locale === lang) {
                    i['meta_keywords'] = propKeyword[lang].join();;
                }
                return true;
            });
        }
    }

    // onsubmit
    const confirmBlog = async (e, model) => {
        e.preventDefault();
        let formData = new FormData();
        let count = 0;
        //validation
        let thisModel = { ...model }
        if (thisModel.locales.length) {
            supportedLanguages.map((language) => {
                let locale = filterLanguage(thisModel.locales, language.slug);
                if (locale.length) {
                    if (locale[0].name && locale[0].name.length >= 3 && locale[0].short_description && locale[0].short_description.length >= 3 && editors[language.slug]) {
                        count++;
                    } else {
                        if (!locale[0].name) {
                            let input = Array.from(document.querySelectorAll(`.requiredName${language.slug}`));
                            input[0].nextSibling.classList.remove("d-none");
                            input[0].nextSibling.innerText = "this field is required";
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX,
                                input[0].getBoundingClientRect().top + window.scrollY - 200);
                        } else if (locale[0].name.length < 3) {
                            let input = Array.from(document.querySelectorAll(`.requiredName${language.slug}`));
                            input[0].nextSibling.classList.remove("d-none");
                            input[0].nextSibling.innerText = "Name must be at least 3 characters";
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX,
                                input[0].getBoundingClientRect().top + window.scrollY - 200);
                        }
                        if (!locale[0].short_description) {
                            let input = Array.from(document.querySelectorAll(`.requiredShort_description${language.slug}`));
                            input[0].nextSibling.innerText = "this field is required";
                            input[0].nextSibling.classList.remove("d-none");
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX,
                                input[0].getBoundingClientRect().top + window.scrollY - 200);
                        } else if (locale[0].short_description.length < 3) {
                            let input = Array.from(document.querySelectorAll(`.requiredShort_description${language.slug}`));
                            input[0].nextSibling.innerText = "Short description must be at least 5 characters";
                            input[0].nextSibling.classList.remove("d-none");
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX,
                                input[0].getBoundingClientRect().top + window.scrollY - 200);
                        }
                        if (!locale[0].description) {
                            let input = Array.from(document.querySelectorAll(`.requiredDescription${language.slug}`));
                            input[0].nextSibling.classList.remove("d-none");
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX,
                                input[0].getBoundingClientRect().top + window.scrollY - 200);
                        }
                    
                    }
                }
                return true
            })
        } else {
            let classes = Array.from(errorDivRef.current.classList);
            if (classes.includes('d-none')) {
                errorDivRef.current.classList.remove('d-none');
                window.scrollTo(errorDivRef.current.getBoundingClientRect().left + window.scrollX,
                    errorDivRef.current.getBoundingClientRect().top + window.scrollY - 150);
            }
        }

        if (blogImage === '') {
            imageRef.current.nextSibling.classList.remove("d-none");
        }

        if (blogImage !== '' && (count === thisModel.locales.length)
        ) {
            setLoading(true);
            for (const key in model) {
                if (key === 'locales') {
                    for (let i = 0; i < model.locales.length; i++) {
                        for (const key2 in model.locales[i]) {
                            if (model.locales[i][key2] !== null && model.locales[i][key2] !== '' && model.locales[i][key2] !== 'null') {
                               
                                formData.append(`locales[${i}][${key2}]`, model.locales[i][key2]);
                            } else {
                                formData.append(`locales[${i}][${key2}]`, '');
                            }
                        }
                    }
                } else if (key === 'image') {
                    if (blogImage !== `${api_host}/${model.image}`) {
                        formData.append("image", model.image);
                    } else {
                        delete model.image;
                    }
                } else if (key === 'status') {
                    if (model.status === true || model.status === 1) {
                        formData.append("status", 1);
                    } else {
                        formData.append("status", 0)
                    }
                } else if (key === 'featured') {
                    if (model.featured === true || model.featured === 1) {
                        formData.append("featured", 1);
                    } else {
                        formData.append("featured", 0)
                    }
                } else {
                    formData.append(key, model[key]);
                }
            }
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if (submitProps === "done") {
                setLoading(false);
                navigate(`${api_basename}/dashboard/news/news-list`)
            } else {
                setErrors(submitProps);
                setLoading(false);
            }
        }
    }

    function preventEnter(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }
    return (
        <>
            <section className='componentGlobalWrapper addBlog'>
                {/* <div>
                    <button ref={languageRef} className='position-relative languageDropdown ms-auto'  data-bs-toggle="dropdown">
                        <img src={langIcon} alt="language icon"/>
                        <span>select language</span>
                        <img src={arrow} alt="arrow"/>
                    </button>
                    <div className="dropdown-menu custom-lang-dropdown">
                        <ul className="nav nav-items languageTabs d-flex flex-column">
                            {supportedLanguages.map((language, index)=>(
                                <li key={index} className='nav-item' onClick={()=>setSelectedLanguage(language.slug)}>
                                    <span className={`${index === 0 && `active`} dropdownItem px-2 py-1 w-100`} id={`${language.name}Form`} data-bs-toggle="pill" data-bs-target={`#pills-${language.name}`} type="button" role="tab" aria-controls={`pills-${language.name}`} {...(index === 0 ? {'aria-selected': "true"} :{})}>
                                        {language.name}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div> */}

                <form onKeyDown={preventEnter} onSubmit={(e) => confirmBlog(e, model)}>
                    <div className={`tab-content`} id="pills-tabContent">
                        <div>
                            {/* name */}
                            {supportedLanguages.map((language, index) => (
                                <div className='d-flex flex-column mb-4' key={index}>
                                    <label htmlFor='name' className='formLabel'>post name ({language.name})</label>
                                    <input maxLength='255' className={`requiredName${language.slug}`} name="name" id='name' value={(model && model.locales && model.locales.length && filterLanguage(model.locales, language.slug).length) ? filterLanguage(model.locales, language.slug)[0].name : ""} onChange={(e) => setFormData(e, 'name', true, language.slug)} type='text' />
                                    <div className='text-danger d-none validateToDelete'>
                                        This field is required!
                                    </div>
                                    {/* <div className='text-danger validateToDelete'>
                                    This field is required!
                                </div> */}
                                </div>
                            ))
                            }
                            {/* short description */}
                            {supportedLanguages.map((language, index) => (
                                <div className='d-flex flex-column mb-4' key={index}>
                                    <label htmlFor='short_description' className='formLabel'>short description ({language.name})</label>
                                    <textarea maxLength='255' minLength='5' className={`requiredShort_description${language.slug}`} name="short_description" id='short_description'
                                        value={(model && model.locales && model.locales.length && filterLanguage(model.locales, language.slug).length) ? filterLanguage(model.locales, language.slug)[0].short_description : ""} onChange={(e) => setFormData(e, 'short_description', true, language.slug)} />
                                    <div className='text-danger d-none validateToDelete'>
                                        This field is required!
                                    </div>
                                </div>
                            ))
                            }
                            {/* content */}
                            {supportedLanguages.map((language, index) => (
                                <div className='d-flex flex-column mb-4' key={index}>
                                    <label htmlFor='content' className='formLabel'>content ({language.name})</label>
                                    <div className={`requiredDescription${language.slug}`} >
                                        <Editor
                                            editorState={editors[language.slug]}
                                            onEditorStateChange={(state) => handleEditorChange(state, language.slug)}
                                        />
                                    </div>
                                    <div className='text-danger d-none mb-1 validateToDelete'>
                                        This field is required!
                                    </div>
                                </div>
                            ))
                            }
                            {/** keywords note  ***/}
                            {supportedLanguages.map((language, index) => (
                                <div className='d-flex flex-column mb-4' key={index}>
                                    <label htmlFor='metaKeyword' className='formLabel'>meta keywords ({language.name})</label>
                                    <div ref={metaKeywordsRef} className={`inputGroup LikeInput d-flex align-items-center mw-100 `}>
                                        <div className="">
                                            {

                                                keywords[language.slug] && keywords[language.slug].map((keyword, index) => (
                                                    <div key={index} className={`tagContainer`}>
                                                        {keyword}
                                                        <img src={removeTag} onClick={() => removeKeywordHandler(index, language.slug)} className='removeTag' alt='remove' />
                                                    </div>
                                                ))
                                            }
                                            <input onKeyDown={(e) => AddKeywordHandler(e, language.slug)} maxLength='30' type="text" name="metaKeyword" className={`tagInput border-0`} id="tags" />
                                        </div>
                                    </div>
                                    {/*  <div ref={metaKeywordsRef} className={`inputGroup LikeInput d-flex align-items-center mw-100 `}>
                                    <div className="">
                                        {
                                            keywords[language.slug] && keywords[language.slug].map((keyword,index)=>(
                                                <div key={index} className={`tagContainer`}>
                                                    {keyword}
                                                    <img src={removeTag} onClick={() => removeKeywordHandler(index ,language.slug)} className='removeTag' alt='remove' />
                                                </div>
                                            ))
                                        }
                                        <input onKeyDown={(e)=>AddKeywordHandler(e,language.slug)} type="text" name="metaKeyword" className={`tagInput border-0`}  id="tags" />
                                    </div>
                                </div> */}
                                    <div className='text-danger d-none validateToDelete'>
                                        This field is required!
                                    </div>
                                </div>
                            ))
                            }
                            {/** meta description ***/}
                            {supportedLanguages.map((language, index) => (
                                <div className='d-flex flex-column mb-4' key={index}>
                                    <label htmlFor='meta_description' className='formLabel'>meta description ({language.name})</label>
                                    <textarea maxLength='255' name="meta_description" id='meta_description'
                                        value={(model && model.locales && model.locales.length && filterLanguage(model.locales, language.slug).length) ? filterLanguage(model.locales, language.slug)[0].meta_description : ""} onChange={(e) => setFormData(e, 'meta_description', true, language.slug)} />
                                    <div className='text-danger d-none validateToDelete'>
                                        This field is required!
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </div>

                    {/** post image **/}
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='favicon' className='formLabel'>image</label>
                        {!blogImage ?
                            <div>
                                <label ref={imageRef} htmlFor="uploadImage" className='position-relative'>
                                    <div className='globalImageWrapper'>
                                        <input onChange={(e) => addImage(e)} type='file' id='uploadImage' name='image' className="d-none" />
                                        <img className='cloudIcon' src={uploadImage} alt="upload" />
                                        <span>Upload Image</span>
                                    </div>
                                {progress !== 0 && (
                                <div className="progress mt-2 p-0 w-`00" >
                                    <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                        aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                        {progress}%
                                    </div>
                                </div>)}
                                </label>

                                <div className='validateToDelete text-danger d-none'>This field is required!</div>
                            </div>
                            :
                            <div className='globalImageWrapper position-relative'>
                                <Tooltip title='Remove Image'>
                                    <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                        <img src={removeIcon} alt="delete" onClick={removeImgHandler} />
                                    </label>
                                </Tooltip>
                                <div className='globalPreviewedImage' style={{ backgroundImage: `url(${blogImage})` }}></div>
                            </div>
                        }
                        {errors && errors.image && <div className='text-danger validateToDelete'>{errors.image}</div>}
                    </div>

                    {/** activate and feature post ***/}
                    <div className='d-flex mb-5'>
                        <div className='me-5 d-flex align-items-center'>
                            <label className="switch mb-0">
                                <input type="checkbox"
                                    id='statusCheck' onChange={(e) => setFormData(e, 'status')} />
                                <span className="slider round" />
                            </label>
                            <label htmlFor='active_status' className='formLabel mb-0 ms-3'>publish post</label>
                        </div>

                        {/* <div className='d-flex align-items-center'>                        
                            <label className="switch mb-0">
                                <input id='featuredCheck' type="checkbox" onChange={(e) => setFormData(e, 'featured')} />
                                <span className="slider round" />
                            </label>
                            <label htmlFor='active_feature' className='formLabel mb-0 ms-3'>feature post</label>
                        </div> */}
                    </div>

                    <div ref={errorDivRef} className="row mb-4 d-none">
                        <div className='alert alert-danger validateToDelete'>
                            you should enter the blog data in one language at least!
                        </div>
                    </div>
                    <div>
                        {
                            !loading ?
                                <button className='confirmBtn' type='submit'>{id ? 'save changes' : 'add'}</button>
                                :
                                <button className='confirmBtn' disabled>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    saving...
                                </button>
                        }
                    </div>
                </form>
            </section>
        </>
    )
}
