import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import TitleContext from '../../TitleContext';
import "../News/News.scss";
import edit_icon from "../../assets/icons/edit.svg";
import delete_icon from "../../assets/icons/delete.svg";
import { deleteMember, getMembers } from '../../Apis/teamMembersApi';
import Tooltip from '@material-ui/core/Tooltip';
import { api_basename, api_host } from '../../Configuration/Config';
import DeleteModal from '../../utilities/deleteModal';
import { Link } from 'react-router-dom';
import { filterLanguage } from '../../utilities/filterLang';
import permissionsContext from '../../permissionsContext';

export default function MembersList() {
    let {Permissions} = useContext(permissionsContext);
    let {changeTitle} = useContext(TitleContext);
    const { pathname } = useLocation();
    let [allMembers , setAllMembers] = useState([]);
    const [memberId ,setMemberId] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // get member id
    function getId(id){
        setMemberId(id)
    }

    // get all members
    function getAllMembers(){
        let myallMembers = [...allMembers];
        getMembers().then(res => {
            myallMembers = res.data.data;
            setAllMembers(myallMembers);
        }).catch(err => 
            console.log(err))
    }

    useEffect(()=>{
        changeTitle(`our team ` ,Permissions && Permissions.member && Permissions.member.create && {url: 'users/add-member', text: 'Add New member'});
        getAllMembers();
        // eslint-disable-next-line
    } , []);

    // delete member
    const onSubmitDelete = async ()=>{
        return await deleteMember(memberId).then(res =>{
            getAllMembers();
            return "done";
        }).catch(err => {
            return err.response.data.errors ;
        })
    }


    return (
        <>
            {Permissions && Permissions.member && <section>
                <div style={{"borderRadius" : "8px" , "border" : "1px solid #DFE0EB"}}>
                <div className='tableWrapper'>
                    <table className="w-100 table table-striped border-0">
                        <thead>
                            <tr>
                                <th>photo</th>
                                <th>name</th>
                                <th>title</th>
                                <th>actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allMembers && allMembers.map((member , index)=> (
                                <tr key={index}>
                                    <td style={{"width" : "28%"}}>
                                        <div className='partnerLogoBoredr'>
                                            <img className='me-0 userPhoto' style={{"objectFit" : "cover"}} src={`${api_host}/${member.image}`} alt="member"/>
                                        </div>
                                    </td>
                                    <td style={{"width" : "30%"}}>{(member && member.locales && filterLanguage(member.locales ,"en").length)? filterLanguage(member.locales ,"en")[0].name : ""}</td>
                                    <td style={{"width" : "30%"}}>{(member && member.locales && filterLanguage(member.locales ,"en").length)? filterLanguage(member.locales ,"en")[0].job_title : ""}</td>
                                    <td>
                                        <div>
                                            {Permissions.member.edit && <Tooltip title="edit">
                                                <Link to={`${api_basename}/dashboard/users/update-member/${member.id}`}>
                                                    <img src={edit_icon} alt="edit"/>
                                                </Link>
                                            </Tooltip>}
                                            {Permissions.member.delete && <Tooltip title="delete">
                                                <img src={delete_icon} alt="delete" data-bs-toggle="modal" data-bs-target='#deleteModal' onClick={()=>getId(member.id)}/>
                                            </Tooltip>}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                    <DeleteModal onSubmitDelete={onSubmitDelete} name='admin'></DeleteModal>
                </div>
            </section>}
        </>
    )
}
