import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { deleteType, editType } from '../../../Apis/typesApi';
import Form from './Form';
import { api_basename } from '../../../Configuration/Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function Edit(props) {
    const { id } = useParams();
    const [deleteLoading, setDeleteLoading] = useState(false);
    let [validateDelete , setValidateDelete] = useState("");
    let closeDeleteTypeModalRef = useRef();
    let deleteModalRef = useRef();
    let navigate = useNavigate();
    let [errors, setErrors] = useState([]);

    const onSubmit = async (e, data) => {
        e.preventDefault();
        //to send request
        return await editType(data , id).then(res => {
            props.getAll();
            console.log(res.data.data);
            return "done";          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };

    // delete type
    function deleteValidation(e){
        validateDelete = e.target.value;
        setValidateDelete(validateDelete);
        if(validateDelete === "DELETE"){
            deleteModalRef.current.classList.add("d-none");
        }
    }

    function confirmDeleteType(e){
        e.preventDefault();
        if (validateDelete === "DELETE"){
            setDeleteLoading(true);
            // send request
            deleteType(id).then(res =>{
                setDeleteLoading(false);
                closeDeleteTypeModalRef.current.click();
                document.getElementById('deleteTypeForm').reset();
                navigate(`${api_basename}/dashboard/property-settings/types/add`);
                props.getAll(); 
                console.log(res.data.data);
            }).catch(err => {
                setDeleteLoading(false);
                let x = err.response.data.errors;
                setErrors(x);
            })
        }else{
            deleteModalRef.current.classList.remove("d-none");
        }
    }

    function resetInput(){
        deleteModalRef.current.classList.add("d-none");
        document.getElementById('deleteTypeForm').reset();
        setValidateDelete("");
        setErrors([]);
    }

    return (
        <>
            <section className='actionCardSideWrapper'>
                <div>
                    <h5>edit type</h5>
                </div>
                <Form onSubmit={onSubmit}></Form>
            </section>

            {/**** delete type modal ****/}
            <div className="modal fade" id="deleteTypeModal" tabIndex={-1} aria-labelledby="deleteTypeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div ref={closeDeleteTypeModalRef} className="closeModalIcon" data-bs-dismiss="modal" aria-label="Close" onClick={resetInput}>
                                <FontAwesomeIcon icon={faXmark}/>
                            </div>
                        </div>
                        <div className="modal-body">
                            <form id="deleteTypeForm" onSubmit={confirmDeleteType}>
                                <div className='formInputsWrapper'>
                                    <p>Do you want to delete this type ?</p>
                                    <div className='mb-4'>
                                        <div className="form-floating">
                                            <input onChange={deleteValidation} className='w-100 form-control' name="validate" id='floatingInput' type='text' placeholder='delete' required/>
                                            <label htmlFor="floatingInput">please type <span>"DELETE"</span> to confirm</label>
                                        </div>
                                        <div ref={deleteModalRef} className='validateToDelete d-none text-danger mt-1'>
                                            please type "DELETE" in capital letters.
                                        </div>
                                        {(errors && errors.length)? <div className="validateToDelete text-danger mt-1">{errors[0]}</div> : ''}
                                    </div>
                                    {/*** modal footer ***/}
                                    <div className='d-flex justify-content-end'>
                                        <div className='cancelBtn' onClick={resetInput} data-bs-dismiss="modal">cancel</div>
                                        {!deleteLoading ? 
                                            <button className={validateDelete === "DELETE" ? `deleteBtn` : `confirmBtn`} type='submit'>delete</button>
                                            :
                                            <button className='confirmBtn deleteBtn' disabled>
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                deleting...
                                            </button>
                                        }                                            
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
