import React, { useContext, useEffect } from 'react';
import TitleContext from '../../TitleContext';
import { useLocation } from 'react-router';
import { useState } from 'react';
import{getScripts, updateScript} from '../../Apis/scripts.js';

export default function Scripts() {
    let {changeTitle} = useContext(TitleContext);
    const [scripts ,setScripts] = useState([]);
    const [loaders ,setLoaders] = useState([]);
    useEffect(()=>{
        changeTitle(`script page`);
        getAllScripts()
        // eslint-disable-next-line
    } , []);

    function getAllScripts(){
        let thisLoaders = [...loaders];
        getScripts().then((res)=>{
            setScripts(res.data.data);
            res.data.data.map((script)=>{
                thisLoaders.push(false);
                return true;
            }); 
            setLoaders(thisLoaders);
            console.log(thisLoaders)
            //console.log(res.data.data);
        })
    }


    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    function updateHandler(e,id){
        let thisScripts = [...scripts]
        thisScripts.map((s)=>{
            if(s.id === id){
                let i = scripts.indexOf(s)
                thisScripts[i][e.target.name] = e.target.value;
            }
            return true
        })
        setScripts(thisScripts);
    }

    function onSubmit(e,id,index){
        e.preventDefault();
        let flags = [...loaders];
        flags[index] = true;
        setLoaders(flags);
        let script =  scripts.filter((s)=>(s.id === id))[0];
        updateScript(id,{...script,'_method' : 'patch'}).then((res=>{
            let flags = [...loaders];
            flags[index] = false;
            setLoaders(flags);
            let thisScripts = [...scripts];
            thisScripts.map((script)=>{
                if(script.id === id){
                    let i = thisScripts.indexOf(script)
                    thisScripts[i] = res.data.data;
                }
                return true
            })
            setScripts(thisScripts);
        })).catch((err)=>{
            let flags = [...loaders];
            flags[index] = false;
            setLoaders(flags);
            console.log(err.response.data)
        })
        console.log(script)
    }

    function toggleStatus(e,id){
        let thisScripts = [...scripts];
        thisScripts.map((script)=>{
            if(script.id === id){
                let i = thisScripts.indexOf(script);
                if(e.target.checked){
                    thisScripts[i].status = true;
                }else{
                    thisScripts[i].status = false;
                }
            }
            return true
        })
        setScripts(thisScripts)
    }

    return (
        <>
            <section>
                <div className='homeSectionWrapper' style={{'minHeight':'100vh'}}>
                    {scripts && scripts.map((script ,i)=>(
                        <div key={i}>
                            <div className='sectionTitle mb-2 px-4 py-3'>
                                <div className='d-flex align-items-center'>                        
                                    <label className="switch mb-0">
                                        <input type="checkbox" onChange={(e)=>toggleStatus(e,script.id)} name={script.id} checked={script.status}/>
                                        <span className="slider round"/>
                                    </label>
                                    <h5 className='formLabel mb-0 ms-3'>{script.name}</h5>
                                </div>
                            </div>
                            <form className='p-4' onSubmit={(e)=>onSubmit(e,script.id ,i)}>
                                {script.type === 2 ?
                                    <div>
                                        <div className='d-flex flex-column mb-4'>
                                            <label htmlFor='key' className='formLabel'>key</label>
                                            <input value={script && script.key ? script.key : ''} name="key" id='key' type='text' onChange={(e)=>updateHandler(e,script.id)} />
                                        </div>
                                        <div className='d-flex flex-column mb-4'>
                                            <label htmlFor='secret' className='formLabel'>secret</label>
                                            <input value={script && script.secret ? script.secret : ''} name="secret" id='secret' type='text' onChange={(e)=>updateHandler(e,script.id)} />
                                        </div>
                                    </div>
                                    :
                                    <div className='d-flex flex-column mb-4'>
                                        <label htmlFor='script' className='formLabel'>script</label>
                                        <textarea value={script && script.script ? script.script : ''} name="script" id='script' onChange={(e)=>updateHandler(e,script.id)} />
                                    </div>
                                }
                                { loaders && !loaders[i] ?   
                                    <button className='confirmBtn' style={{"borderRadius" : "0"}} type='submit'>Update 
                                    </button>
                                    :
                                    <button className='confirmBtn' style={{"borderRadius" : "0"}} disabled>
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Saving...
                                    </button>
                                }
                            </form>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}
