import React from 'react'
import { createNewSlider } from '../../../../Apis/homeApi';
import Form from './Form'

export default function AddSlider() {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        console.log(data)
        //to send request
        return await createNewSlider(data).then(res => {
            console.log(res.data.data);
            return "done";          
        }).catch(err =>{
            console.log(err.response);
            return err.response.data.errors;
        })
    };
    
    return (
        <Form onSubmit={onSubmit}></Form>
    )
}
