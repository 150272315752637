import { AxiosClone } from './../AxiosClone';
import {api_url} from '../Configuration/Config';

//get members
export async function getMembers(){
    return await AxiosClone.get(`${api_url}/admin/members`);
}

//get one member
export async function getMember(id){
    return await AxiosClone.get(`${api_url}/admin/members/${id}`);
}

//delete member
export async function deleteMember(id){
    return await AxiosClone.delete(`${api_url}/admin/members/${id}`);
}

//create member
export async function createNewMember(data){
    return await AxiosClone.post(`${api_url}/admin/members`, data);
}

//edit member
export async function editMember(id ,data){
    return await AxiosClone.post(`${api_url}/admin/members/${id}`, data);
}
