import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { getDeveloperDetails } from "../../../Apis/developersApi";
import { api_basename, api_host, api_url } from "../../../Configuration/Config";
import { filterLanguage } from "../../../utilities/filterLang";
import uploadImage from "../../../assets/icons/uploadimage.svg";
import removeIcon from "../../../assets/icons/removeIcon.svg";
import Tooltip from '@material-ui/core/Tooltip';
import { supportedLanguages } from './../../../utilities/SupportedLanguages';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from 'draft-convert';
import { textEditorValidation } from './../../../utilities/HandleTextEditorValidation';
import { setDeveloperData } from "./formHandler";
import axios from "axios";

export default function DeveloperForm(props) {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const { id } = useParams();
    let navigate = useNavigate()

    const [loading, setLoading] = useState(false);
    // let [developerDetails, setdeveloperDetails] = useState({});
    const [developerImage, setdeveloperImage] = useState('');
    // let [developerImageObj, setdeveloperImageObj] = useState('');
    let [editors, setEditors] = useState({})

    const [errors, setErrors] = useState({ image: '' })
    const errorDivRef = useRef();

    let imageRef = useRef();
    const [model, setModel] = useState({
        locales: [],
        image: '',
        status: 0
    })
    // get category data
    useEffect(() => {
        if (id) {
            getDeveloperDetails(id).then((res) => {
                let developer = res.data.data;
                setdeveloperImage(`${api_host}/${developer.image?.image}`);
                let dev = developer
                dev.image = developer?.image?.id
                setModel(dev)
                if (developer.status) {
                    document.getElementById('statusCheck').checked = true;
                }
                let thisEditors = { ...editors };
                supportedLanguages.map((language) => {
                    let lang = filterLanguage(dev.locales, language.slug);
                    if (lang.length) {
                        //set values at editor state
                        const contentDataStateAr = ContentState.createFromBlockArray(convertFromHTML(`${lang[0].description}`));
                        const editorDataState = EditorState.createWithContent(contentDataStateAr);
                        thisEditors[language.slug] = editorDataState;

                    }
                    return true
                })
                setEditors(thisEditors);
            })

        }
        //eslint-disable-next-line
    }, [id]);

    let [progress, setProgress] = useState(0)
    /* upload images */
    const token = `Bearer ` + localStorage.getItem('rh_Admin_Token');
    function addImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const config = {
            onUploadProgress: data => {
                setProgress(Math.round((100 * data.loaded) / data.total))
            }, headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                AccessControlAllowOrigin: '*',
                lang: 'en',
                country: 'uae',
            }
        }

        axios.post(`${api_url}/admin/upload`, formData, config).then((res) => {
            let result = res.data.data;
            setdeveloperImage(`${api_host}/${result.image}`)
            setProgress(0);
            let obj = { ...model };
            obj.image = result.id;
            setModel(obj)
            // image validation
            let classes = Array.from(imageRef.current.nextSibling.classList);
            if (!classes.includes("d-none")) {
                imageRef.current.nextSibling.classList.add("d-none");
            }
        }).catch((err) => {
            console.log(err.response)
            setProgress(0);
        })
    }
    /* convert body to html */
    const convertContentToHTML = (state) => {
        return convertToHTML(state.getCurrentContent());
    }
    // onchange editor handler
    const handleEditorChange = (state, lang) => {
        textEditorValidation(lang)
        let thisEditors = { ...editors }
        if (!thisEditors[lang]) {
            thisEditors[lang] = EditorState.createEmpty()
        }
        thisEditors[lang] = state;
        setEditors(thisEditors);

        let m = { ...model }
        let existsLocales = m.locales.filter((i) => {
            return i.locale === lang;
        })
        if (existsLocales.length) {
            existsLocales[0]['description'] = convertContentToHTML(state);
        } else {
            m.locales.push({ 'locale': lang });
            m.locales.map((i) => {
                if (i.locale === lang) {
                    i['description'] = convertContentToHTML(state);
                }
                return true;
            });
        }
        setModel(m)
    }

    // onsubmit
    const confirmDeveloper = async (e, model) => {
        e.preventDefault();
        let formData = new FormData();
        let count = 0;
        //validation
        let thisModel = { ...model }
        if (thisModel.locales.length) {
            supportedLanguages.map((language) => {
                let locale = filterLanguage(thisModel.locales, language.slug);
                if (locale.length) {
                    if (locale[0].name && locale[0].name.length >= 3 && editors[language.slug]) {
                        count++;
                    } else {
                        if (!locale[0].name) {
                            let input = Array.from(document.querySelectorAll(`.requiredName${language.slug}`));
                            input[0].nextSibling.classList.remove("d-none");
                            input[0].nextSibling.innerText = "this field is required";
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX,
                                input[0].getBoundingClientRect().top + window.scrollY - 200);
                        } else if (locale[0].name.length < 3) {
                            let input = Array.from(document.querySelectorAll(`.requiredName${language.slug}`));
                            input[0].nextSibling.classList.remove("d-none");
                            input[0].nextSibling.innerText = "Name must be at least 3 characters";
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX,
                                input[0].getBoundingClientRect().top + window.scrollY - 200);
                        }
                        if (!locale[0].description) {
                            let input = Array.from(document.querySelectorAll(`.requiredDescription${language.slug}`));
                            input[0].nextSibling.classList.remove("d-none");
                            window.scrollTo(input[0].getBoundingClientRect().left + window.scrollX,
                                input[0].getBoundingClientRect().top + window.scrollY - 200);
                        }

                    }
                }
                return true
            })
        } else {
            let classes = Array.from(errorDivRef.current.classList);
            if (classes.includes('d-none')) {
                errorDivRef.current.classList.remove('d-none');
                window.scrollTo(errorDivRef.current.getBoundingClientRect().left + window.scrollX,
                    errorDivRef.current.getBoundingClientRect().top + window.scrollY - 150);
            }
        }

        if (developerImage === '') {
            imageRef.current.nextSibling.classList.remove("d-none");
        }
        if (developerImage !== '' && (count === thisModel.locales.length)
        ) {
            setLoading(true);
            for (const key in model) {
                if (key === 'locales') {
                    for (let i = 0; i < model.locales.length; i++) {
                        for (const key2 in model.locales[i]) {
                            if (model.locales[i][key2] !== null && model.locales[i][key2] !== '' && model.locales[i][key2] !== 'null') {
                                console.log(model.locales[i][key2]);
                                formData.append(`locales[${i}][${key2}]`, model.locales[i][key2]);
                            } else {
                                formData.append(`locales[${i}][${key2}]`, '');
                            }
                        }
                    }
                }
                else if (key === 'status') {
                    if (model.status === true || model.status === 1) {
                        formData.append("status", 1);
                    } else {
                        formData.append("status", 0)
                    }
                } else {
                    formData.append(key, model[key]);
                }
            }
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            // send request
            let submitProps = await props.onSubmit(e, formData);
            if (submitProps === "done") {
                setLoading(false);
                navigate(`${api_basename}/dashboard/developers/developers-list`)
            } else {
                setErrors(submitProps);
                setLoading(false);
            }
        }
    }

    // onchange
    const setFormData = (e, property, locale = false, language = null) => {
        setDeveloperData(model, setModel, e, property, locale, language);

        if (locale) {
            let classes = Array.from(errorDivRef.current.classList);
            if (!classes.includes('d-none')) {
                errorDivRef.current.classList.add('d-none');
            }
            let classesOfNextSibling = Array.from(e.target.nextSibling.classList);
            if (!classesOfNextSibling.includes("d-none")) {
                e.target.nextSibling.classList.add("d-none");
            }
        }
    }
    // remove image
    function removeImgHandler() {
        setdeveloperImage('');
        let obj = { ...model };
        obj.image = '';
        setModel(obj)
    }
    return (
        <section className='componentGlobalWrapper addBlog'>
            <form onSubmit={(e) => confirmDeveloper(e, model)}>
                <div className={`tab-content`} id="pills-tabContent">
                    <div>
                        {/* name */}
                        {supportedLanguages.map((language, index) => (
                            <div className='d-flex flex-column mb-4' key={index}>
                                <label htmlFor='name' className='formLabel'>post name ({language.name})</label>
                                <input maxLength='255' className={`requiredName${language.slug}`} name="name" id='name' value={(model && model.locales && model.locales.length && filterLanguage(model.locales, language.slug).length) ? filterLanguage(model.locales, language.slug)[0].name : ""} onChange={(e) => setFormData(e, 'name', true, language.slug)} type='text' />
                                <div className='text-danger d-none validateToDelete'>
                                    This field is required!
                                </div>
                            </div>
                        ))
                        }
                        {/* content */}
                        {supportedLanguages.map((language, index) => (
                            <div className='d-flex flex-column mb-4' key={index}>
                                <label htmlFor='content' className='formLabel'>content ({language.name})</label>
                                <div className={`requiredDescription${language.slug}`} >
                                    <Editor
                                        editorState={editors[language.slug]}
                                        onEditorStateChange={(state) => handleEditorChange(state, language.slug)}
                                    />
                                </div>
                                <div className='text-danger d-none mb-1 validateToDelete'>
                                    This field is required!
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </div>

                {/** post image **/}
                <div className='d-flex flex-column mb-4'>
                    <label htmlFor='favicon' className='formLabel'>image</label>
                    {!developerImage ?
                        <div>
                            <label ref={imageRef} htmlFor="uploadImage" className='position-relative'>
                                <div className='globalImageWrapper'>
                                    <input onChange={(e) => addImage(e)} type='file' id='uploadImage' name='image' className="d-none" />
                                    <img className='cloudIcon' src={uploadImage} alt="upload" />
                                    <span>Upload Image</span>
                                </div>
                            </label>
                            {progress !== 0 && (
                                <div className="progress mt-2 p-0" style={{ width:'11.25rem' }}>
                                    <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                        aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: progress + "%" }}>
                                        {progress}%
                                    </div>
                                </div>)}
                            <div className='validateToDelete text-danger d-none'>This field is required!</div>
                        </div>
                        :
                        <div className='globalImageWrapper position-relative'>
                            <Tooltip title='Remove Image'>
                                <label htmlFor='viewImage' className={`globalRemoveImage`}>
                                    <img src={removeIcon} alt="delete" onClick={removeImgHandler} />
                                </label>
                            </Tooltip>
                            <div className='globalPreviewedImage' style={{ backgroundImage: `url(${developerImage})` }}></div>
                        </div>
                    }
                    {errors && errors.image && <div className='text-danger validateToDelete'>{errors.image}</div>}
                </div>

                {/** activate and feature post ***/}
                <div className='d-flex mb-5'>
                    <div className='me-5 d-flex align-items-center'>
                        <label className="switch mb-0">
                            <input type="checkbox"
                                id='statusCheck' onChange={(e) => setFormData(e, 'status')} />
                            <span className="slider round" />
                        </label>
                        <label htmlFor='active_status' className='formLabel mb-0 ms-3'>publish developer</label>
                    </div>
                </div>

                <div ref={errorDivRef} className="row mb-4 d-none">
                    <div className='alert alert-danger validateToDelete'>
                        you should enter the data in one language at least!
                    </div>
                </div>
                <div>
                    {
                        !loading ?
                            <button className='confirmBtn' type='submit'>{id ? 'save changes' : 'add'}</button>
                            :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                saving...
                            </button>
                    }
                </div>
            </form>
        </section>
    )
}