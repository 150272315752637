import React from 'react'
import { createNewAdmin } from '../../../Apis/adminApis';
import Form from './Form'

export default function AddUser() {
    const onSubmit = async (e, data) => {
        e.preventDefault();
        console.log(data)
        //to send request
        return await createNewAdmin(data).then(res => {
            console.log(res.data.data);
            return 'done';          
        }).catch(err =>{
            return err.response.data.errors;
        })
    };

    return (
        <Form onSubmit={onSubmit} requireds='required'></Form>
    )
}
