import React from 'react';
import "../AddRole.scss";
import { useParams } from 'react-router';
import { filterLanguage } from './../../../utilities/filterLang';
import formModel from './formModel';
import { useState } from 'react';
import { setRoleData } from './formHandler';
import { useEffect } from 'react';
import { deleteRole, getRole, getRolePermissions } from '../../../Apis/rolesApi';
import DeleteModal from './../../../utilities/deleteModal';
import { useContext } from 'react';
import PermissionsContext from '../../../permissionsContext';
/* import { api_basename } from './../../../Configuration/Config'; */

export default function Form(props){
    let { id } = useParams();
    let {Permissions} = useContext(PermissionsContext);
    const [model, setModel] = useState(formModel);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    /* const navigate = useNavigate(); */
    const [allpermissions, setallpermissions] = useState();
    //const [role, setRole] = useState({});

    function getAllPermissions(){
        getRolePermissions().then((res) => {
            let roles = { ...allpermissions }
            roles = res.data.data
            setallpermissions(roles)
            console.log(roles);
        })
    }
    useEffect(() => {
        getAllPermissions()
        //eslint-disable-next-line
    }, [])
    
    useEffect(() => {
        if(id){
            getRole(id).then((res)=>{
                console.log(res.data.data);
                let thisModel = {...model};
                thisModel.locales = [];
                thisModel.permissions = []
                thisModel.locales = res.data.data.locales;
                res.data.data.permissions.map((p)=>{
                    thisModel.permissions.push(p.id);
                    // console.log(document.getElementById(p.id));
                    // document.getElementById(p.id).checked = true;
                    return true
                });
                setModel(thisModel);
                console.log(thisModel)
            })
        }else{
            setModel({
                    locales: [],
                    permissions:[]
                }
            )
        }
        // eslint-disable-next-line
    },[id])

    // onchange
    const setFormData = (e, property , locale=false , language=null) => {
        setRoleData(model, setModel, e, property , locale , language);
        console.log(model)
    }

    // onsubmit
    async function confirmRole(e){
        e.preventDefault();
        let thisModel = {...model};
        setLoading(true);
        // send request
        let submitProps = await props.onSubmit(e, thisModel);
        if(submitProps === 'done'){
            if(!id){
            setModel({
                locales: [],
                permissions:[]
            }
            )
        }
            setLoading(false);
        }else{
            setLoading(false);
            setErrors(submitProps);
            console.log(submitProps)
        }
    }

    function preventEnter(e) {
        if(e.keyCode===13){
            e.preventDefault();
        }
    }

    function selectAllHandler(e){
        let thisModel = {...model,permissions:[]};
        if(e.target.checked){
            allpermissions && Object.keys(allpermissions).map((section) => {
                allpermissions[section].map((permission) => {
                    thisModel.permissions.push(permission.id)
                    return true
                })
                return true
            })
        }else{
            thisModel.permissions = []
        }
        setModel(thisModel);
    }

    const onSubmitDelete = async ()=>{
        return await deleteRole(id).then((res) =>{
            props.getAll();
            //console.log(props)
            return 'deleteRole';
        }).catch((err) => {
            console.log(err.response);
            return err.response.data.errors;
        })
    }
    return (
        <>
            <form onSubmit={confirmRole} onKeyDown={preventEnter}>
                <div className='d-flex justify-content-between align-items-center mb-5'>
                    <h5>{id ? `update role` : `add new role`}</h5>
                    <div className='d-flex'>
                        {id && Permissions?.role?.delete  ?
                            <div style={{"width" : "fitContent" , "cursor" : "pointer"}} data-bs-toggle="modal" data-bs-target='#deleteModal' className='confirmBtn deleteBtn'>delete</div>
                        : ''}
                        { !loading ?
                            <button className='confirmBtn' type='submit'>{id ? `save changes` : `add role`}</button>
                            :
                            <button className='confirmBtn' disabled>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                saving...
                            </button>
                        }
                    </div>
                </div>
                {/*** role title and description ***/}
                <div style={{"width" : "50%"}}>
                    <div className='d-flex flex-column mb-4'>
                        <label htmlFor='name' className='formLabel'>name</label>
                        <input name="name" id='name' type='text' placeholder='name' value={(model && model.locales && model.locales.length && filterLanguage(model.locales ,'en').length) ? filterLanguage(model.locales ,'en')[0].name : ""} onChange={(e) => setFormData(e, 'name' , true , 'en')} minLength={3} maxLength={20} required/>
                    </div>
                </div>

                {/**** permissions ****/}
                <div className='permissionsWrapper'>
                    <div className='mb-5'>
                        <h5 >permissions</h5>
                        {errors?.permissions && <div className='validateToDelete text-danger'>
                            {errors?.permissions}
                        </div>}
                    </div>
                    <div className='checkLabelWrapper'>
                        <input className='permissionsCheckbox' onChange={selectAllHandler} type="checkbox" id="all" name="all" defaultValue="Bike" />
                        <label htmlFor="all">select all</label>
                    </div>
                    <div>
                        {
                            allpermissions && Object.keys(allpermissions).map((section) => {
                                return (
                                <div className='mt-5' key={section}>
                                        <p className='mb-2'>{section}</p>
                                    <div className='row row-cols-4 m-0 p-0' key={section}>
                                            {allpermissions[section].map((permission) => {
                                            return (
                                                <div className='col mt-2' key={permission.name}>
                                                    <div key={permission.id} className='checkLabelWrapper'>
                                                        <input className='permissionsCheckbox' type="checkbox" name="view" value={permission.id} id={permission.id} onChange={(e) => setFormData(e, 'permissions')} {...(model.permissions.includes(Number(permission.id)) ? {'checked': true} :{}) }/>
                                                        <label htmlFor={permission.id}>
                                                            {permission.locales && permission.locales.length && filterLanguage(permission.locales ,'en')[0].name}
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                            })}
                                    </div>
                                </div>)
                            })
                            }
                    </div>
                </div>
            </form>
            <DeleteModal onSubmitDelete={onSubmitDelete} name='role'></DeleteModal>
        </>
    )
}
