import React, { useContext, useState, useEffect } from 'react'
import './Header.scss';
import notification from '../../assets/icons/notification.svg';
import man from '../../assets/icons/user.png';
import TitleContext from '../../TitleContext';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import Tooltip from "@material-ui/core/Tooltip";
import { faCog, faSignOutAlt, faUser, faEnvelopeOpen, faBars } from '@fortawesome/free-solid-svg-icons';
import { FaRegBell } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Echo from 'laravel-echo';
// eslint-disable-next-line
import Pusher from "pusher-js";
import { notification_key, api_host, api_basename } from './../../Configuration/Config';
import { getAllNotifications, readNotifications } from '../../Apis/header';
import UnReadLeadsContext from '../../notificationContext';
import { getAdminProfileInfo } from '../../Apis/profileApi';

export default function Header() {
    let { Title, btn } = useContext(TitleContext);
    let { changeUnReadPropertyRequest, changeUnReadContactUs } = useContext(UnReadLeadsContext);
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const [notificationAlert, setNotificationAlert] = useState();
    let [obj, setObj] = useState({});
    /*     const AdminName = localStorage.getItem('alomran_Admin_Name'); */
    const [profile, setProfile] = useState({});

    useEffect(() => {
        getAdminInfo();

        getAllNotifications().then(res => {
            setNotifications(res.data.data.notifications);
            setObj(res.data.data);
            //console.log(res.data.data)
        });
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: notification_key,
            cluster: `mt1`,
            forceTLS: true
        });
        var channel = window.Echo.channel('admin-notifications');
        channel.listen('.admin-notifications', function (data) {
            getNotifications();
            setNotificationAlert(true);
            console.log('listen');
        });
        // eslint-disable-next-line
    }, [])

    function getNotifications() {
        getAllNotifications().then(res => {
            setNotifications(res.data.data.notifications);
            setObj(res.data.data);
        })
    }

    function clickNotificationBtn() {
        setNotificationAlert(false);
    }

    function readNotificationHandler(no) {
        let notification = no;
        readNotifications(notification.id).then((res) => {
            //console.log(res.data.data)
        });
        getNotifications()
        if (notification.is_contact) {
            navigate(`${process.env.REACT_APP_BASENAME}/dashboard/leads/contact-requests`);
        } else {
            navigate(`${process.env.REACT_APP_BASENAME}/dashboard/leads/leads-requests`)
        }
    }

    function getAdminInfo() {
        getAdminProfileInfo().then((res) => {
            setProfile(res.data.data.admin);
            //console.log(res.data.data.admin)
        })
            ;

    }

    useEffect(() => {
        if (obj && obj.inactive_enquiry_count) {
            changeUnReadPropertyRequest(obj.inactive_enquiry_count);
        }
        // eslint-disable-next-line
    }, [obj]);

    useEffect(() => {
        if (obj && obj.inactive_contact_count) {
            changeUnReadContactUs(obj.inactive_contact_count);
        }
        // eslint-disable-next-line
    }, [obj]);

    function logoutHandler() {
        localStorage.removeItem("rh_Admin_Token");
        localStorage.removeItem("alomran_Admin_Name");
        navigate(`/${process.env.REACT_APP_BASENAME}`);
    }

    function goToProfile() {
        navigate(`${api_basename}/dashboard/settings/account`);
    }
    function goToSettings() {
        navigate(`${api_basename}/dashboard/settings/security`);
    }

    return (
        <div className='headerWraper'>
            <div className='d-flex align-items-center'>
                <h3 className='mb-0'>{`${Title}`}</h3>
                {btn && <NavLink className="headerBtn" to={btn.url}>{btn.text}</NavLink>}
            </div>
            <div className='d-flex justify-content-between align-items-center my-3 my-md-0'>
                <div className='dropdown'>
                    <Tooltip title='Notifications'>
                        <button className='position-relative me-4 notification' data-bs-toggle="dropdown" onClick={clickNotificationBtn} >
                            <img src={notification} alt="notification" />
                            {notificationAlert && <span className="position-absolute notificationDot">
                            </span>}
                        </button>
                    </Tooltip>
                    <ul className="dropdown-menu dropdown-menu-end notificationDropdown " aria-labelledby="dropdownMenuButton1">
                        <li className='notificationHeader'>Notifications</li>
                        {notifications && notifications.map((no, index) => (
                            <div key={index} className={`linkNotification ${!no.status && `unreadNotification`}`}
                                onClick={() => readNotificationHandler(no)}>
                                <li className='align-items-start d-flex'>
                                    <span className="dropdownItem d-flex justify-content-between pe-2">
                                        <span className="icon">
                                            {no.is_contact ?
                                                <FontAwesomeIcon icon={faEnvelopeOpen} />
                                                :
                                                <FaRegBell />
                                            }

                                        </span>
                                    </span>
                                    <span className="dropdownItem">
                                        <div className='d-flex flex-column'>
                                            <span className='user'>{no.is_contact ? `Contact Us`
                                                : `Property Request`}</span>
                                            <span className='issue'>{no.message}</span>
                                        </div>
                                        <span className='hour'>
                                            {moment(no.created_at).format('DD MMM')}
                                        </span>
                                    </span>
                                </li>
                            </div>
                        ))
                        }
                    </ul>
                </div>

                <span className='userName'>{profile && profile.first_name}</span>

                {profile && <div>
                    <span className='userImg' data-bs-toggle="dropdown" style={{ "cursor": "pointer" }}>
                        {profile?.image
                            ?
                            <img src={`${api_host}/${profile.image?.image}`} alt='user' />
                            :
                            <img src={`${man}`} alt='user' />
                        }
                    </span>
                    <ul className="dropdown-menu py-0">
                        <li onClick={goToProfile} className="py-0"><span className="dropdown-item"><FontAwesomeIcon className='me-2' icon={faUser} />Profile</span></li>
                        <li onClick={goToSettings} className="py-0"><span className="dropdown-item"><FontAwesomeIcon icon={faCog} className='me-2' />Settings</span></li>
                        <li onClick={logoutHandler} className="py-0"><span className="dropdown-item"><FontAwesomeIcon icon={faSignOutAlt} className='me-2' />logout</span></li>

                    </ul>
                </div>}
            </div>
            <button className="btn sideBarToggler d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">  <FontAwesomeIcon icon={faBars} /></button>
        </div>
    )
}
